<div class="col-md-12 center" >
  <h5 style="margin-left: 30px">{{'start.title' | translate}}</h5>
  <ng-container class="row" *ngFor="let group of companies">
    <div class="col-md-12">
      <button class="btn btn-primary" style="width: 100%; margin: 5px" (click)="setCompany(group)">{{group.name}}</button>
    </div>
  </ng-container>


</div>
