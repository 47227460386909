<div class="combobox-container" (clickOutside)="closeDropdown()">
  <input
    type="text"
    [readonly]="this.readonly"
    [(ngModel)]="inputValue"
    (click)="this.filterOptions()"
    (input)="filterOptions()"
    (keydown.enter)="handleEnter()"
    placeholder="Type to search..."
    class="form-control form-control-sm"
  />

  <div class="dropdown" *ngIf="isDropdownOpen">
    <div
      *ngFor="let option of filteredOptions"
      (click)="selectOption(option[this.keyToSearch])"
      class="dropdown-item"
    >
      {{ option[this.keyToSearch] }}
    </div>
  </div>
</div>
