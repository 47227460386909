import { NgModule } from '@angular/core';
import { PricingRoutingModule } from './pricing-routing.module';

@NgModule({
  imports: [
    PricingRoutingModule
  ]
})

export class PricingModule { }
