<app-modal #logbookFormModal [title]="'navigation.addressDeliveryDocumentRequirementTitle' | translate" [maxWidth]="'900px'" (confirmed)="this.confirmAndEmitForm()" (cancelled)="this.closeAndReset()">
  <div class="modal-body">
    @for (doc of this.formGroup.controls; track $index) {

      @if ((doc.value.deliveryDocumentRequirementsId == 2 || doc.value.id == 2) || (doc.value.deliveryDocumentRequirementsId == 3 || doc.value.id == 3)) {
          <h4 *ngIf="doc.value.id == 2">{{ 'address.deliveryDocumentRequirements.IDTF' | translate }}</h4>
          <h4 *ngIf="doc.value.id == 3">{{ 'address.deliveryDocumentRequirements.IDTFClean' | translate }}</h4>
          <form [formGroup]="doc" class="mb-5">
            <div class="row">
              <div class="col-6 form-group">
                <label for="type">{{ 'logbooktype.metadata.idtfnumber' | translate }}</label>
                <select [name]="'idtfitem' + $index" [id]="'idtfitem' + $index" (change)="this.selectIDTFItem($event, $index)" (select)="this.selectIDTFItem($event, $index)" class="form-select">
                  <option value="" [selected]="doc.value.id != 2 || (doc.value.IDTFId == '' || doc.value.IDTFId == null)" disabled></option>
                  <option *ngFor="let item of this.idtfItems" [value]="item | json" [selected]="item.idtf === doc.value.IDTFId">{{ item.idtf }} - {{ item.productName }}</option>
                </select>
              </div>
            </div>


            <div *ngIf="(doc.value.manual && doc.value.id == 2)" class="row">
              <div class="col-6 form-group">
                <label for="type">{{ 'logbooktype.metadata.cleanliness' | translate }}</label>
                <select class="form-select" (change)="this.selectCleaningLevel($event, $index)">
                  <option disabled [selected]="!this.returnCleaningValue(doc)" value=""></option>
                  <option
                    *ngFor="let cleaningItem of this.cleanlinessArray; let i = index"
                    [disabled]="this.hideCleaningLevels(cleaningItem, doc, $index)"
                    [selected]="this.returnCleaningValue(doc, cleaningItem)"
                  >
                    {{ cleaningItem }}
                  </option>
                </select>
              </div>
            </div>
            <div *ngIf="(doc.value.id != 2)" class="row">
              <div class="col-6 form-group">
                <label for="type">{{ 'logbooktype.metadata.cleanliness' | translate }}</label>
                <select class="form-select" (change)="this.selectCleaningLevel($event, $index)">
                  <option disabled [selected]="!this.returnCleaningValue(doc)" value=""></option>
                  <option
                    *ngFor="let cleaningItem of this.cleanlinessArray; let i = index"
                    [disabled]="this.hideCleaningLevels(cleaningItem, doc, $index)"
                    [selected]="this.returnCleaningValue(doc, cleaningItem)"
                  >
                    {{ cleaningItem }}
                  </option>
                </select>
              </div>
            </div>
          </form>
        }


        @if (doc.value.deliveryDocumentRequirementsId == 4 || doc.value.id == 4) {
          <h4 *ngIf="doc.value.id == 4">{{ 'address.deliveryDocumentRequirements.commDoc' | translate }}</h4>

          <!-- If checking if documentid is 4 (meaning it's a document) -->
           <form [formGroup]="this.tradeDocumentObj">
            <div class="row">
              <div class="col-6 form-group">
                <label for="">{{ 'address.deliveryDocument.commDoc.categoryTitle' | translate }}</label>
                <select class="form-select" formControlName="category" (change)="this.updateTradeDocumentMeta($index)">
                  <option disabled selected value=""></option>
                  <option value="1">{{ 'address.deliveryDocument.commDoc.categoryOption' | translate }} 1</option>
                  <option value="2">{{ 'address.deliveryDocument.commDoc.categoryOption' | translate }} 2</option>
                  <option value="3">{{ 'address.deliveryDocument.commDoc.categoryOption' | translate }} 3</option>
                </select>
              </div>
            </div>

            <div class="row">
              <div class="col-6 form-group">
                <label for="">{{ 'address.deliveryDocument.commDoc.amountTitle' | translate }}</label>
                <input formControlName="amountKg" type="number" class="form-control" (input)="this.updateTradeDocumentMeta($index)" (blur)="this.updateTradeDocumentMeta($index)">
              </div>
            </div>

            <div class="row">
              <div class="col-6 form-group">
                <label for="">{{ 'address.deliveryDocument.commDoc.treatment' | translate }}</label>
                <input formControlName="treatment" type="text" class="form-control" (input)="this.updateTradeDocumentMeta($index)" (blur)="this.updateTradeDocumentMeta($index)">
              </div>
            </div>

            <div class="row">
              <div class="col-6 form-group">
                <label for="">{{ 'address.deliveryDocument.commDoc.senderCustomer' | translate }}</label>
                <ejs-combobox
                  #senderCustomerId
                  formControlName="senderCustomerId"
                  [id]='"senderCustomerId" + $index'
                  [dataSource]='this.customers'
                  [fields]="{ id: 'id', text: 'name', value: 'id' }"
                  [allowFiltering]="true"
                  [value]="this.tradeDocumentObj.value.senderCustomerId"
                  (select)="this.updateTradeDocumentMeta($index)"
                  (blur)="this.updateTradeDocumentMeta($index)"
                ></ejs-combobox>
              </div>

              <div class="col-6 form-group">
                <label for="">{{ 'address.deliveryDocument.commDoc.receiverCustomer' | translate }}</label>
                <ejs-combobox
                  #receiverCustomerId
                  formControlName="receiverCustomerId"
                  [id]='"senderCustomerId" + $index'
                  [dataSource]='this.customers'
                  [fields]="{ id: 'id', text: 'name', value: 'id' }"
                  [allowFiltering]="true"
                  [value]="this.tradeDocumentObj.value.receiverCustomerId"
                  (select)="this.updateTradeDocumentMeta($index)"
                  (blur)="this.updateTradeDocumentMeta($index)"
                ></ejs-combobox>
              </div>
            </div>
          </form>
        }



        <!-- <div class="row">
          <div class="col-6 form-group">
            <label for="type">{{ 'logbooktype.metadata.deliveryDocumentRequirementsId' | translate }}</label>
            <input type="number" class="form-control" formControlName="deliveryDocumentRequirementsId" readonly>
          </div>

          <div class="col-6 form-group">
            <label for="name">
              {{ 'logbooktype.metadata.deliveryDocumentRequirementsMeta' | translate }}
            </label>
            <input type="text" class="form-control" formControlName="deliveryDocumentRequirementsMeta" [min]="0">
          </div>
        </div> -->

    }
  </div>
</app-modal>
