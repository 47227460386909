import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { faCheck, faPencilAlt, faTimes, faTrash } from '@fortawesome/free-solid-svg-icons';
import { DateValidator } from 'src/app/form-validators/date-validator';
import { TimeValidator } from 'src/app/form-validators/time-validator';
import { CreateUserEventModel, UpdateUserEventModel, UserEventModel } from 'src/app/users/shared/user.model';
import { SharedService } from '../services/shared.service';
import { DatePipe } from "@angular/common";
import { UserService } from 'src/app/users/shared/user.service';

@Component({
  selector: 'app-user-event-widget',
  templateUrl: './user-event-widget.component.html'
})

export class UserEventWidgetComponent implements OnInit {
  @Input()
  event: UserEventModel | undefined;

  @Input()
  createMode = false;

  @Output()
  cancelCreate = new EventEmitter();

  @Output()
  createEvent = new EventEmitter();

  @Output()
  updateEvent = new EventEmitter();

  @Output()
  deleteEvent = new EventEmitter();

  @Output()
  cancelEvent = new EventEmitter();

  formGroup: UntypedFormGroup;
  editFormGroup: UntypedFormGroup;

  @Input()
  includeAccept = true;

  types: any[] = [];

  faEdit = faPencilAlt;
  faTrash = faTrash;
  faAccept = faCheck;
  faCancel = faTimes;

  readOnly = true;

  allDay: boolean = false;
  endDate: string | undefined;


  constructor(private formBuilder: UntypedFormBuilder, private sharedService: SharedService, private datePipe: DatePipe, private userService: UserService) {
    this.formGroup = this.formBuilder.group({
      title: '',
      description: ['', [Validators.required, Validators.maxLength(255),Validators.required]],
      startDate: ['', [Validators.required]],
      endDate: ['', [DateValidator('startDate')]],
      startTime: '',
      endTime: ['', TimeValidator('startDate', 'startTime', 'endDate')],
      type: [undefined, [Validators.required]]
    });
    this.editFormGroup = this.formBuilder.group({
      id: '',
      title: '',
      description: ['', [Validators.required, Validators.maxLength(255)]],
      startDate: ['', [Validators.required]],
      endDate: ['', [DateValidator('startDate')]],
      startTime: '',
      endTime: ['', TimeValidator('startDate', 'startTime', 'endDate')],
      type: [undefined, [Validators.required]]
    });
    userService.getUserEvents().subscribe((events) => {
      this.types = events;
    });
  }


  ngOnInit(): void {
    //this.types = ['BusinessRelated', 'Holidays', 'Personal'];
    if (this.event) {
      this.allDay = new Date(this.event?.startDate).getHours().toString() == '0'
        && new Date(this.event?.startDate).getMinutes().toString() == '0'
        && new Date(this.event?.endDate).getHours().toString() == '0'
        && new Date(this.event?.endDate).getMinutes().toString() == '0' ? true : false;
      this.editFormGroup.patchValue({
        id: this.event.id,
        title: this.event?.description,
        description: this.event?.description,
        startDate: this.datePipe.transform(this.event?.startDate, 'yyyy-MM-dd'),
        endDate: this.datePipe.transform(this.event?.endDate, 'yyyy-MM-dd'),
        startTime: `${this.getFormattedTime(new Date(this.event?.startDate).getHours())}:${this.getFormattedTime(new Date(this.event?.startDate).getMinutes())}`,
        endTime: `${this.getFormattedTime(new Date(this.event?.endDate).getHours())}:${this.getFormattedTime(new Date(this.event?.endDate).getMinutes())}`,
        type: this.event?.typeId,
        allDay: this.allDay
      });
    }
  }


  changeAllDay(ev: any) {
    this.allDay = ev.target.checked;
    if (this.allDay) {
      if (this.formGroup.get('startDate')?.value !== '') {
        let date = new Date(this.formGroup.get('startDate')?.value);
        if (this.formGroup.get('endDate')?.value === '' || new Date(this.formGroup.get('endDate')?.value) <= date) {
          this.formGroup.patchValue({
            endDate: this.datePipe.transform(date.setDate(date.getDate() + 1), 'yyyy-MM-dd'),
            startTime: `${this.getFormattedTime(date.getUTCHours())}:${this.getFormattedTime(date.getUTCMinutes())}`,
            endTime: `${this.getFormattedTime(date.getUTCHours())}:${this.getFormattedTime(date.getUTCMinutes())}`,
          });
        } else {
          this.formGroup.patchValue({
            startTime: `${this.getFormattedTime(date.getUTCHours())}:${this.getFormattedTime(date.getUTCMinutes())}`,
            endTime: `${this.getFormattedTime(date.getUTCHours())}:${this.getFormattedTime(date.getUTCMinutes())}`,
          });
        }
      }
    }
  }


  setAllDayEndDate(ev: any) {
    if (this.allDay && ev.target.value) {
      this.endDate = ev.target.value;
      let date = new Date(ev.target.value);
      if (this.formGroup.get('endDate')?.value === '') {
        this.formGroup.patchValue({
          endDate: this.datePipe.transform(date.setDate(date.getDate() + 1), 'yyyy-MM-dd'),
          startTime: `${this.getFormattedTime(new Date(ev.target.value).getUTCHours())}:${this.getFormattedTime(new Date(ev.target.value).getUTCMinutes())}`,
          endTime: `${this.getFormattedTime(new Date(ev.target.value).getUTCHours())}:${this.getFormattedTime(new Date(ev.target.value).getUTCMinutes())}`,
        });
      } else {
        if (new Date(this.formGroup.get('endDate')?.value) <= date) {
          // here could be calculated datediff.
          this.formGroup.patchValue({
            endDate: this.datePipe.transform(date.setDate(date.getDate() + 1), 'yyyy-MM-dd'),
            startTime: `${this.getFormattedTime(new Date(ev.target.value).getUTCHours())}:${this.getFormattedTime(new Date(ev.target.value).getUTCMinutes())}`,
            endTime: `${this.getFormattedTime(new Date(ev.target.value).getUTCHours())}:${this.getFormattedTime(new Date(ev.target.value).getUTCMinutes())}`,
          });
        } else {
          this.formGroup.patchValue({
            startTime: `${this.getFormattedTime(new Date(ev.target.value).getUTCHours())}:${this.getFormattedTime(new Date(ev.target.value).getUTCMinutes())}`,
            endTime: `${this.getFormattedTime(new Date(ev.target.value).getUTCHours())}:${this.getFormattedTime(new Date(ev.target.value).getUTCMinutes())}`,
          });
        }
      }
    }
  }


  changeAllDayEdit(ev: any) {
    this.allDay = ev.target.checked;
    if (this.allDay) {
      if (this.editFormGroup.get('startDate')?.value !== '') {
        let date = new Date(this.editFormGroup.get('startDate')?.value);
        if (this.editFormGroup.get('endDate')?.value === '' || new Date(this.editFormGroup.get('endDate')?.value) <= date) {
          this.editFormGroup.patchValue({
            endDate: this.datePipe.transform(date.setDate(date.getDate() + 1), 'yyyy-MM-dd'),
            startTime: `${this.getFormattedTime(date.getUTCHours())}:${this.getFormattedTime(date.getUTCMinutes())}`,
            endTime: `${this.getFormattedTime(date.getUTCHours())}:${this.getFormattedTime(date.getUTCMinutes())}`,
          });
        } else {
          this.editFormGroup.patchValue({
            startTime: `${this.getFormattedTime(date.getUTCHours())}:${this.getFormattedTime(date.getUTCMinutes())}`,
            endTime: `${this.getFormattedTime(date.getUTCHours())}:${this.getFormattedTime(date.getUTCMinutes())}`,
          });
        }
      }
    }
  }


  setAllDayEndDateEdit(ev: any) {
    if (this.allDay && ev.target.value) {
      this.endDate = ev.target.value;
      let date = new Date(ev.target.value);
      if (this.editFormGroup.get('endDate')?.value === '' || new Date(this.editFormGroup.get('endDate')?.value) <= date) {
        this.editFormGroup.patchValue({
          endDate: this.datePipe.transform(date.setDate(date.getDate() + 1), 'yyyy-MM-dd'),
          startTime: `${this.getFormattedTime(new Date(ev.target.value).getUTCHours())}:${this.getFormattedTime(new Date(ev.target.value).getUTCMinutes())}`,
          endTime: `${this.getFormattedTime(new Date(ev.target.value).getUTCHours())}:${this.getFormattedTime(new Date(ev.target.value).getUTCMinutes())}`,
        });
      } else {
        this.editFormGroup.patchValue({
          startTime: `${this.getFormattedTime(new Date(ev.target.value).getUTCHours())}:${this.getFormattedTime(new Date(ev.target.value).getUTCMinutes())}`,
          endTime: `${this.getFormattedTime(new Date(ev.target.value).getUTCHours())}:${this.getFormattedTime(new Date(ev.target.value).getUTCMinutes())}`,
        });
      }
    }
  }


  getFormattedTime(time: number): string {
    return ('0' + time).slice(-2);
  }


  updateTitle(ev: any) {
    this.formGroup.patchValue({
      title: ev.target.value,
    });
  }


  create(): void {
    // test allday check
    this.createEvent.emit(this.formGroup.value as UpdateUserEventModel)
    this.formGroup.reset();
  }


  update(): void {
    this.readOnly = !this.readOnly;
    // test allday check

    this.updateEvent.emit(this.editFormGroup.value as UpdateUserEventModel);
  }


  delete(): void {
    this.deleteEvent.emit(this.event);
  }


  cancel(): void {
    this.readOnly = !this.readOnly;
    this.cancelEvent.emit();
  }


  getValue(type: string): string {
    return `user.event.${type.toLowerCase()}`;
  }
}
