<div *ngIf="createMode" class="bg-light">
    <form [formGroup]="formGroup" class="col-md-12">
      <div class="col-md-12" style="margin-bottom: 15px">
        <div class="row">

          <div class="col-md-12">
            <label>{{'resource.event.description' | translate}}</label>
            <span *ngIf="formGroup.controls['description'].hasError('required')" class="required-text">
              {{'message.required' | translate}}
            </span>
            <textarea autosize id="description" type="text" formControlName="description" class="form-control form-control-sm"></textarea>
          </div>
          <div class="col-md-6">
            <label>{{'resource.event.type' | translate}}
              <span *ngIf="formGroup.controls['type'].hasError('required')" class="required-text">
                {{'message.required' | translate}}
              </span></label>
            <select  type="text" formControlName="type" class="form-select form-select-sm">
              <option *ngFor="let type of types; let i = index" [value]="type">{{'resource.event.types.' + type.toLowerCase() | translate}}</option>
            </select>
          </div>
          <div class="col-md-6">
            <label class="form-check-label" for="allDay">{{'user.event.allDay' | translate}}</label>
            <input type="checkbox" class="form-spec-input" (change)="changeAllDay($event)" [checked]="allDay" />
          </div>
          <div class="col-md-4">
            <label for="startDate">{{'resource.event.startDate' | translate}}
              <span *ngIf="formGroup.controls['startDate'].hasError('required')" class="required-text">
                {{'message.required' | translate}}
              </span>
              <span *ngIf="formGroup.controls['startDate'].hasError('wrong-date')" class="required-text">
                {{'message.wrong-date' | translate}}
              </span></label>
            <input id="startDate" type="date" formControlName="startDate" class="form-control form-control-sm">
          </div>
          <div class="col-md-2">
            <label for="startTime">{{'resource.event.startTime' | translate}}
              <span *ngIf="formGroup.controls['startTime'].hasError('wrong-time')" class="required-text">
                {{'message.wrong-date' | translate}}
              </span></label>
            <input id="startTime" type="time" formControlName="startTime" [readOnly]="allDay" class="form-control form-control-sm">
          </div>
          <div class="col-md-4">
            <label for="endDate">{{'resource.event.endDate' | translate}}
              <span *ngIf="formGroup.controls['endDate'].hasError('required')" class="required-text">
                {{'message.required' | translate}}
              </span>
              <span *ngIf="formGroup.controls['endDate'].hasError('wrong-date')" class="required-text">
                {{'message.wrong-date' | translate}}
              </span></label>
            <input id="endDate" type="date" formControlName="endDate"  class="form-control form-control-sm">
          </div>
          <div class="col-md-2">
            <label for="endTime">{{'resource.event.endTime' | translate}}
              <span *ngIf="formGroup.controls['endTime'].hasError('wrong-time')" class="required-text">
                {{'message.wrong-date' | translate}}
              </span></label>
            <input id="endTime" type="time" formControlName="endTime" [readOnly]="allDay" class="form-control form-control-sm">
          </div>
        </div>
      </div>
    </form>
    <div class="btn-container">
      <app-btn-accept-widget *ngIf="includeAccept" (cancel)="cancelCreate.emit()" (accept)="create()" [formGroup]="formGroup"></app-btn-accept-widget>
    </div>
  </div>

  <div class="col-md-12" *ngIf="event" style="padding: 2px,10px,2px,10px; ">
    <div class="row" *ngIf="readOnly">

        <!-- <div class="col-md-6">
          <label>{{'backVehicle.title' | translate}}</label>
          <input class="form-control form-control-sm" [readOnly]="readOnly" value="{{event.title}}" [(ngModel)]="event.title">
        </div> -->

        <div class="col-md-11">
          <label>{{'resource.event.description' | translate}}</label>
          <input class="form-control form-control-sm" [readOnly]="readOnly" [value]="event.description" [(ngModel)]="event.description">
        </div>

        <div class="col-md-1 text-end">
          <button type="button" class="btn btn-primary btn-sm" (click)="readOnly = ! readOnly"
                  style="margin-top: 30px;"
                  placement="left"
                  ngbTooltip="{{'btn.edit' | translate}}">
              <fa-icon [icon]="faEdit"></fa-icon>
          </button>
        </div>


        <div class="col-md-6">
          <label>{{'resource.event.type' | translate}}</label>
          <input class="form-control form-control-sm" [readOnly]="readOnly" value="{{'resource.event.types.' + event.type.toString().toLowerCase() | translate}}">
        </div>
        <div class="col-md-6">

        </div>

        <div class="col-md-4">
          <label>{{'resource.event.startDate' | translate}}</label>
          <input type="date" class="form-control form-control-sm" [readOnly]="readOnly" [value]="event.actualStartDate | date: 'yyyy-MM-dd'">
        </div>
        <div class="col-md-2">
          <label>{{'resource.event.startTime' | translate}}</label>
          <input type="time" class="form-control form-control-sm" [readOnly]="readOnly" [value]="event.actualStartDate | date: 'HH:mm'">
        </div>
        <div class="col-md-4">
          <label>{{'resource.event.endDate' | translate}}</label>
          <input type="date" class="form-control form-control-sm" [readOnly]="readOnly" [value]="event.actualEndDate | date: 'yyyy-MM-dd'">
        </div>
        <div class="col-md-2">
          <label>{{'resource.event.endTime' | translate}}</label>
          <input type="time" class="form-control form-control-sm" [readOnly]="readOnly" [value]="event.actualEndDate | date: 'HH:mm'">
        </div>

      </div>
    </div>
    <div class="ng-container" *ngIf="!readOnly">
      <form [formGroup]="editFormGroup" class="col-md-12">
        <input id="id" type="hidden" formControlName="id">
        <div class="row">


            <div class="col-md-12">
              <label>{{'resource.event.description' | translate}}</label>
              <textarea autosize id="description" type="text" formControlName="description" class="form-control form-control-sm"></textarea>
            </div>
            <div class="col-md-6">
              <label>{{'resource.event.type' | translate}}</label>
              <select  type="text" formControlName="type" class="form-select form-select-sm">
                <option *ngFor="let type of types; let i = index" [value]="type">{{'resource.event.types.' + type.toLowerCase() | translate}}</option>
              </select>
            </div>
            <div class="col-md-6">
              <label class="form-check-label" for="allDay">{{'user.event.allDay' | translate}}</label>
              <input type="checkbox" [readOnly]="readOnly" class="form-check-input form-control form-control-sm form-spec-input" (change)="changeAllDayEdit($event)" [checked]="allDay"/>
            </div>
            <div class="col-md-4">
              <label for="endDate">{{'resource.event.endDate' | translate}}
                <span *ngIf="editFormGroup.controls['endDate'].hasError('required')" class="required-text">
                  {{'message.required' | translate}}
                </span>
                <span *ngIf="editFormGroup.controls['endDate'].hasError('wrong-date')" class="required-text">
                  {{'message.wrong-date' | translate}}
                </span></label>
              <input id="endDate" type="date" formControlName="endDate" class="form-control form-control-sm">
            </div>
            <div class="col-md-2">
              <label for="endTime">{{'resource.event.endTime' | translate}}
                <span *ngIf="editFormGroup.controls['endTime'].hasError('wrong-time')" class="required-text">
                  {{'message.wrong-date' | translate}}
                </span></label>
              <input id="endTime" type="time" formControlName="endTime" class="form-control form-control-sm">
            </div>
          </div>
      </form>
      <div *ngIf="!readOnly" class="col-md-12 btn-container">
        <div class="row">
          <div class="col-md-8 text-end">
            <button class="btn accept-btn btn-success" (click)="update()">
              <fa-icon [icon]="faAccept"></fa-icon>
            </button>

            <button class="btn accept-btn btn-outline-secondary ml-1" (click)="cancel()">
              <fa-icon [icon]="faCancel"></fa-icon>
            </button>
          </div>
      </div>
    </div>
  </div>
