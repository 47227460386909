import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { UsersRoutingModule } from './users-routing.module';
import { UsersComponent } from './list/users.component';
import {SharedModule} from "../shared/shared.module";
import { UserDetailComponent } from './user-detail/user-detail.component';
import {NgbModule} from "@ng-bootstrap/ng-bootstrap";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {FontAwesomeModule} from "@fortawesome/angular-fontawesome";
import {ToursModule} from "../tours/tours.module";
import { JobDetailComponent } from '../jobs/job-detail/job-detail.component';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { GoogleStreetViewModalComponent } from '../google-street-view-modal/google-street-view-modal.component';
import { SimpleAddressComponent } from "../shared/simple-address/simple-address.component"
import { DeliveryRequirementsDropdown } from '../freight-types/components/deliveryRequirements-dropdown/deliveryRequirements-dropdown.component';



@NgModule({
  declarations: [
    UsersComponent,
    UserDetailComponent,
    JobDetailComponent
  ],
    imports: [
    CommonModule,
    UsersRoutingModule,
    SharedModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    FontAwesomeModule,
    ToursModule,
    NgxSliderModule,
    GoogleStreetViewModalComponent,
    SimpleAddressComponent,
    DeliveryRequirementsDropdown
],
    exports: [
      UserDetailComponent

    ]
})
export class UsersModule { }
