import { Injectable } from '@angular/core';
import { environment } from "../../../environments/environment";
import { HttpClient, HttpResponse } from "@angular/common/http";
import { Observable } from "rxjs";
import { CompanyModel, CreateUserEventModel, SplitTimeRegModel, CreateUserMetaModel, UpdateUserEventModel, UpdateUserMetaModel, UserEventModel, UserModel, UserTimeRegistrationModel, UpdateUserTimeRegistrationModel, UserStartTimeRegistrationModel } from "./user.model";
import { ITuple } from "../../shared/tuple";
import { WeekTimeRegistrationsModel } from "./week-time-registrations.model";
import { CreateUserAgreedWeekModel, FreezeSalaryWeekModel, FreezeSalaryWeekRangeModel, FreezeSalaryWeekResponse, HasFrozenSalaryWeekModel, UserAgreedWeekModel } from "./user-agreed-week.model";
import { SalaryFoundationRegistrationsModel } from './salary-foundation-registrations.model';
import { map } from 'rxjs/operators';
import { DomSanitizer } from '@angular/platform-browser';
import { DataExchangeModel } from 'src/app/shared/fileuploader/shared/data-exchange.model';
import { TokenService } from 'src/app/services/token.service';
const url = environment.baseUrl + '/V1/Users';
const resourceUrl = environment.baseUrl + '/V1/Resource';
const eventsUrl = environment.baseUrl + '/V1/UserEvents';
const tripRecordsUrl = environment.baseUrl + '/V1/triprecords';



@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private http: HttpClient, private sanitizer: DomSanitizer, private tokenService: TokenService) { }

  getGroups(): Observable<CompanyModel[]> {
    return this.http.get<CompanyModel[]>(`${url}/Groups`);
  }

  getAll(): Observable<UserModel[]> {
    return this.http.get<UserModel[]>(`${url}/All`)
  }

  getAllAvailable(dateTimes: | ITuple[]): Observable<UserModel[]> {
    const model = {
      dateTimes
    }
    return this.http.post<UserModel[]>(`${url}/Available`, model);
  }

  getAllUsersWithActivities(userId: number, start: string, end: string) {
    const model = {
      userId,
      start,
      end
    }
    return this.http.post<UserModel[]>(`${url}/Activities`, model);
  }

  getSingle(id: number): Observable<UserModel> {
    return this.http.get<UserModel>(`${url}/${id}`);
  }

  getSingleFromDriversid(driversid: string): Observable<UserModel> {
    return this.http.get<UserModel>(`${url}/From-Driversid/${driversid}`);
  }

  getSingleWithMeta(id: number): Observable<UserModel> {
    return this.http.get<UserModel>(`${url}/${id}/Meta`);
  }

  createMeta(userId: number, model: CreateUserMetaModel): Observable<UserModel> {
    return this.http.post<UserModel>(`${url}/${userId}/Meta`, model);
  }

  updateMeta(userId: number, model: UpdateUserMetaModel): Observable<UserModel> {
    return this.http.put<UserModel>(`${url}/${userId}/Meta`, model);
  }

  updateUserTimeRegistration(userId: number, timeregistrationId: number, model: UpdateUserTimeRegistrationModel): Observable<UserModel> {
    return this.http.put<UserModel>(`${url}/${userId}/TimeRegistrations/${timeregistrationId}`, model);
  }

  getUserWithTimeRegistrations(userId: number, start: any, end: any): Observable<UserModel> {
    return this.http.get<UserModel>(`${url}/${userId}/TimeRegistrations/${start}/${end}`);
  }

  getUserWithTimeRegistrationsInWeeks(userId: number, start: any, end: any): Observable<WeekTimeRegistrationsModel[]> {
    return this.http.get<WeekTimeRegistrationsModel[]>(`${url}/${userId}/TimeRegistrations/${start}/${end}/In-Weeks`);
  }
  getUserWithTimeRegistrationsSalaryFoundation(userId: number, start: any, end: any, agreement: any): Observable<SalaryFoundationRegistrationsModel> {
    return this.http.get<SalaryFoundationRegistrationsModel>(`${url}/${userId}/SalaryFoundation/${start}/${end}/${agreement}`);
  }

  getUserTimeRegistrations(userId: number): Observable<any> {
    return this.http.get<any>(`${url}/${userId}/TimeRegistrations`);
  }

  getUsersActiveTimeRegistrations(): Observable<any> {
    return this.http.get<any>(`${url}/TimeRegistrations/Active`);
  }

  getUserWithTours(userId: number, start: any, end: any): Observable<any> {
    return this.http.get<any>(`${url}/${userId}/With-Tours/${start}/${end}`);
  }

  getUserWithToursExt(userId: number, start: any, end: any) : Observable<any>{
    return this.http.get<any>(`${url}/${userId}/With-Tours-Ext/${start}/${end}`);
  }

  createAgreedWeek(userId: number, agreedWeek: CreateUserAgreedWeekModel): Observable<any>{
    return this.http.post<any>(`${url}/${userId}/AgreedWeek`, agreedWeek);
  }

  FreezeWeek(model: FreezeSalaryWeekModel): Observable<FreezeSalaryWeekResponse>
  {
     return this.http.post<FreezeSalaryWeekResponse>(`${url}/FreezeSalaryWeek`, model);
  }

  getLatestFrozenWeek(userId: number, start: string, end: string, isAbroad: boolean): Observable<FreezeSalaryWeekModel>
  {
    return this.http.get<FreezeSalaryWeekModel>(`${url}/${userId}/LatestFrozenSalaryWeek/${start}/${end}/${isAbroad}`);
  }

  hasFrozenWeek(userId: number, start: string, end: string): Observable<HasFrozenSalaryWeekModel>
  {
    return this.http.get<HasFrozenSalaryWeekModel>(`${url}/${userId}/HasFrozenSalaryWeek/${start}/${end}`);
  }

  GetFrozenWeekRange(userId: number, start: string, end: string): Observable<FreezeSalaryWeekRangeModel>
  {
    return this.http.get<FreezeSalaryWeekRangeModel>(`${url}/${userId}/FrozenSalaryWeekRange/${start}/${end}`);
  }

  exportAgreedWeeks(userId: number, start: any, end: any): Observable<any> {
    return this.http.get(`${url}/${userId}/SalaryFoundation/${start}/${end}/Export/1`, { responseType: 'blob' })
      .pipe(map((res) => {
        return new Blob([res], { type: 'text/csv' });
      }));
  }

  getAgreedWeeks(userId: number, start: any, end: any): Observable<UserAgreedWeekModel[]> {
    return this.http.get<UserAgreedWeekModel[]>(`${url}/${userId}/AgreedWeek/${start}/${end}`);
  }

  getUserEvents(): Observable<any> {
    return this.http.get<any>(`${eventsUrl}/Types`);
  }

  createUserEvent(userId: number, model: CreateUserEventModel): Observable<any> {
    return this.http.post<any>(`${url}/${userId}/Events`, model);
  }

  updateUserEvent(userId: number, model: UpdateUserEventModel): Observable<any> {
    return this.http.patch<any>(`${url}/${userId}/Events`, model);
  }

  deleteUserEvent(userId: number, id: number): Observable<any> {
    return this.http.delete<any>(`${url}/${userId}/Events/${id}`);
  }

  // startUserTimeRegistration(userId: number, model: UserStartTimeRegistrationModel) : Observable<any> {
  //   return this.http.patch<any>(`${url}/${userId}/TourManuel/Start`, model);
  // }

  splitTimeReg(userId: number, timeRegId: number, splitModel: SplitTimeRegModel): Observable<any> {
    return this.http.put<any>(`${url}/${userId}/TimeRegistrationsSplit/${timeRegId}`, splitModel);
  }

  getImportSchemas() {
    return this.http.get<DataExchangeModel[]>(`${url}/GetDataExchangeImportSchemas`);
  }

  processImportFile(file: File, schemaid: number, customerid: number): Observable<any> {
    const formData: FormData = new FormData();
    formData.append('file', file);
    formData.append('CountryId', this.tokenService.getUserCountryId());
    formData.append('UiLanguage', this.tokenService.getUserLanguage());
    formData.append('dataExchangeSchemaId', schemaid.toString());
    return this.http.post(`${url}/ImportDataExchange`, formData);
  }

}
