<div *ngIf="tour" class="bg-lightgray">
  <app-google-street-view-modal *ngIf="showStreetViewModal" [lat]="streetviewLat" [lng]="streetviewLng" (closed)="hideStreetview($event)"></app-google-street-view-modal>

  <div ngbAccordion #accordion="ngbAccordion" [closeOthers]="true">
    <div ngbAccordionItem [collapsed]="false" class="bg-lightgray">
      <h2 ngbAccordionHeader>
        <button ngbAccordionButton>{{'common.masterData' | translate}}</button>
      </h2>
      <div ngbAccordionCollapse>
        <div ngbAccordionBody>
          <ng-template>
            <div class="col-md-12 text-end" style="padding: 10px 15px 0 0">
              <div class="row">
                <div class="col-md-11" style="padding-left: 30px">
                  <div *ngIf="readOnly">
                    <div style="float: left" class="chip bg-secondary unselectable">
                      <span class="chip-text">{{this.translateType() | translate}}</span>
                    </div>
                    <div *ngIf="tour.isSpecial" style="float: left" class="chip bg-secondary unselectable">
                      <span class="chip-text">{{'tour.isSpecial' | translate}}</span>
                    </div>
                    <div style="float: left" class="chip bg-secondary unselectable">
                      <span *ngIf="tour.isLocked" class="chip-text"><fa-icon [icon]="faLock"></fa-icon></span>
                      <span *ngIf="!tour.isLocked" class="chip-text"><fa-icon [icon]="faLockOpen"></fa-icon></span>
                    </div>
                  </div>
                </div>

                <div class="col-md-1 text-end pb-1">
                  <button type="button" class="btn btn-primary btn-sm" (click)="this.toggleEditMode()" placement="left" ngbTooltip="{{'btn.edit' | translate}}">
                    <fa-icon [icon]="faEdit"></fa-icon>
                  </button>
                </div>
              </div>
            </div>

            <form [formGroup]="formGroup" class="col-md-12 transition-widget" style="padding-bottom: 10px">
              <div class="form-group row">
                <div class="col-md-12" *ngIf="!readOnly">
                  <div class="form-check form-check-inline ps-0">
                    <label>
                      <input type="radio" value="Export" formControlName="type" style="height:15px; width:15px; margin-right: 5px">
                      <span>{{'tour.export' | translate}}</span>
                    </label>
                  </div>

                  <div class="form-check form-check-inline ps-0">
                    <label>
                      <input type="radio" value="Inland" formControlName="type" style="height:15px; width:15px; margin-right: 5px">
                      <span>{{'tour.inland' | translate}}</span>
                    </label>
                  </div>

                  <span *ngIf="formGroup.controls['type'].hasError('required')" class="required-text">
                    {{'message.required' | translate}}
                  </span>

                  <div class="form-check form-check-inline ps-0">
                    <label>
                      <input type="checkbox" value="Inland" formControlName="isSpecial" style="height:15px; width:15px; margin-right: 5px">
                      <span>{{'tour.isSpecial' | translate}}</span>
                    </label>
                  </div>

                  <div class="form-check form-check-inline ps-0">
                    <label>
                      <input type="checkbox" value="Inland" formControlName="isLocked" style="height:15px; width:15px; margin-right: 5px">
                      <span>{{'tour.isLocked' | translate}}</span>
                    </label>
                  </div>
                </div>
              </div>

              <div class="form-group row align-items-end mb-0">
                <div class="col-md-4">
                  <label for="expectedStartDate">
                    {{'tour.startDate' | translate}}
                    <span *ngIf="formGroup.controls['expectedStartDate'].hasError('required')" class="required-text">
                      {{'message.required' | translate}}
                    </span>
                  </label>
                  <input id="expectedStartDate" type="datetime-local" formControlName="expectedStartDate" class="form-control" [value]="'2024-12-23T10:55:00'" [readOnly]="this.readOnly">
                </div>

                <div class="col-md-4">
                  <label for="expectedEndDate">
                    {{'tour.endDate' | translate}}
                    <span *ngIf="formGroup.controls['expectedEndDate'].hasError('required')" class="required-text">
                      {{'message.required' | translate}}
                    </span>
                    <span *ngIf="formGroup.controls['expectedEndDate'].hasError('wrong-date')" class="required-text">
                      {{'message.wrong-date' | translate}}
                    </span>
                  </label>
                  <input id="expectedEndDate" type="datetime-local" formControlName="expectedEndDate" class="form-control" [value]="this.formGroup.value.expectedEndDate" [readOnly]="this.readOnly">
                </div>

                <div class="col-md-4 mb-1">
                  <label for="repititionEnabled">
                    Gentag opgaven?
                    <input [(ngModel)]="this.repititionEnabled" type="checkbox" id="repititionEnabled" [ngModelOptions]="{standalone: true}" [checked]="formGroup.get('repitition')?.value" [disabled]="this.readOnly">
                  </label>
                </div>
              </div>

              <div *ngIf="this.repititionEnabled" class="form-group row">
                <div class="col-md-6">
                  <label>{{'tour.create.repetition.title' | translate}}</label>
                  <select class="form-select" formControlName="repetition" [disabled]="this.readOnly">
                    <option [ngValue]="0">{{'tour.create.repetition.none' | translate}}</option>
                    <option [ngValue]="1">{{'tour.create.repetition.daily'| translate}}</option>
                    <option [ngValue]="2">{{'tour.create.repetition.weekly' | translate}}</option>
                    <option [ngValue]="3">{{'tour.create.repetition.monthly' | translate}}</option>
                  </select>
                </div>

                <div class="col-md-4">
                  <label>
                    {{'tour.create.repetition.endRepetitionDate' | translate}}
                    <span *ngIf="formGroup.controls['endRepetitionDate'].hasError('required')" class="required-text">
                      {{'message.required' | translate}}
                    </span>
                    <span *ngIf="formGroup.controls['endRepetitionDate'].hasError('wrong-date')" class="required-text">
                      {{'message.wrong-date' | translate}}
                    </span>
                  </label>
                  <input formControlName="endRepetitionDate" type="date" class="form-control" (click)="calculateRepetitionCount()" [readOnly]="this.readOnly">
                </div>
              </div>

              <div class="form-group row mb-0">
                <div class="col-md-9">
                  <label for="description">
                    {{'tour.description' | translate}}
                    <span *ngIf="formGroup.controls['description'].hasError('maxlength')" class="required-text">
                      {{'message.maxLength' | translate: {number: 40} }}
                    </span>

                    <span *ngIf="formGroup.controls['description'].hasError('required')" class="required-text">
                      {{'message.required' | translate}}
                    </span>
                  </label>
                  <input id="description" type="text" formControlName="description" class="form-control" [readOnly]="this.readOnly">
                </div>

                <div class="col-md-3">
                  <label for="orderId">
                    {{'tour.orderId' | translate}}
                    <span *ngIf="formGroup.controls['orderId'].hasError('maxlength')" class="required-text">
                      {{'message.maxLength' | translate: {number: 40} }}
                    </span>
                  </label>
                  <input id="orderId" type="text" formControlName="orderId" class="form-control" [readOnly]="this.readOnly">
                </div>
              </div>

              <div *ngIf="!this.tour.tourFreights || this.tour.freight" class="form-group row mb-0">
                <!-- if there are no tourFreights - meaning it's an old tour - hide this section -->
                <br>

                <div class="col-md-6">
                  <label for="freightType">{{'tour.freight' | translate}}</label>
                  <input id="freightType" type="text" formControlName="freight" class="form-control" [readOnly]="readOnly">
                </div>


                <!-- Not Calculated Freight -->
                <div class="col-md-6 pe-0">
                  <label for="freightTypeId">
                    {{'tour.freightType' | translate}}
                    <span *ngIf="formGroup.controls['freightTypeId'].hasError('required')" class="required-text">
                      {{'message.required' | translate}}
                    </span>
                  </label>
                  <select class="form-select form-select-sm" formControlName="freightTypeId" (change)="onChangeFreightType($event)" #freightTypeDescSelect [disabled]="this.readOnly" [attr.disabled]="readOnly ? '' : null">
                    <option value="1">{{'tour.freightTypeUnitDesc.weight' | translate}}</option>
                    <option value="2">{{'tour.freightTypeUnitDesc.volume'| translate}}</option>
                    <option value="3">{{'tour.freightTypeUnitDesc.pallets' | translate}}</option>
                  </select>
                </div>


                <!-- Calculated Freight -->
                <div class="col-md-6" *ngIf="tour.hasFreightOnAddress">
                  <label for="calculatedTotalFreightAmount">{{'tour.freightTypeUnit.' + tour.freightTypeId + 'calc' | translate}}</label>
                  <input id="calculatedTotalFreightAmount" type="number" formControlName="calculatedTotalFreightAmount" class="form-control" [readOnly]="true">
                </div>

                <div class="col-md-6" *ngIf="tour.hasFreightOnAddress">
                  <div style="margin-top: 28px; margin-bottom: 0;" class="chip bg-secondary unselectable">
                    <span class="chip-text">{{'tour.calculatedFreightAmount' | translate}}</span>
                  </div>
                </div>
              </div>


              <br>


              <div class="form-group row">
                <!-- Customer Select -->
                <div class="col-md-12 pe-0">
                  <label>{{'tour.customer' | translate}} </label>
                  <button *ngIf="!readOnly" class="btn btn-primary btn-sm" style="margin-left: 10px;" (click)="customerSelect = true">
                    {{'tour.create.user-select.btn' | translate}}
                  </button>

                  <input *ngIf="tour.customer" class="form-control" [value]="tour.customer?.name" disabled>
                  <input *ngIf="!tour.customer" class="form-control" disabled>

                  <app-customer-select
                    *ngIf="customerSelect"
                    id="customer-select"
                    (selected)="selectedCustomerCallback($event)"
                  ></app-customer-select>
                </div>



                <!-- User Select -->
                <div class="col-md-12 pe-0">
                  <label>{{'tour.chauffeur' | translate}} </label>
                  <button
                    *ngIf="!readOnly"
                    class="btn btn-primary btn-sm"
                    style="margin-left: 10px"
                    (click)="userSelect = true; setTourTimes()"
                    [disabled]="(!formGroup.value.expectedStartDate || !formGroup.value.expectedEndDate) || (formGroup.value.expectedStartDate == '' || formGroup.value.expectedEndDate == '')"
                  >
                    {{'tour.create.user-select.btn' | translate}}
                  </button>

                  <input
                    *ngIf="tour.assignedUser"
                    class="form-control"
                    [value]="tour.assignedUser?.firstname + ' ' + tour.assignedUser?.lastname"
                    disabled
                  >
                  <input
                    *ngIf="!tour.assignedUser"
                    class="form-control"
                    disabled
                  >

                  <app-user-select
                    *ngIf="userSelect"
                    id="user-select"
                    (selected)="this.selectedUserCallback($event)"
                    [dateTimes]="tourTimes"
                  ></app-user-select>
                </div>



                <!-- Front Vehicle Select -->
                <div class="col-md-12 pe-0">
                  <div *ngIf="tour.wrongVehicle === true" class="wrong-vehicle text-white" style="background-color: #B54B5D; margin-top: 10px;" title="{{ 'table.wrongVehicle' | translate}}">
                    <fa-icon style="margin-right: 4px;" class="text-white child" [icon]="faExclamation"></fa-icon>
                  </div>

                  <label>{{'tour.frontVehicle' | translate}} </label>

                  <button *ngIf="!readOnly" class="btn btn-primary btn-sm" style="margin-left: 10px" (click)="frontVehicleSelect = true">
                    {{'tour.create.user-select.btn' | translate}}
                  </button>
                  <input *ngIf="tour.frontVehicle" class="form-control" [value]="getVehicleDisplayText(tour.frontVehicle)" disabled>
                  <input *ngIf="!tour.frontVehicle" class="form-control" disabled>
                  <app-front-vehicle-select *ngIf="frontVehicleSelect" id="frontVehicle-select" (selected)="selectedFrontVehicleCallback($event)"></app-front-vehicle-select>
                </div>
              </div>

              <br>

              <div class="row">
                <h2>{{ 'tour.address' | translate }}</h2>

                <p *ngIf="tour.addresses.length === 0" class="none-text" style="margin-left: 15px;">{{'message.none' | translate}}</p>

                <div class="col-md-12">
                  <app-address-widget
                    class="address-widget"
                    [addresses]="addresses"
                    [backVehicles]="backVehicles"
                    [customerAddresses]="this.customerAddresses"
                    [transportEquipment]="transportEquipment"
                    [selectedCustomer]="tour.customer"
                    [showLocationNameAndId]="true"
                    [showFreightSection]="true"
                    [showFreightType]="!tour.hasFreightOnAddress"
                    [showDescription]="true"
                    [createMode]="this.createModes.create_as_parent"
                    [showCreateButton]="true"
                    [showEditButton]="false"
                    [readOnly]="readOnly"
                    (editAddress)="editAddress(0, $event)"
                    (delete)="deleteAddress($event)"
                    (showStreetview)="showStreetview($event)"
                    (updateReadOnly)="this.updateReadOnly($event)"
                  ></app-address-widget>
                </div>
              </div>
              <!-- <div *ngIf="!readOnly && tour.addresses.length > 1 " class="col-md-12 pb-2">
                <button *ngIf="i > 0" class="btn btn-sm btn-primary" style="margin-right: 5px" (click)="swap(i - 1, i)"> <fa-icon [icon]="faUpIcon"></fa-icon> </button>
                <button *ngIf="i < tour.addresses.length-1" class="btn btn-sm btn-primary" style="margin-right: 5px" (click)="swap(i, i + 1)"> <fa-icon [icon]="faDownIcon"></fa-icon></button>
              </div> -->
              <!-- <hr *ngIf=" i + 1 < tour.addresses.length" style="margin-top:0;margin-bottom:0;"> -->

              <div *ngIf="!readOnly && !editRegistration" class="btn-container">
                <app-btn-accept-widget (cancel)="readOnly = true; patchFormValues()" [formGroup]="formGroup" (accept)="update()"></app-btn-accept-widget>
              </div>
            </form>
          </ng-template>
        </div>
      </div>
    </div>


    <div ngbAccordionItem class="bg-lightgray">
      <h2 ngbAccordionHeader>
        <button ngbAccordionButton>{{'detail.subTitles.registrations' | translate}}</button>
      </h2>
      <div ngbAccordionCollapse>
        <div ngbAccordionBody>
          <ng-template>
            <form [formGroup]="formGroup" class="col-md-12">
              <div class="row w-100 pb-3">
                <div class="col-md-4">
                  <label for="actualStartDate">{{'tour.startDate' | translate}}</label>
                  <input id="actualStartDate" type="date" formControlName="actualStartDate" class="form-control form-control-sm" [readOnly]="!showEditStartDate()" (focus)="editRegistration = true && showEditStartDate()">
                </div>

                <div class="col-md-2">
                  <label for="actualStartTime">{{'tour.startTime' | translate}}</label>
                  <input id="actualStartTime" type="time" formControlName="actualStartTime" class="form-control form-control-sm" [readOnly]="!showEditStartDate()" (focus)="editRegistration = true && showEditStartDate()">
                </div>

                <div class="col-md-4">
                  <label for="actualEndDate">{{'tour.endDate' | translate}}</label>
                  <input id="actualEndDate" type="date" formControlName="actualEndDate" class="form-control form-control-sm" [readOnly]="!showEditEndDate()" (focus)="editRegistration = true && showEditEndDate()">
                </div>
                <div class="col-md-2 pe-1">
                  <label for="actualEndTime">{{'tour.endTime' | translate}}</label>
                  <input id="actualEndTime" type="time" formControlName="actualEndTime" class="form-control form-control-sm" [readOnly]="!showEditEndDate()" (focus)="editRegistration = true && showEditEndDate()">
                </div>

                <div class="col-6">
                  <label>{{'tour.coordinatesStart' | translate}}</label>
                  <span class="form-control form-control-sm" readonly>
                    X: {{formGroup.value.startLongitude}}, Y:
                    {{formGroup.value.startLatitude}}
                  </span>
                </div>
                <div class="col-6 pe-1">
                  <label>{{'tour.coordinatesEnd' | translate}}</label>
                  <span class="form-control form-control-sm" readonly>
                    X: {{formGroup.value.endLongitude}}, Y:
                    {{formGroup.value.endLatitude}}
                  </span>
                </div>

                <div class="col-md-6">
                  <label for="fuel">{{'tour.fuel' | translate}}</label>
                  <input id="fuel" formControlName="fuel" class="form-control form-control-sm" [readOnly]="true">
                </div>
                <div class="col-md-6 pe-1">
                  <label for="adBlue">{{'tour.adBlue' | translate}}</label>
                  <input id="adBlue" formControlName="adBlue" class="form-control form-control-sm" [readOnly]="true">
                </div>
                <div class="col-md-12 pe-1">
                  <label for="distance">{{'tour.distance' | translate}}</label>
                  <input id="distance" formControlName="distance" class="form-control form-control-sm" [readOnly]="true">
                </div>
              </div>
            </form>

            <app-note-widget *ngIf="editRegistration" [createMode]="true" (cancelCreate)="editRegistration = !editRegistration" (createNote)="editNoteRegistration($event)" [showInAppCheckBox]="false"></app-note-widget>

            <div class="col-md-12 text-center" *ngIf="tour.actualStartDate && tour.actualEndDate && !readOnly && !editRegistration">
              <button class="btn btn-default" (click)="cancelTourRegistration()" style="margin-bottom: 1vh;" placement="top" ngbTooltip="{{'tour.cancelRegistration' | translate}}">
                <fa-icon [icon]="faTrash"></fa-icon>
              </button>
            </div>
            <app-note-widget *ngIf="cancelRegistration" [createMode]="true" (cancelCreate)="cancelRegistration = !cancelRegistration" (createNote)="cancelNoteRegistration($event)" [showInAppCheckBox]="false"></app-note-widget>

            <div class="col-md-12 text-center" *ngIf="tour.actualStartDate && tour.status == 'OnHold' && !readOnly && !editRegistration">
              <button class="btn btn-default" (click)="start()" style="margin-bottom: 1vh;" placement="top" ngbTooltip="{{'tour.restartTour' | translate}}">
                <fa-icon [icon]="faStart"></fa-icon>
              </button>
            </div>

            <div class="col-md-12 text-center" *ngIf="tour.actualStartDate && !tour.actualEndDate && !readOnly && !editRegistration">
              <button class="btn btn-default" (click)="cancel()" style="margin-bottom: 1vh;" placement="top" ngbTooltip="{{'tour.cancelTour' | translate}}">
                <fa-icon [icon]="faStop"></fa-icon>
              </button>
            </div>
            <app-note-date-widget *ngIf="endTour" [createMode]="true" text="tour.endTourText" (cancelCreate)="endTour = !endTour" (createNote)="cancelNoteDescription($event)"></app-note-date-widget>

            <div class="col-md-12 text-center" *ngIf="!tour.actualStartDate && !tour.actualEndDate && !readOnly && !editRegistration">
              <button class="btn btn-default" (click)="start()" style="margin-bottom: 1vh;" placement="top" ngbTooltip="{{'tour.startTour' | translate}}">
                <fa-icon [icon]="faStart"></fa-icon>
              </button>
            </div>
            <app-note-date-widget *ngIf="startTour" [createMode]="true" text="tour.startTourText" (cancelCreate)="startTour = !startTour" (createNote)="startNoteDescription($event)"></app-note-date-widget>
          </ng-template>
        </div>
      </div>
    </div>


    <div ngbAccordionItem class="bg-lightgray">
      <h2 ngbAccordionHeader>
        <button ngbAccordionButton>{{'detail.subTitles.events' | translate}}</button>
      </h2>

      <div ngbAccordionCollapse>
        <div ngbAccordionBody class="px-4">
          <ng-template>
            <p *ngIf="tour.timeRegistrations?.length === 0" class="none-text" style="margin-left: 15px">{{'message.none' | translate}}</p>

            <ng-container *ngFor="let timereg of tour?.timeRegistrations; let i = index">
              <div class="col-md-12" style="margin-bottom: 15px">
                <div class="row">
                  <div class="col-md-6">
                    <label>{{'tour.event.type' | translate}}</label>
                    <input class="form-control form-control-sm" readonly value="{{'tour.event.regTypeIds.' + timereg.timeRegistrationTypeId | translate}}">
                  </div>

                  <div class="col-md-6">
                    <label>{{'tour.event.description' | translate}}</label>
                    <input class="form-control form-control-sm" readonly [value]="timereg.comment">
                  </div>

                  <div class="col-md-4">
                    <label>{{'tour.startDate' | translate}}</label>
                    <input type="date" class="form-control form-control-sm" readonly [value]="timereg.startTime | date: 'yyyy-MM-dd'">
                  </div>

                  <div class="col-md-2">
                    <label>{{'tour.startTime' | translate}}</label>
                    <input class="form-control form-control-sm" readonly [value]="timereg.startTime | date: 'HH:mm'">
                  </div>

                  <div class="col-md-4">
                    <label>{{'tour.endDate' | translate}}</label>
                    <input type="date" class="form-control form-control-sm" readonly [value]="timereg.endTime | date: 'yyyy-MM-dd'">
                  </div>

                  <div class="col-md-2">
                    <label>{{'tour.endTime' | translate}}</label>
                    <input class="form-control form-control-sm" readonly [value]="timereg.endTime | date: 'HH:mm'">
                  </div>

                  <div class="col-md-6" *ngIf="timereg.drivingRecord">
                    <label>{{'tour.event.startKilometerAge' | translate}}</label>
                    <input class="form-control form-control-sm" readonly [value]="convertToDecimal(timereg.drivingRecord.mileageStart)">
                  </div>

                  <div class="col-md-6" *ngIf="timereg.drivingRecord">
                    <label>{{'tour.event.endKilometerAge' | translate}}</label>
                    <input class="form-control form-control-sm" readonly [value]="convertToDecimal(timereg.drivingRecord.mileageEnd)">
                  </div>

                  <div class="col-6">
                    <label>{{'tour.coordinatesStart' | translate}}</label>
                    <span class="form-control form-control-sm" readonly>
                      X: {{timereg.startLongitude}}, Y:
                      {{timereg.startLatitude}}
                    </span>
                  </div>
                  <div class="col-6">
                    <label>{{'tour.coordinatesEnd' | translate}}</label>
                    <span class="form-control form-control-sm" readonly>
                      <span *ngIf="timereg.endTime">
                        X:
                        {{timereg.endLongitude}}, Y: {{timereg.endLatitude}}
                      </span>
                    </span>
                  </div>
                </div>
              </div>
              <hr *ngIf=" i + 1 < tour.timeRegistrations.length">
            </ng-container>
          </ng-template>
        </div>
      </div>
    </div>


    <div ngbAccordionItem #attachment="ngbAccordionItem" class="bg-lightgray">
      <h2 ngbAccordionHeader>
        <button ngbAccordionButton>{{'detail.subTitles.attachment' | translate}}
          <div class="plus-btn-right">
            <button class="btn btn-light btn-sm rounded-circle" placement="left" ngbTooltip="{{'btn.addAttachment' | translate}}"
              (click)="addNewAttachment($event, attachment)"
            >
              <fa-icon [icon]="faAdd"></fa-icon>
            </button>
          </div>
        </button>
      </h2>

      <div ngbAccordionCollapse>
        <div ngbAccordionBody>
          <ng-template>
            <app-attachment-widget *ngIf="addAttachment" [createMode]="true" [oldLook]="true" (cancelCreate)="addAttachment = false" (create)="createAttachment($event); addAttachment = false;"></app-attachment-widget>
            <p *ngIf="tour.attachments?.length === 0" class="none-text" style="margin-left: 15px">{{'message.none' | translate}}</p>

            <ng-container *ngFor="let attachment of tour?.attachments; let i = index">
              <button *ngIf="!readOnly" class="close delete" style="margin: 0 20px 0 0 " placement="left" ngbTooltip="{{'btn.deleteAttachment' | translate}}" (click)="deleteAttachment(attachment.id)">
                <span aria-hidden="true" class="text-white">&times;</span>
              </button>

              <div class="col-md-12 widget-margin">
                <app-attachment-widget (delete)="deleteAttachment($event)" [attachment]="attachment" [oldLook]="true"></app-attachment-widget>
              </div>
            </ng-container>
          </ng-template>
        </div>
      </div>
    </div>


    <div ngbAccordionItem #note="ngbAccordionItem" class="bg-lightgray">
      <h2 ngbAccordionHeader>
        <button ngbAccordionButton>{{'detail.subTitles.note' | translate}}
          <div class="plus-btn-right">
            <button
              class="btn btn-light btn-sm rounded-circle"
              placement="left"
              ngbTooltip="{{'btn.addNote' | translate}}"
              (click)="addNewNote($event, note)"
            >
              <fa-icon [icon]="faAdd"></fa-icon>
            </button>
          </div>
        </button>
      </h2>

      <div ngbAccordionCollapse>
        <div ngbAccordionBody>
          <ng-template>
            <app-note-widget *ngIf="addNote" [createMode]="true" (cancelCreate)="addNote = false" (createNote)="createNote($event)"></app-note-widget>
            <p *ngIf="tour.notes?.length === 0" class="none-text" style="margin-left: 15px">{{'message.none' | translate}}</p>

            <ng-container *ngFor="let note of tour?.notes; let i = index">
              <app-note-widget (deleteNote)="deleteNote($event)" [note]="note"></app-note-widget>
              <hr *ngIf=" i + 1 < tour.notes.length">
            </ng-container>
          </ng-template>
        </div>
      </div>
    </div>
  </div>

  <div class="col-md-12 btn-container" *ngIf="!readOnly && !(tour?.actualStartDate != null && tour?.actualEndDate == null)">
    <div class="row">
      <div class="col-md-12 text-center">
        <button class="btn btn-danger" (click)="delete()" placement="top" ngbTooltip="{{'btn.deleteTour' | translate}}">
          <fa-icon [icon]="faTrash"></fa-icon>
        </button>
      </div>
    </div>
  </div>
</div>

<app-loading class="overlay" *ngIf="loading"></app-loading>
