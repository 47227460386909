<app-google-street-view-modal *ngIf="showStreetViewModal" [lat]="streetviewLat" [lng]="streetviewLng" (closed)="hideStreetview($event)"></app-google-street-view-modal>


<div class="tour-create bg-lightgray">
  <div class="px-0">
    <div #body id="body">
      <form [formGroup]="formGroup" class="col-md-12">
        <h6 style="padding-top: 15px">{{'tour.times' | translate}}</h6>
        <div class="form-group row align-items-end mb-0">
          <div class="col-md-4">
            <label for="expectedStartDate">
              {{'tour.startDate' | translate}}
              <span *ngIf="formGroup.controls['expectedStartDate'].hasError('required')" class="required-text">
                {{'message.required' | translate}}
              </span>
            </label>
            <input id="expectedStartDate" type="datetime-local" formControlName="expectedStartDate" class="form-control" [value]="this.expectedStartDate">
          </div>

          <div class="col-md-4">
            <label for="expectedEndDate">
              {{'tour.endDate' | translate}}
              <span *ngIf="formGroup.controls['expectedEndDate'].hasError('required')" class="required-text">
                {{'message.required' | translate}}
              </span>
              <span *ngIf="formGroup.controls['expectedEndDate'].hasError('wrong-date')" class="required-text">
                {{'message.wrong-date' | translate}}
              </span>
            </label>
            <input id="expectedEndDate" type="datetime-local" formControlName="expectedEndDate" class="form-control" [value]="this.expectedEndDate">
          </div>

          <div class="col-md-4 mb-1">
            <label for="repititionEnabled">
              Gentag opgaven?
              <input [(ngModel)]="this.repititionEnabled" type="checkbox" id="repititionEnabled" [ngModelOptions]="{standalone: true}">
            </label>
          </div>
        </div>

        <div *ngIf="this.repititionEnabled" class="form-group row">
          <div class="col-md-6">
            <label>{{'tour.create.repetition.title' | translate}}</label>
            <select class="form-select" formControlName="repetition">
              <option [ngValue]="0">{{'tour.create.repetition.none' | translate}}</option>
              <option [ngValue]="1">{{'tour.create.repetition.daily'| translate}}</option>
              <option [ngValue]="2">{{'tour.create.repetition.weekly' | translate}}</option>
              <option [ngValue]="3">{{'tour.create.repetition.monthly' | translate}}</option>
            </select>
          </div>

          <div class="col-md-4">
            <label>
              {{'tour.create.repetition.endRepetitionDate' | translate}}
              <span *ngIf="formGroup.controls['endRepetitionDate'].hasError('required')" class="required-text">
                {{'message.required' | translate}}
              </span>
              <span *ngIf="formGroup.controls['endRepetitionDate'].hasError('wrong-date')" class="required-text">
                {{'message.wrong-date' | translate}}
              </span>
            </label>
            <input formControlName="endRepetitionDate" type="date" class="form-control" (click)="calculateRepetitionCount()">
          </div>
        </div>



        <hr class="mt-4" style="width: 100%">



        <div class="form-group row mb-0">
          <div class="col-md-9">
            <label for="description">
              {{'tour.description' | translate}}
              <span *ngIf="formGroup.controls['description'].hasError('maxlength')" class="required-text">
                {{'message.maxLength' | translate: {number: 40} }}
              </span>

              <span *ngIf="formGroup.controls['description'].hasError('required')" class="required-text">
                {{'message.required' | translate}}
              </span>
            </label>
            <input id="description" type="text" formControlName="description" class="form-control">
          </div>

          <div class="col-md-3">
            <label for="orderId">
              {{'tour.orderId' | translate}}
              <span *ngIf="formGroup.controls['orderId'].hasError('maxlength')" class="required-text">
                {{'message.maxLength' | translate: {number: 40} }}
              </span>
            </label>
            <input id="orderId" type="text" formControlName="orderId" class="form-control">
          </div>
        </div>


        <hr>


        <!-- Product/Freight -->
        <div class="form-group row mt-3">
          <h6 class="mb-0">{{ 'navigation.freightTypes' | translate }}</h6> <br>
        </div>

        <div *ngIf="this.formGroup.get('tourFreights')?.value && this.formGroup.get('tourFreights')?.value.length > 0" class="row px-2">
          <div class="col-12 bg-white rounded-3 p-3">
            <table class="table">
              <thead>
                <tr>
                  <th scope="col">[Godstype]</th>
                  <th scope="col">[Type]</th>
                  <th scope="col">[Tillæg]</th>
                  <th scope="col">[Mængde]</th>
                </tr>
              </thead>
              <tbody>
                @for (tourFreight of this.tourFreights.controls; track $index) {
                  <tr>
                    <th scope="row">{{ tourFreight.value.productTypeName }}</th>
                    <td>{{ tourFreight.value.type }}</td>
                    <td>
                      <p *ngFor="let surcharge of tourFreight.value.surcharges">
                        {{ surcharge.name }}
                      </p>
                    </td>
                    <td>{{ tourFreight.value.amount }}</td>
                  </tr>
                }
              </tbody>
            </table>
          </div>
        </div>

        <div class="row mt-3">
          <div class="d-flex col-12 justify-content-center">
            <div class="product-type-picker product-type-picker--component">
              <button class="btn btn-primary btn-sm" (click)="this.openProductModal()">
                @if (this.formGroup.get('freight')?.value) {
                  <span>[Ændre godstyper]</span>
                }
                @else {
                  <span>[Vælg godstyper]</span>
                }
              </button>
            </div>

          </div>
        </div>


        <hr class="mt-4" style="width: 100%">


        <div class="form-group row">
          <div class="col-md-12">
            <h6>{{'tour.resources' | translate}}</h6>
          </div>

          <!-- FIRST USER -->
          <div [ngClass]="{ 'col-md-12': !showSecondUserField, 'col-md-6': showSecondUserField }">
            <label>{{'tour.chauffeur' | translate}} </label>
            <button
              class="btn btn-default btn-sm"
              style="margin-left: 10px; margin-right: 10px;"
              (click)="userSelect = true; calculateRepetitionCount()"
              [disabled]="!formGroup.value.expectedStartDate || !formGroup.value.expectedEndDate"
            >
              {{'tour.create.user-select.btn' | translate}}
            </button>
            <button
              *ngIf="!showSecondUserField && selectedUser"
              class="btn btn-sm btn-success"
              (click)="toogleSecondUserField()"
            >
              <fa-icon [icon]="faAddIcon"></fa-icon>
            </button>
            <p>
              <input
                *ngIf="selectedUser"
                class="form-control"
                style="margin-top: 5px"
                [value]="selectedUser?.firstname + ' ' + selectedUser?.lastname"
                disabled
              >
              <input
                *ngIf="!selectedUser"
                class="form-control"
                style="margin-top: 5px"
                disabled
              >
              <app-user-select
                *ngIf="userSelect"
                id="user-select"
                (selected)="selectedUserCallback($event)"
                [dateTimes]="repetitionDateTimes"
              ></app-user-select>
            </p>
          </div>

          <!-- SECOND USEr -->
          <div [ngClass]="{ 'col-md-6': showSecondUserField }" *ngIf="showSecondUserField">
            <label>{{'tour.chauffeur2' | translate}} </label>
            <button
              class="btn btn-default btn-sm"
              style="margin-left: 10px; margin-right: 10px;"
              (click)="secondUserSelect = true; calculateRepetitionCount()"
              [disabled]="!formGroup.value.expectedStartDate || !formGroup.value.expectedEndDate"
            >
              {{'tour.create.user-select.btn' | translate}}
            </button>

            <button
              *ngIf="showSecondUserField"
              class="btn btn-sm btn-danger"
              (click)="toogleSecondUserField()"
            >
              <fa-icon [icon]="faMinusIcon"></fa-icon>
            </button>

            <p>
              <input
                *ngIf="selectedSecondUser"
                class="form-control"
                style="margin-top: 5px"
                [value]="selectedSecondUser?.firstname + ' ' + selectedSecondUser?.lastname"
                disabled
              >
              <input *ngIf="!selectedSecondUser" class="form-control" style="margin-top: 5px" disabled>
              <app-user-select
                *ngIf="secondUserSelect"
                id="second-user-select"
                (selected)="selectedSecondUserCallback($event)"
                [dateTimes]="repetitionDateTimes"
              ></app-user-select>
            </p>
          </div>

          <!-- FRONT VEHICLE -->
          <div class="col-md-12">
            <label>{{'tour.frontVehicle' | translate}} </label>
            <button
              class="btn btn-default btn-sm"
              style="margin-left: 10px"
              (click)="frontVehicleSelect = true"
            >
              {{'tour.create.user-select.btn' | translate}}
            </button>
            <p>
              <input
                *ngIf="selectedFrontVehicle"
                class="form-control"
                style="margin-top: 5px"
                [value]="getVehicleDisplayText(selectedFrontVehicle)"
                disabled
              >
              <input *ngIf="!selectedFrontVehicle" class="form-control" style="margin-top: 5px" disabled>
              <app-front-vehicle-select
                *ngIf="frontVehicleSelect"
                id="frontVehicle-select"
                (selected)="selectedFrontVehicleCallback($event)"
              ></app-front-vehicle-select>
            </p>
          </div>

          <!-- BACKVEHICLE -->
          <div class="col-md-6">
            <label>{{'tour.backVehicle' | translate}}</label>
            <button class="btn btn-default btn-sm" style="margin-left: 10px" (click)="backVehicleSelect = true" [disabled]="this.addresses.length >= 1">{{'tour.create.user-select.btn' | translate}}</button>
            <p>
              <input class="form-control" style="margin-top: 5px" [value]="backVehiclesString" disabled>
              <app-back-vehicle-select *ngIf="backVehicleSelect" id="backVehicle-select" (selected)="selectedBackVehicleCallback($event)"></app-back-vehicle-select>
            </p>
          </div>

          <!-- EQUIPMENT -->
          <div class="col-md-6">
            <label>{{'tour.transportEquipment' | translate}}</label>
            <button class="btn btn-default btn-sm" style="margin-left: 10px" (click)="transportEquipmentSelect = true" [disabled]="this.addresses.length >= 1">{{'tour.create.user-select.btn' | translate}}</button>
            <p>
              <input
                *ngIf="selectedTransportEquipment"
                class="form-control"
                style="margin-top: 5px"
                [value]="selectedTransportEquipment?.manufacturer + ' ' + selectedTransportEquipment?.resourceType?.resourceTypeName + ' ' + selectedTransportEquipment?.unitIdentification"
                disabled
              >
              <input *ngIf="!selectedTransportEquipment" class="form-control" style="margin-top: 5px" disabled>
              <app-transport-equipment-select *ngIf="transportEquipmentSelect" (selected)="selectedTransportEquipmentCallback($event)"></app-transport-equipment-select>
            </p>
          </div>



          <hr class="mt-4" style="width: 100%">



          <div class="col-md-12">
            <h6>{{'tour.delivery' | translate}}</h6>
          </div>

          <div class="col-md-12">
            <div class="form-check form-check-inline ps-0">
              <label>
                <input type="radio" value="Export" formControlName="type" style="height:15px; width:15px; margin-right: 5px">
                <span>{{'tour.export' | translate}}</span>
              </label>
            </div>

            <div class="form-check form-check-inline ps-0">
              <label>
                <input type="radio" value="Inland" formControlName="type" style="height:15px; width:15px; margin-right: 5px">
                <span>{{'tour.inland' | translate}}</span>
              </label>
            </div>

            <span *ngIf="formGroup.controls['type'].hasError('required')" class="required-text">
              {{'message.required' | translate}}
            </span>

            <div class="form-check form-check-inline ps-0">
              <label>
                <input type="checkbox" value="Inland" formControlName="isSpecial" style="height:15px; width:15px; margin-right: 5px">
                <span>{{'tour.isSpecial' | translate}}</span>
              </label>
            </div>
          </div>


          <!-- customer -->
          <div class="col-md-12">
            <label>{{'tour.customer' | translate}} </label>
            <button
              class="btn btn-default btn-sm"
              style="margin-left: 10px;"
              (click)="customerSelect = true"
            >
              {{'tour.create.user-select.btn' | translate}}
            </button>
            <p>
              <input
                class="form-control"
                style="margin-top: 5px"
                [value]="selectedCustomer?.name"
                disabled
              >

              <app-customer-select
                *ngIf="customerSelect"
                id="customer-select"
                (selected)="selectedCustomerCallback($event)"
              ></app-customer-select>
            </p>
          </div>

          <br><br>

          <div class="col-md-12">
            <!-- <label>{{'tour.address' | translate}}</label> -->
            <ng-container>
              <app-address-widget
                #addressWidget
                [addresses]="addresses"
                [selectedCustomer]="this.selectedCustomer"
                [backVehicles]="this.backVehicles"
                [transportEquipment]="this.transportEquipment"
                [showFreightSection]="true"
                [showDescription]="true"
                [showEditButton]="false"
                [showUpdateAndCancelButtons]="false"
                [createMode]="createModes.create_as_child"
                [selectedBackVehicles]="this.selectedBackVehicles"
                [selectedTransportEquipment]="this.selectedTransportEquipment"
                (arriveBackVehiclesEmitter)="this.updateArriveBackvehicles($event)"
                (showStreetview)="this.emitStreetview($event)"
                (createAddress)="this.addAddressToList($event)"
              ></app-address-widget>
            </ng-container>
          </div>
        </div>
      </form>
      <div class="col-md-12">
        <hr class="mt-4" style="width: 100%;">
      </div>

      <app-attachment-widget [createMode]="true" [includeAccept]="false" [oldLook]="false" (create)="patchAttachment($event)"></app-attachment-widget>
      <app-note-widget [createMode]="true" [includeAccept]="false" [oldLook]="false" (createNote)="patchNote($event)"></app-note-widget>

      <div class="col-md-12 btn-container">
        <app-btn-accept-widget
          [formGroup]="formGroup"
          (accept)="create()"
          (cancel)="cancel.emit()"
        ></app-btn-accept-widget>

        <hr class="mt-4" style="width: 100%;">
      </div>
    </div>
  </div>
</div>
