import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import {
  faBookmark,
  faCalendarAlt, faCheck, faChevronLeft, faChevronRight, faFilter,
  faMinusSquare,
  faPlus, faPlusSquare,
  faSearch,
  faSort,
  faSortDown,
  faSortUp, faSync, faFileImport, faExclamation, faLock, faUnlock
} from "@fortawesome/free-solid-svg-icons";
import { SortEvent } from "./sortable.directive";
import { IconDefinition } from "@fortawesome/free-brands-svg-icons";
import { ActivatedRoute } from "@angular/router";
import { CalenderView, StatusType } from "../../constants";
import { ModalDismissReasons, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { DatePipe } from "@angular/common";
import { SharedService } from "../services/shared.service";
import { MainGroup } from 'src/app/grouping/grouping.models';
import { EventProxyService, UpdateGroup } from '../services/event-proxy.service';
import { GroupingService } from 'src/app/grouping/grouping.service';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';

function compare(v1: any, v2: any) {
  return v1 < v2 ? -1 : v1 > v2 ? 1 : 0;
}

interface Chip {
  type: string;
  filter?: { col: string; action?: string; condition: string } | undefined;
  search?: string;
  viewValue: string;
}

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
  providers: [DatePipe]
})
export class TableComponent implements OnInit, OnChanges, OnDestroy {
  @Input() addNewOption = true;
  @Input() showImport = false;
  @Input() nestedTable = true;
  @Input() data: any[] = [];
  @Input() multipleSelection = false;
  @Input() bookmarkOption = false;
  @Input() modal: any
  @Input() serviceToUse: string = 'tour';
  @Input() calendarViewOption: CalenderView = CalenderView.none;
  @Input() completedViewOption: boolean = false;
  @Input() showCalenderView: boolean = false;
  @Input() multipleSelectionButtons: boolean = false;
  @Input() groupsOptions: MainGroup[] = []
  @Input() groupKey: string = ''
  @Input() showGroupButton: boolean = false
  @Input() showTemporaryAgToggle: boolean = false;

  @Output() selectedItem = new EventEmitter();
  @Output() eventClicked = new EventEmitter();
  @Output() addItem = new EventEmitter();
  @Output() requestData = new EventEmitter();
  @Output() selectedSubItem = new EventEmitter();
  @Output() selectedJobItem = new EventEmitter();
  @Output() selectedItemsToRelease = new EventEmitter();
  @Output() selectedItemsToPullBack = new EventEmitter();
  @Output() selectedItemsToCopy = new EventEmitter();
  @Output() createTourForItem = new EventEmitter();
  @Output() addSelectedToGroupEmit = new EventEmitter();
  @Output() temporaryToggleChange = new EventEmitter<boolean>()
  @Output() bubbleSaveEvent = new EventEmitter();

  dataCopy: any[] = [];
  columns: any[] = [];
  nestedColumns: any[] = [];
  searchQuery: string = '';
  sortedColumn: SortEvent | undefined;
  btnTap: string = 'active';
  page = 1;
  pageSize: number = 50;
  showNestedTable = true;
  filterAction = '0';
  filterColBy = '';
  chipQueries: Chip[] = [];
  language: any;
  selectedAll = false;
  refreshTime: Date = new Date();
  refetchInterval: any;
  reloadCounter = 0;
  importTours: any;
  showNewGroupModal = false
  temporaryToggle: boolean = true;
  newGroup = {
    name: '',
    description: '',
    edit: true
  }


  faAdd = faPlus;
  faAsc = faSortUp;
  faDesc = faSortDown;
  faDirections = faSort;
  faSearchIcon = faSearch;
  faCalendarIcon = faCalendarAlt;
  faMinusIcon = faMinusSquare;
  faPlusIcon = faPlusSquare;
  faFilterIcon = faFilter;
  faSyncIcon = faSync;
  faLeftIcon = faChevronLeft;
  faRightIcon = faChevronRight;
  faBookmarkIcon = faBookmark;
  faCheck = faCheck;
  faUpload = faFileImport;
  faExclamation = faExclamation;
  faLock = faLock;
  faLockOpen = faUnlock;



  constructor(
    private route: ActivatedRoute,
    private modalService: NgbModal,
    private datePipe: DatePipe,
    private sharedService: SharedService,
    private eventProxyService: EventProxyService,
    private groupingService: GroupingService,
    private toaster: ToastrService,
    private translate: TranslateService
  ) {
    this.filterColBy = this.today();
  }

  async getLanguage() {
    this.sharedService.getLanguageJson().subscribe(response => this.language = response)
  }

  async ngOnInit(): Promise<void> {
    await this.getLanguage()

    if (this.data.length > 0) this.columns = Object.keys(this.data[0]);
    const nested = this.nestedTable ? this.data.find(x => x.nested.length > 0) : null;
    if (this.nestedTable && nested) this.nestedColumns = Object.keys(nested.nested[0]);
    this.assignCopy();
    this.refreshTime = new Date();
    this.route.queryParams.subscribe(x => {
      if (x.item) {
        this.selectedItem.emit(x.item);
      }
    });


    this.refetchInterval = setInterval(() => {
      this.refetchData();
    }, 60_000);


    this.columns = this.columns.filter(x => {
      return x !== "isAutoGeneratedUserWithTimeRegs";
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.isSomeSelected()) return;
    this.data = changes.data.currentValue;

    if (this.data.length > 0) this.columns = Object.keys(this.data[0]);
    this.refreshTime = new Date();
    this.assignCopy();
    if (this.sortedColumn) this.onSort(this.sortedColumn);
    this.filterCol();
    this.search();

    this.reloadCounter++;


    this.columns = this.columns.filter(x => {
      return x !== "isAutoGeneratedUserWithTimeRegs";
    });

  }


  assignCopy() {
    this.dataCopy = Object.assign([], this.data.map(x => ({ ...x, selected: false })));
  }

  selected(event: any): void {
    this.selectedItem.emit(event);
  }

  jobSelected(event: any): void {
    this.selectedJobItem.emit(event);
  }

  event(event: any): void {
    this.eventClicked.emit(event);
  }

  closeImport(event: any): void {
    this.importTours = event;
    this.requestData.emit();
  }

  onSort({ column, direction }: any) {
    if (direction === '') {
      this.sortedColumn = undefined;
      this.dataCopy = Object.assign([], this.data.filter(x => this.dataCopy.find(c => c.id === x.id)))
      return;
    }

    this.sortedColumn = { column: column, direction: direction };
    this.dataCopy = this.dataCopy.sort((a, b) => {
      if (!a[column]) {
        a[column] = '';
      }
      if (!b[column]) {
        b[column] = '';
      }
      const res = isNaN(Number(a[column])) || isNaN(Number(b[column])) ? compare(a[column], b[column]) : a[column] - (b[column]);
      return direction === 'asc' ? res : -res;
    });
  }

  sortIconDirection(direction: string): IconDefinition {
    if (direction === 'asc') {
      return this.faAsc;
    } else return this.faDesc;
  }

  search(): void {
    this.chipQueries.filter(value => value.type === 'search')?.forEach(value => {
      this.dataCopy = Object.assign([], this.dataCopy)
        .filter(item =>
          Object.keys(item).some(k => {
            const itemValue: string = (item[k]);
            return !itemValue ? '' : itemValue.toString().toUpperCase().includes(<string>value?.search?.toUpperCase());
          }));
    });
  }

  getStatusColorBg(key: string, value: string): string {
    if (key.toUpperCase() !== 'STATUS')
      return '';
    return this.sharedService.getStatusColor(value);
  }

  getValue(key: string, value: string): string {
 
    if (key != "type") {
    switch (key.toUpperCase()) {
      case 'STATUS': return `status.${value}`;
      case 'HASBEENSEEN': return '';
      case 'WRONGVEHICLE': return '';
      case 'ISLOCKED': return '';
      case 'ACTIVE': return '';
      case 'TYPE': return `type.${value.toLowerCase()}`;
      default: return value;
    }}
    return "";
  }

  getColumn(col: string): string {
    return `table.column.${col}`
  }

  //Used to switch between active and completed tours
  switchData(modalContent: any): void {

    if (this.btnTap === 'ended') {


      this.openDateModal(modalContent);
      return;
    }

    //request active data again
    this.requestData.emit();
  }

  public get calenderView(): typeof CalenderView {
    return CalenderView;
  }

  openDateModal(content: NgbModal) {


    this.modalService.open(content).result.then((result) => {
      this.requestData.emit(result)
    }, () => {
      this.btnTap = 'active';
      this.switchData(null);
    });
  }

  today(): string {
    const today = new Date();
    return <string>this.datePipe.transform(today, 'yyyy-MM-dd');
  }

  rowColor(i: number): string {
    if ((i + 1) % 2 == 0) {
      return 'bg-row clickable'
    }
    else {
      return 'clickable'
    }
  }


  addSelectedToGroupEvent(event: any) {
    const selected = this.dataCopy.filter(x => x.selected)
    this.addSelectedToGroupEmit.emit({ event, selectedItems: selected })

    // deselect all items again, to make table buttons work properly
    this.dataCopy.forEach(x => x.selected = false)

    this.eventProxyService.setEvent(new UpdateGroup(null))
  }


  filterCol(): void {
    this.chipQueries.filter(value => value.type === 'filter')?.forEach(value => {
      if (value.filter)
        value.filter.condition = <string>this.datePipe.transform(value.filter.condition, 'yyyy-MM-dd 00:00');
      this.dataCopy = Object.assign([], this.dataCopy)
        .filter((item: any) => {
          switch (value.filter?.action) {
            case '<':
              return item[value.filter.col] <= value.filter?.condition;
            case '>':
              return item[value.filter.col] >= value.filter?.condition;
            default:
              // @ts-ignore
              return (item[value.filter.col] >= value.filter.condition && item[value.filter.col] <= <string>this.datePipe.transform(value.filter.condition, 'yyyy-MM-dd 23:59'));
          }
        });
    });

  }

  popChip(chip: Chip): void {
    const index = this.chipQueries.indexOf(chip);
    if (index > -1) {
      this.chipQueries.splice(index, 1);
      this.assignCopy();
      if (this.sortedColumn) this.onSort(this.sortedColumn);
      this.search();
      this.filterCol();
    }
  }

  pushSearch(): void {
    this.assignCopy();
    this.chipQueries.push({ type: 'search', search: this.searchQuery, viewValue: this.searchQuery });
    this.filterCol();
    this.search();
    this.searchQuery = '';
  }

  pushFilter(col: string): void {
    this.assignCopy();
    const chip: Chip = {
      type: 'filter',
      filter: { col: col, action: this.filterAction, condition: this.filterColBy },
      viewValue: `${this.language.table.column[col]} ${this.filterAction?.charAt(0)} ${this.filterColBy}`
    }
    this.chipQueries.push(chip);
    this.search();
    this.filterCol();
    this.filterAction = '0';
    this.filterColBy = this.today();
  }

  selectAll(): void {
    this.dataCopy.forEach(item => item.selected = this.selectedAll);
  }

  disabledReleaseBtn(): boolean {

    return !(this.dataCopy.filter(x => x.selected).every(y => (!y.released && !(y.status === "InProgress" || y.status === "Completed" || y.status === "Paused"))) && this.dataCopy.filter(x => x.selected).length > 0);
  }
  disabledpullBackBtn(): boolean {

    return !(this.dataCopy.filter(x => x.selected).every(y => (y.released && !(y.status === "InProgress" || y.status === "Completed" || y.status === "Paused"))) && this.dataCopy.filter(x => x.selected).length > 0);
  }
  disabledBtn(): boolean {
    return !this.dataCopy.some(x => x.selected);
  }

  emitSelectedToRelease(): void {
    this.selectedItemsToRelease.emit(this.dataCopy.filter(x => x.selected)?.map(x => x.id));
    this.assignCopy();
  }
  emitSelectedToPullBack(): void {
    this.selectedItemsToPullBack.emit(this.dataCopy.filter(x => x.selected)?.map(x => x.id));
    this.assignCopy();
  }

  emitSelectedToCopy(content: any): void {
    this.modalService.open(content).result.then((result) => {
      if (!this.dataCopy) return;
      if (!result.endDate) result.endDate = result.startDate;
      this.selectedItemsToCopy.emit({ startDate: result.startDate, endDate: result.endDate, ids: this.dataCopy.filter(x => x.selected)?.map(x => x.id) });
      this.assignCopy();
    }, () => {
      this.assignCopy();
    });
  }

  addDaysToFilterColBy(value: number): void {
    const date = new Date(this.filterColBy);
    date.setDate(date.getDate() + value);
    this.filterColBy = <string>this.datePipe.transform(date, 'yyyy-MM-dd');
  }

  refetchData(): void {
    if (this.showCalenderView == true) return;
    if (this.btnTap === 'ended') return;
    if (this.isSomeSelected())
      return;
    this.requestData.emit();
  }

  setBookmark(value: number): void {
    if (value === 1) {
      const date = new Date();
      date.setDate(date.getDate() + 1);
      this.filterColBy = <string>this.datePipe.transform(date, 'yyyy-MM-dd');
      this.filterAction = '==='
      this.pushFilter('expectedStart');
    }
    if (value === 2) {
      const date1 = new Date();
      date1.setDate(date1.getDate() + 1);
      this.filterColBy = <string>this.datePipe.transform(date1, 'yyyy-MM-dd');
      this.filterAction = '>'
      this.pushFilter('expectedStart');
      const date2 = new Date();
      date2.setDate(date1.getDate() + 7);
      this.filterColBy = <string>this.datePipe.transform(date2, 'yyyy-MM-dd');
      this.filterAction = '<'
      this.pushFilter('expectedStart');
    }
  }

  isSomeSelected(): boolean {
    return this.dataCopy.some(x => x.selected);
  }

  saveGroup(event: any) {
    // open the "modal" with the card
    // save the group and use the response from the backend to grab the ID and use for automatically adding the selected items to the newly created group
    this.bubbleSaveEvent.emit({event, selected: this.dataCopy.filter(x => x.selected)})

    this.dataCopy.forEach(x => x.selected = false)
    this.showNewGroupModal = false
    this.newGroup.name = ''
    this.newGroup.description = ''
    this.newGroup.edit = true



  }



  toggleTeporaryToggle() {
    this.temporaryToggle = !this.temporaryToggle;
    this.temporaryToggleChange.emit(this.temporaryToggle);
  }


  ngOnDestroy(): void {
    clearInterval(this.refetchInterval);
  }
}
