import { Component, EventEmitter, Input, Output, ViewChild, signal, ElementRef, ChangeDetectorRef } from '@angular/core'
import { WidgetComponent } from "../../shared/widget-component"
import { AbstractControl, FormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms"
import { TourService } from "../shared/tour.service"
import { CustomerService } from "../../customers/shared/customer.service"
import { FrontVehicleService } from "../../front-vehicles/shared/front-vehicle.service"
import { BackVehicleService } from "../../back-vehicles/shared/back-vehicle.service"
import { UserService } from "../../users/shared/user.service"
import { Observable, Subscription } from "rxjs"
import { UserModel } from "../../users/shared/user.model"
import { FrontVehicleModel } from "../../front-vehicles/shared/front-vehicle.model"
import { BackVehicleModel } from "../../back-vehicles/shared/back-vehicle.model"
import { CustomerModel } from "../../customers/shared/customer.model"
import { CreateMode } from "../../constants"
import { TransportEquipmentModel } from "../../transport-equipments/shared/transport-equipment.model"
import { TransportEquipmentService } from "../../transport-equipments/shared/transport-equipment.service"
import { AddressModel } from "../../shared/address-widget/shared/address.model"
import { CreateTourModel } from "../shared/tour.model"
import { NotificationService } from "../../shared/services/notification.service"
import { ResourceTypes, SharedService } from "../../shared/services/shared.service"
import { FreightService } from "../shared/freight.service"
import { FreightModel } from "../shared/freight.model"
import { DatePipe } from "@angular/common"
import { NoteModel } from "../../shared/note-widget/shared/note.model"
import * as moment from "moment"
import { DateValidator } from "../../form-validators/date-validator"
import DurationConstructor = moment.unitOfTime.DurationConstructor
import { ITuple } from "../../shared/tuple"
import { faArrowDown, faArrowUp, faClone, faKeyboard, faMinus, faPlus, faSync, faTrash, faTrashAlt, faTruck, faTruckRampBox, faStreetView, faPencilAlt } from "@fortawesome/free-solid-svg-icons"
import { GeocodeService } from "../../shared/services/geocode.service"
import { HttpClient } from "@angular/common/http"
import { environment } from 'src/environments/environment'
import { TranslateService } from '@ngx-translate/core'
import { IDropdownSettings } from 'ng-multiselect-dropdown'
import { ResourceTypeService } from 'src/app/shared/services/resource-type.service'
import { FreightTypeService } from 'src/app/freight-types/shared/freight-types.service'
import { Product } from 'src/app/freight-types/shared/freight-types.model'
import { AddressWidgetComponent } from 'src/app/shared/address-widget/address-widget.component'
import { ProductTypePickerComponent } from 'src/app/molecules/product-type-picker/product-type-picker.component'
import { EventProxyService, ProductModalClose, ProductModalEdit, ProductModalOpen } from 'src/app/shared/services/event-proxy.service'
import { ModalComponent } from 'src/app/molecules/modal/modal.component'


export interface ResourceTypesCollection {
  frontVehicleResourceTypes?: ResourceTypes[],
  backVehicleResourceTypes?: ResourceTypes[],
  transportEquipmentResourceTypes?: ResourceTypes[]
}

export interface StreetViewCoordinates {
  lat: number;
  lng: number;
}
@Component({
  selector: 'app-tour-create',
  templateUrl: './tour-create.component.html',
  styleUrls: ['./tour-create.component.scss'],
  providers: [DatePipe]
})
export class TourCreateComponent extends WidgetComponent {
  private EventProxySubscription: Subscription | undefined

  @ViewChild('addressWidget') addressWidget: AddressWidgetComponent | undefined;
  @ViewChild('sighsloudly') sighsloudly: ProductTypePickerComponent | undefined;
  @ViewChild('productTypePicker') productTypePicker!: ProductTypePickerComponent | undefined

  @Input() startDate: Date | undefined;
  @Input() backVehicles: BackVehicleModel[] | undefined;
  @Input() transportEquipment: TransportEquipmentModel[] | undefined;
  @Input() arriveBackVehicle: string[] = [];
  @Input() leaveBackVehicle: string[] = [];
  @Input() arriveEquipment: string[] = [];
  @Input() leaveEquipment: string[] = [];
  @Input() selectedUser: UserModel | null | undefined; // used when selecting a user before adding an address
  @Input() selectedFrontVehicle: FrontVehicleModel | null | undefined; // used when selecting a front vehicle before adding an address
  @Input() selectedBackVehicles: BackVehicleModel[] = []; // used when selecting a back vehicle before adding an address
  @Input() selectedTransportEquipment: TransportEquipmentModel | null | undefined; // used when selecting a transport equipment before adding an address


  @Output() created = new EventEmitter();
  @Output() cancel = new EventEmitter();
  @Output() showStreetview = new EventEmitter();



  selectLeaveBackVehicle: string[] = [];
  selectArriveBackVehicle: string[] = [];
  selectArriveEquipment: string[] = [];
  selectLeaveEquipment: string[] = [];

  formGroup: UntypedFormGroup;
  selectedAddress: any;
  selectedSecondUser: UserModel | null | undefined;
  selectedCustomer: CustomerModel | null | undefined;
  loading = false;
  language: any;
  userSelect = false;
  secondUserSelect = false;
  showSecondUserField = false;
  customerSelect = false;
  frontVehicleSelect = false;
  backVehicleSelect = false;
  transportEquipmentSelect = false;
  createNewAddress = false;
  repetitionDateTimes: ITuple[] = [];
  faCloneIcon = faClone;
  faUpIcon = faArrowUp;
  faDownIcon = faArrowDown
  faAddIcon = faPlus
  faTrashIcon = faTrash
  faSyncIcon = faSync
  faMinusIcon = faMinus
  faStreetView = faStreetView
  faKeyboard = faKeyboard
  faTruck = faTruck
  faEdit = faPencilAlt
  faTrash = faTrashAlt
  faTruckRampBox = faTruckRampBox
  isGeocoding: boolean = false
  isReverseGeocoding: boolean = false
  addressCount: number = 0
  repititionEnabled: boolean = false

  customerAddresses: AddressModel[] = []

  products = signal<Product[]>([])
  expectedStartDate: Date | string = new Date()
  expectedEndDate: Date | string = new Date()

  currentLat: number = 55.850849
  currentLon: number = 9.845612

  currSearch: number = 0

  streetviewLat: number = 55.850849;
  streetviewLng: number = 9.845612;

  showStreetViewModal: boolean = false;
  searchResults: any = []

  freightTypeUnitTitle: string = ''
  freightTypeDescValue: string = ''

  dropdownList: any[] = []
  arriveBackVehicles: any = []
  leaveBackVehicles: any = []
  showArriveMultiSelect: boolean[] = [false]
  showLeaveMultiSelect: boolean[] = [false]
  indexPointer: number = 0


  resourceTypes: ResourceTypesCollection = {
    frontVehicleResourceTypes: [],
    backVehicleResourceTypes: [],
    transportEquipmentResourceTypes: []
  }

  dropdownSettings: IDropdownSettings = {
    singleSelection: false,
    idField: 'item_id',
    textField: 'item_text',
    allowSearchFilter: true,
    searchPlaceholderText: this.translate.instant('reports.multiselect.search'),
    noDataAvailablePlaceholderText: this.translate.instant('reports.multiselect.noDataAvailable'),
    itemsShowLimit: 3,
    limitSelection: 3
  }

  @ViewChild('freightTypeDescSelect') freightTypeDescSelect!: ElementRef;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private http: HttpClient,
    private tourService: TourService,
    private customerService: CustomerService,
    private frontVehicleService: FrontVehicleService,
    private backVehicleService: BackVehicleService,
    private userService: UserService,
    private transportEquipmentService: TransportEquipmentService,
    private notifyService: NotificationService,
    protected sharedService: SharedService,
    private freightService: FreightTypeService,
    private resourceTypeService: ResourceTypeService,
    private datePipe: DatePipe,
    private geocoder: GeocodeService,
    private translate: TranslateService,
    private cd: ChangeDetectorRef,
    private freightTypeService: FreightTypeService,
    private eventProxy: EventProxyService
  ) {
    super()

    this.formGroup = this.formBuilder.group({
      orderId: ['', [Validators.maxLength(40)]],
      description: ['', [Validators.maxLength(40), Validators.required]],
      freight: ['', [Validators.maxLength(255)]],
      freightTotalAmount: [0],
      removedFreightType: [null],
      freightUnit: [''],
      surcharge: [''],
      freightTypeId: [1],
      arriveBackVehicle: [this.arriveBackVehicle ? this.arriveBackVehicle : '', Validators.maxLength(255)],
      selectArriveBackVehicle: ['', Validators.maxLength(255)],
      arriveEquipment: [this.arriveEquipment ? this.arriveEquipment : '', Validators.maxLength(255)],
      selectArriveEquipment: ['', Validators.maxLength(255)],
      leaveBackVehicle: ['', Validators.maxLength(255)],
      selectLeaveBackVehicle: ['', Validators.maxLength(255)],
      leaveEquipment: ['', Validators.maxLength(255)],
      selectLeaveEquipment: ['', Validators.maxLength(255)],
      expectedStartDate: ['', Validators.required],
      expectedEndDate: ['', [DateValidator('expectedStartDate'), Validators.required]],
      frontVehicleId: [null],
      backVehicleIds: [[]],
      transportEquipmentId: [null],
      assignedUserId: [null],
      customerId: [null],
      tagIds: [[]],
      attachment: [null],
      note: [null],
      repetition: 0,
      endRepetitionDate: [{ value: '', disabled: true }, DateValidator('expectedStartDate')],
      addresses: this.formBuilder.array([]),
      type: ['Inland', Validators.required],
      isSpecial: false,
      assignedSecondUserId: null,
      isLocked: true,
      calculatedTotalFreightAmount: 0
    })


    this.EventProxySubscription = this.eventProxy.getEvent().subscribe((param: any) => {
      if (param instanceof ProductModalClose) {
        // this is for the overall tour create modal
        if (param.data.type == 9999) {
          if (this.tourFreights.length > 0) {
            this.tourFreights.at(0).patchValue(
              this.formBuilder.group({
                productTypeId: param.data.formGroup.value.productTypeId,
                productType: {name: param.data.formGroup.value.productType.name},
                type: param.data.formGroup.value.type,
                surcharges: [param.data.formGroup.value.surcharges],
                priceUnitId: param.data.formGroup.value.priceUnitId,
                amount: param.data.formGroup.value.amount,
                freightUnit: param.data.formGroup.value.freightUnit,
                freightUnitId: param.data.formGroup.value.freightUnitId
              })
            )
          } else {
            this.tourFreights.push(
              this.formBuilder.group({
                productTypeId: param.data.formGroup.value.productTypeId,
                productType: {name: param.data.formGroup.value.productType.name},
                type: param.data.formGroup.value.type,
                surcharges: [param.data.formGroup.value.surcharges],
                priceUnitId: param.data.formGroup.value.priceUnitId,
                amount: param.data.formGroup.value.amount,
                freightUnit: param.data.formGroup.value.freightUnit,
                freightUnitId: param.data.formGroup.value.freightUnitId
              })
            )
          }
        }
      }
    })
  }



  async getLanguage() {
    this.sharedService.getLanguageJson().subscribe(response => this.language = response)
  }

  async ngOnInit(): Promise<void> {
    await this.getLanguage()

    this.selectedUserCallback({ user: this.selectedUser })
    this.selectedFrontVehicleCallback({ frontVehicle: this.selectedFrontVehicle })
    this.selectedBackVehicleCallback({ backVehicles: this.selectedBackVehicles })
    this.selectedTransportEquipmentCallback({ transportEquipment: this.selectedTransportEquipment })

    if (this.startDate) {
      this.formGroup.patchValue(({
        expectedStartDate: this.datePipe.transform(this.startDate, 'yyyy-MM-dd'),
        expectedStartTime: `${this.getFormattedTime(new Date(this.startDate).getHours())}:${this.getFormattedTime(new Date(this.startDate).getMinutes())}`,
      }))
    }

    this.transportEquipment = this.transportEquipment?.filter(x => x.resourceType !== null)
    // this.backVehicles = this.backVehicles?.filter(x => x.resourceType !== null)
    this.backVehicles?.forEach(backVehicle => {
      this.dropdownList.push({ item_id: backVehicle.id, item_text: backVehicle.unitIdentification + ' / ' + backVehicle.registrationNumber });
    });


    this.onChanges()
  }



  ngOnChanges() {
    this.transportEquipment = this.transportEquipment?.filter(x => x.resourceType !== null)
  }


  onChanges(): void {
    this.formGroup.get('repetition')?.valueChanges.subscribe(x => {
      if (x === 0) {
        this.formGroup.patchValue({ endRepetitionDate: '' });
        this.formGroup.get('endRepetitionDate')?.disable();
      }
      else {
        this.formGroup.get('endRepetitionDate')?.addValidators(Validators.required);
        this.formGroup.get('endRepetitionDate')?.enable();
      }
    });

    // update expectedEndDate when expectedStartDate changes
    this.formGroup.get('expectedStartDate')?.valueChanges.subscribe((x) => {
      this.formGroup.patchValue({ expectedEndDate: x })
    })
  }


  // should update the associated input field as well as the leaveBackVehicle one
  selectedArriveBackVehicle(index: number) {
    this.arriveBackVehicle[index] = this.selectArriveBackVehicle[index]

    this.leaveBackVehicle[index] = this.selectArriveBackVehicle[index]
    this.selectLeaveBackVehicle[index] = this.selectArriveBackVehicle[index]
  }

  // should update the associated input field but not the arriveBackVehicle one since it's assumed that has been set and done
  selectedLeaveBackVehicle(index: number) {
    this.leaveBackVehicle[index] = this.selectLeaveBackVehicle[index]
  }

  // should update the associated input field as well as the leaveEquipment one
  selectedArriveEquipment(index: number) {
    this.arriveEquipment[index] = this.selectArriveEquipment[index]

    this.leaveEquipment[index] = this.selectArriveEquipment[index]
    this.selectLeaveEquipment[index] = this.selectArriveEquipment[index]
  }

  // should update the associated input field but not the arriveBackVehicle one since it's assumed that has been set and done
  selectedLeaveEquipment(index: number) {
    this.leaveEquipment[index] = this.selectLeaveEquipment[index]
  }


  get addresses() {
    return this.formGroup.get('addresses') as FormArray
  }

  get tourFreights() {
    return this.formGroup.get('tourFreights') as FormArray
  }



  /**
   * Sends event for tour.component to listen to and open the modal
   * This is a workaround to avoid a css nightmare by trying to place the fixed modal in the sidebar
   */
  openProductModal(addressIndex?: number) {
    this.eventProxy.setEvent(new ProductModalOpen({
      addressIndex: addressIndex ? addressIndex : 9999,
      products: this.products,
      formGroup: this.formBuilder.group({
        productTypeId: 0,
        productType: {name: ''},
        type: '',
        surcharges: this.formBuilder.array([]),
        priceUnitId: null,
        amount: 0,
        freightUnit: '',
        freightTypeId: ''
      })
    }))
  }

  editProductModal(productIndex: number) {
    this.eventProxy.setEvent(new ProductModalEdit({
      addressIndex: 9999,
      products: this.products,
      formGroup: this.tourFreights.at(productIndex)
    }))
  }

  deleteProduct(productIndex: number) {
    this.tourFreights.removeAt(productIndex)
  }






  /**
   * When the user toggles between multiselect and free-form writing
   */
  switchArriveField(index: number): void {
    this.showArriveMultiSelect[index] = !this.showArriveMultiSelect[index];
  }

  /**
   * When the user toggles between multiselect and free-form writing
   */
  switchLeaveField(index: number): void {
    this.showLeaveMultiSelect[index] = !this.showLeaveMultiSelect[index]
  }

  getFormattedTime(time: number): string {
    return ('0' + time).slice(-2)
  }

  inheritFromCustomer(): void {
    if (this.formGroup.value.customerId)
      this.getCustomer().subscribe(response => {
        this.formGroup.value.tagIds = []
        this.addresses.clear()
        // for (let i = 0; i < response.addresses?.length; i++) { this.addArriveAddress() }
        this.formGroup.patchValue({ addresses: response.addresses })

      })
  }

  inheritFromChauffeur(): void {
    if (this.formGroup.value.assignedUserId)
      this.userService.getSingleWithMeta(this.formGroup.value.assignedUserId).subscribe(response => {
        this.selectedFrontVehicleCallback(response?.userMeta);
        this.selectedBackVehicleCallback(response?.userMeta);
        this.selectedBackVehicleCallback(response?.userMeta);
        this.selectedTransportEquipmentCallback(response?.userMeta);
      });
  }

  getCustomer(): Observable<CustomerModel> {
    return this.customerService.getSingle(this.formGroup.value.customerId);
  }


  addAddressToList(event: any, index?: number) {
    if (index) this.formGroup.value.addresses[index] = event
  }



  create(): void {
    this.loading = true;
    const values = this.formGroup.value

    // modify values.tourFreights to use an array of ids for surcharges instead
    // values.tourFreights = values.tourFreights.map((x: any) => {
    //   return {
    //     productTypeId: x.productTypeId,
    //     type: x.type,
    //     surcharges: x.surcharges.map((y: any) => y.id)
    //   }
    // })


    let creations: CreateTourModel[] = [];
    //if (this.repetitionDateTimes.length === 0) this.calculateRepetitionCount();
    this.calculateRepetitionCount();
    this.repetitionDateTimes.forEach(repetition => {
      const model: CreateTourModel = {
        orderId: values.orderId,
        assignedUserId: this.selectedUser
          && !this.selectedUser.tours?.some(x => moment(repetition.item1).isBetween(moment(x.expectedStartDate), moment(x.expectedEndDate)))
          && !this.selectedUser.tours?.some(x => moment(repetition.item2).isBetween(moment(x.expectedStartDate), moment(x.expectedEndDate)))
          ? values.assignedUserId : values.assignedUserId,
        description: values.description,
        freight: values.freight,
        freightTypeId: values.freightTypeId,
        freightTotalAmount: values.freightTotalAmount,
        backVehicleIds: values.backVehicleIds,
        tagIds: values.tagIds,
        customerId: values.customerId,
        frontVehicleId: values.frontVehicleId,
        transportEquipmentId: values.transportEquipmentId,
        expectedStartDate: repetition.item1,
        expectedEndDate: repetition.item2,
        note: values.note,
        addresses: values.addresses,
        tourFreight: values.tourFreights,
        TourType: values.type,
        isSpecial: values.isSpecial,
        assignedSecondUserId: this.selectedSecondUser
          && !this.selectedSecondUser.tours?.some(x => moment(repetition.item1).isBetween(moment(x.expectedStartDate), moment(x.expectedEndDate)))
          && !this.selectedSecondUser.tours?.some(x => moment(repetition.item2).isBetween(moment(x.expectedStartDate), moment(x.expectedEndDate)))
          ? values.assignedSecondUserId : values.assignedSecondUserId,
        isLocked: values.isLocked
      };
      creations.push(model)
    });

    this.tourService.createRange({ models: creations }, this.formGroup.value.attachment?.file as File,
      (this.formGroup.value.attachment ? this.formGroup.value.attachment.showInApp : false))
      .subscribe(() => {
        this.created.emit();
        this.notifyService.successMessage(this.language?.message?.createdTour);
      }, error => this.loading = false);
  }

  selectedUserCallback(e: any): void {
    this.userSelect = false;
    this.selectedUser = e.user;
    this.formGroup.patchValue({ assignedUserId: this.selectedUser?.id });
    if (this.selectedUser) {
      this.inheritFromChauffeur();
    }
  }

  selectedSecondUserCallback(e: any): void {
    if (this.selectedUser?.id == e.user.id) return;
    this.secondUserSelect = false;
    this.selectedSecondUser = e.user;
    this.formGroup.patchValue({ assignedSecondUserId: this.selectedSecondUser?.id });
  }

  toogleSecondUserField(): void {
    this.showSecondUserField = !this.showSecondUserField;
    if (!this.showSecondUserField) {
      this.secondUserSelect = false;
      this.selectedSecondUser = null;
      this.formGroup.patchValue({ assignedSecondUserId: null });
    }
  }

  selectedCustomerCallback(e: any): void {
    this.customerSelect = false
    this.selectedCustomer = e.customer

    this.customerService.getSingle(e.customer.id).subscribe(response => {
      if (this.selectedCustomer && response.addresses.length >= 1) {
        this.selectedCustomer.addresses = response.addresses
      }
    })

    this.formGroup.patchValue({ customerId: this.selectedCustomer?.id })
  }

  selectedFrontVehicleCallback(e: any): void {
    this.frontVehicleSelect = false;
    this.selectedFrontVehicle = e?.frontVehicle;
    this.formGroup.patchValue({ frontVehicleId: this.selectedFrontVehicle?.id });
  }

  selectedBackVehicleCallback(e: any): void {
    this.backVehicleSelect = false;
    this.selectedBackVehicles = e?.backVehicles;

    this.formGroup.patchValue({ backVehicleIds: this.selectedBackVehicles?.map(x => x.id) })
  }

  selectedTransportEquipmentCallback(e: any): void {
    this.transportEquipmentSelect = false;
    this.selectedTransportEquipment = e?.transportEquipment;
    this.formGroup.patchValue({ transportEquipmentId: this.selectedTransportEquipment?.id });
  }

  get createModes() {
    return CreateMode;
  }

  get backVehiclesString(): string {
    let str = '';

    this.selectedBackVehicles?.forEach(x => {
      str = str + ` ${x.unitIdentification} ${x.registrationNumber},`
    });

    str = str.slice(0, -1)
    return str;
  }

  patchNote(note: NoteModel): void {
    this.formGroup.patchValue({ note: note });
  }

  patchAttachment(attachment: File): void {
    this.formGroup.patchValue({ attachment: attachment });
  }


  onChangeFreightType(event: any): void {
    this.freightTypeDescValue = this.freightTypeDescSelect.nativeElement.value;
    this.freightTypeUnitTitle = this.language.tour.freightTypeUnit[this.freightTypeDescValue];
  }

  calculateRepetitionCount(): number {
    const startDate = moment(this.datePipe.transform(this.formGroup.value.expectedStartDate, 'yyyy-MM-dd'));
    let selectedStopDate = moment(this.formGroup.value.endRepetitionDate);
    switch (this.formGroup.value.repetition) {
      case 1: {
        const difference = selectedStopDate.diff(startDate, 'days', true);
        this.setRepetitionDateTimes('days', difference);
        return difference;
      }
      case 2: {
        const difference = selectedStopDate.diff(startDate, 'weeks');
        this.setRepetitionDateTimes('weeks', difference);
        return difference;
      }
      case 3: {
        const difference = selectedStopDate.diff(startDate, 'months');
        this.setRepetitionDateTimes('months', difference);
        return difference;
      }
      default: {
        this.setRepetitionDateTimes('days', 0);
        return 0;
      }
    }
  }

  setRepetitionDateTimes(unitOfTime: DurationConstructor, repetitions: number): void {
    this.repetitionDateTimes = [];
    for (let i = 0; i <= repetitions; i++) {
      const start = moment(this.datePipe.transform(`${this.formGroup.value.expectedStartDate}`, 'yyyy-MM-dd HH:mm'));
      const end = moment(this.datePipe.transform(`${this.formGroup.value.expectedEndDate}`, 'yyyy-MM-dd HH:mm'));
      this.repetitionDateTimes?.push({ item1: start.add(i, unitOfTime).toDate(), item2: end.add(i, unitOfTime).toDate() });
    }
  }



  updateArriveBackvehicles(event: any) {
    this.arriveBackVehicles[event.index] = event.data
  }






  emitStreetview(form: StreetViewCoordinates) {
    if (form.lat != undefined && form.lng != undefined) {

      this.streetviewLat = form.lat;
      this.streetviewLng = form.lng;
      this.showStreetViewModal = true;

    }

  }

  hideStreetview(event: any) {
    this.showStreetViewModal = false;
  }

  geocode(address: AbstractControl): void {
    this.isGeocoding = true;
    const values = address.value;
    this.geocoder.geocodeAddress(values.street, values.zipcode, values.city, values.state, values.country).subscribe(response => {
      address.patchValue({ longitude: response.longitude, latitude: response.latitude });
      this.isGeocoding = false;
    }, error => this.isGeocoding = false);
  }

  reverseGeocode(address: AbstractControl): void {
    this.isReverseGeocoding = true;
    const values = address.value;
    this.geocoder.reverseGeocodeAddress(values.latitude, values.longitude).subscribe(response => {
      address.patchValue({
        country: response.country,
        state: response.state,
        zipcode: response.zipcode,
        city: response.city,
        street: response.street,
      });
      this.isReverseGeocoding = false;
    }, error => this.isReverseGeocoding = false);
  }

  getValue(value: string) {
    this.http
      .get(
        'https://api.tomtom.com/search/2/search/' +
        value + '.json?' +
        'lat=' + this.currentLat + '&' +
        'lon=' + this.currentLon + '&' +
        'minFuzzyLevel=1&' +
        'maxFuzzyLevel=2&' +
        'view=Unified&' +
        'relatedPois=off&' +      'returnPostalCodeForPOI=true&' +
        'typeahead=true&' +
        'key=' + environment.tomtom.key)
      .subscribe((data: any) => (this.searchResults = data['results']));
  }

  generateAdressTitle(result: any) {
    var returnString = "";
    if (result.poi !== undefined) {
      if (result.poi.name !== undefined) returnString += result.poi.name + " ";
    }

    if (result.address !== undefined) {


      let city = "";

      if (result.address.municipality !== undefined) city = result.address.municipality;
      if (result.address.municipalitySubdivision !== undefined) city = result.address.municipalitySubdivision;



      if (result.address.streetName !== undefined) returnString += result.address.streetName + " ";
      if (result.address.streetNumber !== undefined) returnString += result.address.streetNumber + " ";
      if (result.address.municipality !== undefined) returnString += city  + " ";
      if (result.address.country !== undefined) returnString += ", " + result.address.country + " ";
    }

    return returnString;
  }

  fillAddressForm(result: any, address: AbstractControl) {
    if (result.address !== undefined) {
      if (result.address.streetName !== undefined) address.patchValue({ street: result.address.streetName });
      if (result.address.streetName !== undefined && result.address.streetNumber !== undefined) address.patchValue({ street: result.address.streetName + " " + result.address.streetNumber });
      if (result.address.municipality !== undefined) address.patchValue({ city: result.address.municipality });
      if (result.address.country !== undefined) address.patchValue({ country: result.address.country });
      if (result.address.postalCode !== undefined) address.patchValue({ zipcode: result.address.postalCode });
      if (result.address.countrySubdivision !== undefined) address.patchValue({ state: result.address.countrySubdivision });
    }

    if (result.position !== undefined) {
      address.patchValue({ longitude: result.position.lon, latitude: result.position.lat });
    }

    this.searchResults = null;
  }

  selectedFreightType: any = 1;

  //event handler for the select element's change event
  freightTypeIdSelectChangeHandler(event: any) {
    //update the ui
    this.selectedFreightType = event.target.value;
  }

  getVehicleDisplayText(vehicle: FrontVehicleModel): string {
    return this.frontVehicleService.GetDisplayText(vehicle);
  }


}
