import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { CompanyIndex, CreateCompanyIndex, CreateIndexAdjustment, IndexAdjustment } from "./index-adjustment.model";

const url = environment.baseUrl + '/V1/Company/Index';

@Injectable({
  providedIn: 'root'
})

export class IndexAdjustmentService {
  constructor(private http: HttpClient) { }

  getAllCompanyIndexes(includeAdjustments: boolean): Observable<CompanyIndex[]> {
    const options = { params: new HttpParams().set('includeAdjustments', includeAdjustments) };
    return this.http.get<CompanyIndex[]>(`${url}/overview`, options);
  }

  getCompanyIndexById(id: number): Observable<CompanyIndex> {
    return this.http.get<CompanyIndex>(`${url}/${id}`);
  }

  createCompanyIndex(model: CreateCompanyIndex): Observable<CompanyIndex> {
    return this.http.post<CompanyIndex>(`${url}/create`, model);
  }

  deleteCompanyIndex(id: number): Observable<CompanyIndex> {
    return this.http.delete<CompanyIndex>(`${url}/delete/${id}`);
  }

  createIndexAdjustment(model: CreateIndexAdjustment): Observable<IndexAdjustment> {
    return this.http.post<IndexAdjustment>(`${url}/Adjustment/create`, model);
  }

  deleteIndexAdjustment(id: number): Observable<IndexAdjustment> {
    return this.http.delete<IndexAdjustment>(`${url}/Adjustment/delete/${id}`);
  }
}
