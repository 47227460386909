import { Component, Inject, OnInit } from '@angular/core';
import { SelectComponent } from "../select-component";
import { UserModel } from "../../../users/shared/user.model";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { UntypedFormBuilder, Validators } from "@angular/forms";
import { SharedService } from "../../../shared/services/shared.service";
import { UserService } from "../../../users/shared/user.service";
import { FrontVehicleModel } from "../../../front-vehicles/shared/front-vehicle.model";
import { FrontVehicleService } from "../../../front-vehicles/shared/front-vehicle.service";

@Component({
  selector: 'app-front-vehicle-select',
  templateUrl: './front-vehicle-select.component.html',
  styleUrls: ['./front-vehicle-select.component.scss']
})
export class FrontVehicleSelectComponent extends SelectComponent<FrontVehicleModel> implements OnInit {
  constructor(@Inject(NgbModal) modalService: NgbModal, @Inject(UntypedFormBuilder) formBuilder: UntypedFormBuilder,
    @Inject(SharedService) public sharedService: SharedService,
    private frontVehicleService: FrontVehicleService) {
    super(modalService, formBuilder, sharedService);
    this.formGroup = this.formBuilder.group({
      frontVehicle: [null, Validators.required]
    });
  }


  ngOnInit(): void {
    this.frontVehicleService.getAll().subscribe(response => {
      this.data = this.getAllActiveFrontVehiclesEquipments(response);

      this.data.forEach(e => {
        if (e.resourceType?.resourceTypeName == null)
          e.resourceType!.resourceTypeName = 'pull';
      });
      this.assignCopy();
    });
    document.getElementById('btn')?.click();
    document.getElementById('search')?.focus();
  }


  getAllActiveFrontVehiclesEquipments(frontVehicle: FrontVehicleModel[]): FrontVehicleModel[] {
    return frontVehicle
      .filter(x => {
        let hasNoActiveEvent = true;

        if(x.events) {
          x.events.forEach(e => {
            if ((new Date(e.actualEndDate) > new Date() || e.actualEndDate === null) && new Date(e.actualStartDate) < new Date())
              hasNoActiveEvent = false;
          });
        }

        return hasNoActiveEvent;
      })
      .filter(x => {
        return !(x.resourceType?.id == 0)
      });
  }
}
