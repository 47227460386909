import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import * as tt from '@tomtom-international/web-sdk-maps';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TomTomLatLng } from './shared/tomtom.model';
import { L } from '@fullcalendar/list/internal-common';
import { TranslateModule } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';
import { faLocationDot } from '@fortawesome/free-solid-svg-icons'
import { FaIconComponent } from '@fortawesome/angular-fontawesome';

@Component({
  selector: 'app-tomtom-map-picker',
  standalone: true,
  imports: [CommonModule, TranslateModule, FaIconComponent],
  templateUrl: './tomtom-map-picker.component.html',
  styleUrl: './tomtom-map-picker.component.scss'
})
export class TomtomMapPickerComponent {
  @ViewChild('content', { static: true }) content: any;

  @Input() mapHeight: string = '500px';
  @Input() mapWidth: string = '500px';

  @Output() LatLng = new EventEmitter();

  map:any
  marker:any
  pickedLat: any
  pickedLon: any
  markers: tt.Marker[] = [] // Array to store all markers
  markerSat: boolean = false
  faLocationDot = faLocationDot


  constructor(
    private httpclient: HttpClient, private modalService: NgbModal
  ) {  }

  open(content: any): void {
    const modalRef = this.modalService.open(content, { size: 'lg', backdrop: 'static', keyboard: false });
    modalRef.shown.subscribe(() => {
      this.initializeTomTomMap();
    });
  }

  initializeTomTomMap(): void {
    // Remove the existing map instance if it's there
    if (this.map) {
      this.map.remove(); // Cleanup the previous map
    }

    this.map = tt.map({
      key: environment.tomtom.key,
      container: 'tomtom-map', // Ensure this ID matches the container in HTML
      zoom: 6.7,
      center: [10.619134787499235, 55.64765864937925]
    });

    // Add click event to the map to place markers
    this.map.on('click', (event: tt.MapMouseEvent<"click">) => {
      const lngLat = event.lngLat;
      this.removeAllMarkers();

      const customMarker = document.createElement('div');
      customMarker.style.backgroundImage = 'url(assets/images/driving.png)';
      customMarker.style.backgroundSize = 'contain';
      customMarker.style.width = '40px';
      customMarker.style.height = '40px';

      this.pickedLat = lngLat.lat;
      this.pickedLon = lngLat.lng;
      this.markerSat = true;

      const marker = new tt.Marker({ element: customMarker })
        .setLngLat([lngLat.lng, lngLat.lat])
        .setDraggable(false)
        .addTo(this.map);

      this.markers.push(marker);
    });
  }

  removeAllMarkers(): void {
    this.markers.forEach(marker => marker.remove());
    this.markers = [];
  }

  emit(): void {
    let outputModel: TomTomLatLng = { lat: this.pickedLat, lng: this.pickedLon };
    this.LatLng.emit(outputModel);
  }

  // Close modal and cleanup map if needed
  onCloseModal(modal: any): void {
    modal.dismiss('Modal closed');
    if (this.map) {
      this.map.remove(); // Cleanup map if modal is closed
      this.map = null;
    }
  }

  ngOnDestroy(): void {
    if (this.map) {
      this.map.remove(); // Cleanup the map when the component is destroyed
    }
  }
}
