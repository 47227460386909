import { Component, OnInit, SimpleChanges } from '@angular/core';
import { DatePipe } from '@angular/common';
import * as htmlToImage from 'html-to-image';
import { ActivatedRoute } from '@angular/router';
import { BlobServiceClient } from "@azure/storage-blob";
import { environment } from 'src/environments/environment';
import { TranslateService } from '@ngx-translate/core';



const blobServiceClient = BlobServiceClient.fromConnectionString(environment.blobStorageSasToken);

export interface CONTENT {
  containerName: string; // desired container name
  file: any;  // file to upload
  filename: string; // filename as desired with path
}


@Component({
  selector: 'app-glec-view',
  templateUrl: './glec-view.component.html',
  styleUrls: ['./glec-view.component.scss'],
  providers: [DatePipe]
})


export class GlecViewComponent implements OnInit {
  constructor(private route: ActivatedRoute, private translate: TranslateService) {

  }

  needleValue: any | undefined = 2;
  centralLabel: any | undefined;
  gaugeWidth: number | undefined = 130;
  gaugeThickness: number | undefined = 10;
  extraInfo: any | undefined;
  tourId: any | undefined = 0;

  consumption: any | undefined = 2;
  co2e: any | undefined = 2;
  co2ePer: any | undefined = 2;
  unit: any | undefined = 2;

  gaugeType: any = "arch";

  thickness = 15;
  gaugeLabel = "123 km";
  gaugeAppendText = this.translate.instant('common.kmLiter');
  size = 140;

  thresholdConfig = {
    "0": {
      "color": "rgb(178, 14, 11)",
      "bgOpacity": 1
    },
    "0.96": {
      "color": "rgb(213, 129, 0)",
      "bgOpacity": 1
    },
    "1.92": {
      "color": "rgb(232, 211, 0)",
      "bgOpacity": 1
    },
    "3": {
      "color": "rgb(177, 188, 3)",
      "bgOpacity": 1
    },
    "4.08": {
      "color": "rgb(106, 164, 38)",
      "bgOpacity": 1
    },
    "5.04": {
      "color": "rgb(10, 145, 52)",
      "bgOpacity": 1
    }
  };


  markersConfig = {};


  ngOnInit(): void {
    const queries = this.route.snapshot.queryParamMap;

    this.needleValue = this.route.snapshot.queryParamMap.get('needleValue')?.replace(',', '.');
    this.needleValue = parseFloat(this.needleValue).toFixed(1);

    this.centralLabel = this.route.snapshot.queryParamMap.get('centralLabel');

    const consVar = this.route.snapshot.queryParamMap.get('consumption');
    const co2eVar = this.route.snapshot.queryParamMap.get('co2e');
    const co2ePerVar = this.route.snapshot.queryParamMap.get('co2ePer');
    this.consumption = consVar != null ? parseFloat(consVar).toFixed(2) : "0";
    this.co2e = co2eVar != null ? parseFloat(co2eVar).toFixed(2) : "0";
    this.co2ePer = co2ePerVar != null ? parseFloat(co2ePerVar).toFixed(2) : "0";
    this.unit = this.route.snapshot.queryParamMap.get('unit');
    this.tourId = this.route.snapshot.queryParamMap.get('tourId');
    this.markersConfig = {
      [this.needleValue]: {
        "color": "#FFF",
        "size": 12,
        "label": "",
        "type": "triangle"
      }
    };

    setTimeout(() => {
      this.generateImage(this.tourId);
    }, 200);
  }


  ngOnChanges(changes: SimpleChanges) {
    this.markersConfig = {

      [this.needleValue]: {
        "color": "#FFF",
        "size": 12,
        "label": "",
        "type": "triangle"
      }
    };
  }


  generateExtraInfo(exString: string) {
    return "" + exString + "kg. CO2e";
  }


  async generateImage(tourId: any = 0) {
    var node: any = document.getElementById('usageBox');
    var outputFile: File;

    htmlToImage.toJpeg(node)
      .then(async function (dataUrl) {
        var img = new Image();

        img.src = dataUrl;
        document.body.appendChild(img);

        if (img.src.length > 0) {
          var splitData = dataUrl.split("base64,");
          const byteString: string = window.atob(splitData[splitData.length - 1]);
          const arrayBuffer: ArrayBuffer = new ArrayBuffer(byteString.length);
          const int8Array: Uint8Array = new Uint8Array(arrayBuffer);
          for (let i = 0; i < byteString.length; i++) {
            int8Array[i] = byteString.charCodeAt(i);
          }

          const blob = new Blob([int8Array], { type: "image/jpeg" });

          outputFile = new File([blob], tourId + ".jpg", {
            type: "image/jpeg"
          });

          let content: CONTENT = {
            containerName: "glec-tour-images",
            file: outputFile,
            filename: tourId + ".jpg"
          };

          const containerClient = blobServiceClient.getContainerClient(content.containerName);
          const blockBlobClient = containerClient.getBlockBlobClient(content.filename);
          const uploadBlobResponse = await blockBlobClient.uploadBrowserData(content.file, {
            maxSingleShotSize: 4 * 1024 * 1024,
            blobHTTPHeaders: { blobContentType: content.file.type } // set mimetype
          }).then((response) => { }).catch(err => { });
        }
      })
      .catch(function (error) {
        console.error('oops, something went wrong!', error);
      });
  }
}
