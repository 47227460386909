@if (this.addresses.controls.length >= 1) {
  <ng-container *ngFor="let addressForm of addresses.controls; let i = index">
    <details
      class="form-group"
      style="border: rgba(33, 58, 69,.3) solid 1px; padding: 15px; border-radius: 6px"
      [open]="this.addressOpenState[i]"
    >
      <summary class="d-flex">
        @if (this.getAddressType(addressForm) == 'arrive') {
          <app-svg-icon [icon]="'truck-unload'" [size]="'28'"></app-svg-icon> &nbsp;
        }

        @if (this.getAddressType(addressForm) == 'leave') {
          <app-svg-icon [icon]="'truck-load'" [size]="'28'"></app-svg-icon> &nbsp;
        }

        @if (this.getAddressType(addressForm) == 'both') {
          <app-svg-icon [icon]="'truck-unload'" [size]="'28'"></app-svg-icon> &nbsp;
          <app-svg-icon [icon]="'truck-load'" [size]="'28'"></app-svg-icon> &nbsp;
        }


        @if (addressForm.get('locationName')?.value) {
          {{ addressForm.get('locationName')?.value }} <span *ngIf="addressForm.get('customerId')?.value"> &nbsp; {{ "- " + this.printCustomerFromCustomerId(addressForm.get('customerId')?.value) }}</span>
        }
        @else if (addressForm.get('street')?.value) {
          {{ addressForm.get('street')?.value }}, {{ addressForm.get('zipcode')?.value }} {{ addressForm.get('city')?.value }} <span *ngIf="addressForm.get('customerId')?.value"> &nbsp; {{ "- " + this.printCustomerFromCustomerId(addressForm.get('customerId')?.value) }}</span>
        }
        @else {
          {{ 'address.newAddress' | translate }}
        }

        <span *ngIf="!this.readOnly && !addressForm.value.id" class="position-absolute end-0 text-danger cursor:pointer" (click)="this.cancelAddressAdd(i)">{{ 'buttons.cancel' | translate }}</span>
      </summary>
      <div class="transition-widget">
        <div class="row pt-3 w-100">
          <div *ngIf="showEditButton" class="col-md-1 text-end full-width pe-1">
            <button
              *ngIf="readOnly && !address?.hasArrived"
              type="button" class="btn btn-default btn-sm"
              (click)="this.toggleReadOnly()"
              placement="left"
              ngbTooltip="{{'btn.edit' | translate}}"
            >
              <fa-icon [icon]="faEdit"></fa-icon>
            </button>
          </div>
        </div>


        <form [formGroup]="addressForm">
          <div class="col-md-12">

            <!-- Priority -->
            <div *ngIf="this.createMode == createModes.create_as_child" class="row mb-3">
              <div class="d-flex col-md-2 pe-0">
                <label for="priority">
                  {{'address.priority' | translate}}
                  <span *ngIf="addressForm.get('priority')?.hasError('required')" class="required-text">
                    {{'message.required' | translate }}
                  </span>
                </label>
                <input [attr.disabled]="true" id="priority" type="number" formControlName="priority" class="form-control bg-warning">
              </div>
              <div class="col-md-10 position-relative">
                <span style="bottom: 0; position: absolute">
                  <button class="btn btn-sm btn-default" style="margin-right: 5px" (click)="duplicate(addressForm)"><fa-icon [icon]="faCloneIcon"></fa-icon></button>
                  <button class="btn btn-sm btn-default" style="margin-right: 5px" (click)="swap(i - 1, i)"> <fa-icon [icon]="faUpIcon"></fa-icon> </button>
                  <button class="btn btn-sm btn-default" style="margin-right: 5px" (click)="swap(i, i + 1)"> <fa-icon [icon]="faDownIcon"></fa-icon> </button>
                  <button class="btn btn-sm btn-danger" (click)="removeAddressAtIndex(i)"> <fa-icon [icon]="faTrashIcon"></fa-icon> </button>
                </span>
              </div>
            </div>

            <app-btn-accept-widget
              *ngIf="!readOnly && !createMode && this.showUpdateAndCancelButtons"
              [formGroup]="addressForm"
              (accept)="update()"
              (cancel)="cancelUpdate()"
            ></app-btn-accept-widget>

            <app-btn-accept-widget
              *ngIf="createMode === createModes.create_as_child && this.showUpdateAndCancelButtons"
              [formGroup]="addressForm"
              (accept)="create()"
              (cancel)="cancelCreate.emit()"
            ></app-btn-accept-widget>


            <h6 *ngIf="address?.hasArrived">{{'address.driverArrivedAtTheAddress' | translate}} {{address?.arrivedDate | date:'dd-MM-yyyy HH:mm'}}</h6>

            <!--------------------------------------------------->

            <div class="row">
              <div *ngIf="(!this.readOnly && this.createMode == createModes.create_as_child) || (!this.readOnly && this.createMode == createModes.create_as_parent)" class="row">
                <div class="col-md-5">
                  <label for="">
                    {{ 'tour.customer' | translate }}

                    <select class="form-select" (change)="this.updateCustomerAddresses($event, true, i)" [disabled]="readOnly">
                      <option vlaue="" selected disabled>{{'tour.create.customer-select.description' | translate }}</option>
                      @for (customer of this.customers; track $index) {
                        @if (customer.id == this.selectedCustomer?.id) {
                          <option [value]="customer.id" selected>{{ customer.name }}</option>
                        }
                        @else {
                          <option [value]="customer.id">{{ customer.name }}</option>
                        }
                      }
                    </select>
                </label>
                </div>
              </div>

              <br>

              <div class="form-group row p-0 m-0">
                @if (!readOnly && (this.selectedCustomer && this.selectedCustomer.addresses) && this.selectedCustomer.addresses.length >= 1) {
                  <div class="col-12 col-md-6 col-xl-3">
                    <label [for]="'customerAddress' + i">
                      {{ 'customer.address' | translate }}
                    </label>

                    <select [name]="'customerAddresses' + i" (change)="this.updateAddress($event, i)" class="form-select">
                      <option value="" selected disabled>{{'address.selectAddress' | translate}}</option>
                      @for (address of this.selectedCustomer?.addresses; track $index) {
                        <option [value]="address.id">{{address.street}}, {{address.zipcode}} {{address.city}}</option>
                      }
                      <option value="enter">{{'address.enterOwnAddress' | translate}}</option>
                    </select>
                  </div>
                }





                <div class="d-flex flex-wrap col-12 col-md-6 col-xl-9 align-items-end">
                  <div *ngIf="!this.readOnly && (this.selectedCustomer && this.selectedCustomer.addresses.length == 0) || (!this.selectedCustomer?.id || this.showSearchAddress)" class="col address-lookup--component position-relative">
                    <label class="col-12" for="search">{{'address.searchAddress' | translate}}</label>
                    <input
                      #search
                      type="text"
                      name="search"
                      class="form-control col"
                      [(ngModel)]="addressSearch[i]"
                      (ngModelChange)="this.addressSearchUpdate.next($event)"
                      [ngModelOptions]="{standalone: true}"
                      placeholder="{{'address.searchAddressDesc' | translate}}"
                      autocomplete="off"
                      [readonly]="readOnly"
                    >


                    <app-tomtom-map-picker (LatLng)="this.updateCoordinates($event, i)" />

                    <ul *ngIf="addressSearch[i]" class="resultList" class="list-group position-absolute mt-1">
                      <li role="button" *ngFor="let result of searchResults" (click)="fillAddressForm(result, addresses, i)" class="list-group-item cursor">
                        {{generateAdressTitle(result)}}
                      </li>
                    </ul>
                  </div>


                  <button
                    #t="ngbTooltip"
                    ngbTooltip="Streetview"
                    placement="top"
                    class="btn btn-outline-secondary ms-2"
                    style="width: 130px; max-height: fit-content;"
                    [disabled]="!addressForm.get('street')?.value"
                    (click)="emitStreetview(i)"
                  >
                    <fa-icon [icon]="this.faStreetView"></fa-icon>
                    &nbsp; Streetview
                  </button>
                </div>
              </div>

              <div class="col-md-10 justify-content-center d-flex py-3" *ngIf="showCoordinates"></div>







              @if (this.readOnly || this.enterCustomAddress[i] || this.createMode == createModes.create_as_user) {
                <div class="form-group row p-0 m-0">
                  <div class="col-md-6 pe-0" *ngIf="showLocationNameAndId">
                    <label for="locationName">{{'address.locationName' | translate}}</label>
                    <input id="locationName" type="text" class="form-control" formControlName="locationName" [readonly]="readOnly"/>
                  </div>

                  <div class="col-md-3 pe-0" *ngIf="showLocationNameAndId">
                    <label for="locationId">{{'address.locationId' | translate}}</label>
                    <input id="locationId" type="text" class="form-control" formControlName="locationId" [readonly]="readOnly" />
                  </div>

                  <div class="col-md-3" *ngIf="showLocationNameAndId">
                    <label for="type">{{'address.type' | translate}}</label>
                    <input
                      *ngIf="address?.type == 'start'"
                      value="{{'address.typeDesc.start' | translate}}"
                      id="type" type="text"
                      class="form-control"
                      [readonly]="true"
                    >
                    <input
                      *ngIf="address?.type == 'waypoint'"
                      value="{{'address.typeDesc.waypoint' | translate}}"
                      id="type"
                      type="text"
                      class="form-control"
                      [readonly]="true"
                    >
                    <input
                      *ngIf="address?.type == 'stop'"
                      value="{{'address.typeDesc.stop' | translate}}"
                      id="type"
                      type="text"
                      class="form-control"
                      [readonly]="true"
                    >
                    <input
                      *ngIf="address?.type != 'waypoint' && address?.type != 'start' && address?.type != 'stop'"
                      value="{{'address.typeDesc.unknown' | translate}}"
                      id="type"
                      type="text"
                      class="form-control"
                      [readonly]="true"
                    >
                  </div>
                </div>


                <!--------------------------------------------------->

                <div class="form-group row p-0 m-0">
                  <div class="col-md-6 pe-0">
                    <label for="street">
                      {{'address.street' | translate}}
                      <span *ngIf="addresses.get('street')?.hasError('maxlength')" class="required-text">
                        {{'message.maxLength' | translate: {number: 255} }}
                      </span>
                      <span *ngIf="addresses.get('street')?.hasError('required')" class="required-text">
                        {{'message.required' | translate}}
                      </span>
                    </label>
                    <input id="street" type="text" formControlName="street" class="form-control" readonly="true">
                  </div>
                  <div class="col-md-3 pe-0">
                    <label for="city">
                      {{'address.city' | translate}}
                      <span *ngIf="addresses.get('city')?.hasError('maxlength')" class="required-text">
                        {{'message.maxLength' | translate: {number: 255} }}
                      </span>
                      <span *ngIf="addresses.get('city')?.hasError('required') && !readOnly" class="required-text">
                        {{'message.required' | translate}}
                      </span>
                    </label>
                    <input id="city" type="text" formControlName="city" class="form-control" readonly="true">
                  </div>
                  <div class="col-md-3">
                    <label for="zipcode">
                      {{'address.zipcode' | translate}}
                      <span *ngIf="addresses.get('zipcode')?.hasError('maxlength')" class="required-text">
                        {{'message.maxLength' | translate: {number: 10} }}
                      </span>
                      <span *ngIf="addresses.get('zipcode')?.hasError('required') && !readOnly" class="required-text">
                        {{'message.required' | translate}}
                      </span>
                    </label>
                    <input id="zipcode" type="text" formControlName="zipcode" class="form-control" readonly="true">
                  </div>
                </div>

                <!--------------------------------------------------->

                <div class="form-group row p-0 m-0">
                  <div class="col-md-6 pe-0">
                    <label for="state">
                      {{'address.state' | translate}}
                      <span *ngIf="addresses.get('state')?.hasError('maxlength')" class="required-text">
                        {{'message.maxLength' | translate: {number: 255} }}
                      </span>
                    </label>
                    <input id="state" type="text" formControlName="state" class="form-control" readonly="true">
                  </div>
                  <div class="col-md-6 ">
                    <label for="country">
                      {{'address.country' | translate}}
                      <span *ngIf="addresses.get('country')?.hasError('maxlength')" class="required-text">
                        {{'message.maxLength' | translate: {number: 255} }}
                      </span>
                      <span *ngIf="addresses.get('country')?.hasError('required')" class="required-text">
                        {{'message.required' | translate}}
                      </span>
                    </label>
                    <input id="country" type="text" formControlName="country" class="form-control" readonly="true">
                  </div>
                </div>

                <div *ngIf="showDescription" class="row">
                  <div class="col">
                    <label for="description">
                      {{'address.description' | translate}}
                      <span
                        *ngIf="addresses.get('description')?.hasError('maxlength')"
                        class="required-text"
                      >
                        {{'message.maxLength' | translate: {number: 255} }}
                      </span>
                    </label>
                    <textarea
                      autosize
                      id="description"
                      type="text"
                      formControlName="description"
                      class="form-control"
                      [readonly]="readOnly"
                    ></textarea>
                  </div>
                </div>



                <!--------------------------------------------------->


                <div class="form-group row p-0 m-0">
                  <div class="row">

                  </div>

                  <div class="form-group row p-0 m-0">
                    <div class="col-md-1" *ngIf="showCoordinates && !readOnly"></div>

                    <div class="col-md-10 justify-content-center d-flex py-3" *ngIf="showCoordinates && !readOnly">

                    </div>

                    <div class="col-md-1" *ngIf="showCoordinates && !readOnly">
                      <div *ngIf="isReverseGeocoding" class="spinner-border content text-dark to-bottom" role="status">
                        <span class="sr-only">{{'common.loading' | translate}}</span>
                      </div>
                    </div>
                  </div>
                </div>
              }


              @if (!this.readOnly && this.selectedCustomer?.addresses) {
                <div class="col-12 col-md-6 col-xl-3">
                  <label [for]="'customerContact' + i">
                    {{ 'customer.contact' | translate }}
                  </label>

                  <select [name]="'customerContact' + i" (change)="this.updateContact($event, i)" class="form-select">
                    <option value="" selected disabled>{{'address.selectContact' | translate}}</option>
                    @if (this.selectedCustomer?.contacts) {
                      @for (contact of this.selectedCustomer?.contacts; track $index) {
                        <option [value]="contact | json">{{contact.name}}</option>
                      }
                    }
                    <option value="enter">{{'address.enterOwnContact' | translate}}</option>
                  </select>
                </div>
              }

              <!-- *ngIf="this.showContactForm || this.readOnly" -->
              <div *ngIf="(this.readOnly && addressForm.get('contact')?.value) || this.showContactForm" class="row p-0 m-0 mt-3">
                <div class="form-group">
                  @if (this.getContacts(i)) {
                    <div formGroupName="contact">
                      <div class="row">
                        <div *ngIf="readOnly" class="col-12 pe-0">
                          <h3>{{ 'customer.contact' | translate }}</h3>
                        </div>
                        <div class="col-md-4 pe-0">
                          <label class="form-label w-100" for="title">
                            {{ 'contact.title' | translate }}
                            <input
                              type="text"
                              class="form-control"
                              name="title"
                              formControlName="title"
                              [readOnly]="readOnly"
                            />
                          </label>
                        </div>
                        <div class="col-md-8 pe-0">
                          <label class="form-label w-100" for="name">
                            {{ 'contact.name' | translate }}
                            <input
                              type="text"
                              class="form-control"
                              name="name"
                              formControlName="name"
                              [readOnly]="readOnly"
                            />
                          </label>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-md-4 pe-0">
                          <label class="form-label w-100" for="phone">
                            {{ 'contact.phone' | translate }}
                            <input
                              type="text"
                              class="form-control"
                              name="phone"
                              formControlName="phone"
                              [readOnly]="readOnly"
                            />
                          </label>
                        </div>
                        <div class="col-md-4 pe-0">
                          <label class="form-label w-100" for="mobile">
                            {{ 'contact.mobile' | translate}}
                            <input
                              type="text"
                              class="form-control"
                              name="mobile"
                              formControlName="mobile"
                              [readOnly]="readOnly"
                            />
                          </label>
                        </div>
                        <div class="col-md-4 pe-0">
                          <label class="form-label w-100" for="email">
                            {{ 'contact.email' | translate}}
                            <input
                              type="text"
                              class="form-control"
                              name="email"
                              formControlName="email"
                              [readOnly]="readOnly"
                              (change)="logFormGroup()"
                            />
                          </label>
                        </div>
                      </div>
                    </div>
                  }
                </div>
              </div>
            </div>


            <!----------------------------------------->
            <hr class="full-width" *ngIf="!readOnly && showCoordinates">


            <div *ngIf="showFreightSection" class="form-group p-0 m-0">
              <div class="form-group row p-0">
                <div class="d-flex col-md-3 pe-0 mt-3 align-items-end flex-wrap">
                  <label for="orderId">
                    {{'tour.orderId' | translate}}
                    <span *ngIf="addresses.get('orderId')?.hasError('maxlength')" class="required-text">
                      {{'message.maxLength' | translate: {number: 40} }}
                    </span>
                  </label>
                  <input id="orderId" type="text" formControlName="orderId" class="form-control" [readOnly]="readOnly">
                </div>
              </div>


              <div class="row">
                <div class="d-flex col-6">
                  <div class="input-group">
                    <label class="col-12" [for]="'startDelivery-' + i" *ngIf="this.leaveAndArriveSections[i] && this.leaveAndArriveSections[i].leave">{{'tour.addresses.earliestPickup' | translate}}</label>
                    <input type="time" class="form-control" [name]="'startDelivery-' + i" aria-label="Tidligst kl." [value]="convertToTimestring(addressForm.value.arriveWindow.from)" (change)="this.updateArriveWindow($event, 'from', i)" [readOnly]="readOnly">
                  </div>
                </div>
                <div class="d-flex col-6">
                  <div class="input-group">
                    <label class="col-12" [for]="'endDelivery-' + i" *ngIf="this.leaveAndArriveSections[i] && this.leaveAndArriveSections[i].leave">{{'tour.addresses.latestPickup' | translate}}</label>
                    <input type="time" class="form-control" [name]="'endDelivery-' + i" aria-label="Senest kl." [value]="convertToTimestring(addressForm.value.arriveWindow.to)" (change)="this.updateArriveWindow($event, 'to', i)" [readOnly]="readOnly">
                  </div>
                </div>
              </div>

              <div class="row p-0">
                <!-- ARRIVE INFORMATION -->
                <div *ngIf="this.createMode == 1 || this.leaveAndArriveSections[i].arrive || this.showBothArriveAndLeave[i]" class="d-flex col-12 pe-0 mt-4 align-items-end flex-wrap" title="afhente udstyr">
                  <div class="col-12 row">
                    <h2>{{ 'tour.delivery' | translate }}</h2>
                  </div>


                  <div class="w-100 row mt-3">
                    <!-- Product/Freight -->
                    <div *ngIf="getAddressFreights(addressForm).value && checkAddressFreightAndType(addressForm, 1)" class="form-group col-12 mb-3">
                      <h6 class="mb-0">{{ 'navigation.freightTypes' | translate }}</h6> <br>
                    </div>

                    <div *ngIf="getAddressFreights(addressForm).value && checkAddressFreightAndType(addressForm, 1)" class="col-12">
                      <div class="col-12 rounded-3 p-3" [ngClass]="{'bg-offwhite': !this.readOnly, 'bg-middlegray': this.readOnly}">
                        <table class="table">
                          <thead>
                            <tr>
                              <th scope="col">{{ 'logbooktype.metadata.productid' | translate }}</th>
                              <th scope="col">{{ 'surcharge' | translate }}</th>
                              <th scope="col">{{ 'tour.freightTypes.amount' | translate }}</th>
                              <th scope="col">{{ 'tour.freightTypes.freightType' | translate }}</th>
                              <th>&nbsp;</th>
                            </tr>
                          </thead>
                          <tbody>
                            @for (addressFreight of getAddressFreights(addressForm).value; track $index) {
                              <tr *ngIf="addressFreight.type == 1">
                                <td scope="row">
                                  <span *ngIf="addressFreight.productType">{{ addressFreight.productType.name }}</span>
                                </td>
                                @if (addressFreight.surchargesArray) {
                                  <td>
                                    <p *ngFor="let surcharge of addressFreight.surchargesArray">
                                      @if (surcharge.name) {
                                        <span>{{surcharge.name}}</span>
                                      }
                                      @else {
                                        <span>{{surcharge}}</span>
                                      }
                                    </p>
                                  </td>
                                }

                                @else if (addressFreight.surcharges && !addressFreight.surchargesArray) {
                                  <td>
                                    <p *ngFor="let surcharge of addressFreight.surcharges">
                                      @if (surcharge.name) {
                                        <span>{{surcharge.name}}</span>
                                      }
                                      @else {
                                        <span>{{surcharge}}</span>
                                      }
                                    </p>
                                  </td>
                                }

                                @else {
                                  <td>&nbsp;</td>
                                }
                                <td>
                                  {{ addressFreight.amount }}
                                  <span *ngIf="addressFreight.amountOverload" class="text-warning">
                                    ({{addressFreight.amountOverload}})
                                  </span>
                                </td>
                                <td>
                                  {{ this.displayFreightUnit(addressFreight.freightUnit) }}
                                  <span *ngIf="addressFreight.freightUnitOverload" class="text-warning">
                                    ({{addressFreight.freightUnitOverload}})
                                  </span>
                                </td>
                                <td>
                                  <div *ngIf="!this.readOnly" class="text-end">
                                    <fa-icon [icon]="faEdit" (click)="this.editProductModal($index, i, 1)" class="me-3"></fa-icon>
                                    <fa-icon [icon]="faTrash" (click)="this.deleteProduct($index, i, 1)"></fa-icon>
                                  </div>
                                </td>
                              </tr>
                            }
                          </tbody>
                        </table>
                      </div>
                    </div>



                    <div *ngIf="!this.readOnly" class="d-flex col-12 justify-content-center mt-2">
                      <div class="product-type-picker product-type-picker--component">
                        <button class="btn btn-primary btn-sm px-4" (click)="this.openProductModal(i, 1, 'arrive')">
                          <span>{{ 'tour.freightTypes.actions.add' | translate }}</span>
                        </button>
                      </div>
                    </div>
                  </div>



                  <div class="col-12 row">
                    <!-- arrive backvehicle -->
                    <div class="d-flex col-md-6 pe-0 align-items-end flex-wrap" title="stille påhæng">
                      <div
                        *ngIf="!this.showArriveBackvehicle[i] && this.createMode == 2 && this.disableStuff(i, 'backVehicle')"
                        class="address-add-button col-12 justify-content-center text-center rounded-2 cursor--pointer bg-orochimaru bg-opacity-10 py-3"
                        (click)="this.toggleArriveBackvehicle(i)"
                        style="margin-top: 30px;"
                      >
                        <!-- <span class="position-absolute" style="top: -21px;">Påhæng til levering</span> -->
                        <!-- <fa-icon [icon]="this.faBoxesStacked" [size]="'xl'"></fa-icon> <br> -->
                         <app-svg-icon [icon]="'backvehicle'" [size]="'40'" [fill]="'#222222'"></app-svg-icon> <br>
                        <span>{{ 'address.multiSelect.selectArriveBackVehicles' | translate }}</span>
                      </div>


                      <div
                        *ngIf="(this.createMode == 1) || (this.showArriveBackvehicle[i] && this.createMode == 2)"
                        class="col d-flex flex-wrap"
                      >
                        <label for="arriveBackVehicle">
                          {{'address.arriveBackVehicle' | translate}}
                          <span *ngIf="addresses.get('arriveBackVehicle')?.hasError('maxlength')" class="required-text">
                            {{'message.maxLength' | translate: {number: 255} }}
                          </span>
                        </label>
                        <ng-multiselect-dropdown
                          [hidden]="(createMode === 1 && !showArriveMultiSelect[i]) || (createMode !== 1 && showArriveMultiSelect[i])"
                          class="w-100"
                          [disabled]="readOnly"
                          [placeholder]="'address.multiSelect.selectArriveBackVehicles' | translate"
                          [settings]="dropdownSettings"
                          [data]="dropdownList"
                          (onSelect)="onItemSelectArriveBackVehicle($event, i)"
                          (onDeSelect)="onItemDeSelectArriveBackVehicle($event, i)"
                        ></ng-multiselect-dropdown>

                        <input
                          [hidden]="(createMode === 1 && showArriveMultiSelect[i]) || (createMode !== 1 && !showArriveMultiSelect[i])"
                          id="arriveBackVehicle"
                          [readOnly]="readOnly"
                          formControlName="arriveBackVehicle"
                          type="text"
                          class="form-control"
                          [(ngModel)]="arriveBackVehicle[i]"
                          (change)="this.manualArriveBackVehicleInput(i)"
                        >
                      </div>

                      <button
                        *ngIf="(!this.readOnly && this.createMode == 1) || (this.showArriveBackvehicle[i] && this.createMode == 2)"
                        #t="ngbTooltip"
                        class="btn change-field ms-3"
                        placement="top"
                        [ngbTooltip]="(showArriveMultiSelect ? 'address.switchToFreeText' : 'address.switchToMultiSelect') | translate"
                        (click)="switchArriveField(i)"
                        (mouseleave)="t.close()"
                        style="width: 44px;"
                      >
                        <fa-icon [icon]="this.faKeyboard"></fa-icon>
                      </button>
                    </div>

                    <!-- arrive equipment -->
                    <div class="d-flex col-md-6 pe-0 mt-3 align-items-end flex-wrap" title="stille udstyr">
                      <div
                        *ngIf="!this.showArriveEquipment[i] && this.createMode == 2 && this.disableStuff(i, 'equipment')"
                        (click)="this.toggleArriveEquipment(i)"
                        class="address-add-button col-12 justify-content-center text-center rounded-2 cursor--pointer bg-orochimaru bg-opacity-10 py-3"
                        style="margin-top: 30px;"
                      >
                        <!-- <span class="position-absolute" style="top: -21px;">Påhæng til levering</span> -->
                        <app-svg-icon [icon]="'equipment'" [size]="'40'" [fill]="'#222222'"></app-svg-icon> <br>
                        <span>{{ 'address.multiSelect.selectArriveEquipment' | translate }}</span>
                      </div>

                      <div *ngIf="(this.createMode == 1) || (this.showArriveEquipment[i] && this.createMode == 2)" class="col-md-8 pe-2 flex-wrap">
                        <label for="arriveEquipment">
                          {{'address.arriveEquipment' | translate}}
                          <span *ngIf="addresses.get('arriveEquipment')?.hasError('maxlength')" class="required-text">
                            {{'message.maxLength' | translate: {number: 255} }}
                          </span>
                        </label>
                        <input
                          id="arriveEquipment"
                          type="text"
                          formControlName="arriveEquipment"
                          class="form-control"
                          [readOnly]="readOnly"
                          [(ngModel)]="arriveEquipment[i]"
                          (change)="this.manualArriveEquipmentInput(i)"
                        />
                      </div>

                      <div *ngIf="(this.createMode == 1) || (this.showArriveEquipment[i] && this.createMode == 2)" class="col-md-4">
                        <select
                          id="selectArriveEquipment"
                          class="col-md-4 form-select mt-half"
                          formControlName="selectArriveEquipment"
                          [(ngModel)]="selectArriveEquipment[i]"
                          (change)="selectedArriveEquipment(i)"
                        >
                          @for(te of transportEquipment; track $index){
                            <div>{{te.id}}</div>
                            <option [value]="te.unitIdentification + ' ' + te.resourceType.resourceTypeName">{{te.unitIdentification}} {{sharedService.getTranslateType(te.resourceType.resourceTypeName) | translate}} test</option>
                          }
                        </select>
                      </div>
                    </div>
                  </div>
                </div>


                <!-- LEAVE INFORMATION -->
                <div *ngIf="this.createMode == 1 || this.leaveAndArriveSections[i].leave || this.showBothArriveAndLeave[i]" class="d-flex col-12 pe-0 mt-4 align-items-end flex-wrap" title="afhente udstyr">
                  <div class="col-12 row">
                    <h2>{{ 'tour.pickup' | translate }}</h2>
                  </div>



                  <div class="w-100 row mt-3">
                    <!-- Product/Freight -->
                    <div *ngIf="getAddressFreights(addressForm).value && checkAddressFreightAndType(addressForm, 2)" class="form-group row mb-3">
                      <h6 class="mb-0">{{ 'navigation.freightTypes' | translate }}</h6> <br>
                    </div>

                    <div *ngIf="getAddressFreights(addressForm).value && checkAddressFreightAndType(addressForm, 2)" class="row">
                      <div class="col-12 rounded-3 p-3" [ngClass]="{'bg-offwhite': !this.readOnly, 'bg-middlegray': this.readOnly}">
                        <table class="table">
                          <thead>
                            <tr>
                              <th scope="col">{{ 'logbooktype.metadata.productid' | translate }}</th>
                              <th scope="col">{{ 'surcharge' | translate }}</th>
                              <th scope="col">{{ 'tour.freightTypes.amount' | translate }}</th>
                              <th scope="col">{{ 'tour.freightTypes.freightType' | translate }}</th>
                              <th>&nbsp;</th>
                            </tr>
                          </thead>
                          <tbody>
                            @for (addressFreight of getAddressFreights(addressForm).value; track $index) {
                              <tr *ngIf="addressFreight.type == 2">
                                <td scope="row">
                                  <span *ngIf="addressFreight.productType">{{ addressFreight.productType.name }}</span>
                                </td>
                                @if (addressFreight.surchargesArray) {
                                  <td>
                                    <p *ngFor="let surcharge of addressFreight.surchargesArray">
                                      @if (surcharge.name) {
                                        <span>{{surcharge.name}}</span>
                                      }
                                      @else {
                                        <span>{{surcharge}}</span>
                                      }
                                    </p>
                                  </td>
                                }

                                @else if (addressFreight.surcharges && !addressFreight.surchargesArray) {
                                  <td>
                                    <p *ngFor="let surcharge of addressFreight.surcharges">
                                      @if (surcharge.name) {
                                        <span>{{surcharge.name}}</span>
                                      }
                                      @else {
                                        <span>{{surcharge}}</span>
                                      }
                                    </p>
                                  </td>
                                }

                                @else {
                                  <td>&nbsp;</td>
                                }
                                <td>
                                  {{ addressFreight.amount }}
                                  <span *ngIf="addressFreight.amountOverload" class="text-warning">
                                    ({{addressFreight.amountOverload}})
                                  </span>
                                </td>
                                <td>
                                  {{ this.displayFreightUnit(addressFreight.freightUnit) }}
                                  <span *ngIf="addressFreight.freightUnitOverload" class="text-warning">
                                    ({{addressFreight.freightUnitOverload}})
                                  </span>
                                </td>
                                <td>
                                  <div *ngIf="!this.readOnly" class="text-end">
                                    <fa-icon [icon]="faEdit" (click)="this.editProductModal($index, i, 1)" class="me-3"></fa-icon>
                                    <fa-icon [icon]="faTrash" (click)="this.deleteProduct($index, i, 1)"></fa-icon>
                                  </div>
                                </td>
                              </tr>
                            }
                          </tbody>
                        </table>
                      </div>
                    </div>


                    <div *ngIf="!this.readOnly" class="d-flex col-12 justify-content-center">
                      <button class="btn btn-primary btn-sm px-4" (click)="this.openProductModal(i, 2, 'leave')">
                        <span>{{ 'tour.freightTypes.actions.add' | translate }}</span>
                      </button>
                    </div>
                  </div>


                  <div class="col-12 row">
                    <!-- leave backvehicle -->
                    <div class="d-flex col-md-6 pe-0 mt-3 align-items-end flex-wrap" title="afhente påhæng">
                      <div
                        *ngIf="!this.showLeaveBackvehicle[i] && this.createMode == 2 && this.disableStuff(i, 'backVehicle')"
                        (click)="this.toggleLeaveBackvehicle(i)"
                        class="address-add-button col-12 justify-content-center text-center rounded-2 cursor--pointer bg-orochimaru bg-opacity-10 py-3"
                        style="margin-top: 30px;"
                      >
                        <!-- <span class="position-absolute" style="top: -21px;">Påhæng til levering</span> -->
                        <app-svg-icon [icon]="'backvehicle'" [size]="'40'" [fill]="'#222222'"></app-svg-icon> <br>
                        <span>{{ 'address.multiSelect.selectLeaveBackVehicles' | translate }}</span>
                      </div>

                      <div
                        *ngIf="(this.createMode == 1) || (this.showLeaveBackvehicle[i] && this.createMode == 2)"
                        class="col d-flex flex-wrap"
                        [hidden]="showLeaveMultiSelect"
                      >
                        <label for="leaveBackVehicle">
                          {{'address.leaveBackVehicle' | translate}}
                          <span *ngIf="addresses.get('leaveBackVehicle')?.hasError('maxlength')" class="required-text">
                            {{'message.maxLength' | translate: {number: 255} }}
                          </span>
                        </label>
                        <ng-multiselect-dropdown
                          [hidden]="(createMode === 1 && !showLeaveMultiSelect[i]) || (createMode !== 1 && showLeaveMultiSelect[i])"
                          class="w-100"
                          [disabled]="readOnly"
                          [placeholder]="'address.multiSelect.selectLeaveBackVehicles' | translate"
                          [settings]="dropdownSettings"
                          [data]="dropdownList"
                          (onSelect)="onItemSelectLeaveBackVehicle($event, i)"
                          (onDeSelect)="onItemDeSelectLeaveBackVehicle($event, i)"
                        ></ng-multiselect-dropdown>

                        <input
                          [hidden]="(createMode === 1 && showLeaveMultiSelect[i]) || (createMode !== 1 && !showLeaveMultiSelect[i])"
                          id="leaveBackVehicle"
                          formControlName="leaveBackVehicle"
                          [readOnly]="readOnly"
                          type="text"
                          class="form-control"
                          [(ngModel)]="leaveBackVehicle[i]"
                          (change)="this.manualLeaveBackVehicleInput(i)"
                        >
                      </div>


                      <button
                        *ngIf="(!this.readOnly && this.createMode == 1) || (this.showLeaveBackvehicle[i] && this.createMode == 2)"
                        #tt="ngbTooltip"
                        placement="top"
                        class="btn change-field ms-3"
                        [ngbTooltip]="(showLeaveMultiSelect ? 'address.switchToFreeText' : 'address.switchToMultiSelect') | translate"
                        (click)="switchLeaveField(i)"
                        (mouseleave)="tt.close()"
                        style="width: 44px;"
                      >
                        <fa-icon [icon]="faKeyboard"></fa-icon>
                      </button>
                    </div>



                    <!-- leave equipment -->
                    <div class="d-flex col-md-6 pe-0 mt-3 align-items-end flex-wrap" title="afhente udstyr">
                      <div
                        *ngIf="!this.showLeaveEquipment[i] && this.createMode == 2 && this.disableStuff(i, 'equipment')"
                        (click)="this.toggleLeaveEquipment(i)"
                        class="address-add-button col-12 justify-content-center text-center rounded-2 cursor--pointer bg-orochimaru bg-opacity-10 py-3"
                        style="margin-top: 30px;"
                      >
                        <!-- <span class="position-absolute" style="top: -21px;">Påhæng til levering</span> -->
                        <app-svg-icon [icon]="'equipment'" [size]="'40'" [fill]="'#222222'"></app-svg-icon> <br>
                        <span>{{ 'address.multiSelect.selectLeaveEquipment' | translate }}</span>
                      </div>


                      <div *ngIf="(this.createMode == 1) || (this.showLeaveEquipment[i] && this.createMode == 2)" class="col-md-8 pe-2 flex-wrap">
                        <label for="leaveEquipment">
                          {{'address.leaveEquipment' | translate}}
                          <span *ngIf="addresses.get('leaveEquipment')?.hasError('maxlength')" class="required-text">
                            {{'message.maxLength' | translate: {number: 255} }}
                          </span>
                        </label>
                        <input
                          id="leaveEquipment"
                          type="text"
                          formControlName="leaveEquipment"
                          class="form-control"
                          [readOnly]="readOnly"
                          [(ngModel)]="leaveEquipment[i]"
                          (change)="this.manualLeaveEquipmentInput()"
                        />
                      </div>

                      <div *ngIf="(this.createMode == 1) || (this.showLeaveEquipment[i] && this.createMode == 2)" class="col-md-4">
                        <select
                          id="selectLeaveEquipment"
                          class="form-select mt-half"
                          formControlName="selectLeaveEquipment"
                          [(ngModel)]="selectLeaveEquipment[i]"
                          (change)="selectedLeaveEquipment(i)"
                        >
                          @for(transportEquipment of transportEquipment; track $index){
                            <option [value]="transportEquipment.unitIdentification + ' ' + transportEquipment.resourceType.resourceTypeName">{{transportEquipment.unitIdentification}} {{sharedService.getTranslateType(transportEquipment.resourceType.resourceTypeName) | translate}}</option>
                          }
                        </select>
                      </div>
                    </div>
                  </div>
                </div>



                <div class="col text-center" *ngIf="addressForm.get('addressType')?.value == 'arrive'">
                  <br>

                  <button
                    *ngIf="(this.createMode == 2 && !this.showBothArriveAndLeave[i])"
                    (click)="this.showBothAddressAndLeaveOptions(addressForm, i)"
                    class="btn btn-primary rounded-circle px-4"
                  >
                    @if (addressForm.get('addressType')?.value == 'arrive') {
                      {{ 'tour.addresses.actions.addCollection' | translate }}
                    }
                  </button>
                </div>

              </div>

              <!-------------------------------------------------->


              <div>
                <br>

                <div *ngIf="this.getDeliveryDocumentRequirements(addressForm) && this.getDeliveryDocumentRequirements(addressForm).value.length > 0" class="form-group col-12 mb-1">
                  <h6 class="mb-0">{{ 'navigation.addressDeliveryDocumentRequirementTitle' | translate }}</h6>
                </div>

                <div *ngIf="this.getDeliveryDocumentRequirements(addressForm) && this.getDeliveryDocumentRequirements(addressForm).value.length > 0" class="col-12">
                  <div class="col-12 rounded-3 p-3" [ngClass]="{'bg-offwhite': !this.readOnly, 'bg-middlegray': this.readOnly}">
                    <table class="table">
                      <thead>
                        <tr>
                          <th scope="col">Dokumenter</th>
                          <th>&nbsp;</th>
                        </tr>
                      </thead>
                      <tbody>
                        @for (dd of getDeliveryDocumentRequirements(addressForm).value; track $index) {
                          <tr>
                            <td>
                              @if (dd.id == 2) {
                                {{ 'address.deliveryDocumentRequirements.IDTF' | translate }}
                              }
                              @else if (dd.id == 3) {
                                {{ 'address.deliveryDocumentRequirements.IDTFClean' | translate }}
                              }
                              @else if (dd.id == 4) {
                                {{ 'address.deliveryDocumentRequirements.commDoc' | translate }}
                              }
                              @else {
                                {{ 'address.deliveryDocumentRequirements.unknown' | translate }}
                              }
                            </td>
                            <td>
                              <div *ngIf="!this.readOnly" class="cursor-pointer text-end">
                                <fa-icon *ngIf="dd.deliveryDocumentRequirementsId == 2 || dd.deliveryDocumentRequirementsId == 3 || dd.deliveryDocumentRequirementsId == 4" [icon]="faEdit" (click)="this.editDeliveryDocumentRequirement($index, i)" class="me-3"></fa-icon>
                                <fa-icon [icon]="faTrash" (click)="this.deleteDeliveryDocument($index, i)"></fa-icon>
                              </div>
                            </td>
                          </tr>
                        }
                      </tbody>
                    </table>

                    <div class="col mt-3">
                      <label>{{ 'address.deliveryDocument.manuallySelectDokumentLabel' | translate }}</label>
                      <select *ngIf="this.deliveryDocuments && this.deliveryDocuments.length > 0" (change)="this.addDeliveryDocumentRequirement($event, i)" class="form-select" [disabled]="readOnly">
                        <option value="null">{{'address.selectExtraDocument' | translate }}</option>
                        <option *ngFor="let document of this.deliveryDocuments; let j = index;" [value]="document | json">{{ document.id }} {{ document.name }}</option>
                      </select>
                      <label *ngIf="this.showDocumentError" class="text-danger">{{ 'address.deliveryDocument.documentAlreadyExists' | translate }}</label>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-md-12" *ngIf="showDescription">
                <ng-container *ngFor="let attachment of address?.attachments; let i = index">
                  <div class="col-md-12 widget-margin">
                    <app-attachment-widget  [attachment]="attachment"></app-attachment-widget>
                  </div>
                </ng-container>
              </div>

              <div class="col-md-12" *ngIf="showDescription && address?.notes != undefined">
                <ng-container  *ngFor="let note of address?.notes; let i = index">
                  <app-note-widget [note]="note"></app-note-widget>
                </ng-container>
              </div>
            </div>
          <div class="d-flex flex-row-reverse">
            <button
              *ngIf="!readOnly && !createMode"
              class="btn btn-danger"
              placement="top"
              (click)="delete.emit(this.address?.id)"
              ngbTooltip="{{'btn.deleteAddress' | translate}}"
              style="margin-bottom: 10px;"
            >
              <fa-icon [icon]="faTrash"></fa-icon>
            </button>
          </div>
          </div>
        </form>

      </div>
    </details>
  </ng-container>
}

@if (!this.readOnly && (this.createMode == createModes.create_as_child || this.createMode == createModes.create_as_parent)) {
  <div class="d-flex col-md-12 text-center border-1 pt-4">
    <div class="col-md-6">
      <button
        class="btn"
        (click)="this.addArriveAddress()"
      >
      <!-- <fa-icon [icon]="faTruck" [size]="'xl'" style="opacity: 80%;"></fa-icon> -->
        <app-svg-icon [icon]="'truck-unload'" [size]="'36'"></app-svg-icon>

        <p class="mt-2">{{ 'tour.addresses.actions.addDeliveryAddress' | translate }}</p>
      </button>
    </div>

    <div class="col-md-6">
      <button
      class="btn"
      (click)="this.addLeaveAddress()"
      >
      <!-- <fa-icon [icon]="faTruckRampBox" [size]="'xl'" style="opacity: 80%;"></fa-icon> -->
        <app-svg-icon [icon]="'truck-load'" [size]="'36'"></app-svg-icon>

        <p class="mt-2">{{ 'tour.addresses.actions.addPickupAddress' | translate }}</p>
      </button>
    </div>
  </div>
}

@if (!this.readOnly && this.createMode == createModes.create_as_user) {
  <div class="d-flex col-md-12 text-center border-1 pt-4">
    <button class="btn btn-primary" (click)="this.addCustomerAddress()">[Ny adresse]</button>
  </div>
}
