<div class="table-reponsive">
  <div class="alert alert-danger" role="alert" *ngIf="failData.length > 0">
    <div class="row">
      <div class="col-12">
        {{'table.failedText' | translate:{value: failData.length} }}
      </div>
    </div>
  </div>

  <div class="alert alert-warning" role="alert" *ngIf="waitData.length > 0">
    <div class="row">
      <div class="col-11">
        {{'table.waitingText' | translate:{value: waitData.length} }}
      </div>
      <div class="col-1">
        <button type="button" class="float-end btn btn-sm btn-primary">
          <fa-icon [icon]="faSync" ngbTooltip="{{'table.refresh' | translate}}" placement="left" (click)="RefrehList()"></fa-icon>
        </button>
      </div>
    </div>
  </div>

  <table class="full-width table table-sm">
    <thead class="unselectable">
      <tr>
        <td>
          <div class="row">
            <div class="col-1">
              <app-checkbox [(ngModel)]="selectedAll" (ngModelChange)="selectAll()" />
            </div>
            <div class="col-10">{{'table.title' | translate}}</div>
            <div class="col-1">
              @if (this.selectedReports.length >= 1) {
              <button type="button" class="float-end btn btn-sm btn-default" (click)="deleteSelectedReportsEmit()">
                <fa-icon [icon]="faTrash"></fa-icon>
              </button>
              }

              @if (this.selectedReports.length >= 2) {
              <button type="button" class="float-end btn btn-sm btn-default" (click)="downloadAllReports()">
                <fa-icon [icon]="faDownload"></fa-icon>
              </button>
              }

              <button type="button" class="float-end btn btn-sm btn-default">
                <fa-icon [icon]="faSync" ngbTooltip="{{'table.refresh' | translate}}" placement="left" (click)="RefrehList()"></fa-icon>
              </button>
            </div>
          </div>
        </td>
      </tr>
    </thead>
    <tbody class="border_bottom"
           [ngClass]="rowColor(i)"
           *ngFor="let reportRow of successData  | slice: (page-1) * pageSize : (page-1) * pageSize + pageSize; index as i">
      <tr>
        <th style="width:max-content">
          <div class="row">
            <div class="col-1">
              <app-checkbox [(ngModel)]="reportRow.selected" (emittedState)="selectItem(reportRow)" />
            </div>
            <div class="col-9">
              <span class="align-middle">{{reportRow.name}}</span>
            </div>
            <div class="col-1">
              <button type="button" class="float-end btn btn-sm btn-default" (click)="SelectView(reportRow)">
                <fa-icon [icon]="faEye"></fa-icon>
              </button>
            </div>
            <div class="col-1">
              <report-download-btn [report]="reportRow" [isTableDownload]="true"></report-download-btn>
            </div>
          </div>
        </th>
      </tr>
    </tbody>
  </table>
</div>
