import { Injectable } from '@angular/core';
import {environment} from "../../../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {TourModel} from "../../../tours/shared/tour.model";
import {map} from "rxjs/operators";
import {EventModel} from "./event.model";
import {SharedService} from "../../services/shared.service";

const url = environment.baseUrl + '/V1';

@Injectable({
  providedIn: 'root'
})
export class CalendarService {

  language: any;

  constructor(private http: HttpClient, private sharedService : SharedService) { }

  async getLanguage() {
    this.sharedService.getLanguageJson().subscribe(response => this.language = response)
  }

  async ngOnInit(): Promise<void> {
    await this.getLanguage()
  }

  getTours(start: any, end: any): Observable<EventModel[]> {
    return this.http.get<TourModel[]>(`${url}/Tours/${start}/${end}`).pipe(
      map((tours: TourModel[]) => tours.map((tour: TourModel) => ({
        id: tour.id.toString(),
        title: ` ${tour?.description} • ${tour?.assignedUser?.firstname} ${tour?.assignedUser?.lastname} ${this.formatValue(tour?.frontVehicle?.registrationNumber)} ${this.formatValue(tour?.backVehicles?.map(b  => b.registrationNumber)?.toString())} ${this.formatValue(tour?.transportEquipment?.resourceType.resourceTypeName)} ${this.formatValue(tour?.transportEquipment?.unitIdentification)}`,
        start: tour?.actualStartDate !== null ? tour.actualStartDate : tour.expectedStartDate,
        end: tour?.actualEndDate !== null ? tour.actualEndDate : tour.expectedEndDate,
        color: this.sharedService.getStatusColor(tour.status),
        classNames: ['clickable']
      })))
    );
  }

  private formatValue(e: any): string {
   return e ? '• ' + e : '';
  }



}
