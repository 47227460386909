<button class="ms-2 map-picker--btn" style="width: 50px;" (click)="this.open(this.content)">
  <fa-icon [icon]="faLocationDot" class="text-default"></fa-icon>
</button>

<!-- Modal content -->
<ng-template #content let-modal>
  <div class="modal-header">
    <h4 class="modal-title">{{'tomtom.picker.title' | translate}}</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="onCloseModal(modal)"></button>
  </div>
  <div class="modal-body custom-modal-body">
    <div id="tomtom-map" style="height: 100%;"></div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="onCloseModal(modal)">{{'modal.footer.cancel' | translate}}</button>
    <button type="button" class="btn btn-primary" (click)="emit(); onCloseModal(modal)">{{'modal.footer.confirm' | translate}}</button>
  </div>
</ng-template>
