<div class="page-content mt-2 pb-2 ps-1">
  <ejs-sidebar #sidebar>
    <ejs-treeview #tree [fields]="treeFields" (nodeSelected)="loadRoutingContent($event)" />
  </ejs-sidebar>

  <div class="main-content ps-2 me-1">
    <ejs-toolbar class="mb-2" (clicked)="toolbarClicked()">
      <e-items>
        <e-item prefixIcon="e-tbar-menu-icon" tooltipText="{{'menu' | translate}}" />
        <e-item text="{{'pricing' | translate}}" />
      </e-items>
    </ejs-toolbar>

    <div class="d-flex mb-3">
      <div class="me-2">
        <h4>{{'grouping.subgroups.customers' | translate}}</h4>
        <ejs-grid
          #customerGrid
          (toolbarClick)="toolbarCustomersClick($event)"
          (actionComplete)="onActionCompleteCustomers($event)"
          (rowSelected)="setPriceTableFromCustomer($event)"
          [toolbar]="customerToolbar"
          [allowSorting]="true"
          [editSettings]="customerEditSettings"
          [allowPaging]="true"
          [pageSettings]="customerPageSettings"
          [loadingIndicator]="loadingIndicator"
          gridLines="Both"
        >
          <e-columns>
            <e-column field="id" [visible]="false" [isPrimaryKey]="true" [allowEditing]="false" />
            <e-column field="name" headerText="{{'common.name' | translate}}">
              <ng-template #template let-data>
                @if(data.standardPrices) {
                  {{'standardPrices' | translate}}
                }
                @else {
                  {{data.name}}
                }
              </ng-template>
            </e-column>
          </e-columns>
        </ejs-grid>

        <ejs-dialog
          #dialogCustomers
          [header]="'addCustomer' | translate"
          [visible]="false"
          [animationSettings]="animationSettings"
          [buttons]="dialogButtonsCustomers"
          [showCloseIcon]="showCloseIcon"
          target=".main-content"
          [width]="width"
        >
          <ng-template #content>
            <ejs-dropdownlist
              #dropdownCustomers
              (change)="setNewCustomer($event)"
              id="rowDropdownCustomers"
              [dataSource]="filteredCustomersDataSource"
              [fields]="fieldsCustomers"
              placeholder="{{'selectCustomer' | translate}}"
              popupHeight="200px"
              [noRecordsTemplate]="'company.basicInformation.noData' | translate"
            />
          </ng-template>
        </ejs-dialog>
      </div>

      <div>
        <h4>{{'periods' | translate}}</h4>
        <ejs-grid
          #dateGrid
          (toolbarClick)="toolbarDatesClick($event)"
          (actionComplete)="onActionCompleteDates($event)"
          (rowSelected)="onDatesRowSelected($event)"
          [allowGrouping]="true"
          [toolbar]="dateToolbar"
          [allowSorting]="true"
          [editSettings]="dateEditSettings"
          [allowPaging]="true"
          [pageSettings]="datePageSettings"
          [loadingIndicator]="loadingIndicator"
          gridLines="Both"
        >
          <e-columns>
            <e-column field="id" [visible]="false" [isPrimaryKey]="true" [allowEditing]="false" />
            <e-column field="validFrom" headerText="{{'validFrom' | translate}}" type="date" editType="datepickeredit" [edit]="dateParams" format="dd-MM-yyyy" [validationRules]="validFromRules" />
            <e-column field="validTo" headerText="{{'validUntil' | translate}}" type="date" editType="datepickeredit" [edit]="dateParams" format="dd-MM-yyyy" [validationRules]="validToRules" />
          </e-columns>
        </ejs-grid>
      </div>
    </div>

    <div class="mb-3">
      <h4>{{'zones' | translate}}</h4>
      <ejs-grid
        #zoneGrid
        (toolbarClick)="toolbarZonesClick($event)"
        (actionComplete)="onActionCompleteZones($event)"
        (rowSelected)="onZoneRowSelected($event)"
        [allowGrouping]="true"
        [toolbar]="zoneToolbar"
        [allowSorting]="true"
        [editSettings]="zoneEditSettings"
        [allowPaging]="true"
        [pageSettings]="zonePageSettings"
        [loadingIndicator]="loadingIndicator"
        gridLines="Both"
      >
        <e-columns>
          <e-column field="id" [visible]="false" [isPrimaryKey]="true" [allowEditing]="false" />
          <e-column field="zoneFrom.fromZone.meta1" headerText="{{'zoneFrom' | translate}}" />
          <e-column field="zoneTo.fromZone.meta1" headerText="{{'zoneTo' | translate}}" />
          <e-column field="fixedPrice" headerText="{{'fixedPrice' | translate}}" />
        </e-columns>

        <ng-template #editSettingsTemplate let-data>
          <div [formGroup]="zoneForm">
            <div class="form-group col-md-12">
              <ejs-multicolumncombobox
                id="ZoneFrom"
                [dataSource]="zonesMapped"
                [fields]="fieldsMultiZoneSelect"
                [(value)]="valueZoneFrom"
                (change)="onMultiZoneSelectChange($event)"
                popupHeight="230px"
                [noRecordsTemplate]="'company.basicInformation.noData' | translate"
              >
                <e-columns>
                  <e-column field='fromMeta1' header="{{'zoneFrom' | translate}}" width="90" />
                  <e-column field='toMeta1' header="{{'zoneTo' | translate}}" width="85" />
                </e-columns>
              </ejs-multicolumncombobox>
            </div>
            <div class="form-group col-md-12">
              <ejs-multicolumncombobox
                id="ZoneTo"
                [dataSource]="zonesMapped"
                [fields]="fieldsMultiZoneSelect"
                [(value)]="valueZoneTo"
                (change)="onMultiZoneSelectChange($event)"
                popupHeight="230px"
                [noRecordsTemplate]="'company.basicInformation.noData' | translate"
              >
                <e-columns>
                  <e-column field="fromMeta1" header="{{'zoneFrom' | translate}}" width="90" />
                  <e-column field="toMeta1" header="{{'zoneTo' | translate}}" width="85" />
                </e-columns>
              </ejs-multicolumncombobox>
            </div>
            <!-- float hejset giver getPanel fejl -->
            <div class="form-group col-md-6">
              <div class="e-float-input e-control-wrapper">
                <input formControlName="fixedPrice" id="fixedPrice" [(value)]="valueFixedprice" name="fixedPrice" type="number" />
                <span class="e-float-line"></span>
                <label class="e-float-text e-label-top" for="fixedPrice">{{'fixedPrice' | translate}}</label>
              </div>
            </div>
            <!-- form-control giver også getPanel fejl, men uden form-control er der ingen fejl -->
            <!-- <div class="form-group col-md-6">
              <label class="mt-0" style="font-size: 12px;" for="fixedPrice">{{'fixedPrice' | translate}}</label>
              <input class="form-control form-control-sm" formControlName="fixedPrice" id="fixedPrice" [(value)]="valueFixedprice" name="fixedPrice" type="number" />
            </div> -->
          </div>
        </ng-template>
      </ejs-grid>

      <ejs-dialog
        #dialog
        [header]="'addZone' | translate"
        [visible]="false"
        [animationSettings]="animationSettings"
        [buttons]="dialogButtons"
        [showCloseIcon]="showCloseIcon"
        target=".main-content"
        [width]="width"
      >
        <ng-template #content>
          <ejs-tab (selected)="onTabSelected($event)">
            <e-tabitems>
              <e-tabitem [header]="{ text: 'zipCodes' | translate }">
                <ng-template #content>
                  <div>
                    <form #dialogForm="ngForm" (ngSubmit)="onSubmit()">
                      <div class="row">
                        <ejs-dropdownlist
                          #countrySel
                          id="country"
                          [dataSource]="countries"
                          [value]="value"
                          [fields]="fieldsCountries"
                          [placeholder]="waterMark"
                          [popupHeight]="height"
                          [noRecordsTemplate]="'company.basicInformation.noData' | translate"
                        />
                      </div>

                      <div class="row">
                        <div class="col-xs-6 col-sm-6 col-lg-6 col-md-6">
                          <ejs-numerictextbox #zipFrom placeholder="{{'zipCodeFrom' | translate}}" format="####" min="0" floatLabelType="Auto" />
                        </div>
                        <div class="col-xs-6 col-sm-6 col-lg-6 col-md-6">
                          <ejs-numerictextbox #zipTo placeholder="{{'zipCodeTo' | translate}}" format="####" min="0" floatLabelType="Auto" />
                        </div>
                      </div>
                    </form>
                  </div>
                </ng-template>
              </e-tabitem>
              <e-tabitem [header]="{ text: 'cities' | translate }">
                <ng-template #content>
                  <div>
                    <div class="row">
                      <ejs-dropdownlist
                        #countrySel
                        id="country"
                        [dataSource]="countries"
                        [value]="value"
                        [fields]="fieldsCountries"
                        [placeholder]="waterMark"
                        [popupHeight]="height"
                        [noRecordsTemplate]="'company.basicInformation.noData' | translate"
                      />
                    </div>

                    <div class="row col-md-12">
                      <ejs-combobox
                        #citySelector
                        id="dropdownlistCity"
                        [dataSource]="dropdownDataCity"
                        (change)="onChangeCityCombo($event)"
                        [fields]="fieldsCity"
                        [value]="valueCity"
                        [popupHeight]="height"
                        [placeholder]="waterMark"
                        [noRecordsTemplate]="'company.basicInformation.noData' | translate"
                      >
                        <ng-template #itemTemplate let-data>
                          <div>
                            <div class="ename"> {{data.city}} </div>
                            <div class="job"> {{data.postalCode}} </div>
                          </div>
                        </ng-template>
                      </ejs-combobox>
                    </div>
                  </div>
                </ng-template>
              </e-tabitem>
              <e-tabitem [header]="{ text: 'tour.address' | translate }">
                <ng-template #content>
                  <div>
                    <div class="col-xs-6 col-sm-6 col-lg-6 col-md-6">
                      <ejs-textbox placeholder="{{'address.searchAddress' | translate}}" (change)="getValueFromTomTom($event)" floatLabelType="Auto" />
                    </div>
                    <div class="col-xs-6 col-sm-6 col-lg-6 col-md-6">
                      <input class="e-input" type="text" placeholder="{{'result' | translate}}" disabled />

                      <div id="listbox-control">
                        <ejs-listbox
                          [dataSource]="searchResults"
                          [fields]="fields"
                          (change)="onSelectTomTomSelector($event)"
                          [noRecordsTemplate]="'company.basicInformation.noData' | translate"
                        >
                          <ng-template #itemTemplate let-data>
                            <div class="list-wrapper">
                              <span class="text"> {{generateAdressTitle(data)}} </span>
                            </div>
                          </ng-template>
                        </ejs-listbox>
                      </div>

                     <!-- <ul class="resultList" *ngFor="let result of searchResults" class="list-group">
                        <li role="button" (click)="fillAddressForm(result)" class="list-group-item cursor">{{generateAdressTitle(result)}}</li>
                      </ul>-->
                    </div>
                  </div>
                </ng-template>
              </e-tabitem>
            </e-tabitems>
          </ejs-tab>
        </ng-template>
      </ejs-dialog>
    </div>

    <div class="d-flex">
      <div class="me-2">
        <h4>{{'types' | translate}}</h4>
        <ejs-grid
          #typeGrid
          (toolbarClick)="toolbarTypesClick($event)"
          (actionComplete)="onActionCompleteTypes($event)"
          (rowSelected)="onTypeRowSelected($event)"
          [allowGrouping]="true"
          [toolbar]="typeToolbar"
          [allowSorting]="true"
          [editSettings]="typeEditSettings"
          [allowPaging]="true"
          [pageSettings]="typePageSettings"
          [loadingIndicator]="loadingIndicator"
          gridLines="Both"
        >
          <e-columns>
            <e-column field="id" [visible]="false" [isPrimaryKey]="true" [allowEditing]="false" />
            <e-column field="transportType.name" headerText="{{'transportType' | translate}}" />
            <e-column field="billingType.name" headerText="{{'unit' | translate}}" />
            <e-column field="productType.name" headerText="{{'logbooktype.metadata.productid' | translate}}" />
          </e-columns>

          <ng-template #editSettingsTemplate let-data>
            <div class="form-group col-md-12">
              <ejs-dropdownlist
                id="transportTypes"
                [dataSource]="deliveryRequirements"
                [(value)]="valueTransportTypeId"
                [fields]="{text: 'name', value: 'id' }"
                placeholder="{{'transportTypes' | translate}}"
                popupHeight="300px"
                floatLabelType="Always"
                [noRecordsTemplate]="'company.basicInformation.noData' | translate"
              />
            </div>

            <div class="form-group col-md-12">
              <ejs-dropdownlist
                id="billingTypes"
                [dataSource]="billingTypes"
                [(value)]="valueBillingTypeId"
                [fields]="{text: 'name', value: 'id' }"
                placeholder="{{'company.licenseInformation.units' | translate}}"
                popupHeight="300px"
                floatLabelType="Always"
                [noRecordsTemplate]="'company.basicInformation.noData' | translate"
              />
            </div>

            <div class="form-group col-md-12">
              <ejs-dropdownlist
                id="products"
                [dataSource]="products"
                [(value)]="valueProductTypeId"
                [fields]="{text: 'name', value: 'id' }"
                placeholder="{{'navigation.freightTypes' | translate}}"
                popupHeight="300px"
                floatLabelType="Always"
                [noRecordsTemplate]="'company.basicInformation.noData' | translate"
              />
            </div>
          </ng-template>
        </ejs-grid>
      </div>

      <div>
        <h4>{{'prices' | translate}}</h4>
        <ejs-grid
          #priceGrid
          (actionComplete)="onActionCompletePrices($event)"
          [toolbar]="priceToolbar"
          [allowSorting]="true"
          [editSettings]="priceEditSettings"
          [allowPaging]="true"
          [pageSettings]="pricePageSettings"
          [loadingIndicator]="loadingIndicator"
          gridLines="Both"
        >
          <e-columns>
            <e-column field="id" [visible]="false" [isPrimaryKey]="true" [allowEditing]="false" />
            <e-column field="amountFrom" headerText="{{'unitFrom' | translate}}" editType="numericedit" [edit]="numericParams" [validationRules]="requiredRules" />
            <e-column field="amountTo" headerText="{{'unitTo' | translate}}" editType="numericedit" [edit]="numericParams" />
            <e-column field="perUnit" headerText="{{'perUnit' | translate}}" editType="booleanedit" [displayAsCheckBox]="true" />
            <e-column field="price" headerText="{{'price' | translate}}" editType="numericedit" [edit]="numericDecimalParams" [validationRules]="requiredRules" />
            <e-column field="minAmount" headerText="{{'minPrice' | translate}}" editType="numericedit" [edit]="numericDecimalParams" />
            <e-column field="maxAmount" headerText="{{'maxPrice' | translate}}" editType="numericedit" [edit]="numericDecimalParams" />
          </e-columns>
        </ejs-grid>
      </div>
    </div>
  </div>
</div>
