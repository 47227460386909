import { DeliveryDocumentRequirement } from '../../freight-types/shared/freight-types.model';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { Component, EventEmitter, Input, Output, signal, ViewChild } from '@angular/core';
import { ModalComponent } from '../modal/modal.component';
import { ComboBoxAllModule, ComboBoxModule, ComboBoxComponent } from '@syncfusion/ej2-angular-dropdowns';
import { FormBuilder, FormsModule, UntypedFormGroup, Validators, ReactiveFormsModule, FormArray, FormGroup } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { TranslateModule } from '@ngx-translate/core';
import { SurchargeService } from 'src/app/pricing/surcharge/shared/surcharge.service';
import { FreightTypeService } from 'src/app/freight-types/shared/freight-types.service';
import { CustomerModel } from 'src/app/customers/shared/customer.model';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { DataManager, Query, ODataV4Adaptor } from '@syncfusion/ej2-data';
import { ProductTypeService } from './helpers/product-type.service';
import { CustomerService } from 'src/app/customers/shared/customer.service';




@Component({
  selector: 'app-logbook-form-modal',
  templateUrl: './logbook-form-modal.component.html',
  styleUrl: './logbook-form-modal.component.scss',
  standalone: true,
  imports: [ComboBoxModule, ModalComponent, FormsModule, CommonModule, ReactiveFormsModule, TranslateModule]
})
export class LogbookFormModal {
  @ViewChild('logbookFormModal') logbookFormModal!: ModalComponent

  @Input() data: any = null
  @Input() addresses: string = ''

  @Output() dataEmit = new EventEmitter<any>()


  faTrash = faTrash
  canAdd: boolean = false

  currentAddresses: any[] = []
  showModal: boolean = false
  formGroup: any = this.formBuilder.array([])
  type: number = 9999
  addressType: number = 9999
  surcharges: any[] = []
  addressIndex: number = 0
  idtfItems: any[] = []
  cleanlinessArray: any[] = ['A', 'B', 'C', 'D']
  customers: CustomerModel[] = []
  tradeDocumentObj = this.formBuilder.group({
    category: ['', Validators.required],
    amountKg: [0, Validators.required],
    treatment: ['', Validators.required],
    senderCustomerId: [null, Validators.required],
    receiverCustomerId: [null, Validators.required],
    referenceNumber: [null, Validators.required],
    tourid: [null, Validators.required]
  })





  constructor(
    private http: HttpClient,
    private formBuilder: FormBuilder,
    private surchargeService: SurchargeService,
    private freightService: FreightTypeService,
    private productTypeService: ProductTypeService,
    private customerService: CustomerService
  ) { }


  ngOnInit(): void {
    this.customerService.getAll().subscribe((result: CustomerModel[]) => {
      this.customers = result
    })
  }


  /**
   * Over-engineered function to update the trade document meta data
   * @param event the event from the input fields
   * @param documentIndex the index of the document being worked on
   * @param receiveOrSend string to determine if the sender or receiver is being updated
   */
  updateTradeDocumentMeta(documentIndex: number) {
    this.formGroup.at(documentIndex).patchValue({
      deliveryDocumentRequirementsMeta: JSON.stringify(this.tradeDocumentObj.value)
    })
  }



  clearForm() {
    // this.formGroup.patchValue({
    //   type: '',
    //   name: '',
    //   description: '',
    //   logbookTypeId: 0,
    //   location: ''
    // });

    // Reset surcharges field as FormArray
  }


  /**
   * Get all the IDTF objects from the API
   */
  getIDTFObjects() {
    this.freightService.getAllItemNumbers().subscribe((data: any) => {
      this.idtfItems = data
    })
  }


  hideCleaningSelection(formGroupIndex: number) {
    const selectedItemCleanliness = this.formGroup.at(formGroupIndex).value.deliveryDocumentRequirementsMeta

    if (typeof selectedItemCleanliness === "string" && selectedItemCleanliness.length === 0) {
      return false
    } else {
      return true
    }
  }


  hideCleaningLevels(cleanliness: string, doc: any, formGroupIndex: number) {
    let limit: string = this.data.selectedProduct && this.data.selectedProduct.idtf ? this.data.selectedProduct.idtf.cleaningRegime : ''


    if (limit && (limit.includes('A') || limit.includes('B') || limit.includes('C') || limit.includes('D'))) {
      if (cleanliness < limit) {
        return true
      } else {
        return false
      }
    } else {
      return false
    }
  }


  returnCleaningValue(document: any, cleaningItem?: any) {
    const cleaningValue = document.value.deliveryDocumentRequirementsMeta ? JSON.parse(document.value.deliveryDocumentRequirementsMeta).cleaningRegime : ''

    if (cleaningValue && cleaningItem) {
      if (cleaningValue === cleaningItem) {
        return true
      } else {
        return false
      }
    }

    return false
  }



  selectIDTFItem(event: any, index: number) {
    const selectElement = event.target as HTMLSelectElement;
    const selectedIndex = selectElement.selectedIndex;
    const selectedOption = JSON.parse(selectElement.options[selectedIndex].value)

    this.formGroup.at(index).patchValue({
      IDTFId: selectedOption.idtf,
      deliveryDocumentRequirementsMeta: JSON.stringify(selectedOption)
    })
  }



  selectCleaningLevel(event: any, formGroupIndex: number) {
    let selectedItemCleanliness = this.formGroup.at(formGroupIndex).value.IDTFId
    const selectElement = event.target as HTMLSelectElement;
    const selectedIndex = selectElement.selectedIndex;
    const selectedOption = selectElement.options[selectedIndex].value

    this.formGroup.at(formGroupIndex).patchValue({
      deliveryDocumentRequirementsMeta: JSON.stringify({ id: selectedItemCleanliness ? selectedItemCleanliness : this.formGroup.at(formGroupIndex).value.IDTFId, cleaningRegime: selectedOption })
    })
  }



  openModal(data: any, clearForm: boolean, addresses?: any, addressIndex?: number) {
    this.addressIndex = data.addressIndex
    this.data = data


    // clean the formGroup before pushing new data later
    if (this.formGroup.controls.length >= 1) {
      this.formGroup.controls.forEach((control: any, index: number) => {
        this.formGroup.removeAt(index)
      })
    }

    (this.formGroup as FormArray).clear();


    this.getIDTFObjects()

    if (addresses && addresses.length > 0) {
      this.currentAddresses = addresses
    }

    if (data && data.formGroup.value.addressDeliveryDocumentRequirements) {
      data.formGroup.value.addressDeliveryDocumentRequirements.forEach((document: any, index: number) => {
        this.formGroup.push(this.formBuilder.group({
          name: document.name,
          id: document.id,
          logbookId: null,
          IDTFId: document.id == 2 && this.data.selectedProduct.idtf ? this.data.selectedProduct.idtf.idtf : '',
          deliveryDocumentRequirementsId: document.id,
          deliveryDocumentRequirementsMeta: "",
          status: 1,
          cleanliness: document.id == 2 && this.data.selectedProduct.idtf ? this.data.selectedProduct.idtf.cleaningRegime : '',
          selection: [],
          manual: document.manual
        }))
      })


      if (this.data && this.data.formGroup) {
        this.tradeDocumentObj.patchValue({ amountKg: this.data.formGroup.value.amount })
      }



    } else {
      this.formGroup.clear()

      if (data.formGroup.value.deliveryDocumentRequirementsId == 2 || data.formGroup.value.deliveryDocumentRequirementsId == 3) {
        this.formGroup.push(this.formBuilder.group({
          name: '',
          id: this.data.formGroup.value.id,
          logbookId: this.data.formGroup.value.logbookId,
          IDTFId: this.data.formGroup.value.IDTFId || this.data.formGroup.value.idtfId, // depend on edit or create of tour/document
          idtf: this.data.formGroup.value.idtf, // from the backend, when you load a saved tour
          deliveryDocumentRequirementsId: this.data.formGroup.value.deliveryDocumentRequirementsId,
          deliveryDocumentRequirementsMeta: this.data.formGroup.value.deliveryDocumentRequirementsMeta,
          status: 1,
          manual: data.formGroup.value.manual
        }))
      }


      if (this.data && this.data.formGroup && this.data.formGroup.value.deliveryDocumentRequirementsId == 4) {
        this.formGroup.push(this.formBuilder.group({
          name: 'Handelsdokument test',
          id: 4,
          logbookId: null,
          IDTFId: null,
          deliveryDocumentRequirementsId: 4,
          deliveryDocumentRequirementsMeta: '',
          status: 1,
          cleanliness: '',
          selection: [],
          manual: data.formGroup.value.manual
        }))
        this.tradeDocumentObj.patchValue({ amountKg: this.data.formGroup.value.amount })
      }
    }


    if (!data && clearForm) {
      this.clearForm()
    }


    // set data in the model from the event
    if (data) {
      this.addressIndex = data.addressIndex
      this.addressType = data.addressType
      this.type = data.addressIndex

      this.logbookFormModal.open()
    } else {
      this.addressIndex = addressIndex!
      this.addressType = data.addressType
      this.type = data.addressIndex
    }

    this.logbookFormModal.open()
  }








  confirmAndEmitForm() {
    this.dataEmit.emit({
      formGroup: this.formGroup,
      addressType: this.addressType,
      addressIndex: this.addressIndex,
      productIndex: this.data.productIndex
    })

    this.logbookFormModal.close()
  }



  closeAndReset() {
    this.logbookFormModal.close()
  }
}
