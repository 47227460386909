<div class="col-md-12 ps-3 pe-3">
  <div class="row">
    <div class="col-md-12" style="padding-top: 15px">
      <h2 style="font-size: 20px!important;">{{'timeTable.overviewOfTimeRegistration' | translate}}</h2>
    </div>
    <form [formGroup]="formGroup" class="full-width row" style="padding: 15px" (ngSubmit)="initData()">
      <div class="col-md-2">
        <div *ngIf="loadingInland || loadingExport" class="spinner-border content text-dark to-bottom" role="status">
          <span class="sr-only">{{'common.loading' | translate}}</span>
        </div>
      </div>
      <div class="col-md-4">
        <label>{{'timeTable.fromDate' | translate}}</label>
        <input type="week" class="form-control full-width" formControlName="fromDate">
      </div>
      <div class="col-md-4">
        <label>{{'timeTable.toDate' | translate}}</label>
        <input type="week" class="form-control full-width" formControlName="toDate">
      </div>
      <div class="col-md-2 relatively">
        <button class="btn btn-primary align-bottom" [disabled]="formGroup.invalid" type="submit">
          <fa-icon [icon]="faCheckIcon"></fa-icon>
        </button>
      </div>
    </form>
    <div class="col-md-12 table-responsive" style="padding-top: 15px" *ngIf="dataLoaded">
      <table class="table table-bordered">
        <thead class="unselectable">
          <tr>
            <th scope="row" style="padding-left: 2px!important;">{{'timeTable.week' | translate}}</th>
            <th class="text-center" *ngFor="let column of columns;let indexOfelement=index;">{{column}}</th>
            <th class="text-center">{{'timeTable.sum' | translate}}</th>
          </tr>
        </thead>
        <tbody class="text-center">
          <tr>
            <td class="text-left bg-light">
              {{'common.inland' | translate}}
            </td>
            <td *ngFor="let key of columns" class="bg-light">
              <fa-icon *ngIf="weekIsFrozen(key, false)" [icon]="frozen"></fa-icon>
            </td>
            <td class="bg-light"></td>
          </tr>

          <tr *ngFor="let weekName of inlandWeekNames">
            <td class="text-left">{{weekName}} </td>
            <td *ngFor="let key of columns">
              {{getRowValueSF(key, salaryWorkWeeks, weekName, false)}}
              <fa-icon *ngIf="cellHasEdit(weekName, key, false)" [icon]="userPen"></fa-icon>
            </td>
            <td [ngStyle]="{'background-color': getRowColor(salaryWorkWeeks, weekName)}">
              {{getRowSumSF(salaryWorkWeeks, weekName, false)}}
              <fa-icon *ngIf="rowHasEdit(weekName, false)" [icon]="userPen"></fa-icon>
            </td>
          </tr>

          <tr>
            <td class="text-left">{{'timeTable.km' | translate}}</td>
            <td *ngFor="let key of columns">{{getWeeklyKmInland(key)}}</td>
            <td></td>
          </tr>

          <tr style="border-top: solid #2E5261 2px">
            <td class="text-left bg-light">{{'timeTable.sum' | translate}}</td>
            <td class="bg-light" *ngFor="let key of columns">
              <span style="font-weight: 500;">{{getColSumSF(salaryWorkWeeks, key, false)}}</span>
              <fa-icon *ngIf="columnHasEdit(key, false)" [icon]="userPen"></fa-icon>
            </td>
            <td class="bg-light">-</td>
          </tr>
          <tr>
            <td [colSpan]="columns.length + 2" style="height: 25px"></td>
          </tr>

          <tr>
            <td class="text-left bg-light">{{'common.export' | translate}}</td>
            <td *ngFor="let key of columns" class="bg-light">
              <fa-icon *ngIf="weekIsFrozen(key, true)" [icon]="frozen"></fa-icon>
            </td>
            <td class="bg-light"></td>
          </tr>

          <tr *ngFor="let weekName of exportWeekNames">
            <td class="text-left">{{weekName}}</td>
            <td *ngFor="let key of columns">
              {{getRowValueSF(key, salaryWorkWeeksExp, weekName, true)}}
              <fa-icon *ngIf="cellHasEdit(weekName, key, true)" [icon]="userPen"></fa-icon>
            </td>
            <td [ngStyle]="{'background-color': getRowColor(salaryWorkWeeksExp, weekName)}">
              {{getRowSumSF(salaryWorkWeeksExp, weekName, true)}}
              <fa-icon *ngIf="rowHasEdit(weekName, true)" [icon]="userPen"></fa-icon>
            </td>
          </tr>
          <tr>
            <td class="text-left">{{'timeTable.km' | translate}}</td>
            <td *ngFor="let key of columns">{{getWeeklyKmExport(key)}}</td>
            <td></td>
          </tr>

          <tr style="border-top: solid #2E5261 2px">
            <td class="text-left bg-light">{{'timeTable.sum' | translate}}</td>
            <td class="bg-light" *ngFor="let key of columns">
              <span style="font-weight: 500;">{{getColSumSF(salaryWorkWeeksExp, key, true)}}</span>
              <fa-icon *ngIf="columnHasEdit(key, true)" [icon]="userPen"></fa-icon>
            </td>
            <td class="bg-light">-</td>
          </tr>
          <!-- <tr>
            <td [colSpan]="columns.length + 2" style="height: 25px"></td>
          </tr> -->
          <tr>
            <td class="text-left">{{'timeTable.kilometer' | translate}}</td>
            <td *ngFor="let key of columns">{{getWeeklyKm(key)}}</td>
            <td><!--{{getKilometersDriven()}}--> </td>
          </tr>
          <tr>
            <td [colSpan]="columns.length + 2" style="height: 25px"></td>
          </tr>
          <tr>
            <td class="text-left bg-light" [colSpan]="columns.length + 2">{{'timeTable.absence' | translate}}</td>
          </tr>
        <td>
          <strong>{{'timeTable.title' | translate}}</strong>
        </td>
        <td><strong>{{'timeTable.weekHours' | translate}}</strong></td>
        <tr *ngFor="let activity of activitiesData">
          <td>{{activity.type}}</td>
          <td>{{getWeekNumber(activity)}}/{{getActHours(activity)}}</td>
        </tr>
        </tbody>
      </table>
      <div class="float-end mb-3">
        <table>
          <tbody>
            <tr>
              <!--<td style="padding-right:30px;">
                <button class="btn btn-primary lock-hours-bottom "
                        *ngIf="salaryWorkWeeks.length != 0 || salaryWorkWeeksExp.length != 0" (click)="createUserAgreedWeek()"
                        type="submit">
                  Overfør løntimer
                </button>
              </td>-->
              <td>
                <button class="btn btn-primary lock-hours-bottom "
                        *ngIf="salaryWorkWeeks.length != 0 || salaryWorkWeeksExp.length != 0"
                        (click)="exportWindow(editwindow)"
                        ngbTooltip="kun frosne timer vil blive exporteret til csv" placement="left"
                        type="submit">
                  {{'timeTable.exportCsv' | translate}}
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>

<ng-template #editwindow let-modal>
  <!--i know i know sorry-->
  <div class="card" style="padding-top:16px;padding-left:16px;padding-right:16px;">
    <div class="card-body">
      <div class="row">
        <div class="form-group col-12">
          <label for="exampleInputEmail1">{{'timeTable.filename' | translate}}</label>
        </div>
      </div>
      <div class="row">
        <div class="form-group col-10">
          <!--<label for="exampleInputEmail1">Filnavn:</label>-->
          <input type="text" class="form-control" [(ngModel)]="csvFilename">
        </div>
        <div class="form-group col-2">
          <button class="btn btn-primary"
                  *ngIf="salaryWorkWeeks.length != 0 || salaryWorkWeeksExp.length != 0"
                  (click)="exportUserAgreedWeek()"
                  placement="right"
                  ngbTooltip="kun frosne timer vil blive exporteret til csv"
                  type="submit">
            {{'timeTable.saveAs' | translate}}
          </button>
        </div>
      </div>

      <div class="row">
        <div class="form-group col-3 col-offset-6">
          <button class="btn btn-danger"
                  (click)="dismissExportWindow()"
                  placement="right"
                  type="submit">
            {{'timeTable.cancel' | translate}}
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-template>
