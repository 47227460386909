import { Injectable } from "@angular/core";
import { NgbAccordionItem } from "@ng-bootstrap/ng-bootstrap";

@Injectable({
  providedIn: 'root'
})

export class AccordionToggleService {
  accordionStatus(event: Event, accordionItem: NgbAccordionItem) {
    if(!accordionItem.collapsed) {
      event.stopPropagation();
    }
  }
}
