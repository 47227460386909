


<div class="reusable-table--component p-3 rounded-3">
  <div class="d-flex reusable-table-header w-full">
    <div class="d-flex col align-items-center gap-2 mb-2">
      <h2 class="reusable-table--component-title fs-5.5 mb-2">{{ this.tableTitle }} </h2>

      <ng-content select="[icon]"></ng-content>

      @if (this.displaySmallTable){
        <span class="fs-small fw-normal brand-font-color mb-2">{{this.localData?.length}}/{{this.totalCount}}</span>
      }
    </div>


    <div class="d-flex col justify-content-end z-3 gap-2">
      @if (this.displaySmallTable) {
        <!-- TODO: Needs fixing -->
        <!-- <app-dropdown-button [buttonType]="'borderedPrimary'" [buttonText]="'Flyt til gruppe'" [options]="this.dropdownOptions"/> -->
      }

      @if (this.selectionLength && this.selectionLength >= 1 && this.displaySmallTable) {
        <app-button [buttonType]="'flatDanger'" (buttonClickEvent)="emitRemoveMembersFromSubgroup()">
          <fa-icon [icon]="faTrash"></fa-icon>
          <span>{{ 'grouping.buttons.removeFromSubGroup' | translate }}</span>
        </app-button>
      }

      <ng-content select="[dropdown]"></ng-content>
    </div>
  </div>

  <div class="reusable-table--component-container rounded-4 pe-3" [ngClass]="{ 'condensed': this.displaySmallTable }">
    <table class="table table-borderless">
      <thead>
        <tr class="align-middle">
          <th scope="col" class="z-3 align-middle" style="width: 50px;">
            <app-checkbox [(ngModel)]="selectedAll" (ngModelChange)="selectAll()"/>
          </th>

          @for (column of this.localColumns; track $index) {
            <th scope="col" [ngStyle]="{'width': column.width || 'initial'}">
              <span [sortable]="column.key" (sort)="onSort($event)" class="clickable fs-6 heading-font fw-medium">
                {{ this.displayNameTranslation(column.displayName) | translate }}
                <fa-icon
                  class="sort-icon text-primary child"
                  *ngIf="sortedColumn && sortedColumn.column === column.key"
                  [icon]="sortIconDirection(sortedColumn.direction)"
                ></fa-icon>
                <fa-icon
                  class="sort-icon text-primary child"
                  *ngIf="sortedColumn?.column !== column.key"
                  [icon]="faSort"
                ></fa-icon>
              </span>
            </th>
          }
        </tr>
      </thead>


      <tbody>
        @for (row of this.localData; track $index;) {
          <tr [ngClass]="{ 'exists': row.isAssignedGroup }">
            <td scope="row" style="width: 50px;">
              <app-checkbox [(ngModel)]="row.selected" (emittedState)="addItemToListEmit(row)"/>
            </td>

            @for (column of this.localColumns; track $index) {
              <td class="align-middle" [ngStyle]="{'width': column.width || 'initial'}">
                @if (column.key == 'type' && this.subgroupKey == 'FrontVehicles') {
                  <fa-icon [icon]="this.returnTypeIcon(row[column.key])" class="brand-font-color"></fa-icon>
                }

                @else if (column.key == 'isAssignedGroup' && row.isAssignedGroup) {
                  <fa-icon [icon]="this.faCheck" class="brand-font-color ps-3"></fa-icon>
                }

                @else {
                  @if (column.key != 'isAssignedGroup' || row.isAssignedGroup.toString() != "false") {
                    <ngb-highlight [result]="row[column.key]" [term]="this.filterString" />
                  }
                }

                @if (column.key == "fullname" && this.subgroupKey == "Users") {
                  <ngb-highlight [result]="returnFullname(row)" [term]="this.filterString" />
                }
              </td>
            }
          </tr>
        } @empty {
          <tr>
            <!-- Just to annoy Peter -->
            <td colspan="9999" style="text-align: center">Ingen data at vise</td>
          </tr>
        }
      </tbody>
    </table>
  </div>
</div>
