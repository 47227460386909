<ng-template #copyContent let-modal>
  <div class="modal-header">
    <h4 class="modal-title">{{'table.copyModal.title' | translate}}</h4>

    <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
  </div>

  <div class="modal-body">
    <p>{{'table.copyModal.description' | translate}}</p>

    <form>
      <div class="form-group">
        <label for="copyToDate">{{'table.copyModal.input.label' | translate}}</label>
        <div class="input-group">
          <input #copyToDate id="copyToDate" class="form-control" type="date" [min]="today()">
        </div>
      </div>

      <div class="form-group">
        <label for="copyToDateEnd">{{'table.copyModal.input.labelEnd' | translate}}</label>
        <div class="input-group">
          <input #copyToDateEnd id="copyToDateEnd" class="form-control" type="date" [min]="today()">
        </div>
      </div>
    </form>
  </div>

  <div class="modal-footer">
    <button type="button" class="btn btn-outline-default" (click)="modal.close({startDate: copyToDate.value, endDate: copyToDateEnd.value})">{{'table.btn.ok' | translate}}</button>
  </div>
</ng-template>
<div class="mx-1 mt-2">
  <div *ngIf="!showCalenderView" class="row overflow-fix">
    <div class="col-md-8 p-0">
      <div class="row">
        <div class="col-md-3 input-group pe-0 width-fix">
          <input type="search"
            class="form-group form-control rounded-full-left"
            placeholder="{{'table.search' | translate}}"
            [(ngModel)]="searchQuery"
            (keyup.enter)="pushSearch()"
          >
          <div class="input-group-append">
            <button class="input-group-text rounded-full-right" (click)="pushSearch()">
              <fa-icon [icon]="faSearchIcon"></fa-icon>
            </button>
          </div>
        </div>

        @if (multipleSelection && showGroupButton) {
          <div class="col-md-3 pe-0">
            <app-dropdown-button
              [buttonType]="'borderedPrimary'"
              [buttonText]="'grouping.buttons.addToGroup' | translate"
              [options]="this.groupsOptions"
              [extraButton]="true"
              [extraButtonText]="'grouping.buttons.extraButtonText' | translate"
              (dropdownSelectionEvent)="addSelectedToGroupEvent($event)"
              (specialButtonEvent)="showNewGroupModal = true"
            />
          </div>
        }

        <div *ngIf="multipleSelectionButtons" class="col-md-1 pe-0">
          <button class="full-width btn btn-success rounded-full" [disabled]="disabledReleaseBtn()" (click)="emitSelectedToRelease()">
            {{'btn.release' | translate}}
          </button>
        </div>

        <div *ngIf="multipleSelectionButtons" class="col-md-1 pe-0">
          <button class="full-width btn btn-danger rounded-full" [disabled]="disabledpullBackBtn()" (click)="emitSelectedToPullBack()">
            {{'btn.pullBack' | translate}}
          </button>
        </div>

        <div *ngIf="multipleSelectionButtons" class="col-md-1 pe-0">
          <button class="full-width btn btn-primary rounded-full" [disabled]="disabledBtn()" (click)="emitSelectedToCopy(copyContent)">
            {{'btn.copy' | translate}}
          </button>
        </div>

        <div class="col-md-5" style="padding: 0">
          <div *ngIf="completedViewOption" class="btn-group tab-button-group" style="margin-left: 15px" role="group">
            <!-- <label class="btn-primary tab-button" ngbButtonLabel>
               <input ngbButton type="radio" ngbButton [value]="'active'">{{'btn.activeTour' | translate}}
             </label>

             <label class="btn-primary tab-button" ngbButtonLabel>
               <input ngbButton type="radio" ngbButton [value]="'ended'">{{'btn.endedTour' | translate}}
             </label>-->
            <input type="radio" [value]="'active'" class="btn-check" id="btnradio1" [(ngModel)]="btnTap" (change)="switchData(this.modal)" autocomplete="off">
            <label class="btn btn-primary tab-button start" for="btnradio1">{{'btn.activeTour' | translate}}</label>

            <input type="radio" [value]="'ended'" class="btn-check" id="btnradio2" [(ngModel)]="btnTap" (change)="switchData(this.modal)" autocomplete="off">
            <label class="btn btn-primary tab-button end" for="btnradio2">{{'btn.endedTour' | translate}}</label>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="col-md-1">
        <button class="btn btn-primary" (click)="importTours = !importTours">
          <fa-icon [icon]="faUpload"></fa-icon>
        </button>
    </div> -->

    <div class="col-md-4 text-end p-0">
      <span style="margin-right: 10px">
        {{refreshTime | date : 'yyyy-MM-dd HH:mm:ss'}}
      </span>

      <div class="btn-group me-2" role="group">
        <button *ngIf="showTemporaryAgToggle" type="button" class="btn btn-primary"
                placement="left" (click)="toggleTeporaryToggle()">
          <span *ngIf="temporaryToggle">AG +</span>
          <span *ngIf="!temporaryToggle">AG -</span>
        </button>
      </div>
      <div class="btn-group" role="group">
        <!--Button to temporaryly toggle AG user and Yours without registrations-->

        <button class="btn btn-primary start" [ngClass]="{'across-line' : isSomeSelected()}"
                (click)="refetchData()"
                placement="left"
                [disabled]="isSomeSelected()"
                ngbTooltip="{{'table.btn.refresh' | translate}}">
          <fa-icon [icon]="faSyncIcon"></fa-icon>
        </button>

        <button
          *ngIf="calendarViewOption"
          type="button"
          class="btn btn-primary"
          placement="left"
          ngbTooltip="{{'table.btn.calendar' | translate}}"
          (click)="showCalenderView = true"
        >
          <fa-icon [icon]="faCalendarIcon"></fa-icon>
        </button>

        <span ngbDropdown *ngIf="bookmarkOption" [ngClass]="{ 'end': !addNewOption && bookmarkOption }">
          <button
            type="button"
            class="btn btn-primary"
            [ngClass]="{ 'end': !addNewOption && bookmarkOption }"
            ngbDropdownToggle
            placement="left"
            ngbTooltip="{{'table.btn.bookmark' | translate}}"
          >
            <fa-icon [icon]="faBookmarkIcon"></fa-icon>

            <span ngbDropdownMenu style="width: 250px; " class="bg-light">
              <a class="dropdown-item" (click)="setBookmark(1)">{{'table.tomorrow' | translate}}</a>
              <a class="dropdown-item" (click)="setBookmark(2)">{{'table.next7Days' | translate}}</a>
            </span>
          </button>
        </span>

        <button type="button" class="btn btn-primary" *ngIf="showImport" (click)="importTours = !importTours" ngbTooltip="{{'table.btn.import' | translate}}">
          <fa-icon [icon]="faUpload"></fa-icon>
        </button>

        <button
          *ngIf="addNewOption"
          type="button"
          class="btn btn-primary"
          [ngClass]="{ 'end': addNewOption}"
          (click)="addItem.emit()"
          placement="left"
          ngbTooltip="{{'table.btn.add' | translate}}"
        >
          <fa-icon [icon]="faAdd"></fa-icon>
        </button>
      </div>
    </div>

    <div class="col-md-12" style="padding: 10px 0 0 10px; margin-bottom: -15px!important;">
      <span *ngFor="let chip of chipQueries">
        <div class="chip bg-row unselectable">
          <span class="chip-text">{{chip.viewValue}}</span>
          <span class="chip-close-btn" (click)="popChip(chip)">&times;</span>
        </div>
      </span>
    </div>
  </div>

  <div class="table-responsive" style="margin-top: 15px; overflow: visible!important">
    <table *ngIf="!showCalenderView && data.length > 0" class="full-width table table-sm" style="white-space: nowrap">
      <thead class="unselectable">
        <tr>
          <td style="width: 60px">
            <button *ngIf="nestedTable" class="btn btn-sm" (click)="showNestedTable =! showNestedTable">
              <fa-icon [icon]="showNestedTable ? faMinusIcon : faPlusIcon" class="text-white"></fa-icon>
            </button>
          </td>

          <td *ngIf="multipleSelection" style="padding: 10px 5px;">
            <input type="checkbox" [(ngModel)]="selectedAll" (ngModelChange)="selectAll()">
          </td>

          <td
            *ngFor="let col of columns; index as i"
            style="line-height: 1.5"
            [style.width]="col.toUpperCase() === 'STATUS' ? '150px' : ''"
            [hidden]="col.toUpperCase() === 'ID' || col.toUpperCase() === 'CALENDAREVENT' || col.toUpperCase() === 'NESTED' ||col.toUpperCase() === 'RELEASED'"
          >
            <p class="parent" *ngIf="col.toUpperCase() != 'WRONGVEHICLE'">
              <span
                [sortable]="col"
                (sort)="onSort($event)"
                class="clickable"
                [style.margin-left]="col.toUpperCase() === 'STATUS' ? '40px' : ''"
              >
                <h3>
                  {{getColumn(col) | translate}}
                </h3>
              </span>

              <span *ngIf="col.toUpperCase().includes('START') || col.toUpperCase().includes('END')" ngbDropdown>
                <fa-icon id="filterDropdown" [icon]="faFilterIcon" class="text-default clickable" style="margin-left: 5px" ngbDropdownToggle>
                  <span ngbDropdownMenu style="width: 250px; padding: 5px 10px 5px 10px;" class="bg-light">
                    <select class="custom-select" style="  margin: 5px 0 5px 0;" [(ngModel)]="filterAction">
                      <option selected value="0">{{'table.chooseAFunction' | translate}}</option>
                      <option value="===">{{'table.isEqualTo' | translate}}</option>
                      <option value="<">{{'table.lessThan' | translate}}</option>
                      <option value=">">{{'table.greaterThan' | translate}}</option>
                    </select>

                    <input type="date" class="form-group form-control" style="margin: 5px 0 5px 0;" [(ngModel)]="filterColBy">

                    <span class="col-md-12" style="padding: 0">
                      <span class="row" style="padding: 0">
                        <span class="col-md-4"><button class="btn btn-primary btn-sm full-width" (click)="addDaysToFilterColBy(-1)"><fa-icon [icon]="faLeftIcon"></fa-icon></button></span>
                        <span class="col-md-4 px-0"><button class="btn btn-primary btn-sm full-width" (click)="filterColBy = today()">{{'table.today' | translate}}</button></span>
                        <span class="col-md-4 text-end"><button class="btn btn-primary btn-sm full-width" (click)="addDaysToFilterColBy(1)"><fa-icon [icon]="faRightIcon"></fa-icon></button></span>
                      </span>
                    </span>

                    <button class="btn btn-primary btn-sm full-width" style="margin: 5px 0 5px 0;" (click)="pushFilter(col)" [disabled]="!filterColBy || filterAction === '0'" ngbDropdownToggle>{{'table.btn.ok' | translate}}</button>
                  </span>
                </fa-icon>
              </span>

              <span [sortable]="col" (sort)="onSort($event)" class="clickable">
                <fa-icon
                  class="sort-icon text-primary child"
                  *ngIf="sortedColumn && sortedColumn.column === col"
                  [icon]="sortIconDirection(sortedColumn.direction)"
                ></fa-icon>
                <fa-icon
                  class="sort-icon text-primary child"
                  *ngIf="sortedColumn?.column !== col"
                  [icon]="faDirections"
                ></fa-icon>
              </span>
            </p>
          </td>
        </tr>
      </thead>

      <tbody *ngFor="let row of dataCopy  | slice: (page-1) * pageSize : (page-1) * pageSize + pageSize; index as i" [ngClass]="rowColor(i)" class="border_bottom">
        <tr class="clickable">
          <th scope="row" class="clickable">
            {{ i + 1 }}
          </th>

          <th *ngIf="multipleSelection" scope="row" class="clickable">
            <input id="select" class="clickable" [(ngModel)]="row.selected" type="checkbox" *ngIf="!row['isEvent']">
          </th>

          <td
            *ngFor="let key of columns; index as i"
            (click)="selected(row)"
            [hidden]="key.toUpperCase() === 'ID' || key.toUpperCase() === 'CALENDAREVENT' || key.toUpperCase() === 'NESTED' || key.toUpperCase() === 'RELEASED'"
          >

            <div
              [ngClass]="key.toUpperCase() === 'STATUS' ? 'status-container text-white' : ''"
              [style.background-color]="getStatusColorBg(key, row[key])"
            >
              {{getValue(key, row[key]) | translate}}
            </div>

            <div
              *ngIf="key.toUpperCase() === 'WRONGVEHICLE' && row[key] === true"
              [ngClass]="key.toUpperCase() === 'WRONGVEHICLE' ? 'seen-container text-white' : ''"
              style="background-color: #B54B5D"
              title="{{ 'table.wrongVehicle' | translate}}"
            >
              <fa-icon style="margin-right: 4px;" class="text-white child" [icon]="faExclamation"></fa-icon>
            </div>

            <div
              *ngIf="key.toUpperCase() === 'ISLOCKED' && row[key] === true"
              [ngClass]="key.toUpperCase() === 'ISLOCKED' ? 'seen-container text-white' : ''"
              style="background-color: #387a93"
              title="{{ 'table.locked' | translate}}"
            >
              <fa-icon
                style="margin-right: 4px;"
                class="text-white child"
                [icon]="faLock"
              ></fa-icon>
            </div>

            <div
              *ngIf="key.toUpperCase() === 'ISLOCKED' && row[key] === false"
              [ngClass]="key.toUpperCase() === 'ISLOCKED' ? 'seen-container text-white' : ''"
              style="background-color: #387a93"
              title="{{ 'table.unlocked' | translate}}"
            >
              <fa-icon style="margin-right: 4px;" class="text-white child" [icon]="faLockOpen"></fa-icon>
            </div>

            <div
              *ngIf="key.toUpperCase() === 'HASBEENSEEN' && row[key] === true && !row['isEvent']"
              [ngClass]="key.toUpperCase() === 'HASBEENSEEN' ? 'seen-container text-white' : ''"
              style="background-color: #709A68"
            >
              <fa-icon style="margin-right: 4px;" class="text-white child" [icon]="faCheck"></fa-icon>
            </div>

            <div
              *ngIf="key.toUpperCase() === 'HASBEENSEEN' && row[key] === false && (row['status'] != 'Scheduled' && row['status'] != 'Scheduled24Hours') && !row['isEvent']"
              [ngClass]="key.toUpperCase() === 'HASBEENSEEN' ? 'seen-container text-white' : ''"
              style="background-color: #777777"
            ></div>

            <div *ngIf="key.toUpperCase() === 'ACTIVE' && row[key] === true">
              <input id="isActive" type="checkbox" style="margin-left: 15px;" checked [attr.disabled]="''">
            </div>

            <div *ngIf="key.toUpperCase() === 'ACTIVE' && row[key] === false">
              <input id="isActive" type="checkbox" style="margin-left: 15px;" [attr.disabled]="''">
            </div>

          </td>
        </tr>
        <td *ngIf="showNestedTable && row?.nested?.length > 0"></td>
        <td
          *ngIf="showNestedTable && row?.nested?.length > 0"
          [colSpan]="columns.length"
          class="clickable unselectable"
          (click)="selected(row)"
          style="padding: 0"
        >
          <table class="table table-sm" style="margin-bottom: 0">
            <thead>
              <tr>
                <td *ngFor="let nestedCol of nestedColumns" style="font-style: italic; font-weight: bold; width: 16%;"
                    [style.width]="nestedCol.toUpperCase() === 'STATUS' ? '150px' : ''" [ngClass]="nestedCol.toUpperCase() === 'STATUS' ? 'text-center' : ''">
                  <span *ngIf="nestedCol.toUpperCase() !== 'STATUS' && nestedCol.toUpperCase() !== 'TOURDESCRIPTION'">{{getColumn(nestedCol) | translate}}</span>
                  <span *ngIf="nestedCol.toUpperCase() !== 'STATUS'  && nestedCol.toUpperCase() === 'TOURDESCRIPTION'">{{getColumn('assigmentdescription') | translate}}</span>
                  <span *ngIf="nestedCol.toUpperCase() === 'STATUS'">{{getColumn('assigmentstatus') | translate}}</span>
                </td>
                <td style="width:150px;"></td>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let nestedRow of row.nested index as i" class="clickable">
                <td *ngFor="let nestedKey of nestedColumns">
                  <div [style.background-color]="getStatusColorBg(nestedKey, nestedRow[nestedKey])"
                      [ngClass]="nestedKey.toUpperCase() === 'STATUS' ? 'status-container text-white' : ''">
                    <!-- {{getValue(nestedKey, nestedRow[nestedKey]) | translate}} -->
                    <!-- *ngIf="nestedKey.toUpperCase() !== 'ACTUALEND'" -->
                    <span>{{getValue(nestedKey, nestedRow[nestedKey]) | translate}}</span>
                  </div>
                </td>
                <td>&nbsp;</td>
              </tr>
            </tbody>
          </table>
        </td>
      </tbody>
    </table>
  </div>

  <ngb-pagination
    *ngIf="dataCopy && !showCalenderView && data.length > 0"
    class="d-flex  unselectable justify-content-center"
    [(page)]="page"
    [pageSize]="pageSize"
    [collectionSize]="dataCopy.length"
    [maxSize]="5"
    [rotate]="true"
    [ellipses]="false"
    [boundaryLinks]="true"
  ></ngb-pagination>

  <h4 *ngIf="data.length === 0" class="text-center" style="margin-top: 50px">{{'table.noData' | translate}}</h4>

  <app-calendar
    *ngIf="showCalenderView && calendarViewOption === calenderView.event"
    (closeCalendar)="showCalenderView = false"
    (openDetails)="selected($event)"
    (createNew)="addItem.emit($event)"
  ></app-calendar>

  <app-resource-calendar
    *ngIf="showCalenderView && calendarViewOption === calenderView.resource"
    [reloadDataCounter]="reloadCounter"
    [showTemporaryAgFix]="showTemporaryAgToggle"
    (closeCalendar)="showCalenderView = false"
    (openResourceDetails)="selected($event)"
    (openJobDetails)="jobSelected($event)"
    (openEventDetails)="selectedSubItem.emit($event)"
    (eventClicked)="event($event)"
    (createResourceTour)="createTourForItem.emit($event)"
    (toggleTemporaryFix)="toggleTeporaryToggle()"
  ></app-resource-calendar>

  <app-detail
    *ngIf="importTours"
    class="overlay"
    (close)="importTours = undefined"
    [closeByBackdrop]="false"
  >
    <app-fileuploader (close)="closeImport($event)" [serviceToUse]="serviceToUse"></app-fileuploader>
  </app-detail>


  @if (showNewGroupModal) {
    <div class="create-group-modal d-flex justify-content-center align-items-center">
      <app-card
        class="d-flex w-100"
        [data]="newGroup"
        [showImage]="false"
        (isEditing)="true"
        (saveEvent)="saveGroup($event)"
        (cancelEvent)="showNewGroupModal = false"
        style="max-height: 169px; max-width: 315px;"
      />
    </div>
  }
</div>
