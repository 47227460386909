export const Constants = {
  token: 'TOKEN',
  companyGuid: 'c',
  companyName: "cN",
  language: "lang"
}

export enum CreateMode  {
  read,
  create_as_parent,
  create_as_child, // seems to be for tour view
  create_as_user // seems to be for customer view
}

export enum FrontVehicleType {
  Pull,
  Load
}

export enum BackVehicleType{
  Book,
  Curtain,
  Mega,
  Block,
  Refrigerated,
  Tarpaulin,
  Hanger,
  FlatBed,
  Tilting,
  PullOut,
  Crane,
  Jumbo
}

export enum TransportEquipmentType{
  Stabler,
  Truck,
  ElectricLift
}

export enum TourType{
  Export = 'Export',
  Inland = 'Inland'
}

export enum JobType{
  Wash = "Wash",
  PersonalService = "PersonalService"
}


export enum StatusType {
  InProgress = 'InProgress',
  Completed = 'Completed',
  Scheduled = 'Scheduled',
  Overdue = 'Overdue',
  Unknown = 'Unknown',
  Taken = 'Taken',
  Available = 'Available',
  Booked = 'Booked',
  Scheduled24Hours = 'Scheduled24Hours',
  Paused = 'Paused',
  BusinessRelated = 'BusinessRelated',
  Holidays = 'Holidays',
  Personal = 'Personal',
  Broken = "Broken",
  Wash = "Wash",
  PersonalService = "PersonalService",
  Alarm = "Alarm",
  Sick = "Sick",
  Off = "Off",
  OffPaid = "OffPaid",
  Education = "Education",
  Vacation = "Vacation",
  OnHold = "OnHold",
  Rest = "Rest",
  Intern = "Intern",
  Wait = "Wait",
  Garage = "Garage",
  Zero = "Zero",
  Task = "Task",
  WrongVehicle = "WrongVehicle"
}

export enum CalenderView  {
  none,
  resource,
  event,
}

export enum PermissionType {
  Full,
  Read,
  Limited,
}


export const COLORS = {
  primary: '#387a93',
  secondary: '#49839c',
  success: '#709A68',
  warning: '#ffd45b',
  danger: '#B54B5D',
  default: '#2E5261',
  light: '#cbdee7',
  lightGray: '#b3b3b3',
  row: '#f7f7f7',
  deepBlue: '#112e50',
  bermudaOnion: '#9b618f', // https://colornamer.robertcooper.me - name of the hex color
  darkRose: '#B54B5D', // https://colornamer.robertcooper.me - name of the hex color
  mizu: '#6ec1e4', // https://colornamer.robertcooper.me - name of the hex color
  mizuDark: '#4fa1c4', // https://colornamer.robertcooper.me - name of the hex color
  hold: "#FF9966",
  orochimaru: '#d9d9d9', // https://colornamer.robertcooper.me - name of the hex color
  lightGray7: '#777777'
}


export const ElementColors = {
  lightBorder: COLORS.lightGray,
  lightBorder40: '#b3b3b340',
  lightGreen: '#ABC0A6',
  paleOrange: "#F2BFA6"
}



export const StatusColors = {
  completed: COLORS.primary,
  inProgress: COLORS.success,
  scheduled: COLORS.orochimaru,
  scheduled24Hours: COLORS.orochimaru,
  overdue:  COLORS.danger,
  available: COLORS.success,
  booked: COLORS.lightGray7,
  taken:  COLORS.warning,
  paused:  COLORS.warning,
  unknown: '#B4DD8D',
  businessRelated: COLORS.bermudaOnion,
  holidays: COLORS.bermudaOnion,
  personal: COLORS.bermudaOnion,
  broken: COLORS.bermudaOnion,
  wash: '#04397D',
  personalService: '#04397D',
  alarm:  COLORS.danger,
  sick: COLORS.bermudaOnion,
  off: COLORS.bermudaOnion,
  offPaid: COLORS.bermudaOnion,
  education: COLORS.bermudaOnion,
  vacation: COLORS.bermudaOnion,
  onHold: "#FF9966",
  zero: COLORS.danger,
  rest: COLORS.lightGray7,
  wait: "#FF9966",
  wrongVehicle: COLORS.danger,
  intern: '#D4DD8D',
  breakdown: COLORS.orochimaru

}

/// NEW BACKGROUND COLORS
export const BackgroundStatusColors = {
  completed: '#77ADC3',
  inProgress: '#B8CDB3',
  scheduled: COLORS.orochimaru,
  scheduled24Hours: COLORS.orochimaru,
  overdue:  COLORS.danger,
  available: COLORS.success,
  booked: COLORS.lightGray7,
  taken:  '#FFE398',
  paused:  '#FFE398',
  unknown: COLORS.danger,
  businessRelated: COLORS.bermudaOnion,
  holidays: COLORS.bermudaOnion,
  personal: COLORS.bermudaOnion,
  broken: COLORS.bermudaOnion,
  wash: '#04397D',
  personalService: '#04397D',
  alarm:  COLORS.danger,
  sick: COLORS.bermudaOnion,
  off: COLORS.bermudaOnion,
  offPaid: COLORS.bermudaOnion,
  education: COLORS.bermudaOnion,
  vacation: COLORS.bermudaOnion,
  onHold: '#FF9966',
  defaultGreen: '#DAEEC6'
}

export const MapStatusColors = {
  rest: '#cccccc',
  work: '#FFDD82',
  driving: COLORS.success,
  raadig: '#8e2b3c',
  default: '#eeeeee'
}
