import { Component, OnInit } from '@angular/core';
import {DomSanitizer, SafeResourceUrl} from "@angular/platform-browser";

@Component({
  selector: 'app-homepage',
  templateUrl: './homepage.component.html',
  styleUrls: ['./homepage.component.scss']
})
export class HomepageComponent implements OnInit {

  safeUrl?: SafeResourceUrl;

  constructor(private sanitizationService: DomSanitizer) { }

  ngOnInit(): void {
   // this.safeUrl = this.sanitizationService
    //  .bypassSecurityTrustResourceUrl('https://api.aquillo.dk/trasolumap2/?operator=Ejvind_Hviid&key=08fd8188-8c34-11ec-a8a3-0242ac120002');
  }

}
