import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { WidgetComponent } from "../../shared/widget-component";
import { CreateMode, FrontVehicleType } from "../../constants";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { FrontVehicleService } from "../shared/front-vehicle.service";
import { NotificationService } from "../../shared/services/notification.service";
import { CreateFrontVehicleModel, FrontVehicleExistsModel } from "../shared/front-vehicle.model";
import { ResourceTypes, SharedService } from "../../shared/services/shared.service";
import { PropellantService } from '../shared/propellant.service';
import { PropellantModel } from '../shared/propellant.model';
import { Observable } from 'rxjs/internal/Observable';
import { lastValueFrom } from 'rxjs';
import { ResourceTypeService } from 'src/app/shared/services/resource-type.service';

@Component({
  selector: 'app-front-vehicle-create',
  templateUrl: './front-vehicle-create.component.html',
  styleUrls: ['./front-vehicle-create.component.scss']
})
export class FrontVehicleCreateComponent extends WidgetComponent {
  get types(): Array<string> {
    var keys = Object.keys(FrontVehicleType)
    return keys.slice(keys.length / 2)
  }

  @Output()
  cancel = new EventEmitter()

  @Output()
  created = new EventEmitter()

  propellantTypes: Observable<PropellantModel[]>

  formGroup: UntypedFormGroup
  language: any
  resourceTypes: ResourceTypes[] = []
  readonly: boolean = false;

  showBoxSetup = false

  constructor(
    private formBuilder: UntypedFormBuilder,
    public frontVehicleService: FrontVehicleService,
    private notifyService: NotificationService,
    private sharedService: SharedService,
    private propellantService: PropellantService,
    private resourceTypeService: ResourceTypeService
  ) {
    super()

    const currDate = new Date()
    currDate.toLocaleDateString()

    this.formGroup = this.formBuilder.group({
      typeName: ['', Validators.required],
      registrationNumber: ['', Validators.maxLength(10)],
      description: ['', Validators.maxLength(255)],
      construction: ['', Validators.maxLength(255)],
      axleCount: ['', [Validators.maxLength(50), Validators.required]],
      euroStandard: ['', Validators.maxLength(50)],
      manufacturer: ['', Validators.maxLength(50)],
      chassisNumber: ['', [Validators.maxLength(50), Validators.pattern('^[a-zA-Z0-9]+$'), sharedService.noWhitespaceValidator]],
      deadWeight: ['', [Validators.maxLength(25), Validators.required]],
      totalWeight: ['', [Validators.maxLength(25), Validators.required]],
      propellantId: [null, [Validators.required]],
      manufactureDate: null,
      unitIdentification: ['', Validators.maxLength(50)],
      equipments: [],
      timestampMileage: ['0', Validators.pattern("^[0-9]*$")],
      timestampFuel: ['0', Validators.pattern("^[0-9]*$")],
      timestampMileageDate: [currDate],
      deliveryRequirementIds: ['']
    })
    this.formGroup.patchValue({ equipments: [] })

    this.resourceTypeService.getAll('frontvehicles').subscribe(response => this.resourceTypes = response)

    this.propellantTypes = propellantService.getAll()
  }


  async getLanguage() {
    this.sharedService.getLanguageJson().subscribe(response => this.language = response)
  }

  async ngOnInit(): Promise<void> {
    await this.getLanguage()

    this.showBoxSetup = this.sharedService.isSuperAdmin()
  }

  async create() {
    var model = this.formGroup.value as CreateFrontVehicleModel

    //const response = await this.frontVehicleService.exists(model.chassisNumber, model.unitIdentification).toPromise();
    const response = await lastValueFrom<any>(this.frontVehicleService.exists(model.chassisNumber, model.unitIdentification))

    if (response.exists) {
      this.notifyService.infoMessage('Bilen eksisterer i forvejen')
    } else {
      //await this.frontVehicleService.create(this.formGroup.value as CreateFrontVehicleModel).toPromise();


      await lastValueFrom<any>(this.frontVehicleService.create(this.formGroup.value as CreateFrontVehicleModel))
      this.notifyService.successMessage(this.language?.message?.createdFrontVehicle)
      this.created.emit()
    }
  }

  updateDeliveryReqs(selectedValues2: any): void {
    
    const selectedValues: number[] = selectedValues2;

    this.formGroup.patchValue({deliveryRequirementIds: selectedValues.join(",")});
 
  }

  updateFormResourceType(input: any) {
    this.formGroup.patchValue({ typeName: input })
  }
}
