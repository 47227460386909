import { CommonModule } from "@angular/common";
import { HttpClientModule } from "@angular/common/http";
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from "@angular/core";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { FaIconComponent, FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { IconDefinition, faPlus, faSortDown } from "@fortawesome/free-solid-svg-icons";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { TranslateModule } from "@ngx-translate/core";
import { SharedModule } from "src/app/shared/shared.module";
import { DeliveryRequirement, FreightUnit } from "../../shared/freight-types.model";
import { FreightTypeService } from "../../shared/freight-types.service";
import { CheckBoxSelectionService, MultiSelectChangeEventArgs } from "@syncfusion/ej2-angular-dropdowns";
import { MultiSelectModule } from "@syncfusion/ej2-angular-dropdowns";
import { CheckBoxModule, ChipListModule } from "@syncfusion/ej2-angular-buttons";
import { EditService, FilterService, PageService, SortService, ToolbarService } from "@syncfusion/ej2-angular-grids";

@Component({
  selector: 'app-deliveryRequirements-dropdown',
  templateUrl: './deliveryRequirements-dropdown.component.html',
  styleUrl: './deliveryRequirements-dropdown.component.scss',
  standalone: true,
  imports: [
    SharedModule,
    CommonModule,
    FontAwesomeModule,
    NgbModule,
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
    TranslateModule,
    MultiSelectModule,
    CheckBoxModule,
    ChipListModule
   ],
   providers: [FreightTypeService, SortService, PageService, ToolbarService, FilterService, CheckBoxSelectionService]
})

export class DeliveryRequirementsDropdown implements OnInit, OnChanges {
  constructor(private freightTypeService: FreightTypeService ) {}

  @Input() selectedItems: any[] = [];

  @Input() readonly: boolean = true;

  @Output() choices = new EventEmitter();

  deliveryRequirements: DeliveryRequirement[] = [];
  freightUnits: FreightUnit[] = [];
  selectedDeliveryRequirements: any[] = [];
  selectedDeliveryRequirementsPre: any[] = [];
  public checkFields: Object = { text: 'name', value: 'id' };
  public checkWaterMark: string = 'Vælg leveringskrav';
  public popHeight: string = '350px';
  public filterPlaceholder: string =  'Vælg leveringskrav';
  public mode: string= 'CheckBox';

  tempTest: any[] = [];
  faPlus: IconDefinition = faPlus;
  faSortDown: IconDefinition = faSortDown;
  dropdownIsVisible: boolean = false;
  searchTerm: string = '';
  chosenElements: number[] = [];
  lastAddedObject: any = null;


  ngOnInit(): void {
    this.tempTest = this.selectedItems[0];
    this.getAllDeliveryRequirements();

    if (this.tempTest != null)
    this.selectedDeliveryRequirementsPre= this.tempTest.map((item: DeliveryRequirement) => item.id);
  }



  ngOnChanges(changes: SimpleChanges): void {
    this.tempTest = this.selectedItems[0];

    if (this.tempTest != null)
    this.selectedDeliveryRequirementsPre= this.tempTest.map((item: DeliveryRequirement) => item.id);

    if(changes['data']) {
      if(changes['data'].previousValue != undefined && changes['data'].currentValue.length > changes['data'].previousValue.length) {
        this.lastAddedObject = changes['data'].currentValue[changes['data'].currentValue.length - 1];
        this.selectedItems.push(this.lastAddedObject);
      }

    }
  }



  getAllDeliveryRequirements(): void {
    this.freightTypeService.getAllDeliveryRequirements().subscribe({
      next: (response) => {
        this.deliveryRequirements = response;
        this.selectedDeliveryRequirements = this.selectedDeliveryRequirementsPre;
      },
      error: (error) => console.error('Fejl: ', error)
    });
  }


  setSelectedDeliveryRequirements(event: MultiSelectChangeEventArgs): void {
    if (event.value == undefined) return;
    this.selectedDeliveryRequirements = event.value.filter(item => typeof item === 'number');
    this.choices.emit(this.selectedDeliveryRequirements);
  }
}
