<div *ngIf="createMode" class="bg-light">
  <form [formGroup]="formGroup" class="col-md-12">
    <div class="col-md-12" style="margin-bottom: 15px">
      <div class="row">
        <div class="col-md-6">
          <input class="form-control form-control-sm" id="title" formControlName="title" hidden>
        </div>
      </div>
      <div class="row">

        <div class="col-md-12">
          <label>{{'user.event.description' | translate}}</label>
          <span *ngIf="formGroup.controls['description'].hasError('required')" class="required-text">
            {{'message.required' | translate}}
          </span>
          <textarea autosize id="description" type="text" formControlName="description" class="form-control form-control-sm" (change)="updateTitle($event)"></textarea>
        </div>
      </div>
      <div class="row">

        <div class="col-md-6">
          <label>
            {{'user.event.type' | translate}}
            <span *ngIf="formGroup.controls['type'].hasError('required')" class="required-text">
              {{'message.required' | translate}}
            </span>
          </label>
          <select type="text" formControlName="type" class="form-select form-select-sm">
            <option *ngFor="let type of types; let i = index" [value]="type.id">{{'user.event.types.' + type.nameKey.toLowerCase() | translate}}</option>
          </select>
        </div>
        <div class="col-md-6">
          <label class="form-check-label" for="allDay1">{{'user.event.allDay' | translate}}</label>
            <input type="checkbox" id="allDay1" class="form-spec-input" (change)="changeAllDay($event)" autocomplete="off" />
        </div>
      </div> <div class="row">
        <div class="col-md-4">
          <label for="startDate">
            {{'user.event.startDate' | translate}}
            <span *ngIf="formGroup.controls['startDate'].hasError('required')" class="required-text">
              {{'message.required' | translate}}
            </span>
            <span *ngIf="formGroup.controls['startDate'].hasError('wrong-date')" class="required-text">
              {{'message.wrong-date' | translate}}
            </span>
          </label>
          <input id="startDate" type="date" formControlName="startDate" class="form-control form-control-sm" (change)="setAllDayEndDate($event)">
        </div>
        <div class="col-md-2">
          <label for="startTime">
            {{'user.event.startTime' | translate}}
            <span *ngIf="formGroup.controls['startTime'].hasError('wrong-time')" class="required-text">
              {{'message.wrong-date' | translate}}
            </span>
          </label>
          <input id="startTime" type="time" formControlName="startTime" [readOnly]="allDay" class="form-control form-control-sm">
        </div>
        <div class="col-md-4">
          <label for="endDate">
            {{'user.event.endDate' | translate}}
            <span *ngIf="formGroup.controls['endDate'].hasError('required')" class="required-text">
              {{'message.required' | translate}}
            </span>
            <span *ngIf="formGroup.controls['endDate'].hasError('wrong-date')" class="required-text">
              {{'message.wrong-date' | translate}}
            </span>
          </label>
          <input id="endDate" type="date" formControlName="endDate" class="form-control form-control-sm">
        </div>
        <div class="col-md-2">
          <label for="endTime">
            {{'user.event.endTime' | translate}}
            <span *ngIf="formGroup.controls['endTime'].hasError('wrong-time')" class="required-text">
              {{'message.wrong-date' | translate}}
            </span>
          </label>
          <input id="endTime" type="time" formControlName="endTime" [readOnly]="allDay" class="form-control form-control-sm">
        </div>
      </div>

    </div>
  </form>
  <div *ngIf="createMode" class="btn-container">
    <app-btn-accept-widget *ngIf="includeAccept" (cancel)="cancelCreate.emit()" (accept)="create()" [formGroup]="formGroup"></app-btn-accept-widget>
  </div>
</div>

<div class="col-md-12" *ngIf="event" style="padding: 15px">
  <div class="row" *ngIf="readOnly">
    <div class="col-md-12 text-end" style="margin-top: 10px;">
      <button type="button" class="btn btn-primary btn-sm" (click)="readOnly = ! readOnly"
              placement="left"
              ngbTooltip="{{'btn.edit' | translate}}">
        <fa-icon [icon]="faEdit"></fa-icon>
      </button>
    </div>
    <!-- <div class="col-md-6">
      <label>{{'user.event.title' | translate}}</label>
      <input class="form-control form-control-sm" [readOnly]="readOnly" value="{{event.title}}" [(ngModel)]="event.title">
    </div> -->

    <div class="col-md-12">
      <label>{{'user.event.description' | translate}}</label>
      <input class="form-control form-control-sm" [readOnly]="readOnly" [value]="event.description" [(ngModel)]="event.description">
    </div>
    <div class="col-md-6">
      <label>{{'user.event.type' | translate}}</label>
      <input class="form-control form-control-sm" [readOnly]="readOnly" value="{{'user.event.types.' + event.typeKey.toLowerCase() | translate}}">
    </div>
    <div class="col-md-6">
      <label>{{'user.event.allDay' | translate}}</label>
      <div class="row g-0 ">
        <input type="checkbox" [readOnly]="readOnly" class="btn-check" [checked]="allDay" autocomplete="off" />
        <label class="btn btn-outline-success" for="allDay"><fa-icon [icon]="faAccept"></fa-icon></label>
      </div>
    </div>
    <div class="col-md-4">
      <label>{{'user.event.startDate' | translate}}</label>
      <input type="date" class="form-control form-control-sm" [readOnly]="readOnly" [value]="event.startDate | date: 'yyyy-MM-dd'">
    </div>
    <div class="col-md-2">
      <label>{{'user.event.startTime' | translate}}</label>
      <input type="time" class="form-control form-control-sm" [readOnly]="readOnly" [value]="event.startDate | date: 'HH:mm'">
    </div>
    <div class="col-md-4">
      <label>{{'user.event.endDate' | translate}}</label>
      <input type="date" class="form-control form-control-sm" [readOnly]="readOnly" [value]="event.endDate | date: 'yyyy-MM-dd'">
    </div>
    <div class="col-md-2">
      <label>{{'user.event.endTime' | translate}}</label>
      <input type="time" class="form-control form-control-sm" [readOnly]="readOnly" [value]="event.endDate | date: 'HH:mm'">
    </div>

  </div>
</div>
<div class="ng-container" *ngIf="!readOnly">
  <form [formGroup]="editFormGroup" class="col-md-12">
    <input id="id" type="hidden" formControlName="id">
    <input class="form-control form-control-sm" id="title" formControlName="title" hidden>
    <div class="row">
      <div class="col-md-12">
        <label>{{'user.event.description' | translate}}</label>
        <textarea autosize id="description" type="text" formControlName="description" class="form-control form-control-sm" (change)="updateTitle($event)"></textarea>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <label>{{'user.event.type' | translate}}</label>
        <select type="text" formControlName="type" class="form-select">
          <option *ngFor="let type of types; let i = index" [value]="type.id">{{'user.event.types.' + type.nameKey.toLowerCase() | translate}}</option>
        </select>
      </div>
      <div class="col-md-6">
        <label>{{'user.event.allDay' | translate}}</label>
        <div class="row g-0 ">

          <input type="checkbox" id="allDay" class="btn-check" (change)="changeAllDayEdit($event)" [checked]="allDay" autocomplete="off" />
          <label class="btn btn-outline-success" for="allDay"><fa-icon [icon]="faAccept"></fa-icon></label>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-4">
        <label for="startDate">
          {{'user.event.startDate' | translate}}
          <span *ngIf="editFormGroup.controls['startDate'].hasError('required')" class="required-text">
            {{'message.required' | translate}}
          </span>
          <span *ngIf="editFormGroup.controls['startDate'].hasError('wrong-date')" class="required-text">
            {{'message.wrong-date' | translate}}
          </span>
        </label>
        <input id="startDate" type="date" formControlName="startDate" class="form-control form-control-sm" (change)="setAllDayEndDateEdit($event)">
      </div>
      <div class="col-md-2">
        <label for="startTime">
          {{'user.event.startTime' | translate}}
          <span *ngIf="editFormGroup.controls['startTime'].hasError('wrong-time')" class="required-text">
            {{'message.wrong-date' | translate}}
          </span>
        </label>
        <input id="startTime" type="time" formControlName="startTime" [readOnly]="allDay" class="form-control form-control-sm">
      </div>
      <div class="col-md-4">
        <label for="endDate">
          {{'user.event.endDate' | translate}}
          <span *ngIf="editFormGroup.controls['endDate'].hasError('required')" class="required-text">
            {{'message.required' | translate}}
          </span>
          <span *ngIf="editFormGroup.controls['endDate'].hasError('wrong-date')" class="required-text">
            {{'message.wrong-date' | translate}}
          </span>
        </label>
        <input id="endDate" type="date" formControlName="endDate" class="form-control form-control-sm">
      </div>
      <div class="col-md-2">
        <label for="endTime">
          {{'user.event.endTime' | translate}}
          <span *ngIf="editFormGroup.controls['endTime'].hasError('wrong-time')" class="required-text">
            {{'message.wrong-date' | translate}}
          </span>
        </label>
        <input id="endTime" type="time" formControlName="endTime" [readOnly]="allDay" class="form-control form-control-sm">
      </div>
    </div>
  </form>
  <br />
  <div *ngIf="!readOnly" class="col-md-12 btn-container">
    <div class="d-flex">

      <button class="ms-auto btn btn-danger" (click)="delete()">
        <fa-icon [icon]="faTrash"></fa-icon>
      </button>
      <app-btn-accept-widget (cancel)="cancel()" (accept)="update()" [formGroup]="editFormGroup"></app-btn-accept-widget>

      <!--<button class="btn btn-success" (click)="update()">
        <fa-icon [icon]="faAccept"></fa-icon>
      </button>

      <button class="btn btn-outline-secondary ml-1" (click)="cancel()">
        <fa-icon [icon]="faCancel"></fa-icon>
      </button>-->

    </div>
  </div>
</div>
