import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { registerLicense } from '@syncfusion/ej2-base';

 
registerLicense('Ngo9BigBOggjHTQxAR8/V1NDaF5cWWtCf1FpRmJGdld5fUVHYVZUTXxaS00DNHVRdkdnWH9ceHVUQ2JZUkR2X0c=');


//registerLicense(environment.syncfusion);

if (environment.production) {
  enableProdMode();
}


//Register Syncfusion license
//registerLicense('Ngo9BigBOggjHTQxAR8/V1NDaF5cWWtCf1FpRmJGdld5fUVHYVZUTXxaS00DNHVRdkdnWH9ceHVUQ2JZUkR2X0c=');



platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
