import {Component, Inject, OnInit} from '@angular/core';
import {SelectComponent} from "../select-component";
import {UserModel} from "../../../users/shared/user.model";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {UntypedFormBuilder, Validators} from "@angular/forms";
import {SharedService} from "../../../shared/services/shared.service";
import {UserService} from "../../../users/shared/user.service";
import {TransportEquipmentModel} from "../../../transport-equipments/shared/transport-equipment.model";
import {TransportEquipmentService} from "../../../transport-equipments/shared/transport-equipment.service";

@Component({
  selector: 'app-transport-equipment-select',
  templateUrl: './transport-equipment-select.component.html',
  styleUrls: ['./transport-equipment-select.component.scss']
})
export class TransportEquipmentSelectComponent extends SelectComponent<TransportEquipmentModel> implements OnInit {

  constructor( @Inject(NgbModal) modalService: NgbModal, @Inject(UntypedFormBuilder) formBuilder: UntypedFormBuilder,
               @Inject(SharedService) public sharedService: SharedService,
               private transportEquipmentService: TransportEquipmentService) {
    super(modalService, formBuilder, sharedService);
    this.formGroup = this.formBuilder.group({
      transportEquipment: [null, Validators.required]
    });
  }

  ngOnInit(): void {
    this.transportEquipmentService.getAll().subscribe(response => {
      this.data = this.getAllActiveTransportEquipments(response);
      this.assignCopy();
    });
    document.getElementById('btn')?.click();
    document.getElementById('search')?.focus();
  }

  getAllActiveTransportEquipments(transportEquipments: TransportEquipmentModel[]): TransportEquipmentModel[]
  {
    return transportEquipments.filter(x=>  {
      let hasNoActiveEvent = true;

      if(x.events) {
        x.events.forEach(e => {
          if((new Date(e.actualEndDate) > new Date() || e.actualEndDate === null) && new Date(e.actualStartDate) < new Date())
            hasNoActiveEvent = false;
        });
      }

      return hasNoActiveEvent;
    });
  }

}
