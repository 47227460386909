import { Component, EventEmitter, Input, Output } from '@angular/core';
import { faDownload, faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { TranslateService } from '@ngx-translate/core';
import { ReportItems } from '../../reports/shared/report.model';
import { ReportService } from '../../reports/shared/report.service';


@Component({
  selector: 'report-download-btn',
  templateUrl: './report-download-btn.component.html',
  styleUrl: './report-download-btn.component.css'
})

export class ReportDownloadBtnComponent
{
  @Input({ required: true }) report!: ReportItems;
  @Input() isTableDownload: boolean = false;

  @Output() onBackToList = new EventEmitter();


  faDownload = faDownload;
  faArrowLeft = faArrowLeft;
  constructor(private reportservice: ReportService)
  { }



  downloadPdf()
  {
    this.reportservice.downloadPdf(this.report.id).subscribe((response) =>
    {
      try
      {
        var fileURL = URL.createObjectURL(response);

        var downloadLink = document.createElement("a");
        downloadLink.href = fileURL;
        downloadLink.download = this.report.name + '.pdf';

        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);

      } catch (error)
      {
        console.error(error)
      }
    })
  }


  downloadCsv()
  {
    this.reportservice.downloadCsv(this.report.id).subscribe((response) =>
    {
      try
      {
        var fileURL = URL.createObjectURL(response);

        var downloadLink = document.createElement("a");
        downloadLink.href = fileURL;
        downloadLink.download = this.report.name + '.csv';

        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
      } catch (error)
      {
        console.error(error);
      }
    })
  }

  backToList()
  {
    this.onBackToList.emit();
  }
}
