import { Component, OnInit } from '@angular/core';
import { NotificationService } from "../../shared/services/notification.service";
import { CopyTourModel, TableTourModel, TourModel } from "../shared/tour.model";
import { TourService } from "../shared/tour.service";
import { DatePipe } from "@angular/common";
import { CalenderView, COLORS, Constants, ElementColors, JobType, StatusType } from "../../constants";
import { SharedService } from "../../shared/services/shared.service";
import { subscribeOn } from 'rxjs/operators';
import { JobService } from "../../jobs/job.service";
import { forkJoin, lastValueFrom, Subscription, zip } from "rxjs";
import { JobModel } from "../../jobs/job.model";
import { FrontVehicleModel } from 'src/app/front-vehicles/shared/front-vehicle.model';
import { FrontVehicleService } from 'src/app/front-vehicles/shared/front-vehicle.service';
import { ResourceView } from 'src/app/shared/resource-calendar/shared/resource.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UserService } from 'src/app/users/shared/user.service';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { UserModel, SplitTimeRegModel } from 'src/app/users/shared/user.model';
import * as moment from 'moment';
import { LabelType, NgxSliderModule, Options } from '@angular-slider/ngx-slider';
import { BackVehicleModel } from 'src/app/back-vehicles/shared/back-vehicle.model';
import { TransportEquipmentModel } from 'src/app/transport-equipments/shared/transport-equipment.model';
import { BackVehicleService } from 'src/app/back-vehicles/shared/back-vehicle.service';
import { TransportEquipmentService } from 'src/app/transport-equipments/shared/transport-equipment.service';
import { TranslateService } from '@ngx-translate/core';
import { EventProxyService, CustomProxyEvent, SetGroupId } from 'src/app/shared/services/event-proxy.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ResourceTypeService } from 'src/app/shared/services/resource-type.service';

@Component({
  selector: 'app-tours',
  templateUrl: './tours.component.html',
  styleUrls: ['./tours.component.scss'],
  providers: [DatePipe]
})
export class ToursComponent implements OnInit {
  private EventProxySubscription: Subscription | undefined

  tours: TourModel[] | undefined;
  jobs: JobModel[] | undefined;
  activeTimeregistrations: any[] = [];
  timeregistrations: any[] = [];
  tableData: TableTourModel[] = [];
  showDetails = false;
  selectedItemId: number = 0;
  addNew = false;
  loading: boolean = false;

  showAcceptBtn: boolean = true;

  isHistoryData: boolean = false;

  createRequestedStartDate: Date | undefined;

  language: any;

  vehicleSelected: boolean = false;
  selectedSubItemId: number = 0;
  frontVehicles: FrontVehicleModel[] | undefined;
  //createNewTour: {frontVehicle?: FrontVehicleModel, event: {startDate: Date, allDay: boolean}} | undefined;

  users: UserModel[] | undefined;
  createNewTour: { user?: UserModel, event: { startDate: Date, allDay: boolean } } | undefined;
  editTimeRegistrationFormGroup: UntypedFormGroup;

  backVehicles: BackVehicleModel[] = [];
  transportEquipment: TransportEquipmentModel[] = [];

  currentAssignment: any = ['test', 'test2', 'test3'];
  availableAssignments: any = [];
  availableTimeregTypes: any = [];

  minValueSlide: number = 0;
  maxValueSlide: number = 100;
  dateRange: Date[] = [];


  currentTourModalTableColor: string = "#ffffff";
  newTourModalTableColor: string = COLORS.success;

  currentTourModalTableColorLower: string = "#ffffff";
  newTourModalTableColorLower: string = ElementColors.lightGreen;

  currentTourModalTableWidth: string = "90%";
  newTourModalTableWidth: string = "10%";

  tourModalTdTitle1: string = "";
  tourModalTdTitle2: string = "";

  sliderTableBackground: string = "";
  sliderTableBackgroundGradiant: string = "";

  selectedCurrentTour: any;
  selectedAvailTour: any;

  selectedCurrentTourTypeId: any;
  selectedAvailTourTypeId: any;

  selectedChassisNumber: any;

  splitTimeStart: any;
  splitTimeEnd: any;

  selectedTimeReg: any;
  selectedUserId: any;

  userTemporaryFilterFix: boolean = true;

  optionsSlide: Options = {
    floor: 0,
    ceil: 23 + 59 / 60,
    step: 1 / 60,
    noSwitching: true,
    animate: false,
  };


  constructor(
    private tourService: TourService,
    private notifyService: NotificationService,
    private datePipe: DatePipe,
    private userService: UserService,
    private modalService: NgbModal,
    private sharedService: SharedService,
    private jobService: JobService,
    private frontVehicleService: FrontVehicleService,
    private backVehicleService: BackVehicleService,
    private transportEquipmentService: TransportEquipmentService,
    private formBuilder: UntypedFormBuilder,
    private translate: TranslateService,
    private eventProxyService: EventProxyService,
    private resourceTypeService: ResourceTypeService
  ) {
    this.EventProxySubscription = this.eventProxyService.getEvent().subscribe((param: CustomProxyEvent) => {
      if (param instanceof SetGroupId) {
        if (param.data && param.data <= 0) {
          sessionStorage.removeItem('groupId')
        }

        return this.getAll()
      }
    })

    this.editTimeRegistrationFormGroup = this.formBuilder.group({
      datestart: [],
      timestart: [],
      dateend: [],
      timeend: [],
      currentTour: ['', Validators.required],
      availTour: ['', Validators.required],
      currentTourTypeId: ['', Validators.required],
      availTourTypeId: ['', Validators.required],
      note: ['', Validators.required],
      id: [],
      userid: [],
      isRunning: []
    }, { validator: this.validateDates });
  }


  async getLanguage() {
    this.sharedService.getLanguageJson().subscribe(response => this.language = response)
  }


  async ngOnInit(): Promise<void> {
    await this.getLanguage()

    // Temp list of timeRegTypes:
    //setTimeout to make translations work.
    setTimeout(() => {
      this.availableTimeregTypes.push({ id: "1", name: this.translate.instant('toursAndUsers.timeRegTypes.task') })
      this.availableTimeregTypes.push({ id: "10", name: this.translate.instant('toursAndUsers.timeRegTypes.washNoTask') })
      this.availableTimeregTypes.push({ id: "11", name: this.translate.instant('toursAndUsers.timeRegTypes.washWithTask') })
      this.availableTimeregTypes.push({ id: "12", name: this.translate.instant('toursAndUsers.timeRegTypes.breakdown') })
      this.availableTimeregTypes.push({ id: "13", name: this.translate.instant('toursAndUsers.timeRegTypes.workshop') })
      this.availableTimeregTypes.push({ id: "4", name: this.translate.instant('toursAndUsers.timeRegTypes.breakRest') })
      this.availableTimeregTypes.push({ id: "14", name: this.translate.instant('toursAndUsers.timeRegTypes.breakPrivate') })
      this.availableTimeregTypes.push({ id: "17", name: this.translate.instant('toursAndUsers.timeRegTypes.timeOffPrivate') })
      this.availableTimeregTypes.push({ id: "16", name: this.translate.instant('toursAndUsers.timeRegTypes.waitingTime') })
      this.availableTimeregTypes.push({ id: "7", name: this.translate.instant('toursAndUsers.timeRegTypes.24HourRest') })
      this.availableTimeregTypes.push({ id: "8", name: this.translate.instant('toursAndUsers.timeRegTypes.weekRest') })
    }, 1)

    this.getAll()
  }

  getAll(): void {
    zip(this.tourService.getAll(), this.jobService.getAll(), this.frontVehicleService.getAll(), this.userService.getAll(), this.userService.getUsersActiveTimeRegistrations()).subscribe(response => {
      this.tours = response[0]
      this.jobs = response[1]
      this.frontVehicles = response[2]
      this.users = response[3]
      this.activeTimeregistrations = response[4]
      this.timeregistrations = []
      this.activeTimeregistrations.forEach((treg) => {
        var user = this.users?.find(x => x.id == treg.userId)
        if (treg.tourId > 0 || treg.id == 0) {
          //this.timeregistrations?.push({tour: this.tours?.find(x => x.id == treg.tourId), active: treg, user: user});
        } else {
          this.timeregistrations?.push({ tour: null, active: treg, user: user })
        }
      })
      this.mapData()
    })


    this.backVehicleService.getAll().subscribe(backVehicles => {
      this.backVehicles = backVehicles
    })

    this.transportEquipmentService.getAll().subscribe(transportEquipment => {
      this.transportEquipment = transportEquipment
    })
  }


  mapData() {
    if (!this.tours || !this.jobs || !this.activeTimeregistrations) return
    if (this.isHistoryData) {
      this.tableData = [...this.tours.map(x => ({
        id: x.id,
        tourDescription: x.description,
        chauffeur: x?.assignedUser ? x?.assignedUser?.firstname + ' ' + x?.assignedUser?.lastname : '',
        frontVehicle: x?.frontVehicle?.registrationNumber,
        backVehicles: x?.backVehicles?.map(b => b.registrationNumber)?.toString(),
        name: x?.customer?.name,
        freight: x?.freight,
        expectedStart: this.datePipe.transform(x?.expectedStartDate, 'yyyy-MM-dd HH:mm')?.toString(),
        actualStart: this.datePipe.transform(x?.actualStartDate, 'yyyy-MM-dd HH:mm')?.toString(),
        expectedEnd: this.datePipe.transform(x?.expectedEndDate, 'yyyy-MM-dd HH:mm')?.toString(),
        actualEnd: this.datePipe.transform(x?.actualEndDate, 'yyyy-MM-dd HH:mm')?.toString(),
        hasBeenSeen: x?.hasBeenSeen,
        isLocked: x?.isLocked,
        wrongVehicle: x.wrongVehicle,
        status: x?.status,
        released: x?.released,
        isAutoGeneratedUserWithTimeRegs: ShoudTemporaryHide(x)
      }))].sort((a: any, b: any) => b.released - a.released)
    } else {
      this.tableData = [...this.tours.map(x => ({
        id: x.id,
        tourDescription: x.description,
        chauffeur: x?.assignedUser ? x?.assignedUser?.firstname + ' ' + x?.assignedUser?.lastname : '',
        frontVehicle: x?.frontVehicle?.registrationNumber,
        backVehicles: x?.backVehicles?.map(b => b.registrationNumber)?.toString(),
        name: x?.customer?.name,
        freight: x?.freight,
        expectedStart: this.datePipe.transform(x?.expectedStartDate, 'yyyy-MM-dd HH:mm')?.toString(),
        actualStart: this.datePipe.transform(x?.actualStartDate, 'yyyy-MM-dd HH:mm')?.toString(),
        expectedEnd: this.datePipe.transform(x?.expectedEndDate, 'yyyy-MM-dd HH:mm')?.toString(),
        actualEnd: this.datePipe.transform(x?.actualEndDate, 'yyyy-MM-dd HH:mm')?.toString(),
        hasBeenSeen: x?.hasBeenSeen,
        isLocked: x?.isLocked,
        wrongVehicle: x.wrongVehicle,
        status: x?.status,
        isAutoGeneratedUserWithTimeRegs: ShoudTemporaryHide(x),
        released: x?.released
      })),
      ...this.timeregistrations.map(x => ({
        id: x?.tour?.id,
        tourDescription: this.language.tour.event.regTypeIds[x?.active.timeRegistrationTypeId.toString().toLowerCase()] + ' - ' + x?.active.comment,
        chauffeur: x?.user ? x?.user.firstname + ' ' + x?.user?.lastname : '',
        frontVehicle: x?.tour?.frontVehicle?.registrationNumber,
        backVehicles: '', // x?.tour.backVehicles?.map((b:any) => b.registrationNumber)?.toString(),
        name: '', //x?.tour.customer?.name,
        freight: '', //x?.tour.freight,
        expectedStart: this.datePipe.transform(x?.active.start, 'yyyy-MM-dd HH:mm')?.toString(),
        actualStart: this.datePipe.transform(x?.active.startTime, 'yyyy-MM-dd HH:mm')?.toString(),
        expectedEnd: this.datePipe.transform(x?.active.end, 'yyyy-MM-dd HH:mm')?.toString(),
        actualEnd: this.datePipe.transform(x?.active.endTime, 'yyyy-MM-dd HH:mm')?.toString(),
        hasBeenSeen: true,// x?.hasBeenSeen,
        wrongVehicle: '',
        status: this.sharedService.getTypeNameById(x?.active.timeRegistrationTypeId), // x?.status,
        released: false, // x?.released
        isEvent: true,
        isAutoGeneratedUserWithTimeRegs: false
      }))].sort((a: any, b: any) => b.released - a.released);
    }


    if (this.userTemporaryFilterFix) {
      this.tableData = this.tableData.filter(x => {
        if (x.isAutoGeneratedUserWithTimeRegs) {
          return true;
        }

        return !(x.tourDescription.indexOf('AG-') >= 0)
      });
    }
  }

  showItemDetails(event: any): void {
    if (event.resource !== ResourceView.users) {
      this.selectedItemId = event.id
      this.showDetails = true
    } else {
      this.createNewTour = {
        event: { startDate: new Date(new Date().toLocaleDateString()), allDay: false },
        user: this.users?.find(x => x.id === Number.parseInt(event.id))
      }
    }
  }

  closeItemDetails(): void {
    this.selectedItemId = 0
    this.showDetails = false
  }


  public get calenderView(): typeof CalenderView {
    return CalenderView
  }

  requestedRefreshedData(event: any) {
    if (event) {
      this.isHistoryData = true;
      this.tourService.getEndedTours(event).subscribe(response => {
        this.tours = response
        this.mapData()
      })
    } else {
      this.isHistoryData = false
      this.getAll()
    }
  }


  release(ids: number[]): void {
    // Check if all selected tours have an user assigned
    if (this.tours?.filter(t => ids.includes(t.id)).some(t => t.assignedUser == null)) {
      this.notifyService.infoMessage(this.language?.message.releasedToursNoUser)
      return
    }

    // Check if all selected tours have a frontvehicle assigned
    if (this.tours?.filter(t => ids.includes(t.id)).some(t => t.frontVehicle == null)) {
      this.notifyService.infoMessage(this.language?.message.releasedToursNoFrontVehicle)
      return
    }

    // Check if all selected tours have a customer assigned
    if (this.tours?.filter(t => ids.includes(t.id)).some(t => t.customer == null)) {
      this.notifyService.infoMessage(this.language?.message.releasedToursNoCustomer)
      return
    }


    lastValueFrom<any>(this.tourService.release(ids)).then(_ => {
      this.notifyService.successMessage(this.language?.message?.releasedTours)
      this.getAll()
    })
  }


  pullBack(ids: number[]): void {
    // Check if all selected tours have an user assigned
    if (this.tours?.filter(t => ids.includes(t.id)).some(t => t.assignedUser == null)) {
      this.notifyService.infoMessage(this.language?.message.pullBackToursNoUser)
      return
    }

    // Check if all selected tours have a customer assigned
    if (this.tours?.filter(t => ids.includes(t.id)).some(t => t.customer == null)) {
      this.notifyService.infoMessage(this.language?.message.pullBackToursNoCustomer)
      return
    }


    lastValueFrom<any>(this.tourService.pullBack(ids)).then(_ => {
      this.notifyService.warningMessage(this.language?.message?.pullBackTours)
      this.getAll()
    })
  }


  copy(data: CopyTourModel): void {
    if (data) {
      lastValueFrom<any>(this.tourService.copyRange(data)).then(_ => {
        this.notifyService.successMessage(this.language?.message?.copiedTours)
        this.getAll()
      })
    }
  }


  today(): string {
    const today = new Date()
    return <string>this.datePipe.transform(today, 'yyyy-MM-dd')
  }


  createTour(e: { resourceId: string, event: { startDate: Date, allDay: boolean } }): void {
    this.createNewTour = {
      event: e.event,
      //frontVehicle: this.frontVehicles?.find(x => x.id === Number.parseInt(e.resourceId))
      user: this.users?.find(x => x.id === Number.parseInt(e.resourceId))
    }
  }


  createDateRange(startTime: Date, endTime: Date): Date[] {
    const dates: Date[] = []
    let latestDate: Date = startTime

    dates.push(startTime)

    while (latestDate < endTime) {
      dates.push(latestDate)
      latestDate = new Date(latestDate.getTime() + 1 * 60000)
    }

    dates.push(endTime)

    return dates
  }


  onCurrentTourTypeIdChange(value: any) {
    if (value.target.value == "10" || value.target.value == "16" || value.target.value == "17") this.selectedCurrentTour = 0

    this.tourModalTdTitle1 = this.availableAssignments.find((y: any) => y.id == this.selectedCurrentTour).name
  }


  onNewTourChange(value: any) {
    this.tourModalTdTitle2 = this.availableAssignments.find((y: any) => y.id == this.selectedAvailTour).name;

    if (this.selectedAvailTour != "0" && (this.selectedAvailTourTypeId == "16" || this.selectedAvailTourTypeId == "17" || this.selectedAvailTourTypeId == "10")) this.selectedAvailTourTypeId = 1;
    //currentTourModalTableColor

    switch (value.target.value) {
      case 'finished':
        this.newTourModalTableColor = COLORS.primary;
        break;
      case 'onHold':
        this.newTourModalTableColor = COLORS.hold;
        break;
      case 'startLater':
        this.newTourModalTableColor = COLORS.success;
        break;
      case 'startEarlier':
        this.newTourModalTableColor = COLORS.primary;
        break;
    }
  }


  onNewTourTypeIdChange(value: any) {
    if (value.target.value == "10" || value.target.value == "16" || value.target.value == "17") this.selectedAvailTour = 0;

    this.tourModalTdTitle2 = this.availableAssignments.find((y: any) => y.id == this.selectedAvailTour).name;
  }


  showEditTimeRegistrationModal(content: any, e: any) {
    this.availableAssignments = [];

    if (e.event._def.publicId > 0) {
      if (e.event.extendedProps.userid) {
        this.selectedUserId = e.event.extendedProps.userid;

        this.userService.getUserTimeRegistrations(e.event.extendedProps.userid).subscribe((response) => {
          let result = response.find((t: any) => t.id == parseInt(e.event._def.publicId));
          if (result) {
            if (result.endTime == null) {
              this.modalService.dismissAll();
              this.notifyService.warningMessage(this.language.tour.splitNoEnd);
            }

            this.selectedTimeReg = parseInt(e.event._def.publicId);
            this.tourService.getSingle(e.event.extendedProps.tourId).subscribe((responseTour) => {
              this.tourModalTdTitle1 = responseTour.description;
              this.tourModalTdTitle2 = responseTour.description;

              this.availableAssignments.push({ id: responseTour.id, name: responseTour.description });

              this.selectedAvailTour = this.availableAssignments[0].id;
              this.selectedCurrentTour = this.availableAssignments[0].id;


              let startTimeDate = new Date(result.startTime);
              let endTimeDate = new Date(result.endTime);

              this.splitTimeStart = result.startTime;
              this.splitTimeEnd = result.endTime;

              this.selectedAvailTourTypeId = null;
              this.selectedCurrentTourTypeId = null;


              let startTimeDateBegining = new Date(result.startTime);
              let endTimeDateBegining = new Date(result.endTime);

              startTimeDateBegining.setHours(0, 0, 0, 0);
              endTimeDateBegining.setHours(23, 59, 59, 999);

              let dateStart = this.datePipe.transform(startTimeDateBegining, 'yyyy-MM-dd');
              let dateEnd = this.datePipe.transform(endTimeDateBegining, 'yyyy-MM-dd');

              this.userService.getUserWithToursExt(e.event.extendedProps.userid, dateStart, dateEnd).subscribe((responseTour) => {
                responseTour.tours.forEach((x: any) => {
                  if (x.id != this.selectedCurrentTour)
                    this.availableAssignments.push({ id: x.id, name: x.description });
                });
                //setTimeout to make translations work.
                setTimeout(() => {
                  this.availableAssignments.push({ id: 0, name: this.translate.instant('toursAndUsers.noTask') });
                }, 1);
              });

              this.minValueSlide = startTimeDate.getTime();

              this.dateRange = this.createDateRange(new Date(result.startTime), new Date(result.endTime));

              this.currentTourModalTableColor = e.event.backgroundColor;

              this.optionsSlide = {
                noSwitching: true,

                stepsArray: this.dateRange.map((date: Date) => {
                  return { value: date.getTime() };
                }),
                translate: (value: number, label: LabelType): string => {
                  let percentvalue: number = ((value - startTimeDate.getTime()) / (endTimeDate.getTime() - startTimeDate.getTime())) * 100;
                  percentvalue = +percentvalue.toFixed();

                  let reversePercentValue = 100 - percentvalue;

                  this.currentTourModalTableWidth = percentvalue + "%";
                  this.newTourModalTableWidth = reversePercentValue + "%";

                  this.sliderTableBackground = this.currentTourModalTableColorLower;
                  this.sliderTableBackgroundGradiant = "linear-gradient(90deg, " + this.currentTourModalTableColorLower + " " + this.currentTourModalTableWidth + ", " + this.newTourModalTableColorLower + " " + this.newTourModalTableWidth + ")";
                  let resultString = new Date(value).toLocaleString();

                  if ((startTimeDate.getFullYear() == endTimeDate.getFullYear()) && (startTimeDate.getMonth() == endTimeDate.getMonth()) && (startTimeDate.getDay() == endTimeDate.getDay())) {
                    let hoursString = ("0" + new Date(value).getHours()).slice(-2);
                    let minutesString = ("0" + new Date(value).getMinutes()).slice(-2);

                    resultString = hoursString + ":" + minutesString;
                  }

                  return resultString;
                },
                animate: false,
              };

              let indexMiddle: number = this.dateRange.length / 2;
              let indexMiddleFixed = +indexMiddle.toFixed();

              this.minValueSlide = this.dateRange[indexMiddleFixed].getTime();


              this.editTimeRegistrationFormGroup.patchValue({
                datestart: this.datePipe.transform(result.startTime, 'yyyy-MM-dd'),
                dateend: this.datePipe.transform(result.endTime, 'yyyy-MM-dd'),
                timestart: `${this.getFormattedTime(new Date(result.startTime).getHours())}:${this.getFormattedTime(new Date(result.startTime).getMinutes())}`,
                timeend: `${this.getFormattedTime(new Date(result.endTime).getHours())}:${this.getFormattedTime(new Date(result.endTime).getMinutes())}`
              });
            });
          }
        })
      }
    }

    if (this.selectedItemId === 0 && e.el.className.includes('timeregistration')) {
      const modalref = this.modalService.open(content);

      modalref.result.then((result) => {
        var start = moment(this.editTimeRegistrationFormGroup.value.datestart).add(this.editTimeRegistrationFormGroup.value.timestart);
        var end = moment(this.editTimeRegistrationFormGroup.value.dateend).add(this.editTimeRegistrationFormGroup.value.timeend);

        lastValueFrom<any>(this.userService.updateUserTimeRegistration(e.event.extendedProps.userid, e.event._def.publicId, { id: e.event._def.publicId, startTime: start.toDate(), endTime: end.toDate() })).then(_ => {
          this.getAll();
        })
      }, () => { });
    }
  }


  splitTimeReg() {
    this.showAcceptBtn = false;
    this.loading = true;

    if (this.selectedCurrentTour != null && this.selectedCurrentTourTypeId != null && this.selectedAvailTour != null && this.selectedAvailTourTypeId != null && this.editTimeRegistrationFormGroup.controls['note'].value.length > 0) {
      var dateisoStartTime = new Date(this.splitTimeStart).toISOString();
      var dateisoSplitTime = new Date(this.minValueSlide).toISOString();
      var dateisoEndTime = new Date(this.splitTimeEnd).toISOString();

      if (dateisoStartTime == dateisoSplitTime) {
        this.minValueSlide = this.minValueSlide + 1000;
        dateisoSplitTime = new Date(this.minValueSlide).toISOString();
      }

      if (dateisoEndTime == dateisoSplitTime) {
        this.minValueSlide = this.minValueSlide - 1000;
        dateisoSplitTime = new Date(this.minValueSlide).toISOString();
      }

      const splitModel: SplitTimeRegModel = {
        StartTime: dateisoStartTime,
        EndTime: dateisoEndTime,
        SplitTime: dateisoSplitTime,
        FirstTourId: this.selectedCurrentTour,
        LastTourId: this.selectedAvailTour,
        FirstTimeRegistrationType_Id: this.selectedCurrentTourTypeId,
        LastTimeRegistrationType_Id: this.selectedAvailTourTypeId,
        Comment: this.editTimeRegistrationFormGroup.controls['note'].value
      }


      lastValueFrom<any>(this.userService.splitTimeReg(this.selectedUserId, this.selectedTimeReg, splitModel)).then(_ => {
        this.getAll();
        this.modalService.dismissAll();
        this.showAcceptBtn = true;
        this.loading = false;
      })
    } else {
      // Show error
    }
  }


  onCurrentTourChange(value: any) {
    this.tourModalTdTitle1 = this.availableAssignments.find((y: any) => y.id == this.selectedCurrentTour).name;
    //currentTourModalTableColor

    switch (value.target.value) {
      case 'finished':
        this.currentTourModalTableColor = COLORS.primary;
        this.currentTourModalTableColorLower = COLORS.mizu;
        break;
      case 'onHold':
        this.currentTourModalTableColor = COLORS.hold;
        this.currentTourModalTableColorLower = ElementColors.paleOrange;
        break;
      case 'startLater':
        this.currentTourModalTableColor = COLORS.success;
        this.currentTourModalTableColorLower = ElementColors.lightGreen;
        break;
      case 'startEarlier':
        this.currentTourModalTableColor = COLORS.primary;
        this.currentTourModalTableColorLower = COLORS.mizu;
        break;
    }
  }


  validateDates(group: UntypedFormGroup) {
    const datestart = group.controls['datestart'].value
    const dateend = group.controls['dateend'].value
    const timestart = group.controls['timestart'].value;
    const timeend = group.controls['timeend'].value;
    if (moment(dateend).isBefore(moment(datestart))) {
      group.controls['dateend'].setErrors({ mustBeLater: true });
      return null;
    }
    if (moment(datestart).isSame(moment(dateend)) && moment(timeend).isBefore(moment(timestart))) {
      group.controls['dateend'].setErrors({ mustBeLater: true });
      return null;
    }
    return {
      mustBeLater: false
    };
  }


  getFormattedTime(time: number): string {
    return ('0' + time).slice(-2);
  }

  onToggleTempAg(tempToggle: boolean) {
    this.userTemporaryFilterFix = tempToggle;

    this.getAll();
  }

  ngOnDestroy() {
    if (this.EventProxySubscription) {
      this.EventProxySubscription.unsubscribe();
    }
  }
}

function ShoudTemporaryHide(x: TourModel): boolean {
  if (!x?.assignedUser) {
    return true;
  }

  if (x?.assignedUser?.isAutogenerated) {
    if (x.assignedUser.timeRegistrations?.length > 0) {
      return true;
    }
    else {
      if (x.timeRegistrations?.length > 0) {
        return true;
      }
      else {
        return false;
      }
    }
  }
  else {
    return true;
  }


}
