import { Injectable, inject } from '@angular/core';
/* import jwt_decode, { JwtPayload } from 'jwt-decode'; */
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { Observable, of } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { TokenService } from '../services/token.service';
import { AuthCompleted, EventProxyService } from '../shared/services/event-proxy.service';

@Injectable({ providedIn: 'root' })
export class AuthorizationGuard {
  private readonly oidc = inject(OidcSecurityService);
  private readonly router = inject(Router);
  private readonly tokenService = inject(TokenService);
  private readonly eventProxyService = inject(EventProxyService);

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    let canActivate = false
    let userRole = ''


    this.oidc.checkAuth().subscribe(x => {
      if (x.isAuthenticated !== true) {
        this.oidc.authorize()
      }
      else {
        this.tokenService.saveToken(x.accessToken);
        this.eventProxyService.setEvent(new AuthCompleted(null))
      }


      if (x.userData) {
        // USED FOR TESTING ROLES
        // x.userData.role = 'Employee'
        // x.userData.role = ['SuperAdmin', 'OrganizationAdmin', 'CompanyAdmin']

        if (x.userData.role == 'SuperAdmin' || x.userData.role.includes('SuperAdmin')) {
          userRole = x.userData.role
          canActivate = true
        }

        if (x.userData.role == 'OrganizationAdmin' || x.userData.role.includes('OrganizationAdmin')) {
          userRole = x.userData.role
          canActivate = true
        }

        if (x.userData.role == 'CompanyAdmin' || x.userData.role.includes('CompanyAdmin')) {
          userRole = x.userData.role
          canActivate = true
        }

        if (x.userData.role == 'Disponent' || x.userData.role.includes('Disponent')) {
          userRole = x.userData.role
          canActivate = true
        }

        if (x.userData.role == 'Speditor' || x.userData.role.includes('Speditor')) {
          userRole = x.userData.role
          canActivate = true
        }

        if (x.userData.role == 'Report' || x.userData.role.includes('Report')) {
          userRole = x.userData.role
          canActivate = false
        }

        if (x.userData.role == 'Salary' || x.userData.role.includes('Salary')) {
          userRole = x.userData.role
          canActivate = true
        }

        if (x.userData.role == 'Employee' || x.userData.role.includes('Salary')) {
          userRole = x.userData.role
          canActivate = false
        }
      }

      return x;
    })

    // change to the role you want to test with
    // userRole = 'Report'


    // config for specific routes
    if (userRole == 'Report' || userRole.includes('Report')) canActivate = route.routeConfig?.path !== 'reports' ? false : true
    if (userRole == 'Employee' || userRole.includes('Employee')) canActivate = false

    if ((userRole != 'Employee' || !userRole.includes('Employee')) && route.routeConfig?.path == 'start') canActivate = true


    if (canActivate) {
      return this.oidc.isAuthenticated()
    } else if (userRole == 'Employee' || userRole.includes('Employee')) {
      this.router.navigate(['employee'])
      return false
    } else {
      this.router.navigate(['unauthorized'])
      return false
    }
  }
}
