import { Component, inject, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { ToastrService } from "ngx-toastr";
import { IndexAdjustmentService } from "../index-adjustment/shared/index-adjustment.service";
import { CreateCompanyIndex, CreateIndexAdjustment, DisplayCompanyIndex, IndexAdjustmentWithValidTo } from "../index-adjustment/shared/index-adjustment.model";
import { ReactiveFormsModule, UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { NgbActiveModal, NgbModal, NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { AccordionModule, NodeSelectEventArgs, SidebarComponent, SidebarModule, ToolbarModule, TreeViewComponent, TreeViewModule } from "@syncfusion/ej2-angular-navigations";
import { Router } from "@angular/router";
import { BaseModal } from "src/app/freight-types/components/base-modal/base-modal.component";
import { EditService, EditSettingsModel, GridModule, IEditCell, PageService, PageSettingsModel, SortService, ToolbarItems, ToolbarService } from "@syncfusion/ej2-angular-grids";
import { SharedModule } from "src/app/shared/shared.module";
import { CommonModule } from "@angular/common";
import { firstValueFrom } from "rxjs";

@Component({
  selector: 'app-index-adjustment',
  templateUrl: './index-adjustment.component.html',
  styleUrl: './index-adjustment.component.scss',
  standalone: true,
  imports: [
    ToolbarModule,
    SidebarModule,
    TreeViewModule,
    BaseModal,
    GridModule,
    CommonModule,
    SharedModule,
    ReactiveFormsModule,
    NgbModule,
    AccordionModule
  ],
  providers: [
    ToolbarService,
    SortService,
    EditService,
    PageService
  ]
})

export class IndexAdjustmentComponent implements OnInit {
  private router: Router = inject(Router);
  private toaster : ToastrService = inject(ToastrService);
  private translate: TranslateService = inject(TranslateService);
  private modalService: NgbModal = inject(NgbModal);
  private formBuilder: UntypedFormBuilder = inject(UntypedFormBuilder);
  private indexAdjustmentService: IndexAdjustmentService = inject(IndexAdjustmentService);

  @ViewChild('sidebar') sidebar!: SidebarComponent;
  @ViewChild('tree') treeview!: TreeViewComponent;

  treeMenuItems: Object[] = [];
  treeFields: Object = {};

  translations: any = {
    prices: '',
    pricing: '',
    indexAdjustment: '',
    surcharges: '',
    gridAdd: '',
    gridDelete: '',
    gridUpdate: '',
    gridCancel: '',
    gridSearch: '',
    noData: '',
    required: '',
    validDate: '',
    dateTodayOrLater: ''
  }

  modal?: NgbActiveModal;

  loadingIndicator: any = { indicatorType: 'Spinner' };
  dateParams: IEditCell = { params: { format: 'dd/MM/yyyy' } };
  numericParams: IEditCell = { params: { decimals: 0, format: 'N', showClearButton: true, showSpinButton: false } };
  stringParams: IEditCell = { params: { showClearButton: true } };

  indexAdjustmentEditSettings: EditSettingsModel = { allowAdding: true, allowDeleting: true };
  indexAdjustmentPageSettings: PageSettingsModel = { pageSize: 10, pageCount: 10 };
  indexAdjustmentToolbar: ToolbarItems[] = ['Add', 'Delete', 'Update', 'Cancel', 'Search'];

  indexCreationFormGroup: UntypedFormGroup = this.formBuilder.group({
    indexName: ['', Validators.required]
  });

  requiredRules: any = {
    required: []
  };

  validFromRules: any = {
    required: [],
    customDateValidation: [],
    customMinValidation: []
  };

  companyIndexes: DisplayCompanyIndex[] = [];

  async ngOnInit() {
    await this.getTranslations();
    this.getAllCompanyIndexes();

    this.treeMenuItems = [
      {
        id: 1,
        name: this.translations.prices,
        url: 'pricing',
        expanded: true,
        childNodes: [
          {
            id: 1.1,
            name: this.translations.pricing,
            url: 'pricing',
          },
          {
            id: 1.2,
            name: this.translations.indexAdjustment,
            url: 'pricing/index-adjustment',
            selected: true
          },
          {
            id: 1.3,
            name: this.translations.surcharges,
            url: 'pricing/surcharge'
          }
        ]
      }
    ];

    this.treeFields = {
      dataSource: this.treeMenuItems,
      id: 'id',
      text: 'name',
      selected: 'selected',
      expanded: 'expanded',
      child: 'childNodes'
    };

    this.requiredRules.required = [true, this.translations.required];
    this.validFromRules.required = this.requiredRules.required;
    this.validFromRules.customDateValidation = [
      (args: { [key: string]: any }) => {
        const parsedDate = typeof args.value === 'string' ? this.parseDate(args.value) : args.value;
        return parsedDate instanceof Date && !isNaN(parsedDate.getTime());
      },
      this.translations.validDate
    ];
    this.validFromRules.customMinValidation = [
      (args: { [key: string]: any }) => {
        const parsedDate = typeof args.value === 'string' ? this.parseDate(args.value) : args.value;
        const today = new Date(); // Today's date
        today.setHours(0, 0, 0, 0); // Ensure comparison ignores time
        return parsedDate instanceof Date && parsedDate >= today;
      },
      this.translations.dateTodayOrLater
    ];
  }

  async getTranslations() {
    try {
      this.translations.prices = await firstValueFrom(this.translate.get('prices'));
      this.translations.pricing = await firstValueFrom(this.translate.get('pricing'));
      this.translations.indexAdjustment = await firstValueFrom(this.translate.get('indexAdjustment'));
      this.translations.gridAdd = await firstValueFrom(this.translate.get('freightTypes.create'));
      this.translations.gridDelete = await firstValueFrom(this.translate.get('freightTypes.delete'));
      this.translations.gridUpdate = await firstValueFrom(this.translate.get('companyUsers.companyUsersRolesSaveBtn'));
      this.translations.gridCancel = await firstValueFrom(this.translate.get('organization.cancel'));
      this.translations.gridSearch = await firstValueFrom(this.translate.get('company.search'));
      this.translations.noData = await firstValueFrom(this.translate.get('company.basicInformation.noData'));
      this.translations.required = await firstValueFrom(this.translate.get('message.required'));
      this.translations.validDate = await firstValueFrom(this.translate.get('validDate'));
      this.translations.dateTodayOrLater = await firstValueFrom(this.translate.get('dateTodayOrLater'));
      this.translations.surcharges = await firstValueFrom(this.translate.get('surcharge'));
    }

    catch(error) {
      console.error('Fejl: ', error);
    }
  }

  loadRoutingContent(args: NodeSelectEventArgs): void {
    let data: any = this.treeview.getTreeData(args.node);
    this.router.navigate([data[0].url]);
  }

  toolbarClicked(): void {
    this.sidebar.toggle();
  }

  closeModal(): void {
    this.modal?.dismiss();
    this.indexCreationFormGroup.get('indexName')?.setValue('');
  }

  openCreateIndexModal(modal: TemplateRef<any>): void {
    this.modal = this.modalService.open(modal, {backdrop: 'static', keyboard: false});
    document.getElementById('indexName')?.focus();
  }

  parseDate(value: string): Date | null {
    if (!value) return null;
    const [day, month, year] = value.split('/').map(Number);
    if (day && month && year) {
      return new Date(year, month - 1, day); // Adjust month (0-based index)
    }
    return null;
  }

  accordionExpand(): void {
    document.querySelectorAll('.e-toolbar .e-input-group > input').forEach((searchField) => {
      (searchField as HTMLInputElement).placeholder = this.translations.gridSearch;
    });

    document.querySelectorAll('.e-emptyrow > td').forEach((noData) => {
      (noData as HTMLElement).textContent = this.translations.noData;
    });

    document.querySelectorAll('.e-grid .e-toolbar-items .e-toolbar-item').forEach((toolbarItem) => {
      (toolbarItem as HTMLElement).title = '';
    });

    document.querySelectorAll('.e-grid .e-toolbar-items .e-toolbar-right .e-toolbar-item .e-input-group-icon').forEach((searchIcon) => {
      (searchIcon as HTMLElement).title = '';
    });

    document.querySelectorAll('.e-gridpager .e-pagercontainer .e-icons').forEach((pagingButton) => {
      (pagingButton as HTMLElement).title = '';
    });

    document.querySelectorAll('.e-toolbar-left .e-toolbar-item .e-add').forEach((addTextSibling) => {
      (addTextSibling as HTMLElement).nextSibling!.textContent = this.translations.gridAdd;
    });

    document.querySelectorAll('.e-toolbar-left .e-toolbar-item .e-delete').forEach((deleteTextSibling) => {
      (deleteTextSibling as HTMLElement).nextSibling!.textContent = this.translations.gridDelete;
    });

    document.querySelectorAll('.e-toolbar-left .e-toolbar-item .e-update').forEach((updateTextSibling) => {
      (updateTextSibling as HTMLElement).nextSibling!.textContent = this.translations.gridUpdate;
    });

    document.querySelectorAll('.e-toolbar-left .e-toolbar-item .e-cancel').forEach((cancelTextSibling) => {
      (cancelTextSibling as HTMLElement).nextSibling!.textContent = this.translations.gridCancel;
    });
  }

  onActionComplete(args: any, indexId: number): void {
    if(args.requestType === 'save') {
      const model: CreateIndexAdjustment = {
        indexId: indexId,
        index: args.data.index,
        percentage: args.data.percentage,
        validFrom: args.data.validFrom,
        comment: args.data.comment
      }
      this.createIndexAdjustment(model);
    }

    if(args.requestType === 'delete') {
      this.deleteIndexAdjustment(args.data[0].id);
    }
  }

  getAllCompanyIndexes(): void {
    this.indexAdjustmentService.getAllCompanyIndexes(true).subscribe({
      next: (response) => this.companyIndexes = response,
      error: (error) => console.error('Fejl: ', error),
      complete: () => {
        this.addValidTo();
      }
    });
  }

  getCompanyIndexById(id: number): void {
    this.indexAdjustmentService.getCompanyIndexById(id).subscribe({
      error: (error) => console.error('Fejl: ', error)
    });
  }

  createCompanyIndex(): void {
    const model: CreateCompanyIndex = {
      name: this.indexCreationFormGroup.get('indexName')!.value
    }

    this.indexAdjustmentService.createCompanyIndex(model).subscribe({
      error: (error) => console.error('Fejl: ', error),
      complete: () => {
        this.toaster.success(this.translate.instant('companyIndexCreated'));
        this.getAllCompanyIndexes();
        this.closeModal();
      }
    });
  }

  deleteCompanyIndex(event: Event, id: number): void {
    event.stopPropagation();

    this.indexAdjustmentService.deleteCompanyIndex(id).subscribe({
      next: (response) => {console.log('this.companyIndexes next: ', this.companyIndexes)},
      error: (error) => console.error('Fejl: ', error),
      complete: () => {
        this.toaster.error(this.translate.instant('companyIndexDeleted'));
        this.getAllCompanyIndexes();
        console.log('this.companyIndexes complete: ', this.companyIndexes)
      }
    });
  }

  createIndexAdjustment(model: CreateIndexAdjustment): void {
    this.indexAdjustmentService.createIndexAdjustment(model).subscribe({
      error: (error) => console.error('Fejl: ', error),
      complete: () => {
        this.toaster.success(this.translate.instant('indexAdjustmentCreated'));
        this.getAllCompanyIndexes();
      }
    });
  }

  deleteIndexAdjustment(id: number): void {
    this.indexAdjustmentService.deleteIndexAdjustment(id).subscribe({
      error: (error) => console.error('Fejl: ', error),
      complete: () => {
        this.toaster.error(this.translate.instant('indexAdjustmentDeleted'));
        this.getAllCompanyIndexes();
      }
    });
  }

  addValidTo(): void {
    if (this.companyIndexes && this.companyIndexes.length > 0) {
      this.companyIndexes = this.companyIndexes.map((companyIndex) => {
        const adjustmentsWithValidTo = companyIndex.adjustments.map((x, index, array) => {
          const adjustmentWithValidTo: IndexAdjustmentWithValidTo = { ...x, validTo: null };

          // Set validTo based on the next adjustment's validFrom date
          if (index < array.length - 1) {
            adjustmentWithValidTo.validTo = array[index + 1].validFrom;
          }

          return adjustmentWithValidTo;
        });
        return { ...companyIndex, adjustments: adjustmentsWithValidTo };
      });
    }
  }
}
