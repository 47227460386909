import {AttachmentModel} from "../../shared/attachment-widget/shared/attachment.model";
import {NoteModel} from "../../shared/note-widget/shared/note.model";
import {EquipmentModel} from "../../shared/equipment-widget/shared/equipment.model";
import {TourSimpleModel} from "../../tours/shared/tour.model";
import { PropellantModel } from "./propellant.model";
import * as internal from "stream";
import { EyeModel } from "src/app/eyes/shared/eye.model";
import { ResourceTypes } from "src/app/shared/services/shared.service";
import { DeliveryRequirement } from "src/app/freight-types/shared/freight-types.model";


export interface FrontVehicleModel {
  id: number;
  resourceType?: ResourceTypes;
  registrationNumber: string;
  description: string;
  construction: string;
  axleCount: string;
  manufacturer: string;
  euroStandard: string;
  createdBy: number;
  manufactureDate: Date;
  chassisNumber: string;
  timestamp: Date;
  attachments: AttachmentModel[];
  notes: NoteModel[];
  equipments: EquipmentModel[];
  status: string;
  unitIdentification: string;
  tours: TourSimpleModel[];
  deadWeight: string;
  totalWeight: string;
  propellant: PropellantModel;
  propellantId: number;
  events: FrontVehicleEventModel[];
  box: BoxModel;
  eyeDeviceId: number;
  eyeDevice: EyeModel;
  tacho: TachoModel;
  boxId?: number;
  boxInstallationDate: Date;
  companyName: string,
  lastKnownKilometerAge: number,
  lastKnownFuel: number,
  autoCalculatedConsumption: number;
  autoTimestampCalculatedConsumption: number;
  timestampMileage: number;
  timestampFuel: number;
  timestampMileageDate: Date;
  deliveryRequirements: DeliveryRequirement[] | [];
  deliveryRequirementIds: number[];

}



export interface FrontVehicleExistsModel {
  exists: boolean;
}

export interface BoxModel
{
  id: number;
  uid: string;
  simPin: string;
  canBaud1: string;
  boxType: string;
  modelName: string;
  storageLocation: string;
  storageBoxNumber: string;
  installationDate: Date;
}

export interface TachoModel
{
  id: number;
  vehicleId: string;
  vehicleDDDDownloadRate: number;
  driverDDDDownloadRate: number;
  analyticCompanyId: number;
  comment: string;
}

export interface FrontVehicleEventModel{
  id: number;
  description: string;
  actualStartDate: Date;
  actualEndDate: Date;
}

export interface TableFrontVehicleModel {
  id: number;
  resourceType?: string;
  chassisNumber: string;
  manufacturer: string;
  description: string;
  construction: string;
  axles: string;
  eu: string;
  manufacturerDate: string | undefined;
  reg: string;
  status: string;
  no: string;
  deadWeight: string;
  totalWeight: string;
  nested: any[];
}

export interface CreateFrontVehicleModel {
  resourceType?: ResourceTypes;
  registrationNumber: string;
  description: string;
  construction: string;
  axleCount: string;
  euroStandard: string;
  manufacturer: string;
  manufactureDate: Date | null;
  chassisNumber: string;
  equipments: EquipmentModel[];
  deadWeight: string;
  totalWeight: string;
  propellantId: number;
  companyName: string;
  unitIdentification: string;
  timestampMileage: number;
  timestampFuel: number;
  timestampMileageDate: Date;
  deliveryRequirementIds: number[];
}

export interface UpdateFrontVehicleModel {
  typeName?: ResourceTypes | string;
  registrationNumber: string;
  description: string;
  construction: string;
  axleCount: string;
  euroStandard: string;
  manufacturer: string;
  manufactureDate: Date | null;
  chassisNumber: string;
  deadWeight: string;
  totalWeight: string;
  propellantId: number;
  boxId?: number;
  companyName: string;
  unitIdentification: string;
  timestampMileage: number;
  timestampFuel: number;
  timestampMileageDate: Date;
  deliveryRequirementIds: number[];
}
export interface UpdateFrontVehicleBoxIdModel {
  id: number;
  boxId: number;
}
export interface FrontVehicleEventModel{
  id: number;
  title: string;
  description: string;
  actualStartDate: Date;
  actualEndDate: Date;
  type: EventTypeEnum;
}
export interface CreateFrontVehicleEventModel {
  title: string;
  description: string;
  startDate: Date;
  endDate: Date | null;
  type?: EventTypeEnum;
}
export interface UpdateFrontVehicleEventModel {
  id: number;
  title: string;
  description: string;
  startDate: Date;
  endDate: Date | null;
  type?: EventTypeEnum;
}
export enum EventTypeEnum
{
  Unavailable,
  Wash,
  PersonalService,
  Broken
}
export interface CreateBoxModel
{
  UId: string;
  ModelName: string;
  SimPin: string;
}
