import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FrontVehicleService } from "../shared/front-vehicle.service";
import { BoxModel, CreateBoxModel, CreateFrontVehicleEventModel, FrontVehicleModel, UpdateFrontVehicleBoxIdModel, UpdateFrontVehicleEventModel, UpdateFrontVehicleModel } from "../shared/front-vehicle.model";
import { CustomerModel } from "../../customers/shared/customer.model";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { faMinus, faPencilAlt, faPlus, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { WidgetComponent } from "../../shared/widget-component";
import { NoteModel } from "../../shared/note-widget/shared/note.model";
import { NotificationService } from "../../shared/services/notification.service";
import { FrontVehicleType } from "../../constants";
import { DatePipe } from "@angular/common";
import { ResourceTypes, SharedService } from "../../shared/services/shared.service";
import { PropellantModel } from '../shared/propellant.model';
import { Observable } from 'rxjs/internal/Observable';
import { PropellantService } from '../shared/propellant.service';
import { boxService } from 'src/app/boxes/shared/box.service';
import * as moment from 'moment';
import { AttachmentCreateModel } from 'src/app/shared/attachment-widget/shared/attachment.model';
import { MessageContent } from 'src/app/molecules/message-list/message-list.component';
import { MessagingService } from 'src/app/molecules/message-list/messaging.service';
import { BindEyeToVehicleModel, EyeModel } from 'src/app/eyes/shared/eye.model';
import { eyeService } from 'src/app/eyes/shared/eye.service';
import { ResourceTypeService } from 'src/app/shared/services/resource-type.service';
import { AccordionToggleService } from 'src/app/shared/services/accordion-toggle-service';
import { NgbAccordionItem } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-front-vehicle-detail',
  templateUrl: './front-vehicle-detail.component.html',
  styleUrls: ['./front-vehicle-detail.component.scss'],
  providers: [DatePipe]
})
export class FrontVehicleDetailComponent extends WidgetComponent {
  get types(): Array<string> {
    var keys = Object.keys(FrontVehicleType);
    return keys.slice(keys.length / 2);
  }

  formGroup: UntypedFormGroup;
  editFormGroup: UntypedFormGroup;
  editEyeFormGroup: UntypedFormGroup;
  readOnly = true;
  vehicles: FrontVehicleModel[] = [];
  frontVehicle: FrontVehicleModel | undefined;
  propellantTypes: Observable<PropellantModel[]>;

  showAddVehicle: boolean = false

  addAttachment = false;
  addNote = false;

  boxes: Observable<BoxModel[]> | undefined;
  eyes: Observable<EyeModel[]> | undefined;

  faAdd = faPlus;
  faEdit = faPencilAlt;
  faTrash = faTrashAlt;
  faPlus = faPlus;
  loading = false;
  language: any;
  addEvent = false;
  addBox = false;
  addEye = false;

  resourceTypes: ResourceTypes[] = []

  showBoxSetup = false;
  messageList: MessageContent[] = []

  constructor(
    public frontVehicleService: FrontVehicleService,
    public eyeService: eyeService,
    private formBuilder: UntypedFormBuilder,
    private notifyService: NotificationService,
    private datePipe: DatePipe,
    private sharedService: SharedService,
    private propellantService: PropellantService,
    private boxService: boxService,
    private resourceTypeService: ResourceTypeService,
    private messagingService: MessagingService,
    private accordionToggleService: AccordionToggleService
  ) {
    super()

    this.formGroup = this.formBuilder.group({
      id: undefined,
      typeName: ['', Validators.required],
      registrationNumber: ['', Validators.maxLength(10)],
      description: ['', Validators.maxLength(255)],
      construction: ['', Validators.maxLength(255)],
      axleCount: ['', [Validators.maxLength(50), Validators.required]],
      euroStandard: ['', Validators.maxLength(50)],
      manufacturer: ['', Validators.maxLength(50)],
      chassisNumber: ['', [Validators.maxLength(50), Validators.pattern('^[a-zA-Z0-9 ]+$'), sharedService.noWhitespaceValidator]],
      deadWeight: ['', [Validators.maxLength(25), Validators.required]],
      totalWeight: ['', [Validators.maxLength(25), Validators.required]],
      manufactureDate: null,
      propellantId: { value: null, disabled: this.readOnly },
      unitIdentification: ['', Validators.maxLength(50)],
      equipments: [],
      boxId: null,
      lastKnownKilometerAge: [''],
      lastKnownFuel: [''],
      autoCalculatedConsumption: [''],
      autoTimestampCalculatedConsumption: [''],
      timestampMileage: ['', Validators.pattern("^[0-9]*$")],
      timestampFuel: ['', Validators.pattern("^[0-9]*$")],
      timestampMileageDate: ['']
    })

    this.resourceTypeService.getAll('frontvehicle').subscribe(response => this.resourceTypes = response)


    this.propellantTypes = propellantService.getAll()
    this.boxes = boxService.getAllAvailableBoxes();
    this.eyes = eyeService.getAllAvailableEyes();

    this.editFormGroup = this.formBuilder.group({
      boxSelect: ['', Validators.required]
    })

    this.editEyeFormGroup = this.formBuilder.group({
      eyeSelect: ['', Validators.required]
    })
  }

  async getLanguage() {
    this.sharedService.getLanguageJson().subscribe(response => this.language = response)
  }

  async ngOnInit(): Promise<void> {
    await this.getLanguage()


    this.refresh()

    // get the messages for the message-list component
    if (this.id) {
      this.messagingService.getMessages({ userId: null, frontVehicleId: this.id}).subscribe((response: any) => {
       this.messageList = response
      })
    }

    this.showBoxSetup = this.sharedService.isSuperAdmin()
  }

  addNewAttachment(event: Event, accordionItem: NgbAccordionItem): void {
    this.accordionToggleService.accordionStatus(event, accordionItem);
    this.addAttachment = true;
  }

  addNewStatus(event: Event, accordionItem: NgbAccordionItem): void {
    this.accordionToggleService.accordionStatus(event, accordionItem);
    this.addEvent = true;
  }

  addNewBox(event: Event, accordionItem: NgbAccordionItem): void {
    this.accordionToggleService.accordionStatus(event, accordionItem);
    this.addBox = true;
  }

  addNewEye(event: Event, accordionItem: NgbAccordionItem): void {
    this.accordionToggleService.accordionStatus(event, accordionItem);
    this.addEye = true;
  }

  patchFormValues(): void {
    const model = this.frontVehicle

    this.formGroup.patchValue({
      id: model?.id,
      typeName: model?.resourceType ? model?.resourceType?.resourceTypeName : '',
      registrationNumber: model?.registrationNumber,
      description: model?.description,
      construction: model?.construction,
      axleCount: model?.axleCount,
      euroStandard: model?.euroStandard,
      manufacturer: model?.manufacturer,
      chassisNumber: model?.chassisNumber,
      manufactureDate: this.datePipe.transform(model?.manufactureDate, 'yyyy-MM-dd'),
      equipments: model?.equipments?.map(e => ({ value: e.value })),
      unitIdentification: model?.unitIdentification,
      deadWeight: model?.deadWeight,
      totalWeight: model?.totalWeight,
      propellantId: model?.propellantId,
      boxId: model?.boxId,
      timestampMileage: model?.timestampMileage ? Math.round(model?.timestampMileage) : model?.timestampMileage,
      timestampFuel: model?.timestampFuel ? Math.round(model?.timestampFuel) : model?.timestampFuel,
      timestampMileageDate: this.datePipe.transform(model?.timestampMileageDate, 'yyyy-MM-dd'),
      autoTimestampCalculatedConsumption: model?.autoTimestampCalculatedConsumption,
      lastKnownKilometerAge: model?.lastKnownKilometerAge ? model?.lastKnownKilometerAge / 1000 : model?.lastKnownKilometerAge,
      lastKnownFuel: model?.lastKnownFuel ? (model?.lastKnownFuel / 1000).toFixed(0) : model?.lastKnownFuel,
      autoCalculatedConsumption: model?.autoCalculatedConsumption
    })
  }

  update(): void {
    this.readOnly = true
    this.loading = true
    this.formGroup.controls['typeName'].enable()
    this.formGroup.controls['propellantId'].enable()

    if (!this.formGroup.value.typeName) {
      this.formGroup.patchValue({ typeName: this.frontVehicle?.resourceType ? this.frontVehicle?.resourceType?.resourceTypeName : '' })

      if (!this.formGroup.value.typeName) {
        this.formGroup.patchValue({ typeName: this.resourceTypes.find(x => x.id === 0)?.resourceTypeName })
      }
    }

    this.frontVehicleService.update(this.id, this.formGroup.value as UpdateFrontVehicleModel).subscribe(response => {
      this.frontVehicle = response
      this.patchFormValues()
      this.updateList.emit()
      this.getBox()
      this.loading = false
    })
  }

  updateBoxidOnFrontVehicle(): void {
    this.readOnly = true;
    this.loading = true;

    let companyName = "undefined";
    if (sessionStorage.getItem("cN"))
      companyName = sessionStorage.getItem("cN")!;
    if (this.frontVehicle && this.editFormGroup.controls['boxSelect'] != null) {
      const model: UpdateFrontVehicleModel =
      {
        typeName: this.frontVehicle?.resourceType?.resourceTypeName,
        registrationNumber: this.frontVehicle?.registrationNumber,
        description: this.frontVehicle?.description,
        construction: this.frontVehicle?.construction,
        axleCount: this.frontVehicle?.axleCount,
        euroStandard: this.frontVehicle?.euroStandard,
        manufacturer: this.frontVehicle?.manufacturer,
        chassisNumber: this.frontVehicle?.chassisNumber,
        manufactureDate: this.frontVehicle.manufactureDate,
        deadWeight: this.frontVehicle?.deadWeight,
        totalWeight: this.frontVehicle?.totalWeight,
        propellantId: this.frontVehicle?.propellantId,
        boxId: this.frontVehicle?.boxId,
        companyName: companyName,
        unitIdentification: this.frontVehicle?.unitIdentification,
        timestampMileageDate: this.frontVehicle?.timestampMileageDate,
        timestampFuel: this.frontVehicle?.timestampFuel,
        timestampMileage: this.frontVehicle?.timestampMileage
      }

      model.boxId = this.editFormGroup.controls['boxSelect'].value;

      this.frontVehicleService.update(this.id, model).subscribe(response => {
        this.frontVehicle = response;
        this.patchFormValues();
        this.updateList.emit();
        this.getBox();
        this.loading = false;
      });
    }
  }

  updateEyeidOnFrontVehicle(): void {
    this.readOnly = true;
    this.loading = true;

    if (this.frontVehicle && this.editEyeFormGroup.controls['eyeSelect'] != null) {
      const model: BindEyeToVehicleModel =
      {
        vehicleId: this.frontVehicle.id,
        vehicleType: 0,
        eyeDeviceId: 0

      }

      model.eyeDeviceId = this.editEyeFormGroup.controls['eyeSelect'].value;

      this.eyeService.bind(model).subscribe(response => {
        this.frontVehicle = response;
        this.patchFormValues();
        this.updateList.emit();
        this.refresh();
        //this.getBox();
        this.loading = false;
      });
    }
  }

  createAttachment(attachment: AttachmentCreateModel): void {
    this.frontVehicleService.createAttachment(this.id, attachment).subscribe(response => {
      if (this.frontVehicle) {
        this.frontVehicle.attachments = response.attachments;
        this.notifyService.successMessage(this.language?.message?.createdAttachment);
      }
    });
  }

  deleteAttachment(attachmentId: number): void {
    this.frontVehicleService.deleteAttachment(this.id, attachmentId).subscribe(response => {
      if (this.frontVehicle) {
        this.frontVehicle.attachments = response.attachments;
        this.notifyService.warningMessage(this.language?.message?.deletedAttachment);
      }
    });
  }

  getAttachments(): void {
    this.frontVehicleService.getAttachments(this.id).subscribe(response => {
      if (this.frontVehicle) this.frontVehicle.attachments = response.attachments;
    });
  }

  createNote(model: NoteModel): void {
    this.frontVehicleService.createNote(this.id, model).subscribe(response => {
      if (this.frontVehicle) {
        this.frontVehicle.notes = response.notes;
        this.notifyService.successMessage(this.language?.message?.createdNote);
      }
    });
  }

  getNotes(): void {
    this.frontVehicleService.getNotes(this.id).subscribe(response => {
      if (this.frontVehicle) this.frontVehicle.notes = response.notes;
    });
  }

  deleteNote(noteId: number): void {
    this.frontVehicleService.deleteNote(this.id, noteId).subscribe(response => {
      if (this.frontVehicle) {
        this.frontVehicle.notes = response.notes;
        this.notifyService.warningMessage(this.language?.message?.deletedNote);
      }
    });
  }

  delete(): void {
    this.frontVehicleService.delete(this.id).subscribe(() => {
      this.updateList.emit();
      this.close.emit();
      this.notifyService.warningMessage(this.language?.message?.deletedFrontVehicle);
    });
  }

  emitAddVehicle(): void {
    this.close.emit();
    this.addVehicle.emit();
  }

  updateEvent(model: any): void {
    this.loading = true;
    const m: UpdateFrontVehicleEventModel = {
      id: model.id,
      title: model.title,
      description: model.description,
      startDate: moment(this.datePipe.transform(`${model.startDate} ${model.startTime}`, 'yyyy-MM-dd HH:mm')).toDate(),
      endDate: model.endDate != null ? moment(this.datePipe.transform(`${model.endDate} ${model.endTime}`, 'yyyy-MM-dd HH:mm')).toDate() : null,
      type: model.type
    }
    this.frontVehicleService.updateFrontVehicleEvent(this.id, m.id, m).subscribe((response) => {
      this.updateList.emit();
      this.refresh();
    }, error => this.loading = false);
  }

  refresh(): void {
    this.loading = true;

    if (this.id != 0) {
      this.frontVehicleService.getSingle(this.id).subscribe(response => {
        this.frontVehicle = response;
        this.patchFormValues();
        this.getNotes();
        this.getAttachments();
        this.getEvents();
        this.getBox();
        this.loading = false;
      });
    } else {
      this.frontVehicleService.getAll().subscribe(response => {
        this.vehicles = response;

        this.vehicles = this.vehicles.filter(x => x.resourceType?.id != 0).sort((a, b) => {
          if (a.unitIdentification < b.unitIdentification) {
            return -1;
          }
          if (a.unitIdentification > b.unitIdentification) {
            return 1;
          }
          return 0;
        });
        this.loading = false;
      });
    }


  }

  getEvents(): void {
    this.frontVehicleService.getSingleEvent(this.id).subscribe(response => {
      if (this.frontVehicle) {
        this.frontVehicle.events = response;
      }
    });
  }

  cancelEvent(): void {
    this.refresh();
  }

  createEvent(model: any): void {
    this.loading = true;
    const m: any = {
      title: model.title,
      description: model.description,
      startLatitude: 0.00000000001,
      startLongitude: 0.00000000001,
      startDate: moment(this.datePipe.transform(`${model.startDate} `, 'yyyy-MM-dd HH:mm')).toDate(),
      endDate: model.endDate != "" ? moment(this.datePipe.transform(`${model.endDate} `, 'yyyy-MM-dd HH:mm')).toDate() : null,
      startKilometerAge: 0,
      type: model.type
    }
    this.frontVehicleService.createFrontVehicleEvent(this.id, m).subscribe((response) => {
      this.updateList.emit();
      this.refresh();
    }, error => this.loading = false);
  }

  getBox() {
    if (this.frontVehicle?.boxId)
      this.frontVehicleService.getBox(this.frontVehicle.boxId).subscribe((response) => {
        this.frontVehicle!.box = response;
        this.frontVehicle!.box.installationDate = this.frontVehicle!.boxInstallationDate;
      });
  }

  removeBox() {
    const model = this.frontVehicle;

    if (model?.boxId) {
      model.boxId = undefined;
      this.frontVehicleService.update(this.id, model).subscribe((response) => {
        this.frontVehicle = response;
        this.updateList.emit();
        this.loading = false;
        this.notifyService.warningMessage(this.language?.message?.boxRemovedFromFrontVehicle);
      })
    }
  }

  removeEye() {
    const model = this.frontVehicle;

    if (model?.eyeDeviceId) {

      this.eyeService.unbind(model?.eyeDeviceId).subscribe((response) => {
        this.updateList.emit();
        this.refresh();
        this.loading = false;
        this.notifyService.warningMessage(this.language?.message?.eyeRemovedFromFrontVehicle);
      })
    }
  }

  isCalculated() {
    if (this.frontVehicle?.autoCalculatedConsumption) {
      return this.frontVehicle?.autoCalculatedConsumption > 0;
    }
    return false;
  }
  getVehicleDisplayText(vehicle: FrontVehicleModel): string {
    return this.frontVehicleService.GetDisplayText(vehicle);
  }

  updateFormResourceType(input: any) {
    this.formGroup.patchValue({ typeName: input })
  }
}
