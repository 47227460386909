<div class="multiselect-dropdown position-relative w-100">
    <label>Leveringskrav</label>
    <div *ngIf="readonly" class="e-info-text-separator">
      <ejs-chiplist  cssClass="e-outline">
        <e-chips>
          <!--<e-chip *ngFor="let chip of data.freightUnit" [text]="chip.name"></e-chip>-->
          <e-chip *ngFor="let chip of selectedItems[0]" [text]="chip.name"></e-chip>
        </e-chips>
      </ejs-chiplist>
  </div>
    <ejs-multiselect *ngIf="!readonly" id='multiselect-checkbox' #checkbox [dataSource]='deliveryRequirements' 
                [placeholder]='checkWaterMark' 
                [fields]='checkFields'
                [mode]='mode' 
                [popupHeight]='popHeight' 
                [showDropDownIcon]='true' 
                 
                showSelectAll='true' 
                [(value)]='selectedDeliveryRequirements'
                (change)='setSelectedDeliveryRequirements($event)'
                [filterBarPlaceholder]='filterPlaceholder'></ejs-multiselect>
  </div>
   
  <!--     [value]='data.deliveryRequirements'    -->