import { Component, Input, OnInit, Output, EventEmitter, HostListener, OnChanges, SimpleChanges } from '@angular/core';
import { AddressModel } from "./shared/address.model";
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { CreateMode } from "../../constants";
import { GeocodeService } from "../services/geocode.service";
import { faKeyboard, faPencilAlt, faSync, faTrash, faTrashAlt, faStreetView } from "@fortawesome/free-solid-svg-icons";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from 'src/environments/environment';
import { BackVehicleModel } from 'src/app/back-vehicles/shared/back-vehicle.model';
import { TransportEquipmentModel } from 'src/app/transport-equipments/shared/transport-equipment.model';
import { SharedService } from '../services/shared.service';
import { TranslateService } from '@ngx-translate/core';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { GoogleStreetViewModalComponent } from 'src/app/google-street-view-modal/google-street-view-modal.component';
import { TomTomLatLng } from 'src/app/tomtom-map-picker/shared/tomtom.model';

//import tt from '@tomtom-international/web-sdk-maps';

//https://developer.tomtom.com/blog/build-different/building-responsive-location-search-component-angular/

@Component({
  selector: 'app-address-widget',
  templateUrl: './address-widget.component.html',
  styleUrls: ['./address-widget.component.scss']
})
export class AddressWidgetComponent implements OnInit {
  get createModes() { return CreateMode; }

  @Input() addressIsRequired: boolean = true;

  @Input()
  address: AddressModel | undefined;

  @Input()
  backVehicles: BackVehicleModel[] | undefined;

  @Input()
  transportEquipment: TransportEquipmentModel[] | undefined;

  @Input()
  createMode: any;

  @Output()
  cancelCreate = new EventEmitter();

  @Input()
  reset = false;

  @Output()
  createAddress = new EventEmitter();

  @Input()
  showDescription = true;

  @Input()
  showCoordinates = true;

  @Input()
  showEditButton = true;

  @Input()
  showLocationNameAndId = true;

  @Input()
  showFreightSection = true;

  @Input()
  showFreightType = true;

  @Input() showBillingAddress: boolean = false;

  @Input()
  freightTypeId = 0;

  @Input()
  arriveBackVehicle: string | undefined;

  selectArriveBackVehicle: string | undefined;

  @Input()
  leaveBackVehicle: string | undefined;

  selectLeaveBackVehicle: string | undefined;

  @Input()
  arriveEquipment: string | undefined;

  selectArriveEquipment: string | undefined;

  @Input()
  leaveEquipment: string | undefined;

  selectLeaveEquipment: string | undefined;

  @Output()
  editAddress = new EventEmitter();

  @Output()
  showStreetview = new EventEmitter();


  @Output()
  delete = new EventEmitter();
  readOnly = true;

  formGroup: UntypedFormGroup;

  isGeocoding: boolean = false;
  isReverseGeocoding: boolean = false;
  faSyncIcon = faSync;
  faEdit = faPencilAlt;
  faTrash = faTrashAlt;
  faKeyboard = faKeyboard
  faStreetView = faStreetView;
  currentLat: number = 55.850849;
  currentLon: number = 9.845612;

  currSearch: number = 0;

  searchResults: any = [];

  language: string | undefined;
  showArriveMultiSelect: boolean = false
  showLeaveMultiSelect: boolean = false
  dropdownList: any[] = []
  arriveBackVehicles: any[] = []
  leaveBackVehicles: any[] = []

  dropdownSettings: IDropdownSettings = {
    singleSelection: false,
    idField: 'item_id',
    textField: 'item_text',
    allowSearchFilter: true,
    searchPlaceholderText: this.translate.instant('reports.multiselect.search'),
    noDataAvailablePlaceholderText: this.translate.instant('reports.multiselect.noDataAvailable'),
    itemsShowLimit: 3,
    limitSelection: 3
  }

  constructor(private formBuilder: UntypedFormBuilder, private http: HttpClient, private geocoder: GeocodeService, protected sharedService: SharedService, private translate: TranslateService) {
    this.formGroup = this.formBuilder.group({});
    this.sharedService.getLanguageJson().subscribe(response => this.language = response);
  }

  async getLanguage() {
    this.sharedService.getLanguageJson().subscribe(response => this.language = response)
  }

  async ngOnInit(): Promise<void> {
    this.backVehicles?.forEach(backVehicle => {
      this.dropdownList.push({ item_id: backVehicle.id, item_text: backVehicle.unitIdentification + ' / ' + backVehicle.registrationNumber });
    });

    this.formGroup = this.formBuilder.group({
      id: undefined,
      country: ['', this.addressIsRequired ? Validators.compose([Validators.required, Validators.maxLength(255)]) : Validators.maxLength(255)],
      state: ['', Validators.maxLength(255)],
      zipcode: ['', this.addressIsRequired ? Validators.compose([Validators.required, Validators.maxLength(10)]) : Validators.maxLength(10)],
      city: ['', this.addressIsRequired ? Validators.compose([Validators.required, Validators.maxLength(255)]) : Validators.maxLength(255)],
      street: ['', this.addressIsRequired ? Validators.compose([Validators.required, Validators.maxLength(255)]) : Validators.maxLength(255)],
      latitude: 0,
      longitude: 0,
      description: ['', Validators.maxLength(255)],
      locationName: ['', Validators.maxLength(255)],
      locationId: ['', Validators.maxLength(255)],
      freight: ['', Validators.maxLength(255)],
      removedFreightAmount: 0,
      freightAmount: 0,
      orderId: ['', [Validators.maxLength(40)]],
      arriveBackVehicle: [this.arriveBackVehicle ? this.arriveBackVehicle : '', Validators.maxLength(255)],
      selectArriveBackVehicle: ['', Validators.maxLength(255)],
      arriveEquipment: [this.arriveEquipment ? this.arriveEquipment : '', Validators.maxLength(255)],
      selectArriveEquipment: ['', Validators.maxLength(255)],
      leaveBackVehicle: ['', Validators.maxLength(255)],
      selectLeaveBackVehicle: ['', Validators.maxLength(255)],
      leaveEquipment: ['', Validators.maxLength(255)],
      selectLeaveEquipment: ['', Validators.maxLength(255)],
      type: ['', Validators.maxLength(255)],
      freightTypeId: { value: 0, disabled: true },
      isBillingAddress: { value: false, disabled: this.readOnly }
    })

    if (this.createMode) {
      this.formGroup = this.formBuilder.group({
        id: undefined,
        country: ['', this.addressIsRequired ? Validators.compose([Validators.required, Validators.maxLength(255)]) : Validators.maxLength(255)],
        state: ['', Validators.maxLength(255)],
        zipcode: ['', this.addressIsRequired ? Validators.compose([Validators.required, Validators.maxLength(10)]) : Validators.maxLength(10)],
        city: ['', this.addressIsRequired ? Validators.compose([Validators.required, Validators.maxLength(255)]) : Validators.maxLength(255)],
        street: ['', this.addressIsRequired ? Validators.compose([Validators.required, Validators.maxLength(255)]) : Validators.maxLength(255)],
        latitude: 0,
        longitude: 0,
        description: ['', Validators.maxLength(255)],
        locationName: ['', Validators.maxLength(255)],
        locationId: ['', Validators.maxLength(255)],
        freight: ['', Validators.maxLength(255)],
        removedFreightAmount: 0,
        freightAmount: 0,
        orderId: ['', [Validators.maxLength(40)]],
        arriveBackVehicle: [this.arriveBackVehicle ? this.arriveBackVehicle : '', Validators.maxLength(255)],
        selectArriveBackVehicle: ['', Validators.maxLength(255)],
        arriveEquipment: [this.arriveEquipment ? this.arriveEquipment : '', Validators.maxLength(255)],
        selectArriveEquipment: ['', Validators.maxLength(255)],
        leaveBackVehicle: ['', Validators.maxLength(255)],
        selectLeaveBackVehicle: ['', Validators.maxLength(255)],
        leaveEquipment: ['', Validators.maxLength(255)],
        selectLeaveEquipment: ['', Validators.maxLength(255)],
        type: ['', Validators.maxLength(255)],
        freightTypeId: { value: 0, disabled: true },
        isBillingAddress: false
      })

      this.readOnly = false;
      this.formGroup.valueChanges.subscribe(() => {
        if ((this.createMode === this.createModes.create_as_user || this.createMode === this.createModes.create_as_parent)
          && this.formGroup.dirty) {
          this.createAddress.emit(this.formGroup.value as AddressModel);
        }
      });
    }

    await this.getLanguage()
    this.patchFormValues();
  }

  emitStreetview() {
    if (this.formGroup.value.latitude != undefined && this.formGroup.value.longitude != undefined) {
      let  outputModel: TomTomLatLng = {lat: this.formGroup.value.latitude, lng: this.formGroup.value.longitude};
      this.showStreetview.emit(outputModel);
    }
  }
  // should update the associated input field as well as the leaveEquipment one
  selectedArriveEquipment() {
    this.arriveEquipment = this.selectArriveEquipment

    this.leaveEquipment = this.selectArriveEquipment
    this.selectLeaveEquipment = this.selectArriveEquipment
  }

  // should update the associated input field but not the arriveBackVehicle one since it's assumed that has been set and done
  selectedLeaveEquipment() {
    this.leaveEquipment = this.selectLeaveEquipment
  }

   // if the user manually updates the input field, clear the dropdowns and mirror the value to the other input
   manualArriveBackVehicleInput() {
     this.leaveBackVehicle = this.arriveBackVehicle

    this.formGroup.patchValue({
      arriveBackVehicle: this.arriveBackVehicle,
      leaveBackVehicle: this.arriveBackVehicle,
      selectLeaveBackVehicle: this.selectLeaveBackVehicle,
      selectArriveBackVehicle: this.selectArriveBackVehicle
    })
  }

  // if the user manually updates the input field, clear the dropdowns and DON'T mirror the value to the other input, since it's assumed that has been set and done
  manualLeaveBackVehicleInput() {
    this.leaveBackVehicle = this.leaveBackVehicle

    this.formGroup.patchValue({
      leaveBackVehicle: this.leaveBackVehicle,
      selectLeaveBackVehicle: this.selectLeaveBackVehicle
    })
  }

  // if the user manually updates the input field, clear the dropdowns and mirror the value to the other input
  manualArriveEquipmentInput() {
    this.leaveEquipment = this.arriveEquipment

    // clear dropdowns
    this.selectArriveEquipment = ""
    this.selectLeaveEquipment = ""
  }

  // if the user manually updates the input field, clear the dropdowns and DON'T mirror the value to the other input, since it's assumed that has been set and done
  manualLeaveEquipmentInput() {
    this.leaveEquipment = this.leaveEquipment
  }

  /**
   * When the user SELECTS a value from the multiselect dropdown for ARRIVE BACKVEHICLES
   * @param item
   */
  onItemSelectArriveBackVehicle(item: any) {
    if (this.arriveBackVehicles == null) {
      this.arriveBackVehicles = [item]
    } else {
      this.arriveBackVehicles.push(item)
    }

    let localIdArray = this.arriveBackVehicles.map((item: any) => item.item_id)
    let localTextArray = this.arriveBackVehicles.map((item: any) => item.item_text)

    this.arriveBackVehicle = localTextArray.join(',')
    this.selectArriveBackVehicle = localIdArray.join(',')

    this.formGroup.patchValue({
      arriveBackVehicle: localIdArray.join(','),
      leaveBackVehicle: localTextArray.join(','),
      selectArriveBackVehicle: localTextArray.join(','),
      selectLeaveBackVehicle: localTextArray.join(',')
    })
  }

  /**
   * When the user DESELECTS a value from the multiselect dropdown for ARRIVE BACKVEHICLES
   * @param item
   */
  onItemDeSelectArriveBackVehicle(item: any) {
      if (this.arriveBackVehicles != null) this.arriveBackVehicles.splice(this.arriveBackVehicles.findIndex((x: any) => x.item_id === item.item_id), 1)

      let localIdArray = this.arriveBackVehicles.map((item: any) => item.item_id)
      let localTextArray = this.arriveBackVehicles.map((item: any) => item.item_text)

      this.arriveBackVehicle = localTextArray.join(',')

      this.formGroup.patchValue({
        arriveBackVehicle: localIdArray.join(','),
        leaveBackVehicle: localTextArray.join(','),
        selectArriveBackVehicle: localTextArray.join(','),
        selectLeaveBackVehicle: localTextArray.join(',')
      })
  }

  /**
   * When the user selects a value from the multiselect dropdown for LEAVE BACKVEHICLES
   * @param item
  */
  onItemSelectLeaveBackVehicle(item: any) {
      if (this.leaveBackVehicles == null) {
        this.leaveBackVehicles = [item]
      } else {
        this.leaveBackVehicles.push(item)
      }

      let localIdArray = this.leaveBackVehicles.map((item: any) => item.item_id)
      let localTextArray = this.leaveBackVehicles.map((item: any) => item.item_text)

      this.leaveBackVehicle = localTextArray.join(',')
      this.formGroup.patchValue({
        leaveBackVehicle: localIdArray.join(','),
        selectLeaveBackVehicle: localTextArray.join(',')
      })
  }

  /**
   * When the user deselects a value from the multiselect dropdown for LEAVE BACKVEHICLES
   * @param item
   */
  onItemDeSelectLeaveBackVehicle(item: any) {
      if (this.leaveBackVehicles != null) this.leaveBackVehicles.splice(this.leaveBackVehicles.findIndex((x: any) => x.item_id === item.item_id), 1)

      let localIdArray = this.leaveBackVehicles.map((item: any) => item.item_id)
      let localTextArray = this.leaveBackVehicles.map((item: any) => item.item_text)

      this.leaveBackVehicle = localTextArray.join(',')
      this.formGroup.patchValue({
        leaveBackVehicle: localIdArray.join(','),
        selectLeaveBackVehicle: localTextArray.join(',')
      })
  }





  /**
   * When the user toggles between multiselect and free-form writing
   */
  switchArriveField(): void {
    this.showArriveMultiSelect = !this.showArriveMultiSelect
  }

  /**
   * When the user toggles between multiselect and free-form writing
   */
  switchLeaveField(): void {
    this.showLeaveMultiSelect = !this.showLeaveMultiSelect
  }

  private patchFormValues(): void {
    let model = this.address

    let arriveBVInputString = '';
    let leaveBVInputString = '';
    let leaveEquipmentString = ''
    let arriveEquipmentString = ''

    if(model != undefined) {
      if(model.arriveBackVehicles != undefined && model.arriveBackVehicles.length > 0) {
        let arriveBV = model.arriveBackVehicles.map((backVehicle) => backVehicle.unitIdentification + ' / ' + backVehicle.registrationNumber);
        arriveBVInputString = arriveBV.join(', ');
      }
      else {
        arriveBVInputString = model.arriveBackVehicle;
      }

      if(model.leaveBackVehicles != undefined && model.leaveBackVehicles.length > 0) {
        let leaveBV = model.leaveBackVehicles.map((backVehicle) => backVehicle.unitIdentification + ' / ' + backVehicle.registrationNumber);
        leaveBVInputString = leaveBV.join(', ');
      }
      else {
        leaveBVInputString = model.leaveBackVehicle;
      }
    }

    if (this.arriveBackVehicle != undefined) arriveBVInputString = this.arriveBackVehicle
    if (this.leaveBackVehicle != undefined) leaveBVInputString = this.leaveBackVehicle
    if (this.arriveEquipment != undefined) arriveEquipmentString = this.arriveEquipment
    if (this.leaveEquipment != undefined) leaveEquipmentString = this.leaveEquipment

    /* if (this.arriveBackVehicle != undefined && model != undefined) model.arriveBackVehicle = this.arriveBackVehicle;
    if (this.arriveEquipment != undefined && model != undefined) model.arriveEquipment = this.arriveEquipment; */

    /* let currArrBackV = model?.arriveBackVehicle;
    let currArrEqu = model?.arriveEquipment; */

    /* if (currArrBackV == undefined && this.arriveBackVehicle != undefined) currArrBackV = this.arriveBackVehicle;
    if (currArrEqu == undefined && this.arriveEquipment != undefined) currArrEqu = this.arriveEquipment; */

    this.formGroup.patchValue({
      id: model?.id,
      country: model?.country,
      state: model?.state,
      zipcode: model?.zipcode,
      city: model?.city,
      street: model?.street,
      latitude: model?.latitude,
      longitude: model?.longitude,
      description: model?.description,
      locationName: model?.locationName,
      locationId: model?.locationId,
      orderId: model?.orderId,
      arriveBackVehicle: arriveBVInputString,
      arriveEquipment: arriveEquipmentString,
      leaveBackVehicle: leaveBVInputString,
      leaveEquipment: leaveEquipmentString,
      freight: model?.freight,
      removedFreightAmount: model?.removedFreightAmount,
      freightAmount: model?.freightAmount,
      type: model?.type,
      freightTypeId: this.freightTypeId > 0 ? this.freightTypeId : model?.freightTypeId,
      isBillingAddress: model?.isBillingAddress
    });
    this.selectedFreightType = model?.freightTypeId?.toString() ?? '1';

    if (model?.notes == undefined && model != undefined) model.notes = [];
    if (model?.attachments == undefined && model != undefined) model.attachments = [];
  }

  create(): void {
    this.formGroup.controls['freightTypeId'].enable();
    this.formGroup.get('isBillingAddress')?.enable();
    this.createAddress.emit(this.formGroup.value as AddressModel);
    this.formGroup.reset();
  }

  update(): void {
    this.formGroup.controls['freightTypeId'].enable();
    this.formGroup.get('isBillingAddress')?.enable();
    if (this.formGroup.valueChanges) {
      this.editAddress.emit(this.formGroup.value as AddressModel);
    };
    this.readOnly = true;
  }

  cancelUpdate(): void {
    this.readOnly = true;
    this.patchFormValues();
  }

  geocode(): void {
    this.isGeocoding = true;
    const values = this.formGroup.value;
    this.geocoder.geocodeAddress(values.street, values.zipcode, values.city, values.state, values.country).subscribe(response => {
      this.formGroup.patchValue({ longitude: response.longitude, latitude: response.latitude });
      this.isGeocoding = false;
    }, error => this.isGeocoding = false);
  }

  reverseGeocode(): void {
    this.isReverseGeocoding = true;
    const values = this.formGroup.value;
    this.geocoder.reverseGeocodeAddress(values.latitude, values.longitude).subscribe(response => {
      this.formGroup.patchValue({
        country: response.country,
        state: response.state,
        zipcode: response.zipcode,
        city: response.city,
        street: response.street,
      });
      this.isReverseGeocoding = false;
    }, error => this.isReverseGeocoding = false);
  }

  getValue(value: string) {
    this.http.get(
      'https://api.tomtom.com/search/2/search/' +
      value + '.json?' +
      'lat=' + this.currentLat + '&' +
      'lon=' + this.currentLon + '&' +
      'minFuzzyLevel=1&' +
      'maxFuzzyLevel=2&' +
      'view=Unified&' +
      'relatedPois=off&' +
      'key=' + environment.tomtom.key)
    .subscribe((data: any) => (this.searchResults = data['results']));
  }

  generateAdressTitle(result: any) {
    var returnString = "";
    if (result.poi !== undefined) {
      if (result.poi.name !== undefined) returnString += result.poi.name + " ";
    }

    if (result.address !== undefined) {
      if (result.address.streetName !== undefined) returnString += result.address.streetName + " ";
      if (result.address.streetNumber !== undefined) returnString += result.address.streetNumber + " ";
      if (result.address.municipality !== undefined) returnString += result.address.municipality + " ";
      if (result.address.country !== undefined) returnString += ", " + result.address.country + " ";
    }

    return returnString;
  }

  fillAddressForm(result: any, address: AbstractControl) {
    if (result.address !== undefined) {
      if (result.address.streetName !== undefined) address.patchValue({ street: result.address.streetName });
      if (result.address.streetName !== undefined && result.address.streetNumber !== undefined) address.patchValue({ street: result.address.streetName + " " + result.address.streetNumber });
      if (result.address.municipality !== undefined) address.patchValue({ city: result.address.municipality });
      if (result.address.country !== undefined) address.patchValue({ country: result.address.country });
      if (result.address.postalCode !== undefined) address.patchValue({ zipcode: result.address.postalCode });
      if (result.address.countrySubdivision !== undefined) address.patchValue({ state: result.address.countrySubdivision });
    }

    if (result.position !== undefined) {
      address.patchValue({ longitude: result.position.lon, latitude: result.position.lat });
    }
    this.searchResults = null;
    this.editAddress.emit(address.value as AddressModel);
  }

  selectedFreightType: any;

  //event handler for the select element's change event
  freightTypeIdSelectChangeHandler(event: any) {
    //update the ui
    this.selectedFreightType = event.target.value;
  }
}
