import { Injectable } from '@angular/core';
import {environment} from "../../../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {
  BoxServiceCompanyModel,
  BoxServiceVehicleModel,
  BoxServiceResultModel,
  BoxServiceCreateBoxRequestModel,
  BoxServiceCreateEyeRequestModel
} from "./boxservice.model";
import {NoteModel} from "../../../shared/note-widget/shared/note.model";
import { AttachmentCreateModel } from 'src/app/shared/attachment-widget/shared/attachment.model';
import { DataExchangeModel } from '../../../shared/fileuploader/shared/data-exchange.model';

const url = environment.baseUrl + '/V1/BoxService'

@Injectable({
  providedIn: 'root'
})
export class BoxService {

  constructor(private http: HttpClient) { }

  getAllCompanies(installmentCode: number): Observable<BoxServiceCompanyModel[]> {
    return this.http.get<BoxServiceCompanyModel[]>(url + '/Companies/'+installmentCode)
  }

  getAllFrontVehicles(installmentCode: number): Observable<BoxServiceResultModel> {
    return this.http.post<BoxServiceResultModel>(url + '/FrontVehicles/'+installmentCode, "");
  }

   createBoxOnVehicle(request: BoxServiceCreateBoxRequestModel): Observable<boolean> {
    return this.http.post<boolean>(url + '/FrontVehicle/Createbox', request);
  }

  createBoxOnBackVehicle(request: BoxServiceCreateBoxRequestModel): Observable<boolean> {
    return this.http.post<boolean>(url + '/BackVehicle/Createbox', request);
  }
  createEyeOnVehicle(request: BoxServiceCreateEyeRequestModel): Observable<boolean> {
    return this.http.post<boolean>(url + '/FrontVehicle/CreateEye', request);
  }

  createEyeOnBackVehicle(request: BoxServiceCreateEyeRequestModel): Observable<boolean> {
    return this.http.post<boolean>(url + '/BackVehicle/CreateEye', request);
  }
 
}
