import { filter } from 'rxjs/operators';
import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core'
import { FilterButtonComponent } from '../filter-button/filter-button.component'
import { faXmark, IconDefinition } from '@fortawesome/free-solid-svg-icons'
import { FormControl } from '@angular/forms';



interface Option {
  key: string;
  displayName: string;
  displaySmallTable: boolean;
}

interface ColumnConfig {
  key: string;
  displayName: string;
  displaySmallTable: boolean;
}



@Component({
  selector: 'app-search-input',
  templateUrl: './search-input.component.html',
  styleUrl: './search-input.component.scss'
})
export class SearchInputComponent implements OnInit {
  @Input() columnConfig: ColumnConfig[] = []
  @Input() filterKey: Option[] = []

  @Output() filterColumnChangeEvent = new EventEmitter<Option>()
  @Output() badgeClickEvent = new EventEmitter()
  @Output() filterChangeEvent = new EventEmitter<string>()

  faXmark: IconDefinition = faXmark
  filter = new FormControl('', { nonNullable: true })
  internalFilterKey: Option[] = []


  constructor() {

  }

  ngOnInit() {
    this.filter.valueChanges.subscribe((value) => {
      this.internalFilterKey = []
      this.internalFilterKey.push(...this.filterKey)
      this.filterChangeEvent.emit(value)
    })
  }


  updateFilterColumn(columnKey: Option) {
    this.internalFilterKey = []
    this.internalFilterKey.push(columnKey)

    this.filterColumnChangeEvent.emit(columnKey)
  }


  badgeClick() {
    this.internalFilterKey = []
    this.badgeClickEvent.emit()
  }

  /**
   * Translate the display name of the column
   * @param str - The string to translate, from the column config dataset
   * @returns { string } - The translated string
   */
  displayNameTranslation(str: string) {
    return `grouping.columns.${str}`
  }
}
