<div class="modal-header">
  <h5 class="modal-title">{{titleTranslationKey | translate}}</h5>
  <button type="button" class="btn-close" (click)="cancel.emit()"></button>
</div>
<div class="modal-body">
  <ng-content></ng-content>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-secondary" (click)="cancel.emit()">{{closeTranslationKey | translate}}</button>
  @if(formGroup) {
    <button type="button" class="btn btn-primary" [disabled]="formGroup.invalid" (click)="accept.emit()">{{acceptTranslationKey | translate}}</button>
  }
</div>
