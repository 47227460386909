import {Component, Input, OnInit, Output, EventEmitter} from '@angular/core';
import {EquipmentModel} from "./shared/equipment.model";
import {faPlus} from "@fortawesome/free-solid-svg-icons";

@Component({
  selector: 'app-equipment-widget',
  templateUrl: './equipment-widget.component.html',
  styleUrls: ['./equipment-widget.component.scss']
})
export class EquipmentWidgetComponent implements OnInit {

  @Input()
  equipments: EquipmentModel[] = [];

  @Input()
  createMode: boolean = false;

  @Output()
  create = new EventEmitter();

  faAdd = faPlus;

  equipmentInput: string = '';


  constructor() { }

  ngOnInit(): void {
  }

  deleteEquipment(equipment: string){
    this.equipments.forEach((element,index) => {
      if(element.value === equipment) this.equipments.splice(index,1);
    });
  }

  createEquipment(): void {
    if(this.equipmentInput === '') return;

    this.equipments.push({value: this.equipmentInput});
    this.equipmentInput = '';
    this.create.emit(this.equipments);
  }

}
