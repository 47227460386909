import { Injectable } from '@angular/core';
import { BackgroundStatusColors, CreateMode, ElementColors, FrontVehicleType, StatusColors, StatusType } from "../../constants";
import { HttpClient } from "@angular/common/http";
import { Observable, of } from "rxjs";
import { UntypedFormControl } from '@angular/forms';
import { TokenService } from 'src/app/services/token.service';
import { environment } from 'src/environments/environment';


export interface ResourceTypes {
  id: number,
  resourceTypeName: string
  resource?: string
}

@Injectable({
  providedIn: 'root'
})
export class SharedService {
  constructor(private http: HttpClient, private tokenService: TokenService) { }


  isSuperAdmin(): boolean {
    return this.tokenService.isSuperAdmin();
  }


  getTypeColors(value: number): string {
    switch (value) {
      case 1: return StatusColors.completed; // task
      case 2: return ElementColors.lightBorder; // night rest
      case 3: return StatusColors.intern; // intern
      case 4: return ElementColors.lightBorder; // rest 15
      case 5: return ElementColors.lightBorder; // rest 30
      case 6: return ElementColors.lightBorder; // rest 45
      case 7: return ElementColors.lightBorder; // day rest
      case 8: return ElementColors.lightBorder; // week rest
      case 9: return ElementColors.lightBorder; // service
      case 10: return StatusColors.wash; // wash no task
      case 11: return StatusColors.wash; // wash task
      case 12: return StatusColors.breakdown; // breakdown
      case 13: return StatusColors.breakdown; // garage
      case 14: return ElementColors.lightBorder; // break private
      case 15: return ElementColors.lightBorder; // tachorest
      case 16: return StatusColors.unknown; // waiting
      case 17: return ElementColors.lightBorder; // zero
      default: return StatusColors.intern;
    }
  }


  getTypeNameById(value: number): string {
    switch (value) {
      case 1: return "Task";// task
      case 2: return "Rest";// night rest
      case 3: return "Intern"; // intern
      case 4: return "Rest"; // rest 15
      case 5: return "Rest"; // rest 30
      case 6: return "Rest"; // rest 45
      case 7: return "Rest"; // day rest
      case 8: return "Rest"; // week rest
      case 9: return "PersonalService"; // service
      case 10: return "Wash"; // wash no task
      case 11: return "Wash"; // wash task
      case 12: return "Broken"; // breakdown
      case 13: return "Garage"; // garage
      case 14: return "PrivateRest"; // break private
      case 15: return "Rest"; // tachorest
      case 16: return "Wait"; // waiting
      case 17: return "Zero"; // zero
      default: return "Unknown";
    }
  }


  getStatusColor(value: string): string {
    switch (value) {
      case StatusType[StatusType.Completed]: return StatusColors.completed;
      case StatusType[StatusType.InProgress]: return StatusColors.inProgress;
      case StatusType[StatusType.Scheduled]: return StatusColors.scheduled;
      case StatusType[StatusType.Scheduled24Hours]: return StatusColors.scheduled24Hours;
      case StatusType[StatusType.Overdue]: return StatusColors.overdue;
      case StatusType[StatusType.Available]: return StatusColors.available;
      case StatusType[StatusType.Booked]: return StatusColors.booked;
      case StatusType[StatusType.Taken]: return StatusColors.taken;
      case StatusType[StatusType.Unknown]: return StatusColors.unknown;
      case StatusType[StatusType.Paused]: return StatusColors.paused;
      case StatusType[StatusType.BusinessRelated]: return StatusColors.businessRelated;
      case StatusType[StatusType.Holidays]: return StatusColors.holidays;
      case StatusType[StatusType.Personal]: return StatusColors.personal;
      case StatusType[StatusType.Broken]: return StatusColors.broken;
      case StatusType[StatusType.Wash]: return StatusColors.wash;
      case StatusType[StatusType.PersonalService]: return StatusColors.personalService;
      case StatusType[StatusType.Alarm]: return StatusColors.alarm;
      case StatusType[StatusType.Sick]: return StatusColors.sick;
      case StatusType[StatusType.Off]: return StatusColors.off;
      case StatusType[StatusType.OffPaid]: return StatusColors.offPaid;
      case StatusType[StatusType.Vacation]: return StatusColors.vacation;
      case StatusType[StatusType.Education]: return StatusColors.education;
      case StatusType[StatusType.OnHold]: return StatusColors.onHold;
      case StatusType[StatusType.Rest]: return StatusColors.rest;
      case StatusType[StatusType.Task]: return StatusColors.taken;
      case StatusType[StatusType.Wait]: return StatusColors.wait;
      case StatusType[StatusType.Intern]: return StatusColors.taken;
      case StatusType[StatusType.Zero]: return StatusColors.zero;
      case StatusType[StatusType.WrongVehicle]: return StatusColors.wrongVehicle;
      default: return StatusColors.intern;
    }
  }


  getBackgroundStatusColor(value: string): string {
    switch (value) {
      case StatusType[StatusType.Completed]: return BackgroundStatusColors.completed;
      case StatusType[StatusType.InProgress]: return BackgroundStatusColors.inProgress;
      case StatusType[StatusType.Scheduled]: return BackgroundStatusColors.scheduled;
      case StatusType[StatusType.Scheduled24Hours]: return BackgroundStatusColors.scheduled24Hours;
      case StatusType[StatusType.Overdue]: return BackgroundStatusColors.overdue;
      case StatusType[StatusType.Available]: return BackgroundStatusColors.available;
      case StatusType[StatusType.Booked]: return BackgroundStatusColors.booked;
      case StatusType[StatusType.Taken]: return BackgroundStatusColors.taken;
      case StatusType[StatusType.Unknown]: return BackgroundStatusColors.unknown;
      case StatusType[StatusType.Paused]: return BackgroundStatusColors.paused;
      case StatusType[StatusType.BusinessRelated]: return BackgroundStatusColors.businessRelated;
      case StatusType[StatusType.Holidays]: return BackgroundStatusColors.holidays;
      case StatusType[StatusType.Personal]: return BackgroundStatusColors.personal;
      case StatusType[StatusType.Broken]: return BackgroundStatusColors.broken;
      case StatusType[StatusType.Wash]: return BackgroundStatusColors.wash;
      case StatusType[StatusType.PersonalService]: return BackgroundStatusColors.personalService;
      case StatusType[StatusType.Alarm]: return BackgroundStatusColors.alarm;
      case StatusType[StatusType.Sick]: return BackgroundStatusColors.sick;
      case StatusType[StatusType.Off]: return BackgroundStatusColors.off;
      case StatusType[StatusType.OffPaid]: return BackgroundStatusColors.offPaid;
      case StatusType[StatusType.Vacation]: return BackgroundStatusColors.vacation;
      case StatusType[StatusType.Education]: return BackgroundStatusColors.education;
      case StatusType[StatusType.OnHold]: return BackgroundStatusColors.onHold;
      case StatusType[StatusType.Rest]: return BackgroundStatusColors.paused;
      case StatusType[StatusType.Task]: return BackgroundStatusColors.completed;
      case StatusType[StatusType.Wait]: return BackgroundStatusColors.paused;
      case StatusType[StatusType.Intern]: return BackgroundStatusColors.inProgress;
      default: return BackgroundStatusColors.defaultGreen;
    }
  }


  getLanguageJson(): Observable<any> {
    var lang = this.tokenService.getUserLanguage();

    switch (lang) {
      case 'da-DK':
        return this.http.get(environment.simpleLocalize.danish)
      case 'en-GB':
        return this.http.get(environment.simpleLocalize.english);
      case 'de':
        return this.http.get(environment.simpleLocalize.german);
      case 'cs':
        return this.http.get(environment.simpleLocalize.czech);
      default:
        return this.http.get(environment.simpleLocalize.danish);
    }
  }


  public get statusTypes(): typeof StatusType {
    return StatusType;
  }


  getTranslateType(type: string): string {
    return `type.${type.toLowerCase()}`
  }


  getResourceTypes() {
    return of([{
      resourceTypeId: 0,
      typeName: 'Internal'
    },{
      resourceTypeId: 1,
      typeName: 'Pull'
    }, {
      resourceTypeId: 2,
      typeName: 'Load'
    }])

    return this.http.get<ResourceTypes[]>(`${environment.baseUrl}/V1/ResourceTypes`);
  }


  public noWhitespaceValidator(control: UntypedFormControl) {
    const isSpace = (control.value || '').match(/\s/g);
    return isSpace ? { 'whitespace': true } : null;
  }
}
