<div class="bg-light" *ngIf="createMode" [style.padding-bottom]="includeAccept ? '0' : '15px'" >
  <form [formGroup]="formGroup" class="col-md-12" style="margin-bottom: 10px; padding: 10px 30px 10px 30px">
    <div class="row">
      <div class="col-md-12">
        <label>{{'attachment.showInApp' | translate}}</label>
        &nbsp;
        <input type="checkbox" id="showInApp" formControlName="showInApp" />
      </div>
      <div class="col-md-12">
    <label>{{'attachment.create-title' | translate}}</label>
    <div class="custom-file">
      <input
        type="file"
        class="custom-file-input"
        id="customFile"
        accept="application/pdf,image/png,image/jpg"
        (change)="onFileChange($event.target)">
      <label *ngIf="formGroup.valid" class="custom-file-label" for="customFile">{{formGroup.value.name}}</label>
      <label *ngIf="formGroup.invalid" class="custom-file-label" for="customFile">{{'attachment.choose-file' | translate}}</label>
    </div>
  </div>
    </div>
  </form>
  <p class="none-text" style="margin-left: 15px; margin-bottom: 0!important;">{{'attachment.accept-message' | translate}}</p>
  <div class="btn-container">
    <app-btn-accept-widget *ngIf="includeAccept" (cancel)="formGroup.patchValue({name: 'Choose file', type: ''}); cancelCreate.emit()" [formGroup]="formGroup" (accept)="createAttachment()"></app-btn-accept-widget>
  </div>
</div>

<div class="col-md-12 text-black clickable btn-secondary" *ngIf="attachment" (click)="checkClick($event)" style="border-radius: 5px">
  <div class="row">

    <div class="col-md-10" style="white-space: pre-line; padding: 5px 0 0 15px!important;">
      <fa-icon [icon]="attachment.showInApp ? faMobileAlt : faDesktop"></fa-icon> {{attachment.fileName}}
    </div>
    <div class="col-md-2 text-end" style="margin-top: 10px;">
      <button *ngIf="!logBook" id="editButton" type="button" class="btn btn-primary btn-sm"
              placement="left"
              ngbTooltip="{{'btn.edit' | translate}}">
          <fa-icon [icon]="faEdit"></fa-icon>
      </button>
    </div>
    <div class="col-md-10" style="margin-top: 10px; font-size: 12px; font-style: italic; opacity: 0.8">
      {{'attachment.uploaded' | translate}} {{attachment.timestamp | date: 'yyyy-MM-dd HH:mm:ss'}} {{'attachment.by' | translate}} {{attachment.createdBy?.firstname}} {{attachment.createdBy?.lastname}}
    </div>

      <div *ngIf="!readOnly" class="col-md-2 tekst-right justify-content-center" >
        <button
        id="deleteButton"
        class="close delete"
        placement="left"
        style="margin-bottom: 5px; margin-right: 7px;"
        ngbTooltip="{{'btn.deleteAttachment' | translate}}"
        (click)="this.delete.emit(attachment.id)">
        <fa-icon [icon]="faTrash" style="color: red;"></fa-icon>
      </button>
      </div>

  </div>
</div>
