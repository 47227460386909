import { Injectable } from '@angular/core'
import { environment } from "../../../environments/environment"
import { HttpClient } from "@angular/common/http"
import { Observable } from "rxjs"
import { ResourceTypes } from './shared.service'

const url = environment.baseUrl + '/V1/ResourceType'

@Injectable({
  providedIn: 'root'
})

export class ResourceTypeService {
  constructor(private http: HttpClient) { }


  getAll(resourceType: string): Observable<ResourceTypes[]>{
    return this.http.get<ResourceTypes[]>(`${url}/${resourceType}`);
  }
}
