<div class="page-content mt-2 pb-2 ps-1">
  <ejs-sidebar #sidebar>
    <ejs-treeview #tree [fields]="treeFields" (nodeSelected)="loadRoutingContent($event)" />
  </ejs-sidebar>

  <div class="main-content ps-2 me-1">
    <ejs-toolbar class="mb-2" (clicked)="toolbarClicked()">
      <e-items>
        <e-item prefixIcon="e-tbar-menu-icon" tooltipText="{{'menu' | translate}}" />
        <e-item text="{{'indexAdjustment' | translate}}" />
      </e-items>
    </ejs-toolbar>

    <ng-template #createIndex>
      <app-base-modal
        (accept)="createCompanyIndex()"
        (cancel)="closeModal()"
        [titleTranslationKey]="'freightTypes.create'"
        [closeTranslationKey]="'freightTypes.closeModal'"
        [acceptTranslationKey]="'freightTypes.create'"
        [formGroup]="indexCreationFormGroup"
      >
        <label for="indexName">
          {{'companyIndexName' | translate}}
          <span *ngIf="indexCreationFormGroup.controls['indexName'].hasError('required')" class="required-text">
            {{'message.required' | translate}}
          </span>
        </label>
        <input formControlName="indexName" class="form-control form-control-sm mb-2" type="text" id="indexName" name="indexName" />
      </app-base-modal>
    </ng-template>

    <button (click)="openCreateIndexModal(createIndex)" class="btn btn-success text-white mb-2">{{'createCompanyIndex' | translate}}</button>

    <ejs-accordion expandMode="Single" (expanded)="accordionExpand()">
      <e-accordionitems>
        @for(index of companyIndexes; track $index) {
          <e-accordionitem>
            <ng-template #header>
              <button class="py-1 px-1">
                <p class="mb-0 p-1 d-inline-block fw-bold">{{index.name}}</p>
                <div class="plus-btn-right me-5">
                  <button (click)="deleteCompanyIndex($event, index.id)" class="btn btn-danger text-white">{{'deleteCompanyIndex' | translate}}</button>
                </div>
              </button>
            </ng-template>
            <ng-template #content>
              <hr class="mx-2 mt-0 mt-1" />
              <ejs-grid
                (actionComplete)="onActionComplete($event, index.id)"
                [dataSource]="index.adjustments"
                [toolbar]="indexAdjustmentToolbar"
                [allowSorting]="true"
                [editSettings]="indexAdjustmentEditSettings"
                [allowPaging]="true"
                [pageSettings]="indexAdjustmentPageSettings"
                [loadingIndicator]="loadingIndicator"
                gridLines="Both"
                class="m-2"
              >
                <e-columns>
                  <e-column field="id" [visible]="false" [isPrimaryKey]="true" [allowEditing]="false" />
                  <e-column field="validFrom" headerText="{{'validFrom' | translate}}" type="date" editType="datepickeredit" [edit]="dateParams" format="dd-MM-yyyy" [validationRules]="validFromRules" />
                  <e-column field="validTo" headerText="{{'validUntil' | translate}}" type="date" format="dd-MM-yyyy" [allowEditing]="false" />
                  <e-column field="percentage" headerText="{{'indexPercentage' | translate}}" editType="numericedit" [edit]="numericParams" />
                  <e-column field="index" headerText="{{'indexNumber' | translate}}" editType="numericedit" [edit]="numericParams" />
                  <e-column field="comment" headerText="{{'timeStatement.comment' | translate}}" editType="stringedit" [edit]="stringParams" [validationRules]="requiredRules" />
                </e-columns>
              </ejs-grid>
            </ng-template>
          </e-accordionitem>
        }
      </e-accordionitems>
    </ejs-accordion>
  </div>
</div>
