import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-switch',
  templateUrl: './switch.component.html',
  styleUrl: './switch.component.scss'
})
export class SwitchComponent {
  @Input() disabled: boolean = false
  @Input() isChecked: boolean = false
  @Input() label: string = ''
  @Input() fullColor: boolean = false

  @Output() switchEvent = new EventEmitter()


  constructor() { }

  toggleSwitch() {
    this.switchEvent.emit(this.isChecked)
  }
}


