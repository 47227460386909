<div *ngIf="job">
  <div class="col-md-12 text-end" [ngClass]="{'bg-light' :!readOnly}" style="padding: 10px 15px 0 0">
    <div class="row">
      <div class="col-md-11" style="padding-left: 30px">
        <div style="float: left" class="chip unselectable">
          <span class="chip-text">{{ this.translateType() | translate}}</span>
        </div>
      </div>
      <div class="col-md-1 text-end pb-1">
        <button type="button" class="btn btn-primary btn-sm" (click)="readOnly = ! readOnly" placement="left" ngbTooltip="{{'btn.edit' | translate}}">
          <fa-icon [icon]="faEdit"></fa-icon>
        </button>
      </div>
    </div>
  </div>

  <form [formGroup]="formGroup" class="col-md-12 transition-widget" style="padding-bottom: 10px" [ngClass]="{'bg-light' :!readOnly}">
    <div class="form-group row" style="padding: 0 15px 15px 0">
      <!-- <div class="col-md-4 pe-0">
        <label for="expectedStartDate">{{'job.startDate' | translate}}</label>
        <input id="expectedStartDate" type="date" formControlName="expectedStartDate"
          class="form-control form-control-sm" [readOnly]="readOnly">
      </div>
      <div class="col-md-2 pe-0">
        <label for="expectedStartTime">{{'job.startTime' | translate}}</label>
        <input id="expectedStartTime" type="time" formControlName="expectedStartTime"
          class="form-control form-control-sm" [readOnly]="readOnly">
      </div>
      <div class="col-md-4 pe-0">
        <label for="expectedEndDate">{{'job.endDate' | translate}}
          <span *ngIf="formGroup.controls['expectedEndDate'].hasError('wrong-date')" class="required-text">
            {{'message.wrong-date' | translate}}
          </span></label>
        <input id="expectedEndDate" type="date" formControlName="expectedEndDate" class="form-control form-control-sm"
          [readOnly]="readOnly">
      </div>
      <div class="col-md-2 pe-0">
        <label for="expectedEndTime">{{'job.endTime' | translate}}
          <span *ngIf="formGroup.controls['expectedEndTime'].hasError('wrong-time')" class="required-text">
            {{'message.wrong-date' | translate}}
          </span></label>
        <input id="expectedEndTime" type="time" formControlName="expectedEndTime" class="form-control form-control-sm"
          [readOnly]="readOnly">
      </div> -->
      <div class="col-md-12 pe-0">
        <label for="description">{{'job.description' | translate}}
          <span *ngIf="formGroup.controls['description'].hasError('maxlength')" class="required-text">{{'message.maxLength' | translate: {number: 255} }}</span>
          <span *ngIf="formGroup.controls['description'].hasError('required')" class="required-text">{{'message.required' | translate}}</span>
        </label>
        <input id="description" type="text" formControlName="description" class="form-control form-control-sm" [readOnly]="readOnly">
      </div>
      <!-- <div class="col-md-12 pe-0">
        <label>{{'job.customer' | translate}} </label>
        <button *ngIf="!readOnly" class="btn btn-primary btn-sm" style="margin-left: 10px;"
          (click)="customerSelect = true">{{'job.create.user-select.btn' | translate}}</button>
        <input *ngIf="job.customer" class="form-control form-control-sm" [value]="job.customer?.name" disabled>
        <input *ngIf="!job.customer" class="form-control form-control-sm" disabled>
        <app-customer-select *ngIf="customerSelect" id="customer-select" (selected)="selectedCustomerCallback($event)">
        </app-customer-select>
      </div> -->
      <div class="col-md-12 pe-0">
        <label>{{'job.chauffeur' | translate}}</label>
        <input *ngIf="job.assignedUser" class="form-control form-control-sm" [value]="job.assignedUser?.firstname + ' ' + job.assignedUser?.lastname" disabled>
        <input *ngIf="!job.assignedUser" class="form-control form-control-sm" disabled>
      </div>
      <div class="col-md-12 pe-0">
        <label>{{'job.frontVehicle' | translate}}</label>
        <input *ngIf="job.frontVehicle" class="form-control form-control-sm" [value]="getVehicleDisplayText(job.frontVehicle)" disabled>
        <input *ngIf="!job.frontVehicle" class="form-control form-control-sm" disabled>
        <p>{{'job.bob' | translate}}</p>
      </div>
    </div>
</form>

<div *ngIf="!readOnly" class="bg-light">
  <app-btn-accept-widget (cancel)="readOnly = true; patchFormValues()" [formGroup]="formGroup" (accept)="update()"></app-btn-accept-widget>
</div>
  <div ngbAccordion #accordion="ngbAccordion" [closeOthers]="true">
	<div ngbAccordionItem>
		<h2 ngbAccordionHeader>
			<button ngbAccordionButton>{{'detail.subTitles.registrations' | translate}}</button>
		</h2>
		<div ngbAccordionCollapse>
			<div ngbAccordionBody>
				<ng-template>
      <form [formGroup]="formGroup" class="col-md-12" [ngClass]="{'bg-light' :!readOnly}">
        <div class="row" style="padding: 0 15px 15px 0">
          <div class="col-md-4">
            <label for="actualStartDate">{{'job.startDate' | translate}}</label>
            <input id="actualStartDate" type="date" formControlName="actualStartDate"
              class="form-control form-control-sm" [readOnly]="showEditStartDate()">
          </div>
          <div class="col-md-2">
            <label for="actualStartTime">{{'job.startTime' | translate}}</label>
            <input id="actualStartTime" type="time" formControlName="actualStartTime"
              class="form-control form-control-sm" [readOnly]="showEditStartDate()">
          </div>
          <div class="col-md-4">
            <label for="actualEndDate">{{'job.endDate' | translate}}</label>
            <input id="actualEndDate" type="date" formControlName="actualEndDate" class="form-control form-control-sm"
              [readOnly]="showEditEndDate()">
          </div>
          <div class="col-md-2">
            <label for="actualEndTime">{{'job.endTime' | translate}}</label>
            <input id="actualEndTime" type="time" formControlName="actualEndTime" class="form-control form-control-sm" [readOnly]="showEditEndDate()">
          </div>
          <div class="col-6">
            <label>{{'job.coordinatesStart' | translate}}</label>
            <span class="form-control form-control-sm" readonly>X: {{formGroup.value.startLongitude}}, Y:
              {{formGroup.value.startLatitude}}</span>
          </div>
          <div class="col-6">
            <label>{{'job.coordinatesEnd' | translate}}</label>
            <span class="form-control form-control-sm" readonly>X: {{formGroup.value.endLongitude}}, Y:
              {{formGroup.value.endLatitude}}</span>
          </div>
          <div class="col-md-12">
            <label for="distance">{{'job.distance' | translate}}</label>
            <input id="distance" formControlName="distance" class="form-control form-control-sm" [readOnly]="true">
          </div>
        </div>
      </form>
      <div class="col-md-12 text-center" *ngIf="job.actualStartDate && !job.actualEndDate && !readOnly && !editRegistration" [ngClass]="{'bg-light' :!readOnly}">
        <button class="btn btn-primary" (click)="cancel()" style="margin-bottom: 1vh;" placement="top" ngbTooltip="{{'job.cancelTour' | translate}}">
            <fa-icon [icon]="faStop"></fa-icon>
        </button>
      </div>
      <app-note-date-widget *ngIf="endJob" [createMode]="true" text="job.endJobText"
        (cancelCreate)="endJob = !endJob" (createNote)="cancelNoteDescription($event)"></app-note-date-widget>
				</ng-template>
			</div>
		</div>
	</div>
</div>
<!-- <div class="col-md-12 btn-container" *ngIf="!readOnly">
  <div class="row">
    <div class="col-md-12 text-center">
      <button class="btn btn-danger" (click)="delete()" placement="top" ngbTooltip="{{'btn.deleteJob' | translate}}">
        <fa-icon [icon]="faTrash"></fa-icon>
      </button>
    </div>
  </div>
</div> -->
</div>
