import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-google-street-view-modal',
  standalone: true,
  imports: [CommonModule, TranslateModule],
  templateUrl: './google-street-view-modal.component.html',
  styleUrl: './google-street-view-modal.component.css'
})
export class GoogleStreetViewModalComponent {


  
  @ViewChild('content', { static: true }) content: any;

  @Input() lat: number = 55.8;
  @Input() lng: number = 9.8;
  
  map:any;
   
  @Output() closed= new EventEmitter();

  constructor(
    private httpclient: HttpClient, private modalService: NgbModal
  ) {  }

  
  ngOnInit() {

    const modalRef = this.modalService.open(this.content, {backdrop: 'static', keyboard: false, size: 'lg'});
 
    modalRef.shown.subscribe(() => {
      this.initializeStreetviewMap();
    });
  
  }

  initializeStreetviewMap(): void {
   
    const fenway = { lat: this.lat, lng: this.lng };
    const map = new google.maps.Map(
      document.getElementById("map") as HTMLElement,
      {
        center: fenway,
        zoom: 14,
      }
    );
    const panorama = new google.maps.StreetViewPanorama(
      document.getElementById("pano") as HTMLElement,
      {
        position: fenway,
        pov: {
          heading: 34,
          pitch: 10,
        },
      }
    );
  
    map.setStreetView(panorama);
   
  }
 
   

  open(content: any): void {
    this.modalService.open(content, { size: 'lg' });
  }

  emit(): void {
    this.closed.emit(true)
  }

  ngOnDestroy(): void {
    if (this.map) {
      this.map.remove(); // Cleanup the map on component destroy
    }
  }

}
