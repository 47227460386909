import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { BillingType, CreateDeliveryRequirement, CreateGroup, CreateProduct, DeliveryDocumentRequirement, DeliveryRequirement, FreightUnit, Group, ItemNumber, Product, UpdateDeliveryRequirement, UpdateGroup, UpdateProduct } from "./freight-types.model";

const url = environment.baseUrl + '/V1/Products';
const idtfUrl = environment.baseUrl + '/V1/Idtf';

@Injectable({
  providedIn: 'root'
})

export class FreightTypeService {
  constructor(private http: HttpClient) { }

  getAllProducts(): Observable<Product[]> {
    return this.http.get<Product[]>(url);
  }

  getProduct(id: number): Observable<Product> {
    return this.http.get<Product>(`${url}/${id}`);
  }

  createProduct(model: CreateProduct): Observable<Product> {
    return this.http.put<Product>(url, model);
  }

  updateProduct(model: UpdateProduct): Observable<Product> {
    return this.http.put<Product>(url, model);
  }

  deleteProduct(id: number): Observable<Product> {
    return this.http.delete<Product>(`${url}/${id}`);
  }

  getAllDeliveryRequirements(): Observable<DeliveryRequirement[]> {
    return this.http.get<DeliveryRequirement[]>(`${url}/DeliveryRequirements`);
  }

  getDeliveryRequirement(id: number): Observable<DeliveryRequirement> {
    return this.http.get<DeliveryRequirement>(`${url}/DeliveryRequirements/${id}`);
  }

  createDeliveryRequirement(model: CreateDeliveryRequirement): Observable<DeliveryRequirement> {
    return this.http.put<DeliveryRequirement>(`${url}/DeliveryRequirements`, model);
  }

  updateDeliveryRequirement(model: UpdateDeliveryRequirement): Observable<DeliveryRequirement> {
    return this.http.put<DeliveryRequirement>(`${url}/DeliveryRequirements`, model);
  }

  deleteDeliveryRequirement(id: number): Observable<DeliveryRequirement> {
    return this.http.delete<DeliveryRequirement>(`${url}/DeliveryRequirements/${id}`);
  }

  getAllGroups(): Observable<Group[]> {
    return this.http.get<Group[]>(`${url}/Groups`);
  }

  getGroup(id: number): Observable<Group> {
    return this.http.get<Group>(`${url}/Groups/${id}`);
  }

  createGroup(model: CreateGroup): Observable<Group> {
    return this.http.put<Group>(`${url}/Groups`, model);
  }

  updateGroup(model: UpdateGroup): Observable<Group> {
    return this.http.put<Group>(`${url}/Groups`, model);
  }

  deleteGroup(id: number): Observable<Group> {
    return this.http.delete<Group>(`${url}/Groups/${id}`);
  }

  forceDeleteGroup(id: number): Observable<Group> {
    const options = { params: new HttpParams().set('force', true) };
    return this.http.delete<Group>(`${url}/Groups/${id}`, options);
  }

  getAllBillingTypes(): Observable<BillingType[]> {
    return this.http.get<BillingType[]>(`${url}/BillingTypes`);
  }

  getAllFreightTypes(): Observable<FreightUnit[]> {
    return this.http.get<FreightUnit[]>(`${url}/FreightTypes`);
  }

  getAllItemNumbers(): Observable<ItemNumber[]> {
    return this.http.get<ItemNumber[]>(`${idtfUrl}`);
  }

  getAllDeliveryDocumentRequirements(): Observable<DeliveryDocumentRequirement[]> {
    return this.http.get<DeliveryDocumentRequirement[]>(`${url}/DeliveryDocumentRequirement`);
  }

  getDeliveryDocumentRequirement(id: number): Observable<DeliveryDocumentRequirement> {
    return this.http.get<DeliveryDocumentRequirement>(`${url}/DeliveryDocumentRequirement/${id}`);
  }
}
