import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { TransportType } from "./transport-type.model";

const url = environment.baseUrl + '/V1/TransportTypes';

@Injectable({
  providedIn: 'root'
})

export class TransportTypeService {
  constructor(private http: HttpClient) { }

  getAllTransportTypes(): Observable<TransportType[]> {
    return this.http.get<TransportType[]>(`${url}/All`);
  }

  getTransportTypesByIds(idList: number[]): Observable<TransportType[]> {
    return this.http.post<TransportType[]>(`${url}/FromIds`, idList);
  }

  createTransportTypes(nameList: string[]): Observable<TransportType> {
    return this.http.post<TransportType>(`${url}/Create`, nameList);
  }

  updateTransportTypes(transportTypeList: TransportType[]): Observable<TransportType> {
    return this.http.put<TransportType>(`${url}/Update`, transportTypeList);
  }

  deleteTransportTypes(idList: number[]): Observable<TransportType> {
    const options = { body: idList };
    return this.http.delete<TransportType>(`${url}/Delete`, options);
  }
}
