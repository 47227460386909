import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { faCheck, faPencilAlt, faTimes, faTrash, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { DateValidator } from 'src/app/form-validators/date-validator';
import { TimeValidator } from 'src/app/form-validators/time-validator';
import { SharedService } from '../services/shared.service';
import { EyeModel } from 'src/app/eyes/shared/eye.model';

@Component({
  selector: 'app-eye-info-widget',
  templateUrl: './eye-info-widget.component.html'
})

export class EyeInfoWidgetComponent implements OnInit {

  @Input()
  eye: EyeModel | undefined;

  @Input()
  createMode = false;

  @Output()
  cancelCreate = new EventEmitter();

  @Output()
  createEvent = new EventEmitter();

  @Output()
  updateEvent = new EventEmitter();

  @Output()
  removeEvent = new EventEmitter();

  @Output()
  cancelEvent = new EventEmitter();

  formGroup: UntypedFormGroup;

  @Input()
  includeAccept = true;

  types: string[] = [];

  faEdit = faPencilAlt;
  faTrash = faTrashAlt;
  faAccept = faCheck;
  faCancel = faTimes;

  readOnlyEye = true;

  constructor(private formBuilder: UntypedFormBuilder, private sharedService: SharedService, private datePipe: DatePipe) {
    this.formGroup = this.formBuilder.group({
      MAC: ['', [Validators.required, Validators.maxLength(20), Validators.minLength(4), sharedService.noWhitespaceValidator]],
      DisplayName: ['', [Validators.maxLength(100)]],
      DeviceName: ['', [Validators.maxLength(100)]],
      SN: [''],
      id: ['']
    });
   }

  ngOnInit(): void {
    if (this.eye){
      this.formGroup.patchValue({
        SN: this.eye?.sn,
        MAC: this.eye?.mac,
        DeviceName: this.eye?.deviceName,
        DisplayName: this.eye?.displayName,
        id: this.eye?.id
      });
    }
  }

  getFormattedTime(time: number): string {
    return ('0' + time).slice(-2);
  }

  update(): void {
    this.readOnlyEye = !this.readOnlyEye;
  }

  cancel(): void {
    this.readOnlyEye = !this.readOnlyEye;
    this.cancelEvent.emit();
  }

  getValue(type: string): string {
    return `eyeIdentifier.event.${type.toLowerCase()}`;
  }

  remove(){
    this.removeEvent.emit();
  }
}
