export interface MainGroup {
  id?: number;
  name: string;
  description?: string;
  tags?: string[];
  companyGuid?: string;
  subGroups?: SubGroup[];
  createdById?: number;
  createdBy?: User | null; // or use appropriate type if known
  timestamp?: string;
  favourite?: boolean;
  edit?: boolean;
  justCreated?: boolean;
  mainGroupoId?: number;
}

export interface SubGroup {
  masterGroupId: number;
  resourceType: string;
  name: string;
  members?: any[];
  companyGuid?: string;
  id?: number;
}

export interface CardEvent {
  data: MainGroup;
  copy: MainGroup | undefined;
}


export interface User {
  role: string | null;
  permission: string | null;
  firstname: string;
  lastname: string;
  email: string;
  mobile: string;
  isActive: boolean;
  driversLicenseNumber: string | null;
  driversLicenseNumberExpires: Date | null;
  userMeta: any | null; // doesn't get sent, is any for now
  events: any | null; // doesn't get sent, list of users, is any for now
  timeRegistrations: any | null; // doesn't get sent, list of time registrations, is any for now
  devices: any | null; // doesn't get sent, list of devices, is any for now
  sumOfWorkingHours: number;
  tours: any | null; // doesn't get sent, list of tours, is any for now
  jobs: any | null; // doesn't get sent, list of jobs, is any for now
  status: string | null;
  sumOfTimeRegistrations: number;
  restHours: number;
  subtractInlandBreaks: boolean | null;
  subtractExportBreaks: boolean | null;
  id: number;
  timestamp: Date;
}
