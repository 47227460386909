import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PricingComponent } from './pricing/pricing.component';
import { IndexAdjustmentComponent } from './index-adjustment/index-adjustment.component';
import { SurchargeComponent } from './surcharge/surcharge.component';

const routes: Routes = [
  { path: '', component: PricingComponent},
  { path: 'index-adjustment', component: IndexAdjustmentComponent},
  { path: 'surcharge', component: SurchargeComponent}
]

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})

export class PricingRoutingModule { }
