import { CommonModule } from "@angular/common";
import { HttpClientModule } from "@angular/common/http";
import { Component, EventEmitter, Input, Output } from "@angular/core";
import { ReactiveFormsModule, FormsModule, UntypedFormGroup } from "@angular/forms";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { TranslateModule } from "@ngx-translate/core";
import { SharedModule } from "src/app/shared/shared.module";

@Component({
  selector: 'app-base-modal',
  templateUrl: './base-modal.component.html',
  standalone: true,
  imports: [
    SharedModule,
    CommonModule,
    FontAwesomeModule,
    NgbModule,
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
    TranslateModule
  ]
})

export class BaseModal {
  @Input() titleTranslationKey: string = '';
  @Input() closeTranslationKey: string = '';
  @Input() acceptTranslationKey: string = '';
  @Input() formGroup: UntypedFormGroup | undefined;

  @Output() accept = new EventEmitter();
  @Output() cancel = new EventEmitter();
}
