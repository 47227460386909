import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { TranslateService } from "@ngx-translate/core";
import { TokenService } from './services/token.service';
import { IconDefinition, faUser, faTruck, faBars, faCaretDown, faSignOutAlt, faBell, faEnvelope, faUsers, faFile, faFlag, faGlobe, faUserCog, faUsersCog, faCaretLeft, faTools, faQuestionCircle, faGlobeEurope, faLayerGroup, faChartPie, faDatabase, faExclamation, faInbox, faGear } from '@fortawesome/free-solid-svg-icons';
import { environment } from '../environments/environment';
import { AuthOptions, LoginResponse, LogoutAuthOptions, OidcSecurityService } from 'angular-auth-oidc-client';
import { MainGroup } from './grouping/grouping.models'
import { GroupingService } from './grouping/grouping.service'
import { Observable, Subscription } from 'rxjs';
import { ModalComponent } from './molecules/modal/modal.component'
import { IDropdownSettings } from 'ng-multiselect-dropdown'
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MessageContent } from './molecules/message-list/message-list.component';
import { MessagingService } from './molecules/message-list/messaging.service';
import { ToastrService } from 'ngx-toastr';
import { AuthCompleted, CreateGroup, CustomProxyEvent, DeleteGroup, EventProxyService, NewMessage, SetGroupId, UpdateGroup } from './shared/services/event-proxy.service'
import { EventMqttService } from './acr/shared/event-mqtt.service';
import { IMqttMessage } from 'ngx-mqtt'



@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnInit {
  private EventProxySubscription: Subscription | undefined

  @ViewChild('messageReadModal') messageReadModal!: ModalComponent;
  @ViewChild('messageCreateModal') messageCreateModal!: ModalComponent;

  menuState: string = 'global'
  urlTours: string = ''
  companyName: string = ''
  language: string = ''
  asideMenu: boolean = false
  asideMenuHover: boolean = false
  username: string = ''
  showSideMenu: boolean = false

  languageList: string[] = ['danish', 'english', 'german', 'czech']

  faUser: IconDefinition = faUser
  faTruck: IconDefinition = faTruck
  faExclamation: IconDefinition = faExclamation
  faBars: IconDefinition = faBars
  faCaretDown: IconDefinition = faCaretDown
  faSignOutAlt: IconDefinition = faSignOutAlt
  faBell: IconDefinition = faBell
  faEnvelope: IconDefinition = faEnvelope
  faUsers: IconDefinition = faUsers
  faFile: IconDefinition = faFile
  faFlag: IconDefinition = faFlag
  faGlobe: IconDefinition = faGlobe
  faUserCog: IconDefinition = faUserCog
  faUsersCog: IconDefinition = faUsersCog
  faCaretLeft: IconDefinition = faCaretLeft
  faTools: IconDefinition = faTools
  faQuestionCircle: IconDefinition = faQuestionCircle
  faGlobeEurope: IconDefinition = faGlobeEurope
  faLayerGroup: IconDefinition = faLayerGroup
  faChartPie: IconDefinition = faChartPie
  faDatabase: IconDefinition = faDatabase
  faInbox: IconDefinition = faInbox
  faGear: IconDefinition = faGear

  showDetailsFrontVehicle: boolean = false
  showDetailsUser: boolean = false
  addNewVehicle: boolean = false
  hasNewMessages: boolean = false

  subscription: Subscription | undefined;

  // isSuperAdmin: boolean = false
  // isCompanyAdmin: boolean = true
  isAuthenticated: any = false
  // isInRole: boolean = true

  userRole: any = null
  userId: string = ''
  userCompanyGuidCountGreaterThanOne: boolean = true
  mainGroups: MainGroup[] = []
  messageList: MessageContent[] = []










  constructor(
    public translate: TranslateService,
    private router: Router,
    private tokenService: TokenService,
    private oidc: OidcSecurityService,
    private groupingService: GroupingService,
    private messagingService: MessagingService,
    private toastr: ToastrService,
    private eventProxyService: EventProxyService,
    private mqttService: EventMqttService
  ) {
    translate.addLangs(['danish'])
    translate.addLangs(['english'])
    translate.setDefaultLang('danish')

    this.language = tokenService.getUserLanguage()
    this.username = tokenService.getUserName()

    this.userRole = tokenService.getUserRole()

    // change to the role you wish to test with
    // this.userRole = "Employee"
    /*
       tokenService.getAuthentication().subscribe(x => {
         this.isAuthenticated = x
       }); */
       
    switch (this.language) {
      case 'da-DK':
        this.language = 'danish'
        break
      case 'en-GB':
        this.language = 'english'
        break
      case 'de':
        this.language = 'german'
        break
      case 'cs':
        this.language = 'czech'
        break
      default:
        this.language = 'danish'
        break
    }

    translate.use(this.language)

    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        if (event.url != '/start') {
          if (!event.url.includes('signin-oidc') && !event.url.includes('signout-callback-oidc') && !event.url.includes('unauthorized') && !event.url.includes('error')) {
            this.menuState = this.getMenuState(router.url);
            sessionStorage.setItem('urlAfterStart', event.url.substring(1));
          }
        }

        this.urlTours = router.url;
      }
    });



    this.companyName = this.tokenService.getCompanyName();
    this.tokenService.companyName.subscribe(
      data => {
        this.companyName = data;
      }
    )



    this.EventProxySubscription = this.eventProxyService.getEvent().subscribe((param: CustomProxyEvent) => {
      if (param instanceof CreateGroup || param instanceof UpdateGroup || param instanceof DeleteGroup) {
        this.getGroups()
      }


      if (param instanceof AuthCompleted) {
        tokenService.getAuthentication().subscribe(x => {
          this.isAuthenticated = x;

          this.username = tokenService.getUserName();
          this.userRole = tokenService.getUserRole();
          this.userId = tokenService.getUserId();

          sessionStorage.setItem('userId', this.userId)



          this.subscription = this.mqttService.messageTopic(this.userId, 'user').subscribe((data: IMqttMessage) => {
            this.eventProxyService.setEvent(new NewMessage(data))
            this.hasNewMessages = true
          })


          this.messagingService.getMessages({ userId: parseInt(this.userId), frontVehicleId: null }).subscribe(response => {
            this.messageList = response
          })
        })
      }


      if (param instanceof NewMessage) {
        this.messagingService.getMessages({ userId: parseInt(this.userId), frontVehicleId: null }).subscribe(response => {
          this.messageList = []

          this.messageList = response
        })
      }
    })
  }




  ngOnInit() {

  }

  ngAfterViewInit(): void {
  }


  getMenuState(url: string) {
    if (url.toLowerCase().indexOf('/acr') >= 0) {
      this.showSideMenu = true
      this.getGroups()
      return 'acr'
    }

    if (url.toLowerCase().indexOf('/reports') >= 0) {
      this.showSideMenu = true
      this.getGroups()
      return 'reports'
    }

    if(url.toLowerCase().indexOf('/setup') >= 0 || url.toLowerCase().indexOf('/freight-types') >= 0 || url.toLowerCase().indexOf('/pricing') >= 0 || url.toLowerCase().indexOf('/eyes') >= 0 || url.toLowerCase().indexOf('/boxes') >= 0) {
      this.showSideMenu = true;
      this.getGroups();
      return 'setup';
    }

    if (url.toLowerCase().indexOf('/glecview') >= 0) {
      this.showSideMenu = false
      return 'glecView'
    }

    if (url.toLowerCase().indexOf('/employee') >= 0) {
      this.showSideMenu = true
      return 'employee'
    }

    if (url.toLowerCase().indexOf('/mss') >= 0) {
      this.showSideMenu = false
      return 'mss'
    }

    if (url.toLowerCase().indexOf('/start') >= 0) {
      this.showSideMenu = false
      return 'start'
    }

    if (url.toLowerCase().indexOf('/tours') >= 0) {
      this.showSideMenu = true
      this.getGroups()
      return 'tours'
    }

    /* if (url.toLowerCase().indexOf('/freight-types') >= 0) {
      this.showSideMenu = true
      this.getGroups()
      return 'freight-types'
    } */

    if (url.toLowerCase().indexOf('/help-center') >= 0 || url.toLowerCase().indexOf('/grouping') >= 0) {
      this.showSideMenu = true
      this.getGroups()
      return ''
    }

    if (url.toLowerCase().indexOf('/companies') >= 0 || url.toLowerCase().indexOf('/organisations') >= 0) {
      this.showSideMenu = true
      this.getGroups()
      return 'admin-panel'
    }

    if (url.toLowerCase().indexOf('/company-users') >= 0) {
      this.showSideMenu = true
      this.getGroups()
      return 'company-users'
    }


    if (url.toLowerCase().indexOf('/admin-panel') >= 0) {
      this.showSideMenu = true
      this.getGroups()
      return 'admin-panel'
    }

    if (this.showSideMenu) this.getGroups()

    /* if (url.toLowerCase().indexOf('/company-reports') >= 0) {
      return 'company-reports';
    }
    if (url.toLowerCase().indexOf('/tracker') >= 0) {
      return 'tracker';
    }
    if (url.toLowerCase().indexOf('/planner') >= 0) {
      return 'planner';
    }
    if (url.toLowerCase().indexOf('/start') >= 0) {
      return 'planner';
    }
    return ''; */
    this.showSideMenu = true
    return 'global'
  }


  showTopMenu(): boolean {
    if ((this.userRole != 'Report' || this.userRole.includes('Report')) && (this.userRole != 'Employee' || this.userRole.includes('Employee'))) {
      return true
    }

    return false
  }


  closeItemDetails(): void {
    this.showDetailsUser = false
    this.showDetailsFrontVehicle = false
  }


  toggleAside(): void {
    this.asideMenu = !this.asideMenu
  }


  hoverAside(): void {
    if (document.getElementsByClassName('move-page').length < 1) {
      this.asideMenuHover = !this.asideMenuHover
    }
  }



  getGroups() {
    this.groupingService.getGroupsList().subscribe(response => {
      this.mainGroups = response
    })
  }


  selectGroup(event: any) {

    if (event.maingroupId <= 0) {
      sessionStorage.removeItem('groupId')
    } else {
      sessionStorage.setItem('groupId', event.maingroupId.toString())
    }

    this.eventProxyService.setEvent(new SetGroupId(event.maingroupId))
  }

  selectedButtonText() {
    if (sessionStorage.getItem('groupId')) {
      return this.mainGroups.find(x => x.id == Number.parseInt(sessionStorage.getItem('groupId')!))?.name || this.translate.instant('grouping.buttons.chooseGroup')
    }
    return this.translate.instant('grouping.buttons.chooseGroup')
  }


  translateLanguage(language: string) {
    return this.translate.instant(`navigation.header.${language}`)
  }


  setLanguage(language: string) {
    if (language == 'danish') {
      this.language = 'danish'
      this.tokenService.setLanguage('da-DK')
    }

    if (language == 'english') {
      this.language = 'english'
      this.tokenService.setLanguage('en-GB')
    }

    if (language == 'german') {
      this.language = 'german'
      this.tokenService.setLanguage('de')
    }
    if (language == 'czech') {
      this.language = 'czhech'
      this.tokenService.setLanguage('cs')
    }

    this.translate.use(this.language)
  }



  openMessages() {
    this.hasNewMessages = false
    this.messageReadModal.open()
  }


  createMessage(event: MessageContent) {
    this.messagingService.createMessage(event).subscribe(response => {

      if (response.userIdReceiver != null) this.mqttService.sendMessageTopic(response.userIdReceiver, 'user', response.message)
      if (response.frontVehicleId != null) this.mqttService.sendMessageTopic(response.frontVehicleId, 'frontvehicle', response.message)

      this.messageCreateModal.close()


      this.toastr.success(this.translate.instant('messaging.create.messageCreated'))
    })
  }

  showAdminMenu(): boolean {
    if (this.menuState == 'admin-panel' || this.menuState == 'company-users') {
      if (this.userRole == 'SuperAdmin' || this.userRole.includes('SuperAdmin')) {
        return true;
      }
    }
    return false;
  }

  isPath(path: string): boolean {
    if (this.router.url.toLowerCase().indexOf(path) >= 0) {
      return true;
    }
    return false;
  }

  signout(): void {
    //this.tokenService.SignoutLocal();
    //this.tokenService.Signout();
    sessionStorage.removeItem('urlAfterStart');
    const logoutAuthOptions: LogoutAuthOptions = {
      logoffMethod: 'GET',
    };
    this.oidc.logoffAndRevokeTokens('', logoutAuthOptions).subscribe(x => { });
    //window.location.href = 'https://app-staging-iframe.trasolu.com/connect/endsession?post_logout_redirect_uri=https%3A%2F%2Fplanner-staging-iframe.trasolu.com%2Fsignout-callback-oidc';

    //const url = environment.idp.authority + '/connect/endsession'
    //window.open(url, '_self');
    //this.router.navigateByUrl('https://app-staging-iframe.trasolu.com/connect/endsession');
  }

  ngOnDestroy() {
    if (this.EventProxySubscription) {
      this.EventProxySubscription.unsubscribe();
    }
  }
}
