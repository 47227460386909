import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { faCheck, faPencilAlt, faTimes, faTrash, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { DateValidator } from 'src/app/form-validators/date-validator';
import { TimeValidator } from 'src/app/form-validators/time-validator';
import { BoxModel, CreateBoxModel } from 'src/app/front-vehicles/shared/front-vehicle.model';
import { SharedService } from '../services/shared.service';

@Component({
  selector: 'app-box-info-widget',
  templateUrl: './box-info-widget.component.html'
})

export class BoxInfoWidgetComponent implements OnInit {
  @Input()
  vehicleEquipment: any;

  @Input()
  box: BoxModel | undefined;

  @Input()
  createMode = false;

  @Output()
  cancelCreate = new EventEmitter();

  @Output()
  createEvent = new EventEmitter();

  @Output()
  updateEvent = new EventEmitter();

  @Output()
  removeEvent = new EventEmitter();

  @Output()
  cancelEvent = new EventEmitter();

  formGroup: UntypedFormGroup;

  @Input()
  includeAccept = true;

  types: string[] = [];

  faEdit = faPencilAlt;
  faTrash = faTrashAlt;
  faAccept = faCheck;
  faCancel = faTimes;

  readOnlyBox = true;

  constructor(private formBuilder: UntypedFormBuilder, private sharedService: SharedService, private datePipe: DatePipe) {
    this.formGroup = this.formBuilder.group({
      UID: ['', [Validators.required, Validators.maxLength(255)]],
      ModelName: ['', [Validators.required]],
      SimPin: [''],
      StorageLocation: [''],
      StorageBoxNumber: [''],
      CanBaud1: ['', [Validators.required, Validators.pattern('^[0-9]+$')]],
      installationDate: ['']
    });
   }

  ngOnInit(): void {
    if (this.box){
      this.formGroup.patchValue({
        UID: this.box?.uid,
        ModelName: this.box?.modelName,
        StorageLocation: this.box?.storageLocation,
        StorageBoxNumber: this.box?.storageBoxNumber,
        SimPin: 'noPin',
        CanBaud1: this.box?.canBaud1,
        BoxType: this.box?.boxType,
        installationDate: this.datePipe.transform(this.vehicleEquipment.boxInstallationDate, 'yyyy-MM-dd') + `  ${this.getFormattedTime(new Date(this.vehicleEquipment.boxInstallationDate).getHours())}:${this.getFormattedTime(new Date(this.vehicleEquipment.boxInstallationDate).getMinutes())}`
      });
    }
  }

  getFormattedTime(time: number): string {
    return ('0' + time).slice(-2);
  }

  update(): void {
    this.readOnlyBox = !this.readOnlyBox;
  }

  cancel(): void {
    this.readOnlyBox = !this.readOnlyBox;
    this.cancelEvent.emit();
  }

  getValue(type: string): string {
    return `boxIdentifier.event.${type.toLowerCase()}`;
  }

  remove(){
    this.removeEvent.emit();
  }
}
