export const environment = {
	idp: { // identity provider
		authority: 'https://connect-dev.trasolu.com',
		redirectUrl: 'https://planner-dev.trasolu.com/signin-oidc',
		postLogoutRedirectUri: 'https://planner-dev.trasolu.com/signout-callback-oidc',
		clientId: 'web-client',
		scope: 'openid profile group offline_access trasolu-api trasolu-agreed IdentityServerApi',
		responseType: 'code',
		silentRenew: true,
		useRefreshToken: true,
		triggerAuthorizationResultEvent: true,
	  },
  production: true,
  baseUrl: 'https://api-dev.trasolu.com/Api',
  agreedBaseUrl: 'https://agreed-dev.trasolu.com/api',
  mqtt: {
		server: 'peppy-pink-elk.rmq5.cloudamqp.com',
    port: 443,
		protocol: "wss",
    path: '/ws/mqtt',
    username: 'dzdofnqq:dzdofnqq',
    password: 'lDD8Yh_WpYYwpG-0-aaRWgMQ7JTpzizz'
	},
  tomtom: {
    key: '72plKvHEdyLx8NW5Kw7rRG5eaiI0wvHz',
  },
  boxApiBaseUrl: 'https://trasolu-box-api.azurewebsites.net',
  boxApiKey: 'e7bd923d-8a10-46c6-a9e0-55eefb0889c9',
  blobStorageSasToken: 'BlobEndpoint=https://trasoludevstorage.blob.core.windows.net/;QueueEndpoint=https://trasoludevstorage.queue.core.windows.net/;FileEndpoint=https://trasoludevstorage.file.core.windows.net/;TableEndpoint=https://trasoludevstorage.table.core.windows.net/;SharedAccessSignature=sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2030-06-01T15:58:00Z&st=2023-06-23T07:58:00Z&spr=https,http&sig=ZQw80A08jccTI%2FRrKOwmeElf05pXyVqQccwiLvadKUU%3D',
  fotaApiKey: '2353|nZ3hbj6rWhdQEqpmbLeM1kBgfTPVWn6uzMwGldW2',
  fotaUrl: 'https://api.teltonika.lt',
  simpleLocalize: {
    danish: 'https://cdn.simplelocalize.io/cba8bb620a724c01a6cef67fb498f91a/_latest/da_DK',
    english: 'https://cdn.simplelocalize.io/cba8bb620a724c01a6cef67fb498f91a/_latest/en',
    german: 'https://cdn.simplelocalize.io/cba8bb620a724c01a6cef67fb498f91a/_latest/de',
	czech:'https://cdn.simplelocalize.io/cba8bb620a724c01a6cef67fb498f91a/_latest/cs',
  }
    , "syncfusion": 'Ngo9BigBOggjHTQxAR8/V1NDaF5cWWtCf1FpRmJGdld5fUVHYVZUTXxaS00DNHVRdkdnWH9ceHVUQ2JZUkR2X0c='
};
