<app-google-street-view-modal *ngIf="showStreetViewModal" [lat]="streetviewLat" [lng]="streetviewLng" (closed)="hideStreetview($event)"></app-google-street-view-modal>
 
<body class="bg-light">
  <div class="px-0">
    <div #body id="body">
      <form [formGroup]="formGroup" class="col-md-12">
        <h6 style="padding-top: 15px">{{'tour.times' | translate}}</h6>
        <div class="form-group row">
          <div class="col-md-4">
            <label for="expectedStartDate">
              {{'tour.startDate' | translate}}
              <span *ngIf="formGroup.controls['expectedStartDate'].hasError('required')" class="required-text">
                {{'message.required' | translate}}
              </span>
            </label>
            <input id="expectedStartDate" type="date" formControlName="expectedStartDate" class="form-control form-control-sm">
          </div>
          <div class="col-md-2">
            <label for="expectedStartTime">{{'tour.startTime' | translate}}</label>
            <input id="expectedStartTime" type="time" formControlName="expectedStartTime" class="form-control form-control-sm">
          </div>
          <div class="col-md-4">
            <label for="expectedEndDate">
              {{'tour.endDate' | translate}}
              <span *ngIf="formGroup.controls['expectedEndDate'].hasError('required')" class="required-text">
                {{'message.required' | translate}}
              </span>
              <span *ngIf="formGroup.controls['expectedEndDate'].hasError('wrong-date')" class="required-text">
                {{'message.wrong-date' | translate}}
              </span>
            </label>
            <input id="expectedEndDate" type="date" formControlName="expectedEndDate" class="form-control form-control-sm">
          </div>
          <div class="col-md-2">
            <label for="expectedEndTime">
              {{'tour.endTime' | translate}}
              <span *ngIf="formGroup.controls['expectedEndTime'].hasError('wrong-time')" class="required-text">
                {{'message.wrong-date' | translate}}
              </span>
            </label>
            <input id="expectedEndTime" type="time" formControlName="expectedEndTime" class="form-control form-control-sm">
          </div>
          <div class="col-md-6">
            <label>{{'tour.create.repetition.title' | translate}}</label>
            <select class="form-select form-select-sm" formControlName="repetition">
              <option [ngValue]="0">{{'tour.create.repetition.none' | translate}}</option>
              <option [ngValue]="1">{{'tour.create.repetition.daily'| translate}}</option>
              <option [ngValue]="2">{{'tour.create.repetition.weekly' | translate}}</option>
              <option [ngValue]="3">{{'tour.create.repetition.monthly' | translate}}</option>
            </select>
          </div>

          <div class="col-md-4">
            <label>
              {{'tour.create.repetition.endRepetitionDate' | translate}}
              <span *ngIf="formGroup.controls['endRepetitionDate'].hasError('required')" class="required-text">
                {{'message.required' | translate}}
              </span>
              <span *ngIf="formGroup.controls['endRepetitionDate'].hasError('wrong-date')" class="required-text">
                {{'message.wrong-date' | translate}}
              </span>
            </label>
            <input formControlName="endRepetitionDate" type="date" class="form-control form-control-sm" (click)="calculateRepetitionCount()">
          </div>
          <hr class="mt-4" style="width: 100%">
          <div class="col-md-12">
            <label for="description">
              {{'tour.description' | translate}}
              <span *ngIf="formGroup.controls['description'].hasError('maxlength')" class="required-text">
                {{'message.maxLength' | translate: {number: 40} }}
              </span>
              <span *ngIf="formGroup.controls['description'].hasError('required')" class="required-text">
                {{'message.required' | translate}}
              </span>
            </label>
            <input id="description" type="text" formControlName="description" class="form-control form-control-sm">
          </div>

          <div class="col-md-12">
            <label for="orderId">
              {{'tour.orderId' | translate}}
              <span *ngIf="formGroup.controls['orderId'].hasError('maxlength')" class="required-text">
                {{'message.maxLength' | translate: {number: 40} }}
              </span>
            </label>
            <input id="orderId" type="text" formControlName="orderId" class="form-control form-control-sm">
          </div>

          <div class="col-md-6">
            <label for="freightType">{{'tour.freight' | translate}}</label>
            <input id="freightType" type="text" formControlName="freight" class="form-control form-control-sm">
          </div>
          <div class="col-md-6">
            <label for="freightTypeId">
              {{'tour.freightType' | translate}}<span *ngIf="formGroup.controls['freightTypeId'].hasError('required')" class="required-text">
                {{'message.required' | translate}}
              </span>
            </label>
            <select class="form-select form-select-sm" formControlName="freightTypeId" (change)="onChangeFreightType($event)" #freightTypeDescSelect>
              <option value="1">{{'tour.freightTypeUnitDesc.weight' | translate}}</option>
              <option value="2">{{'tour.freightTypeUnitDesc.volume'| translate}}</option>
              <option value="3">{{'tour.freightTypeUnitDesc.pallets' | translate}}</option>
            </select>
          </div>
          <!-- <div class="col-md-6" *ngIf="freightTypeDescValue.length > 0">
            <label for="freightTotalAmount">
              {{freightTypeUnitTitle}}<span *ngIf="formGroup.controls['freightTotalAmount'].hasError('required')" class="required-text">
                {{'message.required' | translate}}
              </span>
            </label>
            <input id="freightTotalAmount" type="number" formControlName="freightTotalAmount" class="form-control form-control-sm">
          </div> -->

          <hr class="mt-4" style="width: 100%">
          <div class="col-md-12">
            <h6>{{'tour.resources' | translate}}</h6>
          </div>
          <div class="col-md-12">
            <label>{{'tour.chauffeur' | translate}} </label>
            <button class="btn btn-primary btn-sm" style="margin-left: 10px; margin-right: 10px;" (click)="userSelect = true; calculateRepetitionCount()" [disabled]="!formGroup.value.expectedStartDate || !formGroup.value.expectedEndDate ">{{'tour.create.user-select.btn' | translate}}</button>
            <button *ngIf="!showSecondUserField && selectedUser" class="btn btn-sm btn-success" (click)="toogleSecondUserField()"><fa-icon [icon]="faAddIcon"></fa-icon></button>
            <p>
              <input *ngIf="selectedUser" class="form-control form-control-sm" style="margin-top: 5px" [value]="selectedUser?.firstname + ' ' + selectedUser?.lastname" disabled>
              <input *ngIf="!selectedUser" class="form-control form-control-sm" style="margin-top: 5px" disabled>
              <app-user-select *ngIf="userSelect" id="user-select" (selected)="selectedUserCallback($event)" [dateTimes]="repetitionDateTimes"></app-user-select>
            </p>
          </div>
          <div class="col-md-12" *ngIf="showSecondUserField">
            <label>{{'tour.chauffeur2' | translate}} </label>
            <button class="btn btn-primary btn-sm" style="margin-left: 10px; margin-right: 10px;" (click)="secondUserSelect = true; calculateRepetitionCount()" [disabled]="!formGroup.value.expectedStartDate || !formGroup.value.expectedEndDate ">{{'tour.create.user-select.btn' | translate}}</button>
            <button *ngIf="showSecondUserField" class="btn btn-sm btn-danger" (click)="toogleSecondUserField()"><fa-icon [icon]="faMinusIcon"></fa-icon></button>
            <p>
              <input *ngIf="selectedSecondUser" class="form-control form-control-sm" style="margin-top: 5px" [value]="selectedSecondUser?.firstname + ' ' + selectedSecondUser?.lastname" disabled>
              <input *ngIf="!selectedSecondUser" class="form-control form-control-sm" style="margin-top: 5px" disabled>
              <app-user-select *ngIf="secondUserSelect" id="second-user-select" (selected)="selectedSecondUserCallback($event)" [dateTimes]="repetitionDateTimes"></app-user-select>
            </p>
          </div>
          <div class="col-md-12">
            <label>{{'tour.frontVehicle' | translate}} </label>
            <button class="btn btn-primary btn-sm" style="margin-left: 10px" (click)="frontVehicleSelect = true">{{'tour.create.user-select.btn' | translate}}</button>
            <p>
              <input *ngIf="selectedFrontVehicle" class="form-control form-control-sm" style="margin-top: 5px" [value]="getVehicleDisplayText(selectedFrontVehicle)" disabled>
              <input *ngIf="!selectedFrontVehicle" class="form-control form-control-sm" style="margin-top: 5px" disabled>
              <app-front-vehicle-select *ngIf="frontVehicleSelect" id="frontVehicle-select" (selected)="selectedFrontVehicleCallback($event)"></app-front-vehicle-select>
            </p>
          </div>
          <div class="col-md-12">
            <label>{{'tour.backVehicle' | translate}}</label>
            <button class="btn btn-default btn-sm" style="margin-left: 10px" (click)="backVehicleSelect = true" [disabled]="this.addresses.length >= 1">{{'tour.create.user-select.btn' | translate}}</button>
            <p>
              <input class="form-control form-control-sm" style="margin-top: 5px" [value]="backVehiclesString" disabled>
              <app-back-vehicle-select *ngIf="backVehicleSelect" id="backVehicle-select" (selected)="selectedBackVehicleCallback($event)"></app-back-vehicle-select>
            </p>
          </div>
          <div class="col-md-12">
            <label>{{'tour.transportEquipment' | translate}}</label>
            <button class="btn btn-default btn-sm" style="margin-left: 10px" (click)="transportEquipmentSelect = true" [disabled]="this.addresses.length >= 1">{{'tour.create.user-select.btn' | translate}}</button>
            <p>
              <input *ngIf="selectedTransportEquipment" class="form-control form-control-sm" style="margin-top: 5px" [value]="selectedTransportEquipment?.manufacturer + ' ' + selectedTransportEquipment?.resourceType?.resourceTypeName + ' ' + selectedTransportEquipment?.unitIdentification" disabled>
              <input *ngIf="!selectedTransportEquipment" class="form-control form-control-sm" style="margin-top: 5px" disabled>
              <app-transport-equipment-select *ngIf="transportEquipmentSelect" (selected)="selectedTransportEquipmentCallback($event)"></app-transport-equipment-select>
            </p>
          </div>
          <hr class="mt-4" style="width: 100%">
          <div class="col-md-12">
            <h6>{{'tour.delivery' | translate}}</h6>
          </div>
          <div class="col-md-12">
            <div class="form-check form-check-inline ps-0">
              <label>
                <input type="radio" value="Export" formControlName="type" style="height:15px; width:15px; margin-right: 5px">
                <span>{{'tour.export' | translate}}</span>
              </label>
            </div>
            <div class="form-check form-check-inline ps-0">
              <label>
                <input type="radio" value="Inland" formControlName="type" style="height:15px; width:15px; margin-right: 5px">
                <span>{{'tour.inland' | translate}}</span>
              </label>
            </div>
            <span *ngIf="formGroup.controls['type'].hasError('required')" class="required-text">
              {{'message.required' | translate}}
            </span>
            <div class="form-check form-check-inline ps-0">
              <label>
                <input type="checkbox" value="Inland" formControlName="isSpecial" style="height:15px; width:15px; margin-right: 5px">
                <span>{{'tour.isSpecial' | translate}}</span>
              </label>
            </div>
          </div>
          <div class="col-md-12">
            <label>{{'tour.customer' | translate}} </label>
            <button class="btn btn-primary btn-sm" style="margin-left: 10px;" (click)="customerSelect = true">{{'tour.create.user-select.btn' | translate}}</button>
            <p>
              <input class="form-control form-control-sm" style="margin-top: 5px" [value]="selectedCustomer?.name" disabled>
              <app-customer-select *ngIf="customerSelect" id="customer-select" (selected)="selectedCustomerCallback($event)"></app-customer-select>
            </p>
          </div>
          <div class="col-md-12">
            <label>{{'tour.address' | translate}}</label>
            <ng-container formArrayName="addresses">
              <ng-container *ngFor="let addressForm of addresses.controls; let i = index">
                <div class="form-group" [formGroupName]="i" style="border: #213a45 solid 1px; padding: 15px; border-radius: 6px">
                  <div class="form-group row">
                    <div class="col-md-1 pe-0">
                      <label for="country">
                        {{'address.priority' | translate}}
                        <span *ngIf="addressForm.get('priority')?.hasError('required')" class="required-text">
                          {{'message.required' | translate }}
                        </span>
                      </label>
                      <input [attr.disabled]="true" id="priority" type="number" formControlName="priority" class="form-control form-control-sm bg-warning">
                    </div>
                    <div class="col-md-11 position-relative">
                      <span style="bottom: 0; position: absolute">
                        <button class="btn btn-sm btn-primary" style="margin-right: 5px" (click)="duplicate(addressForm)"><fa-icon [icon]="faCloneIcon"></fa-icon></button>
                        <button class="btn btn-sm btn-primary" style="margin-right: 5px" (click)="swap(i - 1, i)"> <fa-icon [icon]="faUpIcon"></fa-icon> </button>
                        <button class="btn btn-sm btn-primary" style="margin-right: 5px" (click)="swap(i, i + 1)"> <fa-icon [icon]="faDownIcon"></fa-icon> </button>
                        <button class="btn btn-sm btn-danger" (click)="removeAddressAtIndex(i)"> <fa-icon [icon]="faTrashIcon"></fa-icon> </button>
                      </span>
                    </div>
                    <div class="col-md-12 pe-0">
                      <label for="search">{{'address.searchAddress' | translate}}   </label>
                      <input type="text" class="form-control" (keyup)="getValue(search.value); currSearch = i" #search name="search" placeholder="{{'address.searchAddressDesc' | translate}} " autocomplete="off" />

                      <div *ngIf="currSearch == i">
                        <ul class="resultList" *ngFor="let result of searchResults" class="list-group">
                          <li role="button" (click)="fillAddressForm(result, addressForm)"
                              class="list-group-item cursor">{{generateAdressTitle(result)}}</li>
                        </ul>
                      </div>

                    </div>
                    <div class="col-md-4 pe-0">
                      <label for="country">
                        {{'address.country' | translate}}
                        <span *ngIf="addressForm.get('country')?.hasError('maxlength')" class="required-text">
                          {{'message.maxLength' | translate: {number: 255} }}
                        </span>
                      </label>
                      <input id="country" type="text" formControlName="country" class="form-control form-control-sm">
                    </div>
                    <div class="col-md-4 pe-0">
                      <label for="state">
                        {{'address.state' | translate}}
                        <span *ngIf="addressForm.get('state')?.hasError('maxlength')" class="required-text">
                          {{'message.maxLength' | translate: {number: 255} }}
                        </span>
                      </label>
                      <input id="state" type="text" formControlName="state" class="form-control form-control-sm">
                    </div>
                    <div class="col-md-4">
                      <label for="zipcode">
                        {{'address.zipcode' | translate}}
                        <span *ngIf="addressForm.get('zipcode')?.hasError('maxlength')" class="required-text">
                          {{'message.maxLength' | translate: {number: 5} }}
                        </span>
                        <span *ngIf="addressForm.get('zipcode')?.hasError('required')" class="required-text">
                          {{'message.required' | translate}}
                        </span>
                      </label>
                      <input id="zipcode" type="text" formControlName="zipcode" class="form-control form-control-sm">
                    </div>
                    <div class="col-md-4 pe-0">
                      <label for="city">
                        {{'address.city' | translate}}
                        <span *ngIf="addressForm.get('city')?.hasError('maxlength')" class="required-text">
                          {{'message.maxLength' | translate: {number: 255} }}
                        </span>
                        <span *ngIf="addressForm.get('city')?.hasError('required')" class="required-text">
                          {{'message.required' | translate}}
                        </span>
                      </label>
                      <input id="city" type="text" formControlName="city" class="form-control form-control-sm">
                    </div>
                    <div class="col-md-8">
                      <label for="street">
                        {{'address.street' | translate}}
                        <span *ngIf="addressForm.get('street')?.hasError('maxlength')" class="required-text">
                          {{'message.maxLength' | translate: {number: 255} }}
                        </span>
                      </label>
                      <input id="street" type="text" formControlName="street" class="form-control form-control-sm">
                    </div>

                    <!-------------------------------------------------------------->
                    <div class="col-md-6 pe-0">
                      <label for="locationName">{{'address.locationName' | translate}}</label>
                      <input id="locationName" type="text" class="form-control form-control-sm" formControlName="locationName" />
                    </div>
                    <div class="col-md-6 pe-0">
                      <label for="locationId">{{'address.locationId' | translate}}</label>
                      <input id="locationId" type="text" class="form-control form-control-sm" formControlName="locationId" />
                    </div>

                    <!-------------------------------------------------------------->
                    <div class="col-md-1">
                    </div>
                    <div class="col-md-10 justify-content-center d-flex" style="padding-top: 15px">
                      <button class="btn btn-sm btn-outline-default" (click)="geocode(addressForm)">{{'btn.geocode' | translate}}</button>
                    </div>
                    <div class="col-md-1">
                      <div *ngIf="isGeocoding" class="spinner-border content text-dark to-bottom" role="status">
                        <span class="sr-only">{{'common.loading' | translate}}</span>
                      </div>
                    </div>
                    <hr class="full-width mt-4">
                    <div class="col-md-6">
                      <label for="longitude">{{'address.longitude' | translate}}</label>
                      <input id="longitude" type="text" formControlName="longitude" class="form-control form-control-sm">
                    </div>
                    <div class="col-md-6">
                      <label for="latitude">{{'address.latitude' | translate}}</label>
                      <input id="latitude" type="text" formControlName="latitude" class="form-control form-control-sm">
                    </div>
                    <div class="col-md-1">
                    </div>
                    <div class="col-md-10 justify-content-center d-flex" style="padding-top: 15px">
                      <button class="btn btn-sm btn-outline-default" (click)="reverseGeocode(addressForm)">{{'btn.reverseGeocode' | translate}}</button>
                    </div>
                    <div class="col-md-10 justify-content-center d-flex py-3">
                      <button class="btn btn-outline-secondary" placement="top" #t="ngbTooltip"  ngbTooltip="Streetview"  (click)="emitStreetview(addressForm)"> <fa-icon [icon]="this.faStreetView"></fa-icon>Streetview</button>
                    </div>
                    <div class="col-md-1">
                      <div *ngIf="isReverseGeocoding" class="spinner-border content text-dark to-bottom" role="status">
                        <span class="sr-only">{{'common.loading' | translate}}</span>
                      </div>
                    </div>
                    <hr class="full-width mt-4">
                    <!-------------------------------------------------------------->

                    <div class="col-md-6 pe-0 mt-3 d-flex align-items-end position-relative">
                      <ng-multiselect-dropdown
                        [hidden]="!showArriveMultiSelect[i]"
                        class="w-100 mt-4"
                        [placeholder]="'address.multiSelect.selectArriveBackVehicles' | translate"
                        [settings]="dropdownSettings"
                        [data]="dropdownList"
                        (onSelect)="onItemSelectArriveBackVehicle($event, i)"
                        (onDeSelect)="onItemDeSelectArriveBackVehicle($event, i)"
                      ></ng-multiselect-dropdown>
                      <div class="w-100" [hidden]="showArriveMultiSelect[i]">
                        <label for="arriveBackVehicle">
                          {{'address.arriveBackVehicle' | translate}}
                          <span *ngIf="addressForm.get('arriveBackVehicle')?.hasError('maxlength')" class="required-text">
                            {{'message.maxLength' | translate: {number: 255} }}
                          </span>
                        </label>
                        <input [hidden]="showArriveMultiSelect[i]" id="arriveBackVehicle" formControlName="arriveBackVehicle" type="text" class="form-control form-control-sm" [(ngModel)]="arriveBackVehicle[i]" (change)="this.manualArriveBackVehicleInput(i)">
                      </div>
                      <button class="btn change-field ms-3" (click)="switchArriveField(i)" placement="top" #t="ngbTooltip" (mouseleave)="t.close()" [ngbTooltip]="(showArriveMultiSelect[i] ? 'address.switchToFreeText' : 'address.switchToMultiSelect') | translate">
                        <fa-icon [icon]="faKeyboard"></fa-icon>
                      </button>
                    </div>

                    <div class="col-md-6 mt-3 d-flex align-items-end position-relative">
                      <ng-multiselect-dropdown
                        class="w-100 mt-4"
                        [hidden]="!showLeaveMultiSelect[i]"
                        [placeholder]="'address.multiSelect.selectLeaveBackVehicles' | translate"
                        [settings]="dropdownSettings"
                        [data]="dropdownList"
                        (onSelect)="onItemSelectLeaveBackVehicle($event, i)"
                        (onDeSelect)="onItemDeSelectLeaveBackVehicle($event, i)"
                      ></ng-multiselect-dropdown>
                      <div class="w-100" [hidden]="showLeaveMultiSelect[i]">
                        <label for="leaveBackVehicle">
                          {{'address.leaveBackVehicle' | translate}}
                          <span *ngIf="addressForm.get('leaveBackVehicle')?.hasError('maxlength')" class="required-text">
                            {{'message.maxLength' | translate: {number: 255} }}
                          </span>
                        </label>
                        <input [hidden]="showLeaveMultiSelect[i]" id="leaveBackVehicle" formControlName="leaveBackVehicle" type="text" class="form-control form-control-sm" [(ngModel)]="leaveBackVehicle[i]" (change)="this.manualLeaveBackVehicleInput(i)">
                      </div>
                      <button class="btn change-field ms-3" (click)="switchLeaveField(i)" placement="top" #tt="ngbTooltip" (mouseleave)="tt.close()" [ngbTooltip]="(showLeaveMultiSelect[i] ? 'address.switchToFreeText' : 'address.switchToMultiSelect') | translate">
                        <fa-icon [icon]="faKeyboard"></fa-icon>
                      </button>
                    </div>

                    <div class="col-md-4 pe-0">
                      <label for="arriveEquipment">
                        {{'address.arriveEquipment' | translate}}
                        <span *ngIf="formGroup.controls['arriveEquipment'].hasError('maxlength')" class="required-text">
                          {{'message.maxLength' | translate: {number: 255} }}
                        </span>
                      </label>
                      <input id="arriveEquipment" type="text" formControlName="arriveEquipment" class="form-control form-control-sm" [(ngModel)]="arriveEquipment[i]" (change)="this.manualArriveEquipmentInput(i)">
                    </div>
                    <div class="col-md-2 mt-4 pe-0">
                      <select id="selectArriveEquipment" class="form-select form-select-sm mt-2" formControlName="selectArriveEquipment" [(ngModel)]="selectArriveEquipment[i]" (change)="selectedArriveEquipment(i)">
                        @if (transportEquipment && transportEquipment.length >= 1) {
                          @for(te of transportEquipment; track $index){
                            <option [value]="te.unitIdentification + ' ' + te.resourceType.resourceTypeName">{{te.unitIdentification}} {{te.resourceType.resourceTypeName}}</option>
                          }
                        }

                        @else {
                          <option value="">{{'ui.misc.empty' | translate}}</option>
                        }
                      </select>
                    </div>

                    <div class="col-md-4 pe-0">
                      <label for="leaveEquipment">
                        {{'address.leaveEquipment' | translate}}
                        <span *ngIf="formGroup.controls['leaveEquipment'].hasError('maxlength')" class="required-text">
                          {{'message.maxLength' | translate: {number: 255} }}
                        </span>
                      </label>
                      <input id="leaveEquipment" type="text" formControlName="leaveEquipment" class="form-control form-control-sm" [(ngModel)]="leaveEquipment[i]" (change)="this.manualLeaveEquipmentInput(i)">
                    </div>
                    <div class="col-md-2 mt-4">
                      <select id="selectLeaveEquipment" class="form-select form-select-sm mt-2" formControlName="selectLeaveEquipment" [(ngModel)]="selectLeaveEquipment[i]" (change)="selectedLeaveEquipment(i)">
                        @if (transportEquipment && transportEquipment.length >= 1) {
                          @for(te of transportEquipment; track $index){
                            <option [value]="te.unitIdentification + ' ' + te.resourceType.resourceTypeName">{{te.unitIdentification}} {{te.resourceType.resourceTypeName}}</option>
                          }
                        }

                        @else {
                          <option value="">{{'ui.misc.empty' | translate}}</option>
                        }
                      </select>
                    </div>

                    <div class="col-md-12">
                      <label for="orderId">
                        {{'tour.orderId' | translate}}
                        <span *ngIf="formGroup.controls['orderId'].hasError('maxlength')" class="required-text">
                          {{'message.maxLength' | translate: {number: 40} }}
                        </span>
                      </label>
                      <input id="orderId" type="text" formControlName="orderId" class="form-control form-control-sm">
                    </div>

                    <div class="col-12 form-group">
                      <label for="freight">{{'address.freight' | translate}}</label>
                      <input id="freight" type="text" formControlName="freight" class="form-control form-control-sm" />
                    </div>
                    <div class="col-4 form-group pe-0">
                      <label for="freightTypeId">{{'address.freightType' | translate}}</label>
                      <select (change)="freightTypeIdSelectChangeHandler($event)" id="freightTypeId" formControlName="freightTypeId" class="form-select form-select-sm">
                        <option value="1">{{'tour.freightTypeUnitDesc.weight' | translate}}</option>
                        <option value="2">{{'tour.freightTypeUnitDesc.volume'| translate}}</option>
                        <option value="3">{{'tour.freightTypeUnitDesc.pallets' | translate}}</option>
                      </select>
                    </div>
                    <div class="col-4 form-group pe-0">
                      <label for="removedFreightAmount">{{'address.removedFreightAmount' | translate}}</label>
                      <div class="input-group input-group-sm">
                        <input id="removedFreightAmount" type="number" formControlName="removedFreightAmount" class="form-control form-control-sm" />
                        <div class="input-group-append">
                          <span *ngIf="selectedFreightType == '1'" class="input-group-text span-styling">{{'tour.freightTypeUnitAbbreviation.weight' | translate}}</span>
                          <span *ngIf="selectedFreightType == '2'" class="input-group-text span-styling">{{'tour.freightTypeUnitAbbreviation.volume'| translate}}</span>
                          <span *ngIf="selectedFreightType == '3'" class="input-group-text span-styling">{{'tour.freightTypeUnitAbbreviation.pallets' | translate}}</span>
                        </div>
                      </div>
                    </div>
                    <div class="col-4 form-group ">
                      <label for="freightAmount">{{'address.freightAmount' | translate}}</label>
                      <div class="input-group input-group-sm">
                        <input id="freightAmount" type="number" formControlName="freightAmount" class="form-control form-control-sm" />
                        <div class="input-group-append">
                          <span *ngIf="selectedFreightType == '1'" class="input-group-text span-styling">{{'tour.freightTypeUnitAbbreviation.weight' | translate}}</span>
                          <span *ngIf="selectedFreightType == '2'" class="input-group-text span-styling">{{'tour.freightTypeUnitAbbreviation.volume'| translate}}</span>
                          <span *ngIf="selectedFreightType == '3'" class="input-group-text span-styling">{{'tour.freightTypeUnitAbbreviation.pallets' | translate}}</span>
                        </div>
                      </div>
                    </div>
                    <hr class="full-width">

                    <div class="col-md-12">
                      <label>
                        {{'address.description' | translate}}
                        <span *ngIf="addressForm.get('description')?.hasError('maxlength')" class="required-text">
                          {{'message.maxLength' | translate: {number: 255} }}
                        </span>
                      </label>
                      <textarea autosize type="text" formControlName="description" class="form-control form-control-sm"></textarea>
                    </div>
                  </div>
                </div>
              </ng-container>
              <div class="col-md-12 text-center">
                <button class="btn btn-sm btn-success" (click)="addAddress()"><fa-icon [icon]="faAddIcon"></fa-icon></button>
              </div>
            </ng-container>
          </div>
        </div>
      </form>
      <div class="col-md-12">
        <hr class="mt-4" style="width: 100%;">
      </div>

      <app-attachment-widget [createMode]="true" [includeAccept]="false" (create)="patchAttachment($event)"></app-attachment-widget>

      <app-note-widget [createMode]="true" [includeAccept]="false" (createNote)="patchNote($event)"></app-note-widget>

      <div class="col-md-12 btn-container">
        <app-btn-accept-widget
          [formGroup]="formGroup"
          (accept)="create()"
          (cancel)="cancel.emit()"
        ></app-btn-accept-widget>

        <hr class="mt-4" style="width: 100%;">
      </div>
    </div>
  </div>
</body>
