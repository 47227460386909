import {Component, Inject, OnInit} from '@angular/core';
import {SelectComponent} from "../select-component";
import {UserModel} from "../../../users/shared/user.model";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {UntypedFormBuilder, Validators} from "@angular/forms";
import {SharedService} from "../../../shared/services/shared.service";
import {CustomerService} from "../../../customers/shared/customer.service";
import {CustomerModel} from "../../../customers/shared/customer.model";

@Component({
  selector: 'app-customer-select',
  templateUrl: './customer-select.component.html',
  styleUrls: ['./customer-select.component.scss']
})
export class CustomerSelectComponent  extends SelectComponent<CustomerModel> implements OnInit {

  constructor(@Inject(NgbModal) modalService: NgbModal, @Inject(UntypedFormBuilder) formBuilder: UntypedFormBuilder,
              @Inject(SharedService) public sharedService: SharedService, private customerService: CustomerService) {

      super(modalService, formBuilder, sharedService);
      this.formGroup = this.formBuilder.group({
        customer: [null, Validators.required]
      });
    }

    ngOnInit(): void {
      this.customerService.getAll().subscribe(response => {
        this.data = response;
        this.assignCopy();
      });
      document.getElementById('btn')?.click();
      document.getElementById('search')?.focus();
    }

}
