<div *ngIf="id == 0">
  <h4 style="padding: 5px; margin-left: 10px;display: inline-flex;margin-top: 10px;">{{ 'vehicle.pickVehicle' | translate }}</h4>
  <a class="btn btn-sm btn-success" style="display: inline-flex; float: right; margin-top: 20px; margin-right: 20px;" (click)="emitAddVehicle()" title="{{ 'vehicle.addVehicle' | translate }}"><fa-icon [icon]="faPlus" style="color: white; cursor: pointer;"></fa-icon></a>
  <ul class="list-group" style="margin-top: 10px;">
    <li class="list-group-item" *ngFor="let vehic of vehicles" (click)="this.id = vehic.id; refresh();"> {{ getVehicleDisplayText(vehic) }}</li>
  </ul>
</div>

<div *ngIf="frontVehicle" [ngClass]="{'bg-light': !readOnly}">
  <div ngbAccordion #accordion="ngbAccordion" [closeOthers]="true">
    <div ngbAccordionItem [collapsed]="false">
      <h2 ngbAccordionHeader>
        <button ngbAccordionButton>{{'common.masterData' | translate}}</button>
      </h2>
      <div ngbAccordionCollapse>
        <div ngbAccordionBody [ngClass]="{'bg-light': !readOnly}">
          <ng-template>
            <form [formGroup]="formGroup" class="col-md-12">
              <div class=" form-group row">
                <div class="col-md-11" style="padding: 10px 0 0 0">
                  <app-equipment-widget [equipments]="formGroup.value.equipments" [createMode]="!readOnly"></app-equipment-widget>
                </div>
                <div class="col-md-1 text-end" style="padding: 10px 0 0 0">
                  <button type="button" class="btn btn-primary btn-sm" (click)="readOnly = ! readOnly;" placement="left" ngbTooltip="{{'btn.edit' | translate}}">
                    <fa-icon [icon]="faEdit"></fa-icon>
                  </button>
                </div>
                <div class="col-md-4 pe-0">
                  <label for="registrationNumber">{{'vehicle.reg' | translate}} </label>
                  <input id="registrationNumber" type="text" formControlName="registrationNumber" class="form-control form-control-sm" [readOnly]="readOnly">
                </div>
                <div class="col-md-4 pe-0">
                  <label for="chassisNumber">
                    {{'vehicle.chassis' | translate}}
                    <span *ngIf="formGroup.controls['chassisNumber'].hasError('pattern')" class="required-text">
                      {{'message.chassisnumberpattern' | translate }} <br>
                    </span>
                    <span *ngIf="formGroup.controls['chassisNumber'].hasError('whitespace')" class="required-text">
                      {{'message.nowhitespace' | translate }} <br>
                    </span>
                    <span *ngIf="formGroup.controls['chassisNumber'].hasError('maxlength')" class="required-text">
                      {{'message.maxLength' | translate: {number: 50} }}
                    </span>
                  </label>
                  <input id="chassisNumber" type="text" formControlName="chassisNumber" class="form-control form-control-sm" [readOnly]="readOnly">
                </div>
                <div class="col-md-4 pe-0">
                  <label>
                    {{'vehicle.type' | translate}}
                    <span *ngIf="formGroup.controls['typeName'].hasError('required')" class="required-text">
                      {{'message.required' | translate}}
                    </span>
                  </label>
                  <!-- <select type="text" formControlName="type" class="form-select form-select-sm" [attr.disabled]="readOnly ? '' : null">
                    <option *ngFor="let type of types" [value]="type">
                      {{frontVehicleService.getTranslateType(type) | translate}}
                    </option>
                  </select> -->
                  <app-combobox [inputValue]="this.frontVehicle.resourceType?.resourceTypeName!" [readonly]="readOnly" (selectedOption)="this.updateFormResourceType($event)" [options]="this.resourceTypes" [keyToSearch]="'resourceTypeName'"></app-combobox>
                </div>
                <div class="col-md-2 pe-0">
                  <label for="unitIdentification">
                    {{'vehicle.no' | translate}}
                    <span *ngIf="formGroup.controls['manufacturer'].hasError('maxlength')" class="required-text">
                      {{'message.maxLength' | translate: {number: 50} }}
                    </span>
                  </label>
                  <input id="unitIdentification" type="text" formControlName="unitIdentification" class="form-control form-control-sm" [readOnly]="readOnly">
                </div>
                <div class="col-md-6 pe-0">
                  <label for="manufacturer">{{'vehicle.manufacturer' | translate}} </label>
                  <input id="manufacturer" type="text" formControlName="manufacturer" class="form-control form-control-sm" [readOnly]="readOnly">
                </div>
                <div class="col-md-4 pe-0">
                  <label for="manufactureDate">{{'vehicle.manufactureDate' | translate}}</label>
                  <input id="manufactureDate" type="date" formControlName="manufactureDate" class="form-control form-control-sm" [readOnly]="readOnly">
                </div>
                <div class="col-md-8 pe-0">
                  <label for="construction">{{'vehicle.construction' | translate}} </label>
                  <input id="construction" type="text" formControlName="construction" class="form-control form-control-sm" [readOnly]="readOnly">
                </div>
                <div class="col-md-2 pe-0">
                  <label for="axleCount">
                    {{'vehicle.axles' | translate}}
                    <span *ngIf="formGroup.controls['axleCount'].hasError('required')" class="required-text">
                      {{'message.required' | translate}}
                    </span>
                  </label>
                  <input id="axleCount" type="text" formControlName="axleCount" class="form-control form-control-sm" [readOnly]="readOnly">
                </div>
                <div class="col-md-2 pe-0">
                  <label for="euroStandard">{{'vehicle.eu' | translate}} </label>
                  <input id="euroStandard" type="text" formControlName="euroStandard" class="form-control form-control-sm" [readOnly]="readOnly">
                </div>
                <div class="col-md-3 pe-0">
                  <label for="deadWeight">
                    {{'vehicle.deadWeight' | translate}}
                    <span *ngIf="formGroup.controls['deadWeight'].hasError('required')" class="required-text">
                      {{'message.required' | translate}}
                    </span>
                  </label>
                  <input id="deadWeight" type="text" formControlName="deadWeight" class="form-control form-control-sm" [readOnly]="readOnly">
                </div>
                <div class="col-md-3 pe-0">
                  <label for="totalWeight">
                    {{'vehicle.totalWeight' | translate}}
                    <span *ngIf="formGroup.controls['totalWeight'].hasError('required')" class="required-text">
                      {{'message.required' | translate}}
                    </span>
                  </label>
                  <input id="totalWeight" type="text" formControlName="totalWeight" class="form-control form-control-sm" [readOnly]="readOnly">
                </div>
                <div class="col-md-6 pe-0">
                  <label for="propellant">{{'vehicle.propellant' | translate}}</label>

                  <select [attr.disabled]="readOnly ? '' : null" id="propellantType" type="text" formControlName="propellantId" class="form-select form-select-sm">
                    <option *ngFor="let propellant of propellantTypes | async " [value]="propellant.id">
                      {{propellant.name}}
                    </option>
                  </select>
                </div>

                <!-- Auto calculated mileage and fuel -->
                <div class="col-md-3 pe-0">
                  <label for="timestampMileage">
                    {{'vehicle.timestampMileage' | translate}}
                    <span *ngIf="formGroup.controls['timestampMileage'].hasError('pattern')" class="required-text">
                      {{'message.timestampmileagepattern' | translate }} <br>
                    </span>
                  </label>
                  <input id="timestampMileage" type="text" formControlName="timestampMileage" class="form-control form-control-sm" [readOnly]="readOnly">
                </div>
                <div class="col-md-3 pe-0">
                  <label for="timestampFuel">
                    {{'vehicle.timestampFuel' | translate}}
                    <span *ngIf="formGroup.controls['timestampFuel'].hasError('pattern')" class="required-text">
                      {{'message.timestampfuelpattern' | translate }} <br>
                    </span>
                  </label>
                  <input id="timestampFuel" type="text" formControlName="timestampFuel" class="form-control form-control-sm" [readOnly]="readOnly">
                </div>
                <div class="col-md-2 pe-0">
                  <label for="autoTimestampCalculatedConsumption">{{'vehicle.autoTimestampCalculatedConsumption' | translate}}</label>
                  <input id="autoTimestampCalculatedConsumption" type="text" formControlName="autoTimestampCalculatedConsumption" class="form-control form-control-sm" [readOnly]="true">
                </div>
                <div class="col-md-4 pe-0">
                  <label for="timestampMileageDate">{{'vehicle.timestampMileageDate' | translate}}</label>
                  <input id="timestampMileageDate" type="date" formControlName="timestampMileageDate" class="form-control form-control-sm" [readOnly]="readOnly">
                </div>

                <!-- Real calculated mileage and fuel -->

                <div class="col-md-3 pe-0" *ngIf="isCalculated()">
                  <label for="lastKnownKilometerAge">{{'vehicle.lastKnownKilometerAge' | translate}}</label>
                  <input id="lastKnownKilometerAge" type="text" formControlName="lastKnownKilometerAge" class="form-control form-control-sm" [readOnly]="true">
                </div>
                <div class="col-md-3 pe-0" *ngIf="isCalculated()">
                  <label for="lastKnownFuel">{{'vehicle.lastKnownFuel' | translate}}</label>
                  <input id="lastKnownFuel" type="text" formControlName="lastKnownFuel" class="form-control form-control-sm" [readOnly]="true">
                </div>

                <div class="col-md-2 pe-0" *ngIf="isCalculated()">
                  <label for="autoCalculatedConsumption">{{'vehicle.autoCalculatedConsumption' | translate}}</label>
                  <input id="autoCalculatedConsumption" type="text" formControlName="autoCalculatedConsumption" class="form-control form-control-sm" [readOnly]="true">
                </div>

                <div class="col-md-12 pe-0">
                  <label for="description">{{'vehicle.description' | translate}} </label>
                  <textarea autosize id="description" type="text" formControlName="description" class="form-control form-control-sm" [readOnly]="readOnly"></textarea>
                </div>
              </div>
            </form>

            <div *ngIf="!readOnly" class="btn-container">
              <app-btn-accept-widget (cancel)="readOnly = true; patchFormValues()" [formGroup]="formGroup" (accept)="update()"></app-btn-accept-widget>
            </div>
          </ng-template>
        </div>
      </div>
    </div>
    <div ngbAccordionItem>
      <h2 ngbAccordionHeader>
        <button ngbAccordionButton>{{'toursBook.accordionTitle' | translate}}</button>
      </h2>
      <div ngbAccordionCollapse>
        <div ngbAccordionBody>
          <ng-template>
            <app-tours-book [selectedItemId]="id" [resourceType]="'FrontVehicle'"></app-tours-book>
          </ng-template>
        </div>
      </div>
    </div>
    <div ngbAccordionItem>
      <h2 ngbAccordionHeader>
        <button ngbAccordionButton>{{'logBook.accordionTitle' | translate}}</button>
      </h2>
      <div ngbAccordionCollapse>
        <div ngbAccordionBody>
          <ng-template>
            <app-log-book [selectedItemId]="id" [resourceType]="'FrontVehicle'"></app-log-book>
          </ng-template>
        </div>
      </div>
    </div>

    <div ngbAccordionItem>
      <h2 ngbAccordionHeader>
        <button ngbAccordionButton>{{'messaging.accordionTitle' | translate}}</button>
      </h2>
      <div ngbAccordionCollapse>
        <div ngbAccordionBody>
          <ng-template>
            <div class="p-5">
              <app-message-list [messageList]="this.messageList"></app-message-list>
            </div>
          </ng-template>
        </div>
      </div>
    </div>

    <div ngbAccordionItem #attachment="ngbAccordionItem">
      <h2 ngbAccordionHeader>
        <button ngbAccordionButton>{{'detail.subTitles.attachment' | translate}}
          <div class="plus-btn-right">
            <button class="btn btn-light btn-sm rounded-circle" placement="left" ngbTooltip="{{'btn.addAttachment' | translate}}"
              (click)="addNewAttachment($event, attachment)">
              <fa-icon [icon]="faAdd"></fa-icon>
            </button>
          </div>
        </button>
      </h2>
      <div ngbAccordionCollapse>
        <div ngbAccordionBody>
          <ng-template>
            <app-attachment-widget *ngIf="addAttachment" [createMode]="true" (cancelCreate)="addAttachment = false" (create)="createAttachment($event); addAttachment = false;"></app-attachment-widget>
            <p *ngIf="frontVehicle.attachments.length === 0" class="none-text" style="margin-left: 15px">{{'message.none' | translate}}</p>
            <ng-container *ngFor="let attachment of frontVehicle?.attachments; let i = index">
              <button *ngIf="!readOnly" class="close delete" style="margin: 0px 20px 0 0 " placement="left" ngbTooltip="{{'btn.deleteAttachment' | translate}}" (click)="deleteAttachment(attachment.id)">
                <span aria-hidden="true" class="text-white">&times;</span>
              </button>

              <div class="col-md-12 widget-margin">
                <app-attachment-widget (delete)="deleteAttachment($event)" [attachment]="attachment"></app-attachment-widget>
              </div>
            </ng-container>
          </ng-template>
        </div>
      </div>
    </div>
    <div ngbAccordionItem #status="ngbAccordionItem">
      <h2 ngbAccordionHeader>
        <button ngbAccordionButton>{{'resource.event.activities' | translate}}
          <div class="plus-btn-right">
            <button class="btn btn-light btn-sm rounded-circle" placement="left" ngbTooltip="{{'btn.addEvent' | translate}}"
              (click)="addNewStatus($event, status)">
              <fa-icon [icon]="faAdd"></fa-icon>
            </button>
          </div>
        </button>
      </h2>
      <div ngbAccordionCollapse>
        <div ngbAccordionBody>
          <ng-template>
            <app-resource-event-widget *ngIf="addEvent" [createMode]="true" (cancelCreate)="addEvent = false" (createEvent)="createEvent($event)"></app-resource-event-widget>
            <p *ngIf="frontVehicle.events?.length === 0" class="none-text" style="margin-left: 15px">{{'message.none' | translate}}</p>
            <ng-container *ngFor="let event of frontVehicle?.events; let i = index">
              <app-resource-event-widget [event]="event" (cancelEvent)="cancelEvent()" (updateEvent)="updateEvent($event)">
              </app-resource-event-widget>
              <hr *ngIf=" i + 1 < frontVehicle.events.length">
            </ng-container>
          </ng-template>
        </div>
      </div>
    </div>
    <div ngbAccordionItem #box="ngbAccordionItem" *ngIf="showBoxSetup">
      <h2 ngbAccordionHeader>
        <button ngbAccordionButton>{{'box.infoBtn' | translate}}
          <div class="plus-btn-right">
            <button class="btn btn-light btn-sm rounded-circle" placement="left" ngbTooltip="{{'box.add' | translate}}"
              (click)="addNewBox($event, box)" *ngIf="!frontVehicle.box">
              <fa-icon [icon]="faAdd"></fa-icon>
            </button>
          </div>
        </button>
      </h2>
      <div ngbAccordionCollapse>
        <div ngbAccordionBody>
          <ng-template>
            <!-- <app-box-info-widget *ngIf="addBox" [createMode]="true" (cancelCreate)="addBox = false" (createEvent)="createBoxEvent($event)"></app-box-info-widget> -->
            <div *ngIf="addBox" class="bg-light">
              <form [formGroup]="editFormGroup">
                <div class="col-md-6 none-padding-right">
                  <label for="boxSelect">
                    {{'box.box' | translate}}
                    <span *ngIf="editFormGroup.controls['boxSelect'].hasError('required')" class="required-text">
                      {{'message.required' | translate}}
                    </span>
                  </label>
                  <select id="boxSelect" type="text" formControlName="boxSelect" class="form-select form-select-sm">
                    <option *ngFor="let box of boxes | async" [ngValue]="box.id">
                      {{'box.info.uId'| translate}}::{{box.uid}}
                    </option>
                  </select>
                </div>
              </form>
              <div class="btn-container">
                <app-btn-accept-widget *ngIf="addBox" (cancel)="addBox = !addBox"
                  (accept)="updateBoxidOnFrontVehicle(); addBox = !addBox;" [formGroup]="editFormGroup">
                </app-btn-accept-widget>
              </div>
            </div>
            <p *ngIf="!frontVehicle.box" class="none-text ms-3">{{'message.none' | translate}}</p>
            <ng-container *ngIf="frontVehicle.box">
              <app-box-info-widget [box]="frontVehicle.box" [vehicleEquipment]="frontVehicle" (cancelEvent)="cancelEvent()" (removeEvent)="removeBox()"></app-box-info-widget>
            </ng-container>
          </ng-template>
        </div>
      </div>
    </div>

    <div ngbAccordionItem #eye="ngbAccordionItem" *ngIf="showBoxSetup">
      <h2 ngbAccordionHeader>
        <button ngbAccordionButton>{{'eye.title' | translate}}
          <div class="plus-btn-right">
            <button
              class="btn btn-light btn-sm rounded-circle"
              placement="left"
              ngbTooltip="{{'eye.add' | translate}}"
              (click)="addNewEye($event, eye)"
              *ngIf="!frontVehicle.eyeDevice"
            >
              <fa-icon [icon]="faAdd"></fa-icon>
            </button>
          </div>
        </button>
      </h2>
      <div ngbAccordionCollapse>
        <div ngbAccordionBody>
          <ng-template>
            <div *ngIf="addEye" class="bg-light">
              <form [formGroup]="editEyeFormGroup">
                <div class="col-md-6 none-padding-right">
                  <label for="eyeSelect">
                    {{'eye.select' | translate}}
                    <span *ngIf="editEyeFormGroup.controls['eyeSelect'].hasError('required')" class="required-text">
                      {{'message.required' | translate}}
                    </span>
                  </label>
                  <select id="eyeSelect" type="text" formControlName="eyeSelect" class="form-select form-select-sm">
                    <option *ngFor="let eye of eyes | async" [ngValue]="eye.id">
                      {{'eye.mac'| translate}}::{{eye.mac}}
                    </option>
                  </select>
                </div>
              </form>
              <div class="btn-container">
                <app-btn-accept-widget *ngIf="addEye" (cancel)="addEye = !addEye"
                  (accept)="updateEyeidOnFrontVehicle(); addEye = !addEye;" [formGroup]="editEyeFormGroup">
                </app-btn-accept-widget>
              </div>
            </div>
            <p *ngIf="!frontVehicle.eyeDevice" class="none-text ms-3">{{'message.none' | translate}}</p>
            <ng-container *ngIf="frontVehicle.eyeDevice">
              <app-eye-info-widget [eye]="frontVehicle.eyeDevice" (cancelEvent)="cancelEvent()" (removeEvent)="removeEye()"></app-eye-info-widget>
            </ng-container>
          </ng-template>
        </div>
      </div>
    </div>

    <div ngbAccordionItem>
      <h2 ngbAccordionHeader>
        <button ngbAccordionButton>{{'tacho.title' | translate}}</button>
      </h2>
      <div ngbAccordionCollapse>
        <div ngbAccordionBody>
          <ng-template>
            <!-- <app-box-info-widget *ngIf="addBox" [createMode]="true" (cancelCreate)="addBox = false" (createEvent)="createBoxEvent($event)"></app-box-info-widget> -->
            <p *ngIf="!frontVehicle.tacho" class="none-text" style="margin-left: 15px">{{'message.none' | translate}}</p>
            <ng-container *ngIf="frontVehicle.tacho">
              <app-tacho-info-widget [tacho]="frontVehicle.tacho" (cancelEvent)="cancelEvent()">
              </app-tacho-info-widget>
            </ng-container>
          </ng-template>
        </div>
      </div>
    </div>
  </div>
  <div class="col-md-12 center" *ngIf="!readOnly">
    <button class="btn btn-danger" (click)="delete()" placement="top" ngbTooltip="{{'btn.deleteVehicle' | translate}}">
      <fa-icon [icon]="faTrash"></fa-icon>
    </button>
  </div>
</div>
<app-loading class="overlay" *ngIf="loading"></app-loading>
