import { Injectable } from '@angular/core'
import { HttpClient } from "@angular/common/http"
import { environment } from 'src/environments/environment'
import { MessageContent } from './message-list.component'
import { Observable, of } from 'rxjs'


const url = environment.baseUrl + '/v1/Messages'


@Injectable({
  providedIn: 'root'
})
export class MessagingService {
  constructor(private http: HttpClient) { }

  /**
   * Gets the list of messages
   * @returns
   */
  getMessages(options: { userId?: number | null, frontVehicleId?: number | null }): Observable<MessageContent[]> {
    return this.http.post<MessageContent[]>(url + "/get", {
      userId: options.userId,
      frontVehicleId: options.frontVehicleId,
      amount: 20
    })
  }


  /**
   *
   * @param message the data that makes up the message
   * @returns
   */
  createMessage(message: MessageContent): Observable<any> {
    return this.http.post(url, {
      userId: message.userId,
      frontVehicleId: message.frontVehicleId,
      message: message.message,
      type: parseInt(message.type)
    })
  }


  /**
   * Update the read status of the message
   * @param id the id of the message to update
   * @returns
   */
  updateReadStatus(id: number) {
    return this.http.patch(`${url}/${id}`, { isRead: true })
  }
}
