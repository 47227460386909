export interface ReportList {
  Reports: ReportItems[];
}

export interface ReportItems {
  id: number;
  reportType: number;
  state: number;
  name: string;
  selected?: boolean;
  hasCsv: boolean;
}
export interface GlecBasicReport {

  Distance: number;
  Fuelconsumption: number;
  Date: Date;
  Drivers: any[];
}

export interface UserIdList {

  Ids: any[];
}
export enum ReportTypes {
  CreateTimeRegistrationReportCommand,
  /// <summary>
  /// ReportController->CreateSalaryReportAsHtml
  /// </summary>
  CreateSalaryReportCommand,
  /// <summary>
  /// ReportController->CreateGlecReportAsHtml
  /// </summary>
  CreateGlecReportCommand,
  /// <summary>
  /// ReportController->CreateGlecBasicReportAsHtml
  /// </summary>
  CreateGlecBasicReportCommand,
  /// <summary>
  /// ReportController->CreateGlecDistReportAsHtml
  /// </summary>
  CreateGlecDistReportCommand,
  /// <summary>
  /// ReportController->CreateTourReportAsHtml
  /// </summary>
  CreateTourReportCommand,
    /// <summary>
  /// ReportController->CreateDayReportAsHtml
  /// </summary>
  CreateDayReportCommand,
  /// <summary>
  /// ReportController->GeneratePdfFromString
  /// </summary>
  GeneratePdfReportCommand
}

export enum ReportState {
  Waiting,
  Failed,
  Succeeded
}
