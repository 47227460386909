import { DatePipe } from '@angular/common';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { faPencilAlt, faStop, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import * as moment from 'moment';
import { DateValidator } from 'src/app/form-validators/date-validator';
import { TimeValidator } from 'src/app/form-validators/time-validator';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { SharedService } from 'src/app/shared/services/shared.service';
import { WidgetComponent } from 'src/app/shared/widget-component';
import { JobModel, UpdateJobModel } from '../job.model';
import { JobService } from '../job.service';
import { FrontVehicleModel } from '../../front-vehicles/shared/front-vehicle.model';
import { FrontVehicleService } from '../../front-vehicles/shared/front-vehicle.service';
import { lastValueFrom } from 'rxjs';

@Component({
  selector: 'app-job-detail',
  templateUrl: './job-detail.component.html',
  styleUrls: ['./job-detail.component.scss'],
  providers: [DatePipe]
})
export class JobDetailComponent extends WidgetComponent {

  readOnly = true;
  formGroup: UntypedFormGroup;
  language: any;
  loading: boolean = false;
  job: JobModel | undefined;

  endJob = false;
  editRegistration: any;

  faEdit = faPencilAlt;
  faTrash = faTrashAlt;
  faStop = faStop;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private notifyService: NotificationService,
    private datePipe: DatePipe,
    private sharedService: SharedService,
    private jobService: JobService,
    private frontVehicleService: FrontVehicleService
  ) {
    super()

    this.formGroup = this.formBuilder.group({
      description: ['', [Validators.maxLength(255), Validators.required]],
      expectedStartDate: ['', Validators.required],
      expectedEndDate: ['', DateValidator('expectedStartDate')],
      expectedStartTime: '',
      expectedEndTime: ['', TimeValidator('expectedStartDate', 'expectedStartTime', 'expectedEndDate')],
      actualStartDate: '',
      actualEndDate: '',
      actualStartTime: '',
      actualEndTime: '',
      startKilometerAge: 0,
      startLatitude: 0,
      startLongitude: 0,
      endKilometerAge: 0,
      endLatitude: 0,
      endLongitude: 0,
      distance: 0,
      frontVehicleId: null,
      assignedUserId: null,
      type: [undefined, Validators.required]
    })
  }


  async getLanguage() {
    this.sharedService.getLanguageJson().subscribe(response => this.language = response)
  }


  async ngOnInit(): Promise<void> {
    await this.getLanguage()
    this.refresh();
  }

  refresh(): void {
    this.loading = true;
    this.jobService.getSingle(this.id).subscribe(response => {
      this.job = response;
      this.patchFormValues();
      this.loading = false;
      this.readOnly = true;
      this.editRegistration = false;
    });
  }

  patchFormValues(): void {
    if (!this.job) return;

    this.formGroup.patchValue({
      description: this.job.description,
      expectedStartDate: this.datePipe.transform(this.job.expectedStartDate, 'yyyy-MM-dd'),
      expectedEndDate: this.datePipe.transform(this.job.expectedEndDate, 'yyyy-MM-dd'),
      expectedStartTime: `${this.getFormattedTime(new Date(this.job.expectedStartDate).getHours())}:${this.getFormattedTime(new Date(this.job.expectedStartDate).getMinutes())}`,
      expectedEndTime: `${this.getFormattedTime(new Date(this.job.expectedEndDate).getHours())}:${this.getFormattedTime(new Date(this.job.expectedEndDate).getMinutes())}`,
      actualStartDate: this.datePipe.transform(this.job.actualStartDate, 'yyyy-MM-dd'),
      actualEndDate: this.datePipe.transform(this.job.actualEndDate, 'yyyy-MM-dd'),
      actualStartTime: `${this.getFormattedTime(new Date(this.job.actualStartDate).getHours())}:${this.getFormattedTime(new Date(this.job.actualStartDate).getMinutes())}`,
      actualEndTime: `${this.getFormattedTime(new Date(this.job.actualEndDate).getHours())}:${this.getFormattedTime(new Date(this.job.actualEndDate).getMinutes())}`,
      startKilometerAge: this.job.startKilometerAge,
      startLatitude: this.job.startLatitude,
      startLongitude: this.job.startLongitude,
      endKilometerAge: this.job.endKilometerAge,
      endLatitude: this.job.endLatitude,
      endLongitude: this.job.endLongitude,
      distance: this.getDistanceTravelled(),
      frontVehicleId: this.job?.frontVehicle?.id,
      assignedUserId: this.job?.assignedUser?.id,
      type: this.job.type,
    });
  }

  update(): void {
    this.loading = true;

    const values = this.formGroup.value;
    let model: UpdateJobModel | undefined

    model = {
      description: values.description,
      expectedStartDate: values.expectedStartDate ? new Date(values.expectedStartDate + ' ' + values.expectedStartTime) : null,
      expectedEndDate: values.expectedEndDate ? new Date(values.expectedEndDate + ' ' + values.expectedEndTime) : null,
      actualStartDate: values.actualStartDate ? new Date(values.actualStartDate + ' ' + values.actualStartTime) : null,
      actualEndDate: values.actualEndDate ? new Date(values.actualEndDate + ' ' + values.actualEndTime) : null,
      id: this.id
    }

    if (model) {
      this.jobService.update(this.id, model).subscribe(response => {
        this.notifyService.successMessage(this.language?.message?.updatedJob);
        this.refresh();
      }, error => this.loading = false)
    }
  }

  showEditStartDate(): any {
    return this.job?.actualStartDate !== null && this.readOnly;
  }

  showEditEndDate(): any {
    return this.job?.actualEndDate !== null && this.readOnly;
  }

  getDistanceTravelled(): number {
    if (this.job?.endKilometerAge != null && this.job?.endKilometerAge != 0) {
      return this.job.endKilometerAge - this.job.startKilometerAge;
    }
    return 0;
  }

  getFormattedTime(time: number): string {
    return ('0' + time).slice(-2);
  }

  translateType(): string {
    return `job.typesLower.${this.job?.type?.toLowerCase()}`;
  }

  cancel(): void {
    if (!this.job) return;
    this.endJob = !this.endJob;
  }

  cancelNoteDescription(model: any) {
    if (!this.job) return;

    const m = {
      cancelDescription: model.value,
      actualEndDate: moment(this.datePipe.transform(`${model.endDate} ${model.endTime}`, 'yyyy-MM-dd HH:mm')).toDate(),
    }

    lastValueFrom<any>(this.jobService.cancel(this.id, m)).then(_ => {
      this.refresh();
      this.updateList.emit();
      this.notifyService.warningMessage(this.language?.message?.canceledJob);
    })
      .catch(_ => {
        this.notifyService.errorMessage(this.language?.message?.canceledJobFailed);
      })
      .finally(() => this.readOnly = true)
    /* this.jobService.cancel(this.id, m).toPromise()
      .then(_ => {
        this.refresh();
        this.updateList.emit();
        this.notifyService.warningMessage(this.language?.message?.canceledJob);
      })
      .catch(_ => {
        this.notifyService.errorMessage(this.language?.message?.canceledJobFailed);
      })
      .finally(() => this.readOnly = true) */
  }

  getVehicleDisplayText(vehicle: FrontVehicleModel): string {
    return this.frontVehicleService.GetDisplayText(vehicle);
  }
}
