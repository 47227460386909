<div class="pb-3 row" style="margin-left: 0!important;">
    <!-- shadow-sm p-3 mb-5 bg-body rounded -->
    <div class="col-lg-5 col-12 search-bg report-search p-3">
        <span class="title-search">{{'reports.glec.title' | translate}}</span>
        <p class="sub-title-search">{{'reports.glec.introText' | translate}}</p>
        <form [formGroup]="formGroup">
            <div class="row">
                <div class="col-9">
                    <!-- <div class="col-lg-5 col-9"> -->
                    <label class="form-label">{{'reports.glec.calc.propellant' | translate}}
                        <span *ngIf="formGroup.controls['propellantId'].hasError('required')" class="required-text">{{'message.required' | translate}}</span>
                    </label>
                    <select formControlName="propellantId" class="form-select form-select-sm" (change)="updatePropellant($event)">
                        <option *ngFor="let propellant of propellants" [value]="propellant.id">{{propellant.name}}</option>
                    </select>
                </div>
                <div class="col-9">
                    <label class="form-label">{{'reports.glec.calc.averageConsumption' | translate}}
                        <span *ngIf="formGroup.controls['propellantAmount'].hasError('required')" class="required-text">{{'message.required' | translate}}</span>
                    </label>
                    <input formControlName="propellantAmount" type="text" class="form-control form-control-sm">
                </div>
                <div class="col-3">
                    <p *ngIf="propellantLabel" style="margin-top: 32px;text-transform: lowercase;">{{'reports.glec.calc.kmDash' | translate}}{{propellantLabel}}</p>
                </div>
            </div>
            <div class="row">
                <!-- <div class="col-12">
                    <label>Køretøj type</label>
                    <input type="text" [disabled]="true" class="form-control form-control-sm">
                </div> -->
                <div class="col-9">
                    <label>{{'reports.glec.calc.distanceIncludingIdling' | translate}}
                        <span *ngIf="formGroup.controls['distance'].hasError('required')" class="required-text">{{'message.required' | translate}}</span>
                    </label>
                    <input type="text" formControlName="distance" class="form-control form-control-sm">
                </div>
                <div class="col-3">
                    <p style="margin-top: 32px;text-transform: lowercase;">{{'reports.glec.calc.km' | translate}}</p>
                </div>
            </div>
            <div class="row">
                <div class="col-9">
                    <label>{{'reports.glec.calc.freightTypeBasedOn' | translate}}
                        <span *ngIf="formGroup.controls['volumeId'].hasError('required')" class="required-text">{{'message.required' | translate}}</span>
                    </label>
                    <select formControlName="volumeId" class="form-select form-select-sm" (change)="updateVolume($event)">
                        <option *ngFor="let volume of volumeTypes" [value]="volume.id">{{volume.name}}</option>
                    </select>
                </div>
                <div class="col-9">
                    <label>{{'reports.glec.calc.numberOfUnits' | translate}}
                        <span *ngIf="formGroup.controls['volumeAmount'].hasError('required')" class="required-text">{{'message.required' | translate}}</span>
                    </label>
                    <input formControlName="volumeAmount" type="text" class="form-control form-control-sm">
                </div>
                <div class="col-3">
                    <p *ngIf="volumeLabel" style="margin-top: 32px;text-transform: lowercase;">{{volumeLabel}}</p>
                </div>
            </div>
            <div class="row mt-3">
                <div class="col-2">
                    <button type="button" class="btn btn-sm btn-primary" (click)="reset($event)">
                        <fa-icon [icon]="faReset"></fa-icon>
                    </button>
                </div>
                <div class="col-7">
                    <button type="button" [disabled]="!formValid()" class="btn btn-sm btn-primary" style="width: 100%;" (click)="calculate()">
                        {{'reports.glec.createReportBtn' | translate}}
                    </button>
                </div>
                <!-- <div class="col-5">
                    <p style="padding: 5px;font-size: 1.3em;" *ngIf="co2result"><strong>Resultat = {{co2result}}</strong></p>
                </div> -->
            </div>
        </form>
    </div>
    <div class="col-lg-7 col-12 row">
        <!-- shadow-sm p-3 mb-5 bg-body rounded -->
        <div class="col-12" >
            <h2 style="color:#2E5261;margin-left: 50px;">{{'reports.glec.titleResult' | translate}}</h2>
            <div *ngIf="co2ResultTotal" style="display: inline-block;position: relative;top: 120px;">
                <p style="padding: 5px;font-size: 1em;margin-bottom: 0;">{{'reports.glec.co2PerUnit' | translate}} {{co2ResultPerUnit}}</p>
                <p style="padding: 5px;font-size: 1.2em;">{{'reports.glec.co2TotalTTW' | translate}} <strong> {{ co2ResultTotalTTW | number:'1.1-1' }} {{co2ResultUnit}}</strong></p>
                <p style="padding: 5px;font-size: 1.2em;">{{'reports.glec.co2TotalWTW' | translate}} <strong> {{ co2ResultTotalWTW | number:'1.1-1' }} {{co2ResultUnit}}</strong></p>
            </div>
        </div>
    </div>
</div>
