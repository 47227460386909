<ng-template #editcontent let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">{{'user.editTimeRegistration' | translate }}</h4>


    <!--
       <button type="button" class="close btnCheck " aria-label="Close" (click)="modal.dismiss('Cross click')">
         <span aria-hidden="true">&times;</span>
       </button>-->
    <div class="btn-group float-end" role="group">
      <div class="custom-loader" *ngIf="loading" style="margin-top: 11px;"></div>
      <button type="button" [disabled]="editTimeRegistrationFormGroup.controls['dateend'].invalid" *ngIf="showAcceptBtn == true" class="m-2 btnCheck btn " (click)="splitTimeReg()"></button>
      <button type="button"  class=" btnClose m-2 " (click)="modal.dismiss('Cross click'); showAcceptBtn = true;  loading = false;"></button>
    </div>
  </div>

  <div class="modal-body">
    <p>{{'user.endAndStartValuesTimeReg' | translate}}. {{'user.changesLogged' | translate}}.</p>

    <form [formGroup]="editTimeRegistrationFormGroup" class="col-md-12">

      <!-- <div class="form-group">
        <label for="date">{{'common.start'| translate}}</label>
        <div class="input-group">
          <input id="datestart" class="form-control" formControlName="datestart" type="date" [max]="today()">
          <input id="timestart" class="form-control" type="time" formControlName="timestart">
        </div>
      </div>

      <div class="form-group">
        <label for="date">{{'common.end'| translate}}</label>
        <div class="input-group">
          <input id="dateend" class="form-control" formControlName="dateend" type="date" [max]="today()">
          <input #time_end id="time_end" type="time" formControlName="timeend" class="form-control">
        </div>
        <span *ngIf="editTimeRegistrationFormGroup.controls['dateend'].hasError('mustBeLater')" class="required-text">
          {{'user.endLaterStart'| translate}}
        </span>
      </div>-->

      <div class="form-group">
        <table style="width: 100%; " class="sliderTable">
          <tbody>
            <tr>
              <td [ngStyle]="{'background-color': currentTourModalTableColor, 'width': currentTourModalTableWidth}">{{tourModalTdTitle1}}</td>
              <td [ngStyle]="{'background-color': newTourModalTableColor, 'width': newTourModalTableWidth}">{{tourModalTdTitle2}}</td>
            </tr>
          </tbody>
        </table>

        <div class="custom-slider sliderDiv" [ngStyle]="{'background-color': sliderTableBackground, 'background': sliderTableBackgroundGradiant}">
          <ngx-slider class="custom-slider"  [(value)]="minValueSlide" [options]="optionsSlide" ></ngx-slider>
        </div>
      </div>

      <div class="row">
        <!-- <div class="form-group col-md-6">
            <label for="currentTour">{{'user.currentAssignment' | translate}}
              <span *ngIf="editTimeRegistrationFormGroup.controls['currentTour'].hasError('required')" class="required-text">
          {{'message.required' | translate}}
        </span></label>
            <select id="currentTour"  type="text" formControlName="currentTour" class="form-select form-select-sm" (change)="onCurrentTourChange($event)">
              <option value="" disabled selected>{{'user.selectRequired'| translate}}</option>
              <option value="finished">{{'user.currentAssignmentTypes.finished' | translate}}</option>
              <option value="onHold">{{'user.currentAssignmentTypes.onHold' | translate}}</option>
              <option value="startLater">{{'user.currentAssignmentTypes.startLater' | translate}}</option>
              <option value="startEarlier">{{'user.currentAssignmentTypes.startEarlier' | translate}}</option>
            </select>
          </div>-->

        <div class="form-group col-md-6">
          <label for="currentTour">
            {{'user.assignmentFill' | translate}}
            <span *ngIf="editTimeRegistrationFormGroup.controls['currentTour'].hasError('required')" class="required-text">
              {{'message.required' | translate}}
            </span>
          </label>
          <select id="currentTour" [(ngModel)]="selectedCurrentTour" type="text" formControlName="currentTour" class="form-select form-select-sm" (change)="onCurrentTourChange($event)">

            <option *ngFor="let tour of availableAssignments" [value]="tour.id"> {{tour.name}}</option>
          </select>
          <br>
          <label for="currentTourTypeId">
            {{'user.assignmentFillTypeId' | translate}}
            <span *ngIf="editTimeRegistrationFormGroup.controls['currentTourTypeId'].hasError('required')" class="required-text">
              {{'message.required' | translate}}
            </span>
          </label>
          <select id="currentTourTypeId" [(ngModel)]="selectedCurrentTourTypeId" type="text" formControlName="currentTourTypeId" class="form-select form-select-sm" (change)="onCurrentTourTypeIdChange($event)">

            <option *ngFor="let typeId of availableTimeregTypes" [value]="typeId.id"> {{typeId.name}}</option>
          </select>
        </div>
        <div class="form-group col-md-6">
          <label for="availTour">
            {{'user.assignmentFill' | translate}}
            <span *ngIf="editTimeRegistrationFormGroup.controls['availTour'].hasError('required')" class="required-text">
              {{'message.required' | translate}}
            </span>
          </label>
          <select id="availTour" [(ngModel)]="selectedAvailTour" type="text" formControlName="availTour" class="form-select form-select-sm" (change)="onNewTourChange($event)">

            <option *ngFor="let tour of availableAssignments" [value]="tour.id"> {{tour.name}}</option>
          </select>
          <br>


        <label for="availTourTypeId">
          {{'user.assignmentFillTypeId' | translate}}
          <span *ngIf="editTimeRegistrationFormGroup.controls['availTourTypeId'].hasError('required')" class="required-text">{{'message.required' | translate}}</span>
        </label>

        <select id="availTourTypeId" [(ngModel)]="selectedAvailTourTypeId"  type="text" formControlName="availTourTypeId" class="form-select form-select-sm"  (change)="onNewTourTypeIdChange($event)">
          <option *ngFor="let typeId of availableTimeregTypes" [value]="typeId.id"> {{typeId.name}}</option></select>
        </div>
      </div>

      <div class="form-group">
        <label for="note">{{'user.note'| translate}}</label>
        <span *ngIf="editTimeRegistrationFormGroup.controls['note'].hasError('required')" class="required-text">{{'message.required' | translate}}</span>

        <div class="input-group">
          <input id="note" type="text" formControlName="note" placeholder="{{'user.noteText'| translate}}" class="form-control form-control-sm">
        </div>
      </div>
    </form>
  </div>
</ng-template>

<ng-template #content let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">{{'table.modal.title' | translate}}</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
  </div>
  <div class="modal-body">
    <p>{{'table.modal.description' | translate}}</p>
    <form>
      <div class="form-group">
        <label for="date">{{'table.modal.input.label' | translate}}</label>
        <div class="input-group">
          <input #date id="date" class="form-control" type="date" [max]="today()">
        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-default" (click)="modal.close(date.value)">{{'table.modal.okayBtn' | translate}}</button>
  </div>
</ng-template>


<div *ngIf="tours" class="container-fluid px-4">
  <app-table
    [data]="tableData"
    [showTemporaryAgToggle]="true"
    (temporaryToggleChange)="onToggleTempAg($event)"
    (addItem)="addNew = true; createRequestedStartDate = $event?.startDate"
    [calendarViewOption]="calenderView.resource"
    (selectedItem)="showItemDetails($event)"
    (requestData)="requestedRefreshedData($event)"
    [nestedTable]="false"
    [bookmarkOption]="true"
    [completedViewOption]="true"
    [multipleSelection]="true"
    [multipleSelectionButtons]="true"
    [modal]="content"
    (selectedItemsToRelease)="release($event)"
    (selectedItemsToPullBack)="pullBack($event)"
    (selectedItemsToCopy)="copy($event)"
    (selectedSubItem)="selectedSubItemId = $event.id"
    (createTourForItem)="createTour($event)"
    [showImport]="true"
    [serviceToUse]="'tour'"
    (eventClicked)="showEditTimeRegistrationModal(editcontent,$event)"
  ></app-table>

  <app-detail
    *ngIf="showDetails && selectedItemId > 0"
    class="overlay"
    (close)="closeItemDetails()"
  >
    <app-tour-detail [backVehicles]="backVehicles" [transportEquipment]="transportEquipment" [id]="selectedItemId" #widget [active]="true" (updateList)="getAll()" (close)="showDetails = false"></app-tour-detail>
  </app-detail>

  <app-detail
    *ngIf="addNew"
    class="overlay"
    (close)="addNew = false"
    [closeByBackdrop]="false"
  >
    <app-tour-create
      #widget
      (cancel)="addNew = false"
      (created)="addNew = false; getAll()"
      [startDate]="createRequestedStartDate"
      [backVehicles]="backVehicles"
      [transportEquipment]="transportEquipment"
    ></app-tour-create>
  </app-detail>

  <app-detail
    *ngIf="selectedSubItemId > 0"
    class="overlay"
    (close)="selectedSubItemId = 0"
  >
    <app-tour-detail [backVehicles]="backVehicles" [transportEquipment]="transportEquipment" [id]="selectedSubItemId" #widget [active]="true" (close)="selectedSubItemId = 0"></app-tour-detail>
  </app-detail>
  <!-- <app-detail
  *ngIf="createNewTour"
  class="overlay"
  (close)="createNewTour = undefined"
  [closeByBackdrop]="false">
  <app-tour-create #widget
                   (close)="createNewTour = undefined"
                   (cancel)="createNewTour = undefined"
                   (created)="createNewTour = undefined; getAll()"
                   [startDate]="createNewTour.event.startDate"
                   [selectedFrontVehicle]="createNewTour.frontVehicle" [backVehicles]="backVehicles" [transportEquipment]="transportEquipment"></app-tour-create>
  </app-detail> -->
  <app-detail
    *ngIf="createNewTour"
    class="overlay"
    (close)="createNewTour = undefined"
    [closeByBackdrop]="false"
  >
    <app-tour-create #widget
      (close)="createNewTour = undefined"
      (cancel)="createNewTour = undefined"
      (created)="createNewTour = undefined; getAll()"
      [startDate]="createNewTour.event.startDate"
      [selectedUser]="createNewTour.user"
      [backVehicles]="backVehicles"
      [transportEquipment]="transportEquipment"
    ></app-tour-create>
  </app-detail>
</div>

<app-product-type-picker #productTypePicker (dataEmit)="this.emitProductTypeData($event)"></app-product-type-picker>

<app-loading *ngIf="!tours"></app-loading>
