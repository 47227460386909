import { FrontVehicleEventModel, FrontVehicleModel } from "../../front-vehicles/shared/front-vehicle.model";
import { TransportEquipmentModel } from "../../transport-equipments/shared/transport-equipment.model";
import { BackVehicleModel } from "../../back-vehicles/shared/back-vehicle.model";
import { TableTourModel, TourSimpleModel } from "../../tours/shared/tour.model";
import { PermissionType } from "../../constants";
import { JobModel } from "../../jobs/job.model";
import { DeliveryRequirement } from "src/app/freight-types/shared/freight-types.model";

export interface UserModel
{
  id: number;
  role: string;
  firstname: string;
  lastname: string;
  email: string;
  mobile: string;
  isActive: boolean;
  userMeta: UserMetaModel;
  status: string;
  tours: TourSimpleModel[] ;
  sumOfWorkingHours: number;
  timeRegistrations: UserTimeRegistrationModel[];
  sumOfTimeRegistrations: number;
  permission: string;
  events: UserEventModel[];
  jobs: JobModel[];
  restHours: number;
  driversLicenseNumber?: string;
  driversLicenseNumberExpires?: Date;
  subtractExportBreaks?: boolean;
  subtractInlandBreaks?: boolean;
  isAutogenerated?: boolean;
  deliveryRequirements: DeliveryRequirement[] | [];
  deliveryRequirementIds: string;
}

export interface UserResourceModel
{
  userId: number;
  name: string;
  tours: TourSimpleModel[];
  totalWorkTimeHours: number;
  timeRegistrations: UserTimeRegistrationModel[];
  totalTimeRegistrationHours: number;
  events: UserEventModel[];
  totalRestHours: number;
}

export interface UserEventModel
{
  id: number;
  title: string;
  description: string;
  startDate: Date;
  endDate: Date;
  type: string;
  typeKey: string;
  typeId: number;
  allDay: boolean;
}

export interface UpdateUserEventModel
{
  id: number;
  title: string;
  description: string;
  startDate: Date;
  endDate: Date;
  userEventTypeId: number;
}
export interface CreateUserEventModel
{
  title: string;
  description: string;
  startDate: Date;
  endDate: Date;
  userEventTypeId: number;
}

export enum EventTypeEnum
{
  BusinessRelated,
  Holidays,
  Personal,
}

export interface UserStartTimeRegistrationModel
{
  startLatitude: number;
  startLongitude: number;
  time: Date;
  tourRelationId: number;
}

export interface UserTimeRegistrationModel
{
  id: number;
  timestamp: Date;
  startTime: Date;
  startLatitude: number;
  startLongitude: number;
  endTime: Date;
  endLatitude: number;
  endLongitude: number;
  tourId: number;
  isAbroad: boolean;
  timeRegistrationTypeId: number;
}

export interface UserWorkWeeksModel
{
  userId: number;
  userName: string;
  salaryWorkWeeks: salaryWorkWeeksModel[];
  transferedSalaryWorkWeeks: salaryWorkWeeksModel[];
}

export interface salaryWorkWeeksModel
{
  week: salaryWorkWeekModel;
  workDays: salaryWorkDayModel[];
  kilometersDrivenThisWeek: number;
  kilometersDrivenInsideCompanyCountry: number;
  kilometersDrivenOutsideCompanyCountry: number;
  kilometerOnExportTours: number;
  kilometerOnInlandTours: number;
  nonAgreedLines: [];
  supplements: [];

}

export interface salaryWorkWeekModel
{
  weekNumber: number;
  startDate: Date;
  endDate: Date;

}
export interface salaryWorkDayModel
{
  day: number;
  date: Date;
  workTimeTotals: salaryWorkTimeTotalsModel[];
  distanceTotal: number;
  distanceTotalExport: number;
  totalBreaktime: number;
  worktime: salaryWorkTimeModel[];
  isHolliday: boolean;
  isSunday: boolean;
}
export interface salaryWorkTimeModel
{
  colorHexCode: string;
  fromTime: string;
  minutes: number;
  toTime: string;
  workTimeName: string;
  worktimeTypeId: number;
}
export interface salaryWorkDaySumModel
{
  name: string;
  sumHours: number;
  colorCode: string;
  restTime: number;
  SumHoursOrig: number;
  date: Date;
}

export interface salaryWorkWeekSumModel
{
  weekNumber: number;
  weekKmDriven: number;
  workDaySum: salaryWorkDaySumModel[];
  startDate: Date;
  endDate: Date;
  weekKmDrivenInland: number;
  weekKmDrivenExport: number;
}

export interface activitiesModel
{
  allDay: boolean | null;
  description: string | null;
  id: number | null;
  startDate: Date | null;
  endDate: Date | null;
  timestamp: number | null;
  title: string | null;
  type: any;
  typeId: number | null;
  typeKey: any;


}


export interface salaryWorkTimeTotalsModel
{
  workTimeId: number;
  workTimeName: string;
  workTimeCode: any;
  workTimeHexColor: string;
  minutes: number;
  breakTime: number;
  minutesSubtractedBreakTime: number;
  isPaidWork: boolean;
  givesSupplement: boolean;


}

export interface UpdateUserTimeRegistrationModel
{
  id: number;
  startTime: Date;
  endTime: Date | null;
}

export interface TableUserModel
{
  id: number;
  name: string;
  email: string;
  mobile: string;
  status: string;
  nested: any[];
}

export interface GroupModel
{

  guid: string;
  name: string;
}

export interface CompanyModel
{

  guid: string;
  name: string;
  isAdmin: boolean;
  isMember: boolean;
}

export interface UserMetaModel
{
  id: number;
  timestamp: Date;
  salaryNumber: string;
  salaryNumberHomeCountry: string;
  cprNumber: string;
  title: string;
  hoursAgreement: string;
  startDate: Date;
  skilledDate: Date;
  frontVehicle: FrontVehicleModel;
  transportEquipment: TransportEquipmentModel;
  backVehicles: BackVehicleModel[];
  country: string;
  state: string;
  zipcode: string;
  city: string;
  street: string;
  resignationDate: Date;
  inlandAgreement: string;
  exportAgreement: string;
  inlandAgreementId: number;
  exportAgreementId: number;
  events: UserEventModel[];
  overrideSkilled: boolean;
  onlyPull: boolean;
  deliveryRequirements: DeliveryRequirement[] | [];
  deliveryRequirementIds: string;
}

export interface UpdateUserMetaModel
{
  salaryNumber: string;
  salaryNumberHomeCountry: string;
  cprNumber: string;
  title: string;
  startDate?: Date;
  skilledDate?: Date;
  frontVehicleId: number;
  transportEquipmentId: number;
  backVehicleIds: number[];
  country: string;
  state: string;
  zipcode: string;
  city: string;
  street: string;
  permission: string;
  resignationDate?: Date;
  agreementInlandId: number;
  agreementExportId: number;
  overrideSkilled: boolean;
  onlyPull: boolean;
  deliveryRequirementIds: string;
}

export interface CreateUserMetaModel
{
  salaryNumber: string;
  salaryNumberHomeCountry: string;
  cprNumber: string;
  title: string;
  startDate?: Date;
  skilledDate?: Date;
  frontVehicleId: number;
  transportEquipmentId: number;
  backVehicleIds: number[];
  country: string;
  state: string;
  zipcode: string;
  city: string;
  street: string;
  permission: string;
  agreementInlandId: number;
  agreementExportId: number;
  overrideSkilled: boolean;
  onlyPull: boolean;
  deliveryRequirementIds: string;
}

export interface SplitTimeRegModel
{
  StartTime: string;
  EndTime: string;
  SplitTime: string;
  FirstTourId: string;
  LastTourId: string;
  FirstTimeRegistrationType_Id: string;
  LastTimeRegistrationType_Id: string;
  Comment: string;
}



