<div class="d-flex flex-nowrap form-check align-content-center ps-0">
  <label class="switch mt-0">
    <input
      [(ngModel)]="this.isChecked"
      [checked]="this.isChecked"
      [disabled]="this.disabled"
      [ngClass]="{ 'fullcolor': this.fullColor}"
      type="checkbox"
      data-size="sm"
      data-toggle="toggle"
      data-on="&nbsp;"
      data-off="&nbsp;"
      (change)="this.toggleSwitch()"
    >
    <span class="slider round"></span>
  </label>

  <label class="d-flex form-check-label align-self-center mt-0">&nbsp; {{ this.label }}</label>
</div>
