import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TableComponent } from "./table/table.component";
import { DetailComponent } from './detail/detail.component';
import { AddressWidgetComponent } from './address-widget/address-widget.component';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { TagComponent } from './tag/tag.component';
import { HttpClientModule } from "@angular/common/http";
import { ContactWidgetComponent } from './contact-widget/contact-widget.component';
import { NoteWidgetComponent } from './note-widget/note-widget.component';
import { AutosizeModule } from 'ngx-autosize';
import { BtnAcceptWidgetComponent } from './btn-accept-widget/btn-accept-widget.component';
import { NgbdSortableHeader } from "./table/sortable.directive";
import { AttachmentWidgetComponent } from './attachment-widget/attachment-widget.component';
import { EquipmentWidgetComponent } from './equipment-widget/equipment-widget.component';
import { LoadingComponent } from './loading/loading.component';
import { CalendarComponent } from './calendar/calendar.component';
import { FullCalendarModule } from "@fullcalendar/angular";
/* import interactionPlugin from '@fullcalendar/interaction';
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import resourceTimelinePlugin from '@fullcalendar/resource-timeline';
import resourceTimeGridPlugin from '@fullcalendar/resource-timegrid';
import listPlugin from '@fullcalendar/list';
import bootstrapPlugin from '@fullcalendar/bootstrap'; */
/* import '@fortawesome/fontawesome-free/css/all.css'; */
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { TranslateModule } from "@ngx-translate/core";
import { CalendarService } from "./calendar/shared/calendar.service";
import { ResourceCalendarComponent } from './resource-calendar/resource-calendar.component';
import { TimeStatementComponent } from './time-statement/time-statement.component';
import { TimeTableComponent } from './time-table/time-table.component';
import { UserEventWidgetComponent } from './user-event-widget/user-event-widget.component';
import { TimeRegistrationsTableComponent } from './time-registrations-table/time-registrations-table.component';
import { BackvehicleEventWidgetComponent } from './resource-event-widget/resource-event-widget.component';
import { NoteDateWidgetComponent } from './note-date-widget/note-widget.component';
import { BoxInfoWidgetComponent } from './box-info-widget/box-info-widget.component';
import { FileuploaderComponent } from './fileuploader/fileuploader.component';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { GlecCalculatorComponent } from './glec-calculator/glec-calculator.component';
import { TachoInfoWidgetComponent } from './tacho-info-widget/tacho-info-widget.component';
import { ReportTableComponent } from './table/report-table/report-table.component';
import { LogBookComponent } from './log-book/log-book.component';
import { CardComponent } from '../molecules/card/card.component';
import { ReusableTableComponent } from '../molecules/reusable-table/reusable-table.component';
import { CheckboxComponent } from '../atoms/checkbox/checkbox.component';
import { ButtonComponent } from '../atoms/button/button.component';
import { ConfirmationActionComponent } from '../molecules/confirmation-action/confirmation-action.component';
import { DropdownButtonComponent } from '../molecules/dropdown-button/dropdown-button.component';
import { SearchInputComponent } from '../molecules/search-input/search-input.component';
import { FilterButtonComponent } from '../molecules/filter-button/filter-button.component'
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { ToursBookComponent } from './tours-book/tours-book.component';
import { ReportsModule } from '../reports/reports.module';
import { ReportDownloadBtnComponent } from './report-download-btn/report-download-btn.component';
import { EyeInfoWidgetComponent } from './eye-info-widget/eye-info-widget.component';
import { ModalComponent } from '../molecules/modal/modal.component'
import { SwitchComponent } from '../atoms/switch/switch.component';
import { CreateMessageComponent } from './create-message/create-message.component';
import { MessageListComponent } from '../molecules/message-list/message-list.component';
import { ComboboxComponent } from '../molecules/combobox/combobox.component'
import { ClickOutsideDirective } from './directives/click-outside.directive'
import { TomtomMapPickerComponent } from '../tomtom-map-picker/tomtom-map-picker.component';
import { AgreedManagementService } from './services/agreed-management.service';
import { ProductTypePickerComponent } from '../molecules/product-type-picker/product-type-picker.component'
import { SvgIconComponent } from './svg-icon/svg-icon.component';

/* FullCalendarModule.registerPlugins([
  resourceTimelinePlugin,
  resourceTimeGridPlugin,
  bootstrapPlugin,
  interactionPlugin,
  dayGridPlugin,
  timeGridPlugin,
  listPlugin
]); */


@NgModule({
  declarations: [
    TableComponent,
    DetailComponent,
    AddressWidgetComponent,
    TagComponent,
    ContactWidgetComponent,
    NoteWidgetComponent,
    ReportDownloadBtnComponent,
    BtnAcceptWidgetComponent,
    NgbdSortableHeader,
    AttachmentWidgetComponent,
    EquipmentWidgetComponent,
    CalendarComponent,
    LoadingComponent,
    ResourceCalendarComponent,
    TimeStatementComponent,
    TimeTableComponent,
    UserEventWidgetComponent,
    TimeRegistrationsTableComponent,
    BackvehicleEventWidgetComponent,
    NoteDateWidgetComponent,
    BoxInfoWidgetComponent,
    EyeInfoWidgetComponent,
    FileuploaderComponent,
    GlecCalculatorComponent,
    TachoInfoWidgetComponent,
    ReportTableComponent,
    LogBookComponent,
    ToursBookComponent,
    CardComponent,
    ReusableTableComponent,
    CheckboxComponent,
    ButtonComponent,
    ConfirmationActionComponent,
    SearchInputComponent,
    ComboboxComponent,
    ClickOutsideDirective,
    SwitchComponent,
    CreateMessageComponent,
    MessageListComponent,
    SvgIconComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FontAwesomeModule,
    HttpClientModule,
    FormsModule,
    AutosizeModule,
    FullCalendarModule,
    NgbModule,
    TranslateModule,
    NgxDropzoneModule,
    DropdownButtonComponent,
    FilterButtonComponent,
    NgMultiSelectDropDownModule.forRoot(),
    TomtomMapPickerComponent,
    ProductTypePickerComponent,
    ModalComponent
  ],
  exports: [
    TableComponent,
    ReportTableComponent,
    DetailComponent,
    AddressWidgetComponent,
    TagComponent,
    ContactWidgetComponent,
    NoteWidgetComponent,
    ReportDownloadBtnComponent,
    BtnAcceptWidgetComponent,
    AttachmentWidgetComponent,
    EquipmentWidgetComponent,
    LoadingComponent,
    TranslateModule,
    TimeStatementComponent,
    TimeTableComponent,
    UserEventWidgetComponent,
    TimeRegistrationsTableComponent,
    BackvehicleEventWidgetComponent,
    NoteDateWidgetComponent,
    BoxInfoWidgetComponent,
    EyeInfoWidgetComponent,
    GlecCalculatorComponent,
    TachoInfoWidgetComponent,
    LogBookComponent,
    ToursBookComponent,
    CardComponent,
    ReusableTableComponent,
    CheckboxComponent,
    ButtonComponent,
    ConfirmationActionComponent,
    DropdownButtonComponent,
    SearchInputComponent,
    ComboboxComponent,
    ClickOutsideDirective,
    ModalComponent,
    SwitchComponent,
    CreateMessageComponent,
    MessageListComponent,
    TomtomMapPickerComponent,
    ProductTypePickerComponent,
    SvgIconComponent
  ]
})

export class SharedModule { }
