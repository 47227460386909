import { UserModel } from "src/app/users/shared/user.model";

export interface Product {
  id: number;
  isDefault: boolean;
  name: string;
  description?: string;
  freightUnit?: FreightUnit[];
  priceIndexId: number;
  deliveryRequirements?: DeliveryRequirement[];
  billingTypes?: BillingType[];
  productGroups?: Group[];
  deliveryDocumentRequirements?: DeliveryDocumentRequirement[];
  idtf?: number;
  active: boolean;
  category?: number;
  categoryId?: number;
  mainCategory: boolean;
  createdById: number;
  createdBy?: UserModel;
}

export interface CreateProduct {
  isDefault: boolean;
  name: string;
  description?: string;
  freightUnit?: number[];
  priceIndexId: number;
  deliveryRequirementIds?: number[];
  billingTypeIds?: number[];
  productGroupIds?: number[];
  deliveryDocumentRequirementIds?: number[];
  idtf?: number;
  categoryId?: number;
  category?: string;
  mainCategory: boolean;
  active: boolean;
}

export interface UpdateProduct {
  id: number;
  isDefault: boolean;
  name: string;
  description?: string;
  freightUnit?: number[];
  priceIndexId: number;
  deliveryRequirementIds?: number[];
  billingTypeIds?: number[];
  productGroupIds?: number[];
  deliveryDocumentRequirementIds?: number[];
  idtf?: number;
  categoryId?: number;
  category?: string;
  mainCategory: boolean;
  active: boolean;
}

export interface DeliveryRequirement {
  id: number;
  name: string;
  isDefault: boolean;
}

export interface CreateDeliveryRequirement {
  isDefault: boolean;
  name: string;
}

export interface UpdateDeliveryRequirement {
  id: number;
  isDefault: boolean;
  name: string;
}

export interface Group {
  id: number;
  name: string;
  productTypes?: Product[];
}

export interface CreateGroup {
  name: string;
}

export interface UpdateGroup {
  id: number;
  name: string;
}

export interface BillingType {
  id: number;
  name: string;
}

export interface FreightUnit {
  id: number;
  name: string;
}

export interface ItemNumber {
  idtf: number;
}

export interface DeliveryDocumentRequirement {
  id: number;
  isDefault: boolean;
  name: string;
  productTypes?: Product[];
}
