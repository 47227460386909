import {FrontVehicleModel} from "../../front-vehicles/shared/front-vehicle.model";
import {BackVehicleModel} from "../../back-vehicles/shared/back-vehicle.model";
import {TransportEquipmentModel} from "../../transport-equipments/shared/transport-equipment.model";
import {CustomerModel} from "../../customers/shared/customer.model";
import {TagModel} from "../../shared/tag/shared/tag.model";
import {UserModel} from "../../users/shared/user.model";
import {AttachmentModel} from "../../shared/attachment-widget/shared/attachment.model";
import {NoteModel} from "../../shared/note-widget/shared/note.model";
import {EventModel} from "../../shared/calendar/shared/event.model";
import { DrivingRecordModel, TimeRegistrationModel } from "src/app/shared/time-registrations-table/shared/timeregistration";
import { ContactModel } from "src/app/shared/contact-widget/shared/contact.model";

export interface TourModel {
  id: number;
  timestamp: Date;
  taskId: string;
  tripRecordId: number;
  tripType: number;
  driver1Name: string;
  driver2Name: string;
  distanceTaxed: number;
  distanceTaxedEnv: number;
  description: string;
  fuelused: number;
  kmtotal: number;
  expectedStartDate: Date;
  expectedEndDate: Date;
  actualStartDate: Date;
  actualEndDate: Date;
  freight: string;
  freightTypeId: number;
  freightTotalAmount: number;
  startKilometerAge: number;
  startLatitude: number;
  startLongitude: number;
  endKilometerAge: number;
  endLatitude: number;
  endLongitude: number;
  frontVehicle: FrontVehicleModel;
  backVehicles: BackVehicleModel[];
  transportEquipment: TransportEquipmentModel;
  customer: CustomerModel;
  assignedUser: UserModel;
  assignedSecondUser: UserModel;
  tags: TagModel[];
  attachments: AttachmentModel[];
  notes: NoteModel[];
  status: string;
  adBlue: number;
  fuel: number;
  events: TourEventModel[];
  addresses: TourAddressModel[];
  type: string;
  isSpecial: boolean;
  hasBeenSeen: boolean;
  seenDate: Date | null;
  wrongVehicle: boolean;
  released: boolean;
  assignedUserTimeRegistrationId: number | null;
  drivingRecords: DrivingRecordModel[];
  timeRegistrations: TimeRegistrationModel[];
  isLocked: boolean;
  hasFreightOnAddress: boolean;
  calculatedTotalFreightAmount: number;
  orderId: string;
  tourFreights?: TourFreight[];
}


export interface TourFreight {
  productTypeId: number;
  type: AddressFreightType;
  surcharges: number[];
  priceUnitId: number;
  amount: number;
  freightUnit: string;
  freightUnitId: number;
}

export enum AddressFreightType {
  Delivery = 1,
  Pickup = 2
}

export interface TourSimpleModel {
  id: number;
  timestamp?: Date;
  description: string;
  expectedStartDate: Date;
  expectedEndDate: Date;
  actualStartDate: Date | null;
  actualEndDate: Date | null;
  status: string;
  tags: TagModel[];
  type: string;
  isSpecial: boolean;
  startKilometerAge: number;
  endKilometerAge: number;
  events: TourEventModel[];
  isLocked: boolean;
  hasFreightOnAddress: boolean;
  calculatedTotalFreightAmount: number;
  orderId: string;
}

export interface TableTourModel {
  id: number;
  tourDescription: string;
  frontVehicle: string;
  backVehicles: string;
  chauffeur: string;
  name: string;
  freight: string;
  expectedStart?: string;
  actualStart?: string;
  expectedEnd?: string;
  actualEnd?: string;
  status: string;
  hasBeenSeen: boolean;
  released: boolean;
  //isLocked: boolean;

  isAutoGeneratedUserWithTimeRegs: boolean;
}


export interface TourAddressModel {

  id: number;
  country: string;
  state: string;
  zipcode: string;
  city: string;
  street: string;
  latitude: number;
  longitude: number;
  priority: number;
  additionalInformation: string;
  description: string;
  hasArrived: boolean;
  arrivedDate: Date | null;
  locationName: string;
  locationId: string;
  hasLeft: boolean;
  freight: string;
  freightAmount: number;
  removedFreightAmount: number;
  removedFreightType?: string;
  freightTypeId: number;
  isDelivery: boolean;
  isPickup: boolean;
  arriveMileage: number;
  leaveMileage: number;
  type: string;
  customerId: number;
  orderId: string;
  arriveBackVehicle: string;
  leaveBackVehicle: string;
  arriveEquipment: string;
  leaveEquipment: string;
  arriveBackVehicles: BackVehicleModel[];
  leaveBackVehicles: BackVehicleModel[];
  notes: [];
  attachments: [];
  contact: any;
  addressFreights?: TourFreight[];
  addressDeliveryDocumentRequirements: any[];
}

export interface CreateTourModel {
  description: string;
  freight: string;
  freightTypeId: number;
  freightTotalAmount: number;
  expectedStartDate: Date | null;
  expectedEndDate: Date | null;
  frontVehicleId: number;
  backVehicleIds: number[];
  transportEquipmentId: number;
  assignedUserId: number;
  assignedSecondUserId: number;
  customerId: number;
  tagIds: number[];
  note: NoteModel,
  addresses: CreateTourAddressModel[];
  TourType: string;
  isSpecial: boolean;
  isLocked: boolean;
  orderId: string;
  tourFreight: TourFreight[];
}

export interface CreateRangeTourModel {
  models: CreateTourModel[];
}

export interface CreateTourAddressModel {
  country: string;
  state: string;
  zipcode: string;
  city: string;
  street: string;
  latitude: number;
  longitude: number;
  priority: number;
  description: string;
  locationName: string;
  locationId: string;
  freight: string;
  freightTypeId: number;
  removedFreightAmount: number;
  removedFreightType?: string;
  freightAmount: number;
  orderId: string;
  arriveBackVehicle: string;
  leaveBackVehicle: string;
  arriveEquipment: string;
  leaveEquipment: string;
  contact: ContactModel;
  addressFreights?: TourFreight[];
  addressDeliveryDocumentRequirements: any[];
}


export interface UpdateTourModel {
  description: string;
  freight: string;
  freightTypeId: number;
  freightTotalAmount: number;
  expectedStartDate: Date | null;
  expectedEndDate: Date | null;
  frontVehicleId: number;
  backVehicleIds: number[];
  transportEquipmentId: number;
  assignedUserId: number;
  customerId: number;
  tagIds: number[];
  TourType: string;
  isSpecial: boolean;
  addresses: TourAddressModel[];
  actualStartDate: Date | null;
  actualEndDate: Date | null;
  isLocked: boolean;
  orderId: string;
  contact: ContactModel;
  contactId: number;
  tourFreights?: TourFreight[];
}

export interface StartTourModel {
  startTime: Date | null;
  mileage: number;
  fuelConsumption: number | null;
  adBlueConsumption: number | null;
  latitude: number;
  longitude: number;
  comment: string;
  createDrivingRecord: boolean;
  countryCode: string;
  userId: number;
}

export interface TourEventModel {
  id: number;
  timestamp: Date;
  type: string;
  description: string;
  startKilometerAge: number;
  actualStartDate: Date;
  startLatitude: number;
  startLongitude: number;
  endKilometerAge: number;
  actualEndDate: Date;
  endLatitude: number;
  endLongitude: number;
  frontVehicleId: number;
}

export interface CopyTourModel{
  ids: number[];
  startDate: Date;
  endDate: Date;
}

export interface CancelTourModel{
  cancelDescription: string
}
