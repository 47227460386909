<div class="card card--component elevated--medium w-100" [ngClass]="{ 'edit': this.edit, 'selected': this.data.selected }">
  <div class="card-header card--component-header">
    <div class="row flex-nowrap">
      <div class="col">
        <!-- @if (!this.edit) {
          <span class="card--component-favourite z-3">
            <button
              class="star"
              [ngClass]="this.data?.favourite ? 'active' : ''"
              (click)="favourite()"
              #favouriteButton
            ></button>
          </span>
        } -->
      </div>

      <div class="col-8" style="min-height: 25px;">
        <!-- @for (label of this.data?.tags; track $index) {
          <span class="badge text-bg-light me-1 pt-1 pb-1 ps-2 pe-2">
            {{ label }}
          </span>

          <span (click)="addLabel()">Add</span>
        } -->
          &nbsp;
      </div>

      <div class="col-3 z-3">
        <div class="text-end position-relative">
          @if (this.edit) {
            <div class="d-flex action-buttons">
              <span class="save-icon me-1" (click)="save()">
                <fa-icon [icon]="this.faCheckCircle" [size]="'2xl'"></fa-icon>
              </span>
              <span class="cancel-icon" (click)="cancel()">
                <fa-icon [icon]="this.faCircleXmark" [size]="'2xl'"></fa-icon>
              </span>
            </div>
          }

          @if (!this.edit) {
            <div class="d-flex justify-content-end col">
              <span class="edit-button" (click)="toggleEdit(this.data)">
                <fa-icon [icon]="this.faPencil"></fa-icon>
              </span>
            </div>
          }
        </div>
      </div>
    </div>
  </div>
  @if (this.showImage) {
    <img
      src="https://placehold.co/600x320"
      class="card-img-top"
      alt="Placeholder image"
    />
  }

  <div (click)="cardSelection()" class="card-body card--component-body pt-0" style="padding-bottom: 32px;">
    @if (!this.edit) {
      <h2 class="fw-medium mb-3">{{ this.data?.name }}</h2>
      <p class="mb-0">{{ this.data?.description }}</p>
    } @else {
      <form (ngSubmit)="onSubmit($event)" (keydown.enter)="onSubmit($event)">
        <div class="w-full position-relative d-flex">
          <input
            type="text"
            name="title"
            class="mb-3 p-0"
            placeholder="Navn"
            [(ngModel)]="this.data.name"
            maxlength="100"
            required
          />

          <!-- @if (this.isEditing && this.data?.name.length === 0) {
            <label class="position-absolute fs-small" style="top: 9px;">Venligst skriv en titel</label>
          } -->
        </div>


        <div class="w-100 position-relative d-flex">
          <input
            type="text"
            name="description"
            class="p-0"
            placeholder="Beskrivelse"
            [(ngModel)]="this.data.description"
            maxlength="100"
            required
          />
          <!-- @if (this.isEditing && this.data?.description.length === 0) {
            <label class="position-absolute fs-small" style="top: 9px;">Venligst skriv en beskrivelse</label>
          } -->
        </div>

        <button type="submit" class="d-none">Update</button>
      </form>
    }
  </div>

  <div
    class="card-footer card--component-footer d-flex flex-row-reverse flex-nowrap gap-3 opacity-50"
  >
    <span>
      <fa-icon [icon]="faDolly"></fa-icon>
      @if (this.data?.subGroups && this.returnCount(this.data, 'Equipment') > 0) {
        {{  this.returnCount(this.data, 'Equipment') }}
      }
      @else {
        0
      }
    </span>

    <span>
      <fa-icon [icon]="faBoxesStacked"></fa-icon>
      @if (this.data?.subGroups && this.returnCount(this.data, 'BackVehicles') > 0) {
        {{  this.returnCount(this.data, 'BackVehicles') }}
      }
      @else {
        0
      }
    </span>
    <span>
      <fa-icon [icon]="faTruck"></fa-icon>
      @if (this.data?.subGroups && this.returnCount(this.data, 'FrontVehicles') > 0) {
        {{  this.returnCount(this.data, 'FrontVehicles') }}
      }
      @else {
        0
      }
    </span>

    <span>
      <fa-icon [icon]="faCircleUser"></fa-icon>
      @if (this.data?.subGroups && this.returnCount(this.data, 'Users') > 0) {
        {{  this.returnCount(this.data, 'Users') }}
      }
      @else {
        0
      }
    </span>
    <span>
      <fa-icon [icon]="faBriefcase"></fa-icon>
      @if (this.data?.subGroups && this.returnCount(this.data, 'Customers') > 0) {
        {{  this.returnCount(this.data, 'Customers') }}
      }
      @else {
        0
      }
    </span>
  </div>
</div>
