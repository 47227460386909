
<div [ngClass]="false ? 'foreground' : ''" style="margin: 15px;">
  <div class="col-md-12" style="padding-bottom: 15px">

    <!--<div class="btn-group" role="group" ngbRadioGroup name="radioBasic" [(ngModel)]="btnTap"
         (ngModelChange)="onChangeCalendar()"
         style="padding-bottom: 15px">
      <label ngbButtonLabel class="btn-outline-default">
        <input ngbButton type="radio" class="btn-check" [value]="tourTypes.Inland"> {{'common.inland' | translate}}
        <fa-icon *ngIf="this.timeStatementService.frozenStatusVisual.inlandHasRevisions" [icon]="frozen"></fa-icon>
      </label>
      <label ngbButtonLabel class="btn-outline-default">
        <input ngbButton type="radio" class="btn-check" [value]="tourTypes.Export"> {{'common.export' | translate}}
        <fa-icon *ngIf="this.timeStatementService.frozenStatusVisual.exportHasRevisions" [icon]="frozen"></fa-icon>
      </label>
    </div>-->

     <div class="btn-group tab-button-group inland-export-container pb-3" role="group">
      <label class="btn-outline-default btn active start" (click)="isRadioChecked($event)">
        <input type="radio" name="inland-export" [value]="tourTypes.Inland" [(ngModel)]="btnTap" (ngModelChange)="onChangeCalendar()" checked /> {{'common.inland' | translate}}
        <fa-icon *ngIf="this.timeStatementService.frozenStatusVisual.inlandHasRevisions" [icon]="frozen"></fa-icon>
      </label>

      <label class="btn-outline-default btn end" (click)="isRadioChecked($event)">
        <input type="radio" name="inland-export" [value]="tourTypes.Export" [(ngModel)]="btnTap" (ngModelChange)="onChangeCalendar()" /> {{'common.export' | translate}}
        <fa-icon *ngIf="this.timeStatementService.frozenStatusVisual.exportHasRevisions" [icon]="frozen"></fa-icon>
      </label>
    </div>
    <div>
      <div class="row">
        <div class="col-md-11">
          <full-calendar #timeStatement [options]="calendarOptions"></full-calendar>
        </div>
        <div class="col-md-1 to-bottom text-default">
          <span>{{'common.sum' | translate}}</span>
        </div>
      </div>
      <!--This can be commented in to enable a toggle that show the hours with the breaks subtracted. for debug purposes-->
      <!--<div class="row">
        <div class="col-md-11">
            <div class="form-group form-check" style="margin-bottom: -3px;margin-top: 10px;margin-left: 5px;">
              <input type="checkbox" class="form-check-input to-bottom"  id="subtrackbreaskcheckmark" [(ngModel)]="showWithBreaskSubtracted">
              <label class="form-check-label pl-2" for="subtrackbreaskcheckmark">Vis timer med pauserne trukket fra</label>
            </div>
        </div>
        <div class="col-md-1 to-bottom text-default">
          <span>{{'common.sum' | translate}}</span>
        </div>
      </div>-->
      <div class="row">
        <div class="col-md-12">
          <table class="table">

            <tbody class="text-center">
              <!--Normal tid-->
              <tr style="overflow: visible">
                <th scope="row" class="bg-green text-success" style="width: 55px">N</th>
                <td style="padding-left: 6%" (click)="openEdit(editwindow, 'mo', this.timeStatementService.getTableTotals())">
                  <div class="overflow">
                    {{this.timeStatementService.getTableTotals().mo.normalHours(true,showWithBreaskSubtracted)}}
                    <fa-icon *ngIf="this.timeStatementService.getTableTotals().mo.normalHasEdit()" [icon]="userPen"></fa-icon>
                  </div>

                </td>
                <td style="padding-left: 6%" (click)="openEdit(editwindow, 'tu', this.timeStatementService.getTableTotals())">
                  <div class="overflow">
                    {{this.timeStatementService.getTableTotals().tu.normalHours(true,showWithBreaskSubtracted)}}
                    <fa-icon *ngIf="this.timeStatementService.getTableTotals().tu.normalHasEdit()" [icon]="userPen"></fa-icon>
                  </div>
                </td>
                <td style="padding-left: 6%" (click)="openEdit(editwindow, 'we', this.timeStatementService.getTableTotals())">
                  <div class="overflow">
                    {{this.timeStatementService.getTableTotals().we.normalHours(true,showWithBreaskSubtracted)}}
                    <fa-icon *ngIf="this.timeStatementService.getTableTotals().we.normalHasEdit()" [icon]="userPen"></fa-icon>
                  </div>
                </td>
                <td style="padding-left: 7%" (click)="openEdit(editwindow, 'th', this.timeStatementService.getTableTotals())">
                  <div class="overflow">
                    {{this.timeStatementService.getTableTotals().th.normalHours(true,showWithBreaskSubtracted)}}
                    <fa-icon *ngIf="this.timeStatementService.getTableTotals().th.normalHasEdit()" [icon]="userPen"></fa-icon>
                  </div>
                </td>
                <td style="padding-left: 7.5%" (click)="openEdit(editwindow, 'fr', this.timeStatementService.getTableTotals())">
                  <div class="overflow">
                    {{this.timeStatementService.getTableTotals().fr.normalHours(true,showWithBreaskSubtracted)}}
                    <fa-icon *ngIf="this.timeStatementService.getTableTotals().fr.normalHasEdit()" [icon]="userPen"></fa-icon>
                  </div>
                </td>
                <td style="padding-left: 8%" (click)="openEdit(editwindow, 'sa', this.timeStatementService.getTableTotals())">
                  <div class="overflow">
                    {{this.timeStatementService.getTableTotals().sa.normalHours(true,showWithBreaskSubtracted)}}
                    <fa-icon *ngIf="this.timeStatementService.getTableTotals().sa.normalHasEdit()" [icon]="userPen"></fa-icon>
                  </div>
                </td>
                <td style="padding-left: 9%" (click)="openEdit(editwindow, 'su', this.timeStatementService.getTableTotals())">
                  <div class="overflow">
                    {{this.timeStatementService.getTableTotals().su.normalHours(true,showWithBreaskSubtracted)}}
                    <fa-icon *ngIf="this.timeStatementService.getTableTotals().su.normalHasEdit()" [icon]="userPen"></fa-icon>
                  </div>
                </td>

                <td class="text-right non-click">
                  {{timeStatementService.getTableTotals().sumNormalHours(true,showWithBreaskSubtracted)}}
                  <fa-icon *ngIf="this.timeStatementService.getTableTotals().sumNormalHasEdit()" [icon]="userPen"></fa-icon>
                </td>
              </tr>
              <tr *ngIf="greaterThan(this.timeStatementService.getTableTotals().calculateOvertime1(false,false), 0)">
                <th scope="row" class="bg-yellow text-warning">T1</th>
                <td style="padding-left: 6%" (click)="openEdit(editwindow, 'mo', this.timeStatementService.getTableTotals())">
                  <div class="overflow">
                    {{this.timeStatementService.getTableTotals().mo.overtime1Hours(true,showWithBreaskSubtracted)}}
                    <fa-icon *ngIf="this.timeStatementService.getTableTotals().mo.overtime1HasEdit()" [icon]="userPen"></fa-icon>
                  </div>
                </td>
                <td style="padding-left: 6%" (click)="openEdit(editwindow, 'tu', this.timeStatementService.getTableTotals())">
                  <div class="overflow">
                    {{this.timeStatementService.getTableTotals().tu.overtime1Hours(true,showWithBreaskSubtracted)}}
                    <fa-icon *ngIf="this.timeStatementService.getTableTotals().tu.overtime1HasEdit()" [icon]="userPen"></fa-icon>
                  </div>
                </td>
                <td style="padding-left: 6%" (click)="openEdit(editwindow, 'we', this.timeStatementService.getTableTotals())">
                  <div class="overflow">
                    {{this.timeStatementService.getTableTotals().we.overtime1Hours(true,showWithBreaskSubtracted)}}
                    <fa-icon *ngIf="this.timeStatementService.getTableTotals().we.overtime1HasEdit()" [icon]="userPen"></fa-icon>
                  </div>
                </td>
                <td style="padding-left: 7%" (click)="openEdit(editwindow, 'th', this.timeStatementService.getTableTotals())">
                  <div class="overflow">
                    {{this.timeStatementService.getTableTotals().th.overtime1Hours(true,showWithBreaskSubtracted)}}
                    <fa-icon *ngIf="this.timeStatementService.getTableTotals().th.overtime1HasEdit()" [icon]="userPen"></fa-icon>
                  </div>
                </td>
                <td style="padding-left: 7.5%" (click)="openEdit(editwindow, 'fr', this.timeStatementService.getTableTotals())">
                  <div class="overflow">
                    {{this.timeStatementService.getTableTotals().fr.overtime1Hours(true,showWithBreaskSubtracted)}}
                    <fa-icon *ngIf="this.timeStatementService.getTableTotals().fr.overtime1HasEdit()" [icon]="userPen"></fa-icon>
                  </div>
                </td>
                <td style="padding-left: 8%" (click)="openEdit(editwindow, 'sa', this.timeStatementService.getTableTotals())">
                  <div class="overflow">
                    {{this.timeStatementService.getTableTotals().sa.overtime1Hours(true,showWithBreaskSubtracted)}}
                    <fa-icon *ngIf="this.timeStatementService.getTableTotals().sa.overtime1HasEdit()" [icon]="userPen"></fa-icon>
                  </div>
                </td>
                <td style="padding-left: 9%" (click)="openEdit(editwindow, 'su', this.timeStatementService.getTableTotals())">
                  <div class="overflow">
                    {{this.timeStatementService.getTableTotals().su.overtime1Hours(true,showWithBreaskSubtracted)}}
                    <fa-icon *ngIf="this.timeStatementService.getTableTotals().su.overtime1HasEdit()" [icon]="userPen"></fa-icon>
                  </div>
                </td>

                <td class="text-right non-click">
                  {{timeStatementService.getTableTotals().sumOvertime1Hours(true,showWithBreaskSubtracted)}}
                  <fa-icon *ngIf="this.timeStatementService.getTableTotals().sumOvertime1HasEdit()" [icon]="userPen"></fa-icon>
                </td>
              </tr>
              <tr *ngIf="btnTap === tourTypes.Inland && greaterThan(this.timeStatementService.getTableTotals().calculateOvertime2(false,false), 0)">
                <th scope="row" class="bg-red text-danger">T2</th>
                <td style="padding-left: 6%" (click)="openEdit(editwindow, 'mo', this.timeStatementService.getTableTotals())">
                  <div class="overflow">
                    {{this.timeStatementService.getTableTotals().mo.overtime2Hours(true,showWithBreaskSubtracted)}}
                    <fa-icon *ngIf="this.timeStatementService.getTableTotals().mo.overtime2HasEdit()" [icon]="userPen"></fa-icon>
                  </div>
                </td>
                <td style="padding-left: 6%" (click)="openEdit(editwindow, 'tu', this.timeStatementService.getTableTotals())">
                  <div class="overflow">
                    {{this.timeStatementService.getTableTotals().tu.overtime2Hours(true,showWithBreaskSubtracted)}}
                    <fa-icon *ngIf="this.timeStatementService.getTableTotals().tu.overtime2HasEdit()" [icon]="userPen"></fa-icon>
                  </div>
                </td>
                <td style="padding-left: 6%" (click)="openEdit(editwindow, 'we', this.timeStatementService.getTableTotals())">
                  <div class="overflow">
                    {{this.timeStatementService.getTableTotals().we.overtime2Hours(true,showWithBreaskSubtracted)}}
                    <fa-icon *ngIf="this.timeStatementService.getTableTotals().we.overtime2HasEdit()" [icon]="userPen"></fa-icon>
                  </div>
                </td>
                <td style="padding-left: 7%" (click)="openEdit(editwindow, 'th', this.timeStatementService.getTableTotals())">
                  <div class="overflow">
                    {{this.timeStatementService.getTableTotals().th.overtime2Hours(true,showWithBreaskSubtracted)}}
                    <fa-icon *ngIf="this.timeStatementService.getTableTotals().th.overtime2HasEdit()" [icon]="userPen"></fa-icon>
                  </div>
                </td>
                <td style="padding-left: 7.5%" (click)="openEdit(editwindow, 'fr', this.timeStatementService.getTableTotals())">
                  <div class="overflow">
                    {{this.timeStatementService.getTableTotals().fr.overtime2Hours(true,showWithBreaskSubtracted)}}
                    <fa-icon *ngIf="this.timeStatementService.getTableTotals().fr.overtime2HasEdit()" [icon]="userPen"></fa-icon>
                  </div>
                </td>
                <td style="padding-left: 8%" (click)="openEdit(editwindow, 'sa', this.timeStatementService.getTableTotals())">
                  <div class="overflow">
                    {{this.timeStatementService.getTableTotals().sa.overtime2Hours(true,showWithBreaskSubtracted)}}
                    <fa-icon *ngIf="this.timeStatementService.getTableTotals().sa.overtime2HasEdit()" [icon]="userPen"></fa-icon>
                  </div>
                </td>
                <td style="padding-left: 9%" (click)="openEdit(editwindow, 'su', this.timeStatementService.getTableTotals())">
                  <div class="overflow">
                    {{this.timeStatementService.getTableTotals().su.overtime2Hours(true,showWithBreaskSubtracted)}}
                    <fa-icon *ngIf="this.timeStatementService.getTableTotals().su.overtime2HasEdit()" [icon]="userPen"></fa-icon>
                  </div>
                </td>
                <td class="text-right non-click">
                  {{timeStatementService.getTableTotals().sumOvertime2Hours(true,showWithBreaskSubtracted)}}
                  <fa-icon *ngIf="this.timeStatementService.getTableTotals().sumOvertime2HasEdit()" [icon]="userPen"></fa-icon>
                </td>
              </tr>
              <tr *ngIf="btnTap === tourTypes.Inland  && greaterThan(this.timeStatementService.getTableTotals().calculateOvertime3(false,false), 0)">
                <th scope="row" class="bg-t3">T3</th>
                <td style="padding-left: 6%" (click)="openEdit(editwindow, 'mo', this.timeStatementService.getTableTotals())">
                  <div class="overflow">
                    {{this.timeStatementService.getTableTotals().mo.overtime3Hours(true,showWithBreaskSubtracted)}}
                    <fa-icon *ngIf="this.timeStatementService.getTableTotals().mo.overtime3HasEdit()" [icon]="userPen"></fa-icon>
                  </div>
                </td>
                <td style="padding-left: 6%" (click)="openEdit(editwindow, 'tu', this.timeStatementService.getTableTotals())">
                  <div class="overflow">
                    {{this.timeStatementService.getTableTotals().tu.overtime3Hours(true,showWithBreaskSubtracted)}}
                    <fa-icon *ngIf="this.timeStatementService.getTableTotals().tu.overtime3HasEdit()" [icon]="userPen"></fa-icon>
                  </div>
                </td>
                <td style="padding-left: 6%" (click)="openEdit(editwindow, 'we', this.timeStatementService.getTableTotals())">
                  <div class="overflow">
                    {{this.timeStatementService.getTableTotals().we.overtime3Hours(true,showWithBreaskSubtracted)}}
                    <fa-icon *ngIf="this.timeStatementService.getTableTotals().we.overtime3HasEdit()" [icon]="userPen"></fa-icon>
                  </div>
                </td>
                <td style="padding-left: 7%" (click)="openEdit(editwindow, 'th', this.timeStatementService.getTableTotals())">
                  <div class="overflow">
                    {{this.timeStatementService.getTableTotals().th.overtime3Hours(true,showWithBreaskSubtracted)}}
                    <fa-icon *ngIf="this.timeStatementService.getTableTotals().th.overtime3HasEdit()" [icon]="userPen"></fa-icon>
                  </div>
                </td>
                <td style="padding-left: 7.5%" (click)="openEdit(editwindow, 'fr', this.timeStatementService.getTableTotals())">
                  <div class="overflow">
                    {{this.timeStatementService.getTableTotals().fr.overtime3Hours(true,showWithBreaskSubtracted)}}
                    <fa-icon *ngIf="this.timeStatementService.getTableTotals().fr.overtime3HasEdit()" [icon]="userPen"></fa-icon>
                  </div>
                </td>
                <td style="padding-left: 8%" (click)="openEdit(editwindow, 'sa', this.timeStatementService.getTableTotals())">
                  <div class="overflow">
                    {{this.timeStatementService.getTableTotals().sa.overtime3Hours(true,showWithBreaskSubtracted)}}
                    <fa-icon *ngIf="this.timeStatementService.getTableTotals().sa.overtime3HasEdit()" [icon]="userPen"></fa-icon>
                  </div>
                </td>
                <td style="padding-left: 9%" (click)="openEdit(editwindow, 'su', this.timeStatementService.getTableTotals())">
                  <div class="overflow">
                    {{this.timeStatementService.getTableTotals().su.overtime3Hours(true,showWithBreaskSubtracted)}}
                    <fa-icon *ngIf="this.timeStatementService.getTableTotals().su.overtime3HasEdit()" [icon]="userPen"></fa-icon>
                  </div>
                </td>
                <td class="text-right non-click">
                  {{timeStatementService.getTableTotals().sumOvertime3Hours(true,showWithBreaskSubtracted)}}
                  <fa-icon *ngIf="this.timeStatementService.getTableTotals().sumOvertime3HasEdit()" [icon]="userPen"></fa-icon>
                </td>
              </tr>
              <tr *ngIf="btnTap === tourTypes.Inland  && greaterThan(this.timeStatementService.getTableTotals().calculateOvertime4(false,false), 0)">
                <th scope="row" class="bg-t4">T4</th>
                <td style="padding-left: 6%" (click)="openEdit(editwindow, 'mo', this.timeStatementService.getTableTotals())">
                  <div class="overflow">
                    {{this.timeStatementService.getTableTotals().mo.overtime4Hours(true,showWithBreaskSubtracted)}}
                    <fa-icon *ngIf="this.timeStatementService.getTableTotals().mo.overtime4HasEdit()" [icon]="userPen"></fa-icon>
                  </div>
                </td>
                <td style="padding-left: 6%" (click)="openEdit(editwindow, 'tu', this.timeStatementService.getTableTotals())">
                  <div class="overflow">
                    {{this.timeStatementService.getTableTotals().tu.overtime4Hours(true,showWithBreaskSubtracted)}}
                    <fa-icon *ngIf="this.timeStatementService.getTableTotals().tu.overtime4HasEdit()" [icon]="userPen"></fa-icon>
                  </div>
                </td>
                <td style="padding-left: 6%" (click)="openEdit(editwindow, 'we', this.timeStatementService.getTableTotals())">
                  <div class="overflow">
                    {{this.timeStatementService.getTableTotals().we.overtime4Hours(true,showWithBreaskSubtracted)}}
                    <fa-icon *ngIf="this.timeStatementService.getTableTotals().we.overtime4HasEdit()" [icon]="userPen"></fa-icon>
                  </div>
                </td>
                <td style="padding-left: 7%" (click)="openEdit(editwindow, 'th', this.timeStatementService.getTableTotals())">
                  <div class="overflow">
                    {{this.timeStatementService.getTableTotals().th.overtime4Hours(true,showWithBreaskSubtracted)}}
                    <fa-icon *ngIf="this.timeStatementService.getTableTotals().th.overtime4HasEdit()" [icon]="userPen"></fa-icon>
                  </div>
                </td>
                <td style="padding-left: 7.5%" (click)="openEdit(editwindow, 'fr', this.timeStatementService.getTableTotals())">
                  <div class="overflow">
                    {{this.timeStatementService.getTableTotals().fr.overtime4Hours(true,showWithBreaskSubtracted)}}
                    <fa-icon *ngIf="this.timeStatementService.getTableTotals().fr.overtime4HasEdit()" [icon]="userPen"></fa-icon>
                  </div>
                </td>
                <td style="padding-left: 8%" (click)="openEdit(editwindow, 'sa', this.timeStatementService.getTableTotals())">
                  <div class="overflow">
                    {{this.timeStatementService.getTableTotals().sa.overtime4Hours(true,showWithBreaskSubtracted)}}
                    <fa-icon *ngIf="this.timeStatementService.getTableTotals().sa.overtime4HasEdit()" [icon]="userPen"></fa-icon>
                  </div>
                </td>
                <td style="padding-left: 9%" (click)="openEdit(editwindow, 'su', this.timeStatementService.getTableTotals())">
                  <div class="overflow">
                    {{this.timeStatementService.getTableTotals().su.overtime4Hours(true,showWithBreaskSubtracted)}}
                    <fa-icon *ngIf="this.timeStatementService.getTableTotals().su.overtime4HasEdit()" [icon]="userPen"></fa-icon>
                  </div>
                </td>
                <td class="text-right non-click">
                  {{timeStatementService.getTableTotals().sumOvertime4Hours(true,showWithBreaskSubtracted)}}
                  <fa-icon *ngIf="this.timeStatementService.getTableTotals().sumOvertime4HasEdit()" [icon]="userPen"></fa-icon>
                </td>
              </tr>

              <tr *ngIf="this.timeStatementService.getTableTotals().showSH()">
                <th scope="row" class="bg-sh">SH</th>
                <td style="padding-left: 6%" (click)="openEdit(editwindow, 'mo', this.timeStatementService.getTableTotals())">
                  <div class="overflow">
                    <span *ngIf="this.timeStatementService.getTableTotals().mo.isSunday" class="big-n-bold">S</span>
                    <span *ngIf="this.timeStatementService.getTableTotals().mo.isHoliday" class="big-n-bold">H</span>
                  </div>
                </td>
                <td style="padding-left: 6%" (click)="openEdit(editwindow, 'tu', this.timeStatementService.getTableTotals())">
                  <div class="overflow">
                    <span *ngIf="this.timeStatementService.getTableTotals().tu.isSunday" class="big-n-bold">S</span>
                    <span *ngIf="this.timeStatementService.getTableTotals().tu.isHoliday" class="big-n-bold">H</span>
                  </div>
                </td>
                <td style="padding-left: 6%" (click)="openEdit(editwindow, 'we', this.timeStatementService.getTableTotals())">
                  <div class="overflow">
                    <span *ngIf="this.timeStatementService.getTableTotals().we.isSunday" class="big-n-bold">S</span>
                    <span *ngIf="this.timeStatementService.getTableTotals().we.isHoliday" class="big-n-bold">H</span>
                  </div>
                </td>
                <td style="padding-left: 7%" (click)="openEdit(editwindow, 'th', this.timeStatementService.getTableTotals())">
                  <div class="overflow">
                    <span *ngIf="this.timeStatementService.getTableTotals().th.isSunday" class="big-n-bold">S</span>
                    <span *ngIf="this.timeStatementService.getTableTotals().th.isHoliday" class="big-n-bold">H</span>
                  </div>
                </td>
                <td style="padding-left: 7.5%" (click)="openEdit(editwindow, 'fr', this.timeStatementService.getTableTotals())">
                  <div class="overflow">
                    <span *ngIf="this.timeStatementService.getTableTotals().fr.isSunday" class="big-n-bold">S</span>
                    <span *ngIf="this.timeStatementService.getTableTotals().fr.isHoliday" class="big-n-bold">H</span>
                  </div>
                </td>
                <td style="padding-left: 8%" (click)="openEdit(editwindow, 'sa', this.timeStatementService.getTableTotals())">
                  <div class="overflow">
                    <span *ngIf="this.timeStatementService.getTableTotals().sa.isSunday" class="big-n-bold">S</span>
                    <span *ngIf="this.timeStatementService.getTableTotals().sa.isHoliday" class="big-n-bold">H</span>
                  </div>
                </td>
                <td style="padding-left: 9%" (click)="openEdit(editwindow, 'su', this.timeStatementService.getTableTotals())">
                  <div class="overflow">
                    <span *ngIf="this.timeStatementService.getTableTotals().su.isSunday" class="big-n-bold">S</span>
                    <span *ngIf="this.timeStatementService.getTableTotals().su.isHoliday" class="big-n-bold">H</span>
                  </div>
                </td>
                <td class="text-right non-click">
                </td>
              </tr>

              <tr *ngIf="this.timeStatementService.getTableTotals().showSleep()">
                <th scope="row" class="bg-on">ON</th>
                <td style="padding-left: 6%" (click)="openEdit(editwindow, 'mo', this.timeStatementService.getTableTotals())">
                  <div class="overflow">
                    <fa-icon *ngIf="this.timeStatementService.getTableTotals().mo.isSleepOver" [icon]="checkMark"></fa-icon>
                  </div>
                </td>
                <td style="padding-left: 6%" (click)="openEdit(editwindow, 'tu', this.timeStatementService.getTableTotals())">
                  <div class="overflow">
                    <fa-icon *ngIf="this.timeStatementService.getTableTotals().tu.isSleepOver" [icon]="checkMark"></fa-icon>
                  </div>
                </td>
                <td style="padding-left: 6%" (click)="openEdit(editwindow, 'we', this.timeStatementService.getTableTotals())">
                  <div class="overflow">
                    <fa-icon *ngIf="this.timeStatementService.getTableTotals().we.isSleepOver" [icon]="checkMark"></fa-icon>
                  </div>
                </td>
                <td style="padding-left: 7%" (click)="openEdit(editwindow, 'th', this.timeStatementService.getTableTotals())">
                  <div class="overflow">
                    <fa-icon *ngIf="this.timeStatementService.getTableTotals().th.isSleepOver" [icon]="checkMark"></fa-icon>
                  </div>
                </td>
                <td style="padding-left: 7.5%" (click)="openEdit(editwindow, 'fr', this.timeStatementService.getTableTotals())">
                  <div class="overflow">
                    <fa-icon *ngIf="this.timeStatementService.getTableTotals().fr.isSleepOver" [icon]="checkMark"></fa-icon>
                  </div>
                </td>
                <td style="padding-left: 8%" (click)="openEdit(editwindow, 'sa', this.timeStatementService.getTableTotals())">
                  <div class="overflow">
                    <fa-icon *ngIf="this.timeStatementService.getTableTotals().sa.isSleepOver" [icon]="checkMark"></fa-icon>
                  </div>
                </td>
                <td style="padding-left: 9%" (click)="openEdit(editwindow, 'su', this.timeStatementService.getTableTotals())">
                  <div class="overflow">
                    <fa-icon *ngIf="this.timeStatementService.getTableTotals().su.isSleepOver" [icon]="checkMark"></fa-icon>
                  </div>
                </td>
                <td class="text-right non-click">
                </td>
              </tr>

              <tr *ngIf="btnTap === tourTypes.Inland" style="color: red;">
                <th scope="row" class="bg-blue text-default">H</th>
                <td style="padding-left: 6%" (click)="openEdit(editwindow, 'mo', this.timeStatementService.getTableTotals())">
                  <div class="overflow">
                    {{this.timeStatementService.getTableTotals().mo.resttimeHours(true)}}
                    <fa-icon *ngIf="this.timeStatementService.getTableTotals().mo.resttimeHasEdit()" [icon]="userPen"></fa-icon>
                  </div>
                </td>
                <td style="padding-left: 6%" (click)="openEdit(editwindow, 'tu', this.timeStatementService.getTableTotals())">
                  <div class="overflow">
                    {{this.timeStatementService.getTableTotals().tu.resttimeHours(true)}}
                    <fa-icon *ngIf="this.timeStatementService.getTableTotals().tu.resttimeHasEdit()" [icon]="userPen"></fa-icon>
                  </div>
                </td>
                <td style="padding-left: 6%" (click)="openEdit(editwindow, 'we', this.timeStatementService.getTableTotals())">
                  <div class="overflow">
                    {{this.timeStatementService.getTableTotals().we.resttimeHours(true)}}
                    <fa-icon *ngIf="this.timeStatementService.getTableTotals().we.resttimeHasEdit()" [icon]="userPen"></fa-icon>
                  </div>
                </td>
                <td style="padding-left: 7%" (click)="openEdit(editwindow, 'th', this.timeStatementService.getTableTotals())">
                  <div class="overflow">
                    {{this.timeStatementService.getTableTotals().th.resttimeHours(true)}}
                    <fa-icon *ngIf="this.timeStatementService.getTableTotals().th.resttimeHasEdit()" [icon]="userPen"></fa-icon>
                  </div>
                </td>
                <td style="padding-left: 7.5%" (click)="openEdit(editwindow, 'fr', this.timeStatementService.getTableTotals())">
                  <div class="overflow">
                    {{this.timeStatementService.getTableTotals().fr.resttimeHours(true)}}
                    <fa-icon *ngIf="this.timeStatementService.getTableTotals().fr.resttimeHasEdit()" [icon]="userPen"></fa-icon>
                  </div>
                </td>
                <td style="padding-left: 8%" (click)="openEdit(editwindow, 'sa', this.timeStatementService.getTableTotals())">
                  <div class="overflow">
                    {{this.timeStatementService.getTableTotals().sa.resttimeHours(true)}}
                    <fa-icon *ngIf="this.timeStatementService.getTableTotals().sa.resttimeHasEdit()" [icon]="userPen"></fa-icon>
                  </div>
                </td>
                <td style="padding-left: 9%" (click)="openEdit(editwindow, 'su', this.timeStatementService.getTableTotals())">
                  <div class="overflow">
                    {{this.timeStatementService.getTableTotals().su.resttimeHours(true)}}
                    <fa-icon *ngIf="this.timeStatementService.getTableTotals().su.resttimeHasEdit()" [icon]="userPen"></fa-icon>
                  </div>
                </td>

                <td class="text-right non-click">
                  {{this.timeStatementService.getTableTotals().sumResttimeHours(true)}}
                  <fa-icon *ngIf="this.timeStatementService.getTableTotals().sumResttimeHasEdit()" [icon]="userPen"></fa-icon>
                </td>
              </tr>

              <tr *ngIf="btnTap === tourTypes.Export">
                <th scope="row" style="padding: 0; height: 100%">
                  <div style="border: #213a45 solid 1px; border-radius: 2px; height: 45px; padding-top:10px ">S</div>
                </th>
                <td style="padding-left: 6%" (click)="openEdit(editwindow, 'mo', this.timeStatementService.getTableTotals())">
                  <div class="overflow">{{timeStatementService.getTableTotals().mo.specialHours()}}</div>
                </td>
                <td style="padding-left: 6%" (click)="openEdit(editwindow, 'tu', this.timeStatementService.getTableTotals())">
                  <div class="overflow">{{timeStatementService.getTableTotals().tu.specialHours()}}</div>
                </td>
                <td style="padding-left: 6%" (click)="openEdit(editwindow, 'we', this.timeStatementService.getTableTotals())">
                  <div class="overflow">{{timeStatementService.getTableTotals().we.specialHours()}}</div>
                </td>
                <td style="padding-left: 7%" (click)="openEdit(editwindow, 'th', this.timeStatementService.getTableTotals())">
                  <div class="overflow">{{timeStatementService.getTableTotals().th.specialHours()}}</div>
                </td>
                <td style="padding-left: 7.5%" (click)="openEdit(editwindow, 'fr', this.timeStatementService.getTableTotals())">
                  <div class="overflow">{{timeStatementService.getTableTotals().fr.specialHours()}}</div>
                </td>
                <td style="padding-left: 8%" (click)="openEdit(editwindow, 'sa', this.timeStatementService.getTableTotals())">
                  <div class="overflow">{{timeStatementService.getTableTotals().sa.specialHours()}}</div>
                </td>
                <td style="padding-left: 9%" (click)="openEdit(editwindow, 'su', this.timeStatementService.getTableTotals())">
                  <div class="overflow">{{timeStatementService.getTableTotals().su.specialHours()}}</div>
                </td>
                <td class="text-right non-click">{{timeStatementService.getTableTotals().sumTotalSpecialHours()}}</td>
              </tr>
              <tr *ngIf="btnTap === tourTypes.Export">
                <th scope="row">Km</th>
                <td style="padding-left: 6%" (click)="openEdit(editwindow, 'mo', this.timeStatementService.getTableTotals())">
                  <div class="overflow">{{timeStatementService.getTableTotals().mo.distanceExport(true, 2)}}</div>
                </td>
                <td style="padding-left: 6%" (click)="openEdit(editwindow, 'tu', this.timeStatementService.getTableTotals())">
                  <div class="overflow">{{timeStatementService.getTableTotals().tu.distanceExport(true, 2)}}</div>
                </td>
                <td style="padding-left: 6%" (click)="openEdit(editwindow, 'we', this.timeStatementService.getTableTotals())">
                  <div class="overflow">{{timeStatementService.getTableTotals().we.distanceExport(true, 2)}}</div>
                </td>
                <td style="padding-left: 7%" (click)="openEdit(editwindow, 'th', this.timeStatementService.getTableTotals())">
                  <div class="overflow">{{timeStatementService.getTableTotals().th.distanceExport(true, 2)}}</div>
                </td>
                <td style="padding-left: 7.5%" (click)="openEdit(editwindow, 'fr', this.timeStatementService.getTableTotals())">
                  <div class="overflow">{{timeStatementService.getTableTotals().fr.distanceExport(true, 2)}}</div>
                </td>
                <td style="padding-left: 8%" (click)="openEdit(editwindow, 'sa', this.timeStatementService.getTableTotals())">
                  <div class="overflow">{{timeStatementService.getTableTotals().sa.distanceExport(true, 2)}}</div>
                </td>
                <td style="padding-left: 9%" (click)="openEdit(editwindow, 'su', this.timeStatementService.getTableTotals())">
                  <div class="overflow">{{timeStatementService.getTableTotals().su.distanceExport(true, 2)}}</div>
                </td>

                <td class="text-right non-click">{{timeStatementService.getTableTotals().distanceExport(true, 2)}}</td>
              </tr>
              <tr *ngIf="btnTap === tourTypes.Inland">
                <td scope="row">{{'common.total' | translate}}</td>
                <td style="padding-left: 6%" (click)="openEdit(editwindow, 'mo', this.timeStatementService.getTableTotals())">
                  <div class="overflow">
                    {{this.timeStatementService.getTableTotals().mo.totalHours(true,showWithBreaskSubtracted)}}
                    <fa-icon *ngIf="this.timeStatementService.getTableTotals().mo.totalHasEdit()" [icon]="userPen"></fa-icon>
                  </div>
                </td>
                <td style="padding-left: 6%" (click)="openEdit(editwindow, 'tu', this.timeStatementService.getTableTotals())">
                  <div class="overflow">
                    {{this.timeStatementService.getTableTotals().tu.totalHours(true,showWithBreaskSubtracted)}}
                    <fa-icon *ngIf="this.timeStatementService.getTableTotals().tu.totalHasEdit()" [icon]="userPen"></fa-icon>
                  </div>
                </td>
                <td style="padding-left: 6%" (click)="openEdit(editwindow, 'we', this.timeStatementService.getTableTotals())">
                  <div class="overflow">
                    {{this.timeStatementService.getTableTotals().we.totalHours(true,showWithBreaskSubtracted)}}
                    <fa-icon *ngIf="this.timeStatementService.getTableTotals().we.totalHasEdit()" [icon]="userPen"></fa-icon>
                  </div>
                </td>
                <td style="padding-left: 7%" (click)="openEdit(editwindow, 'th', this.timeStatementService.getTableTotals())">
                  <div class="overflow">
                    {{this.timeStatementService.getTableTotals().th.totalHours(true,showWithBreaskSubtracted)}}
                    <fa-icon *ngIf="this.timeStatementService.getTableTotals().th.totalHasEdit()" [icon]="userPen"></fa-icon>
                  </div>
                </td>
                <td style="padding-left: 7.5%" (click)="openEdit(editwindow, 'fr', this.timeStatementService.getTableTotals())">
                  <div class="overflow">
                    {{this.timeStatementService.getTableTotals().fr.totalHours(true,showWithBreaskSubtracted)}}
                    <fa-icon *ngIf="this.timeStatementService.getTableTotals().fr.totalHasEdit()" [icon]="userPen"></fa-icon>
                  </div>
                </td>
                <td style="padding-left: 8%" (click)="openEdit(editwindow, 'sa', this.timeStatementService.getTableTotals())">
                  <div class="overflow">
                    {{this.timeStatementService.getTableTotals().sa.totalHours(true,showWithBreaskSubtracted)}}
                    <fa-icon *ngIf="this.timeStatementService.getTableTotals().sa.totalHasEdit()" [icon]="userPen"></fa-icon>
                  </div>
                </td>
                <td style="padding-left: 9%" (click)="openEdit(editwindow, 'su', this.timeStatementService.getTableTotals())">
                  <div class="overflow">
                    {{this.timeStatementService.getTableTotals().su.totalHours(true,showWithBreaskSubtracted)}}
                    <fa-icon *ngIf="this.timeStatementService.getTableTotals().su.totalHasEdit()" [icon]="userPen"></fa-icon>
                  </div>
                </td>
                <td class="text-right non-click">
                  {{timeStatementService.getTableTotals().sumTotalHours(true,showWithBreaskSubtracted)}}
                  <fa-icon *ngIf="this.timeStatementService.getTableTotals().sumTotalHasEdit()" [icon]="userPen"></fa-icon>
                </td>
              </tr>

            </tbody>
          </table>
        </div>
      </div>
      <div class="row">
        <div class="col-md-1">
          <div *ngIf="loading" class="spinner-border content text-dark to-bottom" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>

      </div>
      <div class="row" *ngFor="let transfered of this.timeStatementService.getTansferedTotals()">

        <div class="col-12 border-top">
          <label>Overført fra uge {{transfered.week}}</label>
        </div>
        <div class="col-12 ">
          <table class="table">

            <tbody class="text-center">
              <!--Normal tid-->
              <tr style="overflow: visible">
                <th scope="row" class="bg-green text-success" style="width: 55px">N</th>
                <td style="padding-left: 6%" (click)="openEdit(editwindow, 'mo', transfered)">
                  <div class="overflow">
                    {{transfered.mo.normalHours(true,showWithBreaskSubtracted)}}
                    <fa-icon *ngIf="transfered.mo.normalHasEdit()" [icon]="userPen"></fa-icon>
                  </div>

                </td>
                <td style="padding-left: 6%" (click)="openEdit(editwindow, 'tu', transfered)">
                  <div class="overflow">
                    {{transfered.tu.normalHours(true,showWithBreaskSubtracted)}}
                    <fa-icon *ngIf="transfered.tu.normalHasEdit()" [icon]="userPen"></fa-icon>
                  </div>
                </td>
                <td style="padding-left: 6%" (click)="openEdit(editwindow, 'we', transfered)">
                  <div class="overflow">
                    {{transfered.we.normalHours(true,showWithBreaskSubtracted)}}
                    <fa-icon *ngIf="transfered.we.normalHasEdit()" [icon]="userPen"></fa-icon>
                  </div>
                </td>
                <td style="padding-left: 7%" (click)="openEdit(editwindow, 'th', transfered)">
                  <div class="overflow">
                    {{transfered.th.normalHours(true,showWithBreaskSubtracted)}}
                    <fa-icon *ngIf="transfered.th.normalHasEdit()" [icon]="userPen"></fa-icon>
                  </div>
                </td>
                <td style="padding-left: 7.5%" (click)="openEdit(editwindow, 'fr', transfered)">
                  <div class="overflow">
                    {{transfered.fr.normalHours(true,showWithBreaskSubtracted)}}
                    <fa-icon *ngIf="transfered.fr.normalHasEdit()" [icon]="userPen"></fa-icon>
                  </div>
                </td>
                <td style="padding-left: 8%" (click)="openEdit(editwindow, 'sa', transfered)">
                  <div class="overflow">
                    {{transfered.sa.normalHours(true,showWithBreaskSubtracted)}}
                    <fa-icon *ngIf="transfered.sa.normalHasEdit()" [icon]="userPen"></fa-icon>
                  </div>
                </td>
                <td style="padding-left: 9%" (click)="openEdit(editwindow, 'su', transfered)">
                  <div class="overflow">
                    {{transfered.su.normalHours(true,showWithBreaskSubtracted)}}
                    <fa-icon *ngIf="transfered.su.normalHasEdit()" [icon]="userPen"></fa-icon>
                  </div>
                </td>

                <td class="text-right non-click">
                  {{transfered.sumNormalHours(true,showWithBreaskSubtracted)}}
                  <fa-icon *ngIf="transfered.sumNormalHasEdit()" [icon]="userPen"></fa-icon>
                </td>
              </tr>
              <tr *ngIf="greaterThan(transfered.calculateOvertime1(false,false), 0)">
                <th scope="row" class="bg-yellow text-warning">T1</th>
                <td style="padding-left: 6%" (click)="openEdit(editwindow, 'mo', transfered)">
                  <div class="overflow">
                    {{transfered.mo.overtime1Hours(true,showWithBreaskSubtracted)}}
                    <fa-icon *ngIf="transfered.mo.overtime1HasEdit()" [icon]="userPen"></fa-icon>
                  </div>
                </td>
                <td style="padding-left: 6%" (click)="openEdit(editwindow, 'tu', transfered)">
                  <div class="overflow">
                    {{transfered.tu.overtime1Hours(true,showWithBreaskSubtracted)}}
                    <fa-icon *ngIf="transfered.tu.overtime1HasEdit()" [icon]="userPen"></fa-icon>
                  </div>
                </td>
                <td style="padding-left: 6%" (click)="openEdit(editwindow, 'we', transfered)">
                  <div class="overflow">
                    {{transfered.we.overtime1Hours(true,showWithBreaskSubtracted)}}
                    <fa-icon *ngIf="transfered.we.overtime1HasEdit()" [icon]="userPen"></fa-icon>
                  </div>
                </td>
                <td style="padding-left: 7%" (click)="openEdit(editwindow, 'th', transfered)">
                  <div class="overflow">
                    {{transfered.th.overtime1Hours(true,showWithBreaskSubtracted)}}
                    <fa-icon *ngIf="transfered.th.overtime1HasEdit()" [icon]="userPen"></fa-icon>
                  </div>
                </td>
                <td style="padding-left: 7.5%" (click)="openEdit(editwindow, 'fr', transfered)">
                  <div class="overflow">
                    {{transfered.fr.overtime1Hours(true,showWithBreaskSubtracted)}}
                    <fa-icon *ngIf="transfered.fr.overtime1HasEdit()" [icon]="userPen"></fa-icon>
                  </div>
                </td>
                <td style="padding-left: 8%" (click)="openEdit(editwindow, 'sa', transfered)">
                  <div class="overflow">
                    {{transfered.sa.overtime1Hours(true,showWithBreaskSubtracted)}}
                    <fa-icon *ngIf="transfered.sa.overtime1HasEdit()" [icon]="userPen"></fa-icon>
                  </div>
                </td>
                <td style="padding-left: 9%" (click)="openEdit(editwindow, 'su', transfered)">
                  <div class="overflow">
                    {{transfered.su.overtime1Hours(true,showWithBreaskSubtracted)}}
                    <fa-icon *ngIf="transfered.su.overtime1HasEdit()" [icon]="userPen"></fa-icon>
                  </div>
                </td>

                <td class="text-right non-click">
                  {{transfered.sumOvertime1Hours(true,showWithBreaskSubtracted)}}
                  <fa-icon *ngIf="transfered.sumOvertime1HasEdit()" [icon]="userPen"></fa-icon>
                </td>
              </tr>
              <tr *ngIf="btnTap === tourTypes.Inland && greaterThan(transfered.calculateOvertime2(false,false), 0)">
                <th scope="row" class="bg-red text-danger">T2</th>
                <td style="padding-left: 6%" (click)="openEdit(editwindow, 'mo', transfered)">
                  <div class="overflow">
                    {{transfered.mo.overtime2Hours(true,showWithBreaskSubtracted)}}
                    <fa-icon *ngIf="transfered.mo.overtime2HasEdit()" [icon]="userPen"></fa-icon>
                  </div>
                </td>
                <td style="padding-left: 6%" (click)="openEdit(editwindow, 'tu', transfered)">
                  <div class="overflow">
                    {{transfered.tu.overtime2Hours(true,showWithBreaskSubtracted)}}
                    <fa-icon *ngIf="transfered.tu.overtime2HasEdit()" [icon]="userPen"></fa-icon>
                  </div>
                </td>
                <td style="padding-left: 6%" (click)="openEdit(editwindow, 'we', transfered)">
                  <div class="overflow">
                    {{transfered.we.overtime2Hours(true,showWithBreaskSubtracted)}}
                    <fa-icon *ngIf="transfered.we.overtime2HasEdit()" [icon]="userPen"></fa-icon>
                  </div>
                </td>
                <td style="padding-left: 7%" (click)="openEdit(editwindow, 'th', transfered)">
                  <div class="overflow">
                    {{transfered.th.overtime2Hours(true,showWithBreaskSubtracted)}}
                    <fa-icon *ngIf="transfered.th.overtime2HasEdit()" [icon]="userPen"></fa-icon>
                  </div>
                </td>
                <td style="padding-left: 7.5%" (click)="openEdit(editwindow, 'fr', transfered)">
                  <div class="overflow">
                    {{transfered.fr.overtime2Hours(true,showWithBreaskSubtracted)}}
                    <fa-icon *ngIf="transfered.fr.overtime2HasEdit()" [icon]="userPen"></fa-icon>
                  </div>
                </td>
                <td style="padding-left: 8%" (click)="openEdit(editwindow, 'sa', transfered)">
                  <div class="overflow">
                    {{transfered.sa.overtime2Hours(true,showWithBreaskSubtracted)}}
                    <fa-icon *ngIf="transfered.sa.overtime2HasEdit()" [icon]="userPen"></fa-icon>
                  </div>
                </td>
                <td style="padding-left: 9%" (click)="openEdit(editwindow, 'su', transfered)">
                  <div class="overflow">
                    {{transfered.su.overtime2Hours(true,showWithBreaskSubtracted)}}
                    <fa-icon *ngIf="transfered.su.overtime2HasEdit()" [icon]="userPen"></fa-icon>
                  </div>
                </td>
                <td class="text-right non-click">
                  {{transfered.sumOvertime2Hours(true,showWithBreaskSubtracted)}}
                  <fa-icon *ngIf="transfered.sumOvertime2HasEdit()" [icon]="userPen"></fa-icon>
                </td>
              </tr>
              <tr *ngIf="btnTap === tourTypes.Inland  && greaterThan(transfered.calculateOvertime3(false,false), 0)">
                <th scope="row" class="bg-t3">T3</th>
                <td style="padding-left: 6%" (click)="openEdit(editwindow, 'mo', transfered)">
                  <div class="overflow">
                    {{transfered.mo.overtime3Hours(true,showWithBreaskSubtracted)}}
                    <fa-icon *ngIf="transfered.mo.overtime3HasEdit()" [icon]="userPen"></fa-icon>
                  </div>
                </td>
                <td style="padding-left: 6%" (click)="openEdit(editwindow, 'tu', transfered)">
                  <div class="overflow">
                    {{transfered.tu.overtime3Hours(true,showWithBreaskSubtracted)}}
                    <fa-icon *ngIf="transfered.tu.overtime3HasEdit()" [icon]="userPen"></fa-icon>
                  </div>
                </td>
                <td style="padding-left: 6%" (click)="openEdit(editwindow, 'we', transfered)">
                  <div class="overflow">
                    {{transfered.we.overtime3Hours(true,showWithBreaskSubtracted)}}
                    <fa-icon *ngIf="transfered.we.overtime3HasEdit()" [icon]="userPen"></fa-icon>
                  </div>
                </td>
                <td style="padding-left: 7%" (click)="openEdit(editwindow, 'th', transfered)">
                  <div class="overflow">
                    {{transfered.th.overtime3Hours(true,showWithBreaskSubtracted)}}
                    <fa-icon *ngIf="transfered.th.overtime3HasEdit()" [icon]="userPen"></fa-icon>
                  </div>
                </td>
                <td style="padding-left: 7.5%" (click)="openEdit(editwindow, 'fr', transfered)">
                  <div class="overflow">
                    {{transfered.fr.overtime3Hours(true,showWithBreaskSubtracted)}}
                    <fa-icon *ngIf="transfered.fr.overtime3HasEdit()" [icon]="userPen"></fa-icon>
                  </div>
                </td>
                <td style="padding-left: 8%" (click)="openEdit(editwindow, 'sa', transfered)">
                  <div class="overflow">
                    {{transfered.sa.overtime3Hours(true,showWithBreaskSubtracted)}}
                    <fa-icon *ngIf="transfered.sa.overtime3HasEdit()" [icon]="userPen"></fa-icon>
                  </div>
                </td>
                <td style="padding-left: 9%" (click)="openEdit(editwindow, 'su', transfered)">
                  <div class="overflow">
                    {{transfered.su.overtime3Hours(true,showWithBreaskSubtracted)}}
                    <fa-icon *ngIf="transfered.su.overtime3HasEdit()" [icon]="userPen"></fa-icon>
                  </div>
                </td>
                <td class="text-right non-click">
                  {{transfered.sumOvertime3Hours(true,showWithBreaskSubtracted)}}
                  <fa-icon *ngIf="transfered.sumOvertime3HasEdit()" [icon]="userPen"></fa-icon>
                </td>
              </tr>
              <tr *ngIf="btnTap === tourTypes.Inland  && greaterThan(transfered.calculateOvertime4(false,false), 0)">
                <th scope="row" class="bg-t4">T4</th>
                <td style="padding-left: 6%" (click)="openEdit(editwindow, 'mo', transfered)">
                  <div class="overflow">
                    {{transfered.mo.overtime4Hours(true,showWithBreaskSubtracted)}}
                    <fa-icon *ngIf="transfered.mo.overtime4HasEdit()" [icon]="userPen"></fa-icon>
                  </div>
                </td>
                <td style="padding-left: 6%" (click)="openEdit(editwindow, 'tu', transfered)">
                  <div class="overflow">
                    {{transfered.tu.overtime4Hours(true,showWithBreaskSubtracted)}}
                    <fa-icon *ngIf="transfered.tu.overtime4HasEdit()" [icon]="userPen"></fa-icon>
                  </div>
                </td>
                <td style="padding-left: 6%" (click)="openEdit(editwindow, 'we', transfered)">
                  <div class="overflow">
                    {{transfered.we.overtime4Hours(true,showWithBreaskSubtracted)}}
                    <fa-icon *ngIf="transfered.we.overtime4HasEdit()" [icon]="userPen"></fa-icon>
                  </div>
                </td>
                <td style="padding-left: 7%" (click)="openEdit(editwindow, 'th', transfered)">
                  <div class="overflow">
                    {{transfered.th.overtime4Hours(true,showWithBreaskSubtracted)}}
                    <fa-icon *ngIf="transfered.th.overtime4HasEdit()" [icon]="userPen"></fa-icon>
                  </div>
                </td>
                <td style="padding-left: 7.5%" (click)="openEdit(editwindow, 'fr', transfered)">
                  <div class="overflow">
                    {{transfered.fr.overtime4Hours(true,showWithBreaskSubtracted)}}
                    <fa-icon *ngIf="transfered.fr.overtime4HasEdit()" [icon]="userPen"></fa-icon>
                  </div>
                </td>
                <td style="padding-left: 8%" (click)="openEdit(editwindow, 'sa', transfered)">
                  <div class="overflow">
                    {{transfered.sa.overtime4Hours(true,showWithBreaskSubtracted)}}
                    <fa-icon *ngIf="transfered.sa.overtime4HasEdit()" [icon]="userPen"></fa-icon>
                  </div>
                </td>
                <td style="padding-left: 9%" (click)="openEdit(editwindow, 'su', transfered)">
                  <div class="overflow">
                    {{transfered.su.overtime4Hours(true,showWithBreaskSubtracted)}}
                    <fa-icon *ngIf="transfered.su.overtime4HasEdit()" [icon]="userPen"></fa-icon>
                  </div>
                </td>
                <td class="text-right non-click">
                  {{transfered.sumOvertime4Hours(true,showWithBreaskSubtracted)}}
                  <fa-icon *ngIf="transfered.sumOvertime4HasEdit()" [icon]="userPen"></fa-icon>
                </td>
              </tr>

              <tr *ngIf="transfered.showSH()">
                <th scope="row" class="bg-sh">SH</th>
                <td style="padding-left: 6%" (click)="openEdit(editwindow, 'mo', transfered)">
                  <div class="overflow">
                    <span *ngIf="transfered.mo.isSunday" class="big-n-bold">S</span>
                    <span *ngIf="transfered.mo.isHoliday" class="big-n-bold">H</span>
                  </div>
                </td>
                <td style="padding-left: 6%" (click)="openEdit(editwindow, 'tu', transfered)">
                  <div class="overflow">
                    <span *ngIf="transfered.tu.isSunday" class="big-n-bold">S</span>
                    <span *ngIf="transfered.tu.isHoliday" class="big-n-bold">H</span>
                  </div>
                </td>
                <td style="padding-left: 6%" (click)="openEdit(editwindow, 'we', transfered)">
                  <div class="overflow">
                    <span *ngIf="transfered.we.isSunday" class="big-n-bold">S</span>
                    <span *ngIf="transfered.we.isHoliday" class="big-n-bold">H</span>
                  </div>
                </td>
                <td style="padding-left: 7%" (click)="openEdit(editwindow, 'th', transfered)">
                  <div class="overflow">
                    <span *ngIf="transfered.th.isSunday" class="big-n-bold">S</span>
                    <span *ngIf="transfered.th.isHoliday" class="big-n-bold">H</span>
                  </div>
                </td>
                <td style="padding-left: 7.5%" (click)="openEdit(editwindow, 'fr', transfered)">
                  <div class="overflow">
                    <span *ngIf="transfered.fr.isSunday" class="big-n-bold">S</span>
                    <span *ngIf="transfered.fr.isHoliday" class="big-n-bold">H</span>
                  </div>
                </td>
                <td style="padding-left: 8%" (click)="openEdit(editwindow, 'sa', transfered)">
                  <div class="overflow">
                    <span *ngIf="transfered.sa.isSunday" class="big-n-bold">S</span>
                    <span *ngIf="transfered.sa.isHoliday" class="big-n-bold">H</span>
                  </div>
                </td>
                <td style="padding-left: 9%" (click)="openEdit(editwindow, 'su', transfered)">
                  <div class="overflow">
                    <span *ngIf="transfered.su.isSunday" class="big-n-bold">S</span>
                    <span *ngIf="transfered.su.isHoliday" class="big-n-bold">H</span>
                  </div>
                </td>
                <td class="text-right non-click">
                </td>
              </tr>

              <tr *ngIf="transfered.showSleep()">
                <th scope="row" class="bg-on">ON</th>
                <td style="padding-left: 6%" (click)="openEdit(editwindow, 'mo', transfered)">
                  <div class="overflow">
                    <fa-icon *ngIf="transfered.mo.isSleepOver" [icon]="checkMark"></fa-icon>
                  </div>
                </td>
                <td style="padding-left: 6%" (click)="openEdit(editwindow, 'tu', transfered)">
                  <div class="overflow">
                    <fa-icon *ngIf="transfered.tu.isSleepOver" [icon]="checkMark"></fa-icon>
                  </div>
                </td>
                <td style="padding-left: 6%" (click)="openEdit(editwindow, 'we', transfered)">
                  <div class="overflow">
                    <fa-icon *ngIf="transfered.we.isSleepOver" [icon]="checkMark"></fa-icon>
                  </div>
                </td>
                <td style="padding-left: 7%" (click)="openEdit(editwindow, 'th', transfered)">
                  <div class="overflow">
                    <fa-icon *ngIf="transfered.th.isSleepOver" [icon]="checkMark"></fa-icon>
                  </div>
                </td>
                <td style="padding-left: 7.5%" (click)="openEdit(editwindow, 'fr', transfered)">
                  <div class="overflow">
                    <fa-icon *ngIf="transfered.fr.isSleepOver" [icon]="checkMark"></fa-icon>
                  </div>
                </td>
                <td style="padding-left: 8%" (click)="openEdit(editwindow, 'sa', transfered)">
                  <div class="overflow">
                    <fa-icon *ngIf="transfered.sa.isSleepOver" [icon]="checkMark"></fa-icon>
                  </div>
                </td>
                <td style="padding-left: 9%" (click)="openEdit(editwindow, 'su', transfered)">
                  <div class="overflow">
                    <fa-icon *ngIf="transfered.su.isSleepOver" [icon]="checkMark"></fa-icon>
                  </div>
                </td>
                <td class="text-right non-click">
                </td>
              </tr>

              <tr *ngIf="btnTap === tourTypes.Inland" style="color: red;">
                <th scope="row" class="bg-blue text-default">H</th>
                <td style="padding-left: 6%" (click)="openEdit(editwindow, 'mo', transfered)">
                  <div class="overflow">
                    {{transfered.mo.resttimeHours(true)}}
                    <fa-icon *ngIf="transfered.mo.resttimeHasEdit()" [icon]="userPen"></fa-icon>
                  </div>
                </td>
                <td style="padding-left: 6%" (click)="openEdit(editwindow, 'tu', transfered)">
                  <div class="overflow">
                    {{transfered.tu.resttimeHours(true)}}
                    <fa-icon *ngIf="transfered.tu.resttimeHasEdit()" [icon]="userPen"></fa-icon>
                  </div>
                </td>
                <td style="padding-left: 6%" (click)="openEdit(editwindow, 'we', transfered)">
                  <div class="overflow">
                    {{transfered.we.resttimeHours(true)}}
                    <fa-icon *ngIf="transfered.we.resttimeHasEdit()" [icon]="userPen"></fa-icon>
                  </div>
                </td>
                <td style="padding-left: 7%" (click)="openEdit(editwindow, 'th', transfered)">
                  <div class="overflow">
                    {{transfered.th.resttimeHours(true)}}
                    <fa-icon *ngIf="transfered.th.resttimeHasEdit()" [icon]="userPen"></fa-icon>
                  </div>
                </td>
                <td style="padding-left: 7.5%" (click)="openEdit(editwindow, 'fr', transfered)">
                  <div class="overflow">
                    {{transfered.fr.resttimeHours(true)}}
                    <fa-icon *ngIf="transfered.fr.resttimeHasEdit()" [icon]="userPen"></fa-icon>
                  </div>
                </td>
                <td style="padding-left: 8%" (click)="openEdit(editwindow, 'sa', transfered)">
                  <div class="overflow">
                    {{transfered.sa.resttimeHours(true)}}
                    <fa-icon *ngIf="transfered.sa.resttimeHasEdit()" [icon]="userPen"></fa-icon>
                  </div>
                </td>
                <td style="padding-left: 9%" (click)="openEdit(editwindow, 'su', transfered)">
                  <div class="overflow">
                    {{transfered.su.resttimeHours(true)}}
                    <fa-icon *ngIf="transfered.su.resttimeHasEdit()" [icon]="userPen"></fa-icon>
                  </div>
                </td>

                <td class="text-right non-click">
                  {{transfered.sumResttimeHours(true)}}
                  <fa-icon *ngIf="transfered.sumResttimeHasEdit()" [icon]="userPen"></fa-icon>
                </td>
              </tr>


              <tr *ngIf="btnTap === tourTypes.Inland">
                <td scope="row">{{'common.total' | translate}}</td>
                <td style="padding-left: 6%" (click)="openEdit(editwindow, 'mo', transfered)">
                  <div class="overflow">
                    {{transfered.mo.totalHours(true,showWithBreaskSubtracted)}}
                    <fa-icon *ngIf="transfered.mo.totalHasEdit()" [icon]="userPen"></fa-icon>
                  </div>
                </td>
                <td style="padding-left: 6%" (click)="openEdit(editwindow, 'tu', transfered)">
                  <div class="overflow">
                    {{transfered.tu.totalHours(true,showWithBreaskSubtracted)}}
                    <fa-icon *ngIf="transfered.tu.totalHasEdit()" [icon]="userPen"></fa-icon>
                  </div>
                </td>
                <td style="padding-left: 6%" (click)="openEdit(editwindow, 'we', transfered)">
                  <div class="overflow">
                    {{transfered.we.totalHours(true,showWithBreaskSubtracted)}}
                    <fa-icon *ngIf="transfered.we.totalHasEdit()" [icon]="userPen"></fa-icon>
                  </div>
                </td>
                <td style="padding-left: 7%" (click)="openEdit(editwindow, 'th', transfered)">
                  <div class="overflow">
                    {{transfered.th.totalHours(true,showWithBreaskSubtracted)}}
                    <fa-icon *ngIf="transfered.th.totalHasEdit()" [icon]="userPen"></fa-icon>
                  </div>
                </td>
                <td style="padding-left: 7.5%" (click)="openEdit(editwindow, 'fr', transfered)">
                  <div class="overflow">
                    {{transfered.fr.totalHours(true,showWithBreaskSubtracted)}}
                    <fa-icon *ngIf="transfered.fr.totalHasEdit()" [icon]="userPen"></fa-icon>
                  </div>
                </td>
                <td style="padding-left: 8%" (click)="openEdit(editwindow, 'sa', transfered)">
                  <div class="overflow">
                    {{transfered.sa.totalHours(true,showWithBreaskSubtracted)}}
                    <fa-icon *ngIf="transfered.sa.totalHasEdit()" [icon]="userPen"></fa-icon>
                  </div>
                </td>
                <td style="padding-left: 9%" (click)="openEdit(editwindow, 'su', transfered)">
                  <div class="overflow">
                    {{transfered.su.totalHours(true,showWithBreaskSubtracted)}}
                    <fa-icon *ngIf="transfered.su.totalHasEdit()" [icon]="userPen"></fa-icon>
                  </div>
                </td>
                <td class="text-right non-click">
                  {{transfered.sumTotalHours(true,showWithBreaskSubtracted)}}
                  <fa-icon *ngIf="transfered.sumTotalHasEdit()" [icon]="userPen"></fa-icon>
                </td>
              </tr>

            </tbody>
          </table>
        </div>
      </div>


      <div class="col-12 d-flex justify-content-center">
        <button class="btn btn-outline-default" (click)="createUserAgreedWeek()"
                *ngIf="canCreateUserAgreedWeek()">
          {{'btn.freezeWeek' | translate}}
        </button>
      </div>
    </div>
  </div>
</div>

<ng-template #editwindow let-modal>
  <div class="card">
    <div class="card-body card-body-with-padding">

      <div class="row">
        <div class="col-12">
          {{dayName}}
        </div>
      </div>

      <div class="row">
        <div class="col-10 offset-2 col-sm-3 offset-sm-1">
          <small>Ret minutter</small>
        </div>
        <div class="col-6 col-sm-1 center-text">
          <small>ny tid</small>
        </div>
        <div class="col-6 col-sm-1 center-text">
          <small>tid</small>
        </div>
        <div class="col-12 col-sm-6">
          <small>kommentar</small>
        </div>
      </div>

      <hr />

      <!--Normal-->
      <div class="row">
        <div class="col-2 col-sm-1 center-text bg-green text-success"><span>N</span></div>
        <div class="col-10 col-sm-3">
          <div class="input-group">
            <input type="number" step="1" (keypress)="numberOnly($event)" class="form-control" [(ngModel)]="selectedDay.edited_normal">
          </div>
        </div>
        <div class="col-6 col-sm-1 center-text">{{selectedDay.normalHours(true,false)}}</div>
        <div class="col-6 col-sm-1 center-text">{{selectedDay.normalHours(false,false)}}</div>
        <div class="col-12 col-sm-6">
          <div class="input-group">
            <input type="text" class="form-control" [(ngModel)]="selectedDay.edited_comment_normal" />
          </div>
        </div>
      </div>
      <div class="spacer"></div>

      <!--Overtime1-->
      <div class="row" *ngIf="greaterThan(selectedTableTotal.calculateOvertime1(false,false), 0)">
        <div class="col-2 col-sm-1 center-text bg-yellow text-warning">T1</div>
        <div class="col-10 col-sm-3">
          <div class="input-group">
            <input type="number" step="1" (keypress)="numberOnly($event)" class="form-control" [(ngModel)]="selectedDay.edited_overtime1">
          </div>
        </div>
        <div class="col-6 col-sm-1 center-text">{{selectedDay.overtime1Hours(true,false)}}</div>
        <div class="col-6 col-sm-1 center-text">{{selectedDay.overtime1Hours(false,false)}}</div>
        <div class="col-12 col-sm-6">
          <div class="input-group">
            <input type="text" class="form-control" [(ngModel)]="selectedDay.edited_comment_overtime1" />
          </div>
        </div>
      </div>
      <div class="spacer" *ngIf="greaterThan(selectedTableTotal.calculateOvertime1(false,false), 0)"></div>

      <!--Overtime2-->
      <div class="row" *ngIf="btnTap === tourTypes.Inland && greaterThan(selectedTableTotal.calculateOvertime2(false,false), 0)">
        <div class="col-2 col-sm-1 center-text bg-red text-danger">T2</div>
        <div class="col-10 col-sm-3">
          <div class="input-group">
            <input type="number" step="1" (keypress)="numberOnly($event)" class="form-control" [(ngModel)]="selectedDay.edited_overtime2">
          </div>
        </div>
        <div class="col-6 col-sm-1 center-text">{{selectedDay.overtime2Hours(true,false)}}</div>
        <div class="col-6 col-sm-1 center-text">{{selectedDay.overtime2Hours(false,false)}}</div>
        <div class="col-12 col-sm-6">
          <div class="input-group">
            <input type="text" class="form-control" [(ngModel)]="selectedDay.edited_comment_overtime2" />
          </div>
        </div>
      </div>
      <div class="spacer" *ngIf="btnTap === tourTypes.Inland && greaterThan(selectedTableTotal.calculateOvertime2(false,false), 0)"></div>

      <!--Overtime3-->
      <div class="row" *ngIf="btnTap === tourTypes.Inland  && greaterThan(selectedTableTotal.calculateOvertime3(false,false), 0)">
        <div class="col-2 col-sm-1 center-text bg-t3">T3</div>
        <div class="col-10 col-sm-3">
          <div class="input-group">
            <input type="number" step="1" (keypress)="numberOnly($event)" class="form-control" [(ngModel)]="selectedDay.edited_overtime3">
          </div>
        </div>
        <div class="col-6 col-sm-1 center-text">{{selectedDay.overtime3Hours(true,false)}}</div>
        <div class="col-6 col-sm-1 center-text">{{selectedDay.overtime3Hours(false,false)}}</div>
        <div class="col-12 col-sm-6">
          <div class="input-group">
            <input type="text" class="form-control" [(ngModel)]="selectedDay.edited_comment_overtime3" />
          </div>
        </div>
      </div>
      <div class="spacer" *ngIf="btnTap === tourTypes.Inland  && greaterThan(selectedTableTotal.calculateOvertime3(false,false), 0)"></div>

      <!--Overtime4-->
      <div class="row" *ngIf="btnTap === tourTypes.Inland  && greaterThan(selectedTableTotal.calculateOvertime4(false,false), 0)">
        <div class="col-2 col-sm-1 center-text bg-t4">T4</div>
        <div class="col-10 col-sm-3">
          <div class="input-group">
            <input type="number" step="1" (keypress)="numberOnly($event)" class="form-control" [(ngModel)]="selectedDay.edited_overtime4">
          </div>
        </div>
        <div class="col-6 col-sm-1 center-text">{{selectedDay.overtime4Hours(true,false)}}</div>
        <div class="col-6 col-sm-1 center-text">{{selectedDay.overtime4Hours(false,false)}}</div>
        <div class="col-12 col-sm-6">
          <div class="input-group">
            <input type="text" class="form-control" [(ngModel)]="selectedDay.edited_comment_overtime4" />
          </div>
        </div>
      </div>
      <div class="spacer" *ngIf="btnTap === tourTypes.Inland  && greaterThan(selectedTableTotal.calculateOvertime4(false,false), 0)"></div>

      <!--holyday-->
      <div class="row">
        <div class="col-2 col-sm-1 center-text bg-sh">H</div>
        <div class="col-10 col-sm-3">
          <div class="input-group">
            <input type="checkbox" class="form-control" [(ngModel)]="selectedDay.isHoliday">
          </div>
        </div>
      </div>
      <div class="spacer"></div>

      <!--Sun-->
      <div class="row">
        <div class="col-2 col-sm-1 center-text bg-sh">S</div>
        <div class="col-10 col-sm-3">
          <div class="input-group">
            <input type="checkbox" class="form-control" [(ngModel)]="selectedDay.isSunday">
          </div>
        </div>
      </div>
      <div class="spacer"></div>

      <!--Sleepover-->
      <div class="row" *ngIf="dayName !== 'Overført'">
        <div class="col-2 col-sm-1 center-text bg-on">ON</div>
        <div class="col-10 col-sm-3">
          <div class="input-group">
            <input type="checkbox" class="form-control" [(ngModel)]="selectedDay.isSleepOver">
          </div>
        </div>
      </div>
      <div class="spacer"></div>

      <!--Resttime-->
      <div class="row" *ngIf="btnTap === tourTypes.Inland" style="color: red;">
        <div class="col-2 col-sm-1 bg-blue text-default center-text" style="height: calc(1.5em + .75rem + 2px);">H</div>
        <div class="col-10 col-sm-3">
          <div class="input-group">
            <!--<input type="number" step="1" (keypress)="numberOnly($event)" class="form-control" [(ngModel)]="selectedDay.edited_resttime">-->
          </div>
        </div>
        <div class="col-6 col-sm-1 center-text">{{selectedDay.resttimeHours(true)}}</div>
        <div class="col-6 col-sm-1 center-text">{{selectedDay.resttimeHours(false)}}</div>
      </div>

      <hr />

      <!--Total-->
      <div class="row" *ngIf="btnTap === tourTypes.Inland">

        <div class="col-6 col-sm-1 offset-6  offset-sm-1 center-text">{{selectedDay.totalHours(true,false)}}</div>
        <div class="col-6 col-sm-1 center-text">{{selectedDay.totalHours(false,false)}}</div>
      </div>

    </div>
  </div>
</ng-template>
