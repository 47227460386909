import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import {HttpClient} from "@angular/common/http";
import { BindEyeToVehicleModel, CreateEyeModel, EyeModel, UpdateEyeModel } from "./eye.model";
import { Observable } from "rxjs";
const url = environment.baseUrl + '/V1/Eye'
@Injectable({
    providedIn: 'root'
  })

  export class eyeService{
    constructor(private http: HttpClient) { }

    createEyeService( model: CreateEyeModel) {
      return this.http.post<any>(`${url}/create`, model);
    }

    getAllEyes() {
      return this.http.get<any>(`${url}/All`);
    }

    getAllAvailableEyes() {
      return this.http.get<any>(`${url}/Available`);
    }

    getSingle(id: number): Observable<EyeModel>{
        return this.http.get<EyeModel>(`${url}/From-Id/${id}`);
    }

    delete(id: number): Observable<any>{
        return this.http.delete(`${url}/delete/${id}`);
    }

    update(id: number, model: UpdateEyeModel): Observable<any> {
      return this.http.post<UpdateEyeModel>(`${url}/update/`, model);
    }

    bind(model: BindEyeToVehicleModel): Observable<any> {
      return this.http.patch<BindEyeToVehicleModel>(`${url}/bind/`, model);
    }
    
    unbind(id: number): Observable<any> {
      return this.http.patch<BindEyeToVehicleModel>(`${url}/unbind/${id}`,null);
    }
  }
