<div class="full-height bg-light">
  <form [formGroup]="formGroup" class="col-md-12 pe-4">
    <div class="form-group row">
      <div class="col-md-4 pe-0">
        <label for="registrationNumber">{{'vehicle.reg' | translate}}
          <span *ngIf="formGroup.controls['registrationNumber'].hasError('maxlength')" class="required-text">
            {{'message.maxLength' | translate: {number: 10} }}
          </span>
        </label>
        <input id="registrationNumber" type="text" formControlName="registrationNumber" class="form-control form-control-sm">
      </div>
      <div class="col-md-4 pe-0">
        <label for="chassisNumber">{{'vehicle.chassis' | translate}}
          <span *ngIf="formGroup.controls['chassisNumber'].hasError('whitespace')" class="required-text">
            {{'message.nowhitespace' | translate }} <br>
          </span>
          <span *ngIf="formGroup.controls['chassisNumber'].hasError('pattern')" class="required-text">
            {{'message.chassisnumberpattern' | translate }} <br>
          </span>
          <span *ngIf="formGroup.controls['chassisNumber'].hasError('maxlength')" class="required-text">
            {{'message.maxLength' | translate: {number: 50} }}
          </span>
        </label>
        <input id="chassisNumber" type="text" formControlName="chassisNumber" class="form-control form-control-sm">
      </div>
      <div class="col-md-4 pe-0">
        <label>{{'vehicle.type' | translate}}
          <span *ngIf="formGroup.controls['typeName'].hasError('required')" class="required-text">
            {{'message.required' | translate}}
          </span>
        </label>
        <!-- <select id="type" type="text" formControlName="type" class="form-select form-select-sm">
          <option *ngFor="let type of types" [value]="type">{{frontVehicleService.getTranslateType(type) | translate}}</option>
        </select> -->
        <app-combobox (selectedOption)="this.updateFormResourceType($event)" [options]="this.resourceTypes" [keyToSearch]="'resourceTypeName'"></app-combobox>
      </div>
      <div class="col-md-2 pe-0">
        <label for="unitIdentification">{{'vehicle.no' | translate}}
          <span *ngIf="formGroup.controls['unitIdentification'].hasError('maxlength')" class="required-text">
            {{'message.maxLength' | translate: {number: 50} }}
          </span>
        </label>
        <input id="unitIdentification" type="text" formControlName="unitIdentification" class="form-control form-control-sm">
      </div>
      <div class="col-md-6 pe-0">
        <label for="manufacturer">{{'vehicle.manufacturer' | translate}}
          <span *ngIf="formGroup.controls['manufacturer'].hasError('maxlength')" class="required-text">
            {{'message.maxLength' | translate: {number: 50} }}
          </span>
        </label>
        <input id="manufacturer" type="text" formControlName="manufacturer" class="form-control form-control-sm">
      </div>
      <div class="col-md-4 pe-0">
        <label for="manufactureDate">{{'vehicle.manufactureDate' | translate}}</label>
        <input id="manufactureDate" type="date" formControlName="manufactureDate" class="form-control form-control-sm">
      </div>
      <div class="col-md-8 pe-0">
        <label for="construction">{{'vehicle.construction' | translate}}
          <span *ngIf="formGroup.controls['construction'].hasError('maxlength')" class="required-text">
            {{'message.maxLength' | translate: {number: 255} }}
          </span>
        </label>
        <input id="construction" type="text" formControlName="construction" class="form-control form-control-sm">
      </div>
      <div class="col-md-2 pe-0">
        <label for="axleCount">{{'vehicle.axles' | translate}}
          <span *ngIf="formGroup.controls['axleCount'].hasError('required')" class="required-text">
            {{'message.required' | translate}}
          </span>
        </label>
        <input id="axleCount" type="text" formControlName="axleCount" class="form-control form-control-sm">
      </div>
      <div class="col-md-2 pe-0">
        <label for="euroStandard">{{'vehicle.eu' | translate}}</label>
        <input id="euroStandard" type="text" formControlName="euroStandard" class="form-control form-control-sm">
      </div>
      <div class="col-md-3 pe-0">
        <label for="deadWeight">{{'vehicle.deadWeight' | translate}}
          <span *ngIf="formGroup.controls['deadWeight'].hasError('required')" class="required-text">
            {{'message.required' | translate}}
          </span>
        </label>
        <input id="deadWeight" type="text" formControlName="deadWeight" class="form-control form-control-sm">
      </div>
      <div class="col-md-3 pe-0">
        <label for="totalWeight">{{'vehicle.totalWeight' | translate}}
          <span *ngIf="formGroup.controls['totalWeight'].hasError('required')" class="required-text">
            {{'message.required' | translate}}
          </span>
        </label>
        <input id="totalWeight" type="text" formControlName="totalWeight" class="form-control form-control-sm">
      </div>

      <div class="col-md-6 pe-0">
        <label for="propellant">{{'vehicle.propellant' | translate}}</label>
        <span *ngIf="formGroup.controls['propellantId'].hasError('required')" class="required-text">
          {{'message.required' | translate}}
        </span>
        <select id="propellantType" type="text" formControlName="propellantId" class="form-select form-select-sm">
          <option *ngFor="let propellant of propellantTypes | async " [value]="propellant.id">{{propellant.name}}</option>
        </select>
      </div>

      <!-- Auto calculated mileage and fuel -->
      <div class="col-md-3 pe-0">
        <label for="timestampMileage">{{'vehicle.timestampMileage' | translate}}
          <span *ngIf="formGroup.controls['timestampMileage'].hasError('pattern')" class="required-text">
            {{'message.timestampmileagepattern' | translate }} <br>
          </span>
        </label>
        <input id="timestampMileage" type="text" formControlName="timestampMileage" class="form-control form-control-sm">
      </div>
      <div class="col-md-3 pe-0">
        <label for="timestampFuel">{{'vehicle.timestampFuel' | translate}}
          <span *ngIf="formGroup.controls['timestampFuel'].hasError('pattern')" class="required-text">
            {{'message.timestampfuelpattern' | translate }} <br>
          </span>
        </label>
        <input id="timestampFuel" type="text" formControlName="timestampFuel" class="form-control form-control-sm">
      </div>

      <div class="col-md-6 pe-0">
        <label for="timestampMileageDate">{{'vehicle.timestampMileageDate' | translate}}</label>
        <input id="timestampMileageDate" type="date" formControlName="timestampMileageDate" class="form-control form-control-sm">
      </div>

      <div class="col-md-12 pe-0">
        <label for="description">{{'vehicle.description' | translate}}
          <span *ngIf="formGroup.controls['description'].hasError('maxlength')" class="required-text">
            {{'message.maxLength' | translate: {number: 255} }}
          </span>
        </label>
        <textarea autosize id="description" type="text" formControlName="description" class="form-control form-control-sm"></textarea>
      </div>

      <div class="col-md-12" style="padding: 10px 0 0 0">
        <app-equipment-widget [equipments]="formGroup.value.equipments" [createMode]="true"></app-equipment-widget>
      </div>
    </div>
  </form>
  <div class="btn-container">
    <app-btn-accept-widget [formGroup]="formGroup" (accept)="create()" (cancel)="cancel.emit()"></app-btn-accept-widget>
  </div>
</div>
