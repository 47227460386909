<!-- <div class="product-type-picker product-type-picker--component">
  <button class="btn btn-primary btn-sm" (click)="this.openSesame()">
    @if (this.formGroup.get('freight')?.value) {
      <span>[Ændre godstyper]</span>
    }
    @else {
      <span>[Vælg godstyper]</span>
    }
  </button>
</div> -->

<app-modal #productTypeModalComponent [maxWidth]="'900px'" (confirmed)="this.confirmAndEmitForm()" (cancelled)="this.closeAndReset()">
  <div class="modal-body">
    <form [formGroup]="this.formGroup">
      <div class="row">
        <!-- Product Type -->
        <div class="col-6 form-group">
          <label for="productType">[Godstype*]</label>
          <span *ngIf="this.formGroup.get('freightTypeId')?.hasError('required')" class="required-text">
            {{'message.required' | translate }}
          </span>
          <ejs-combobox
            #productType
            id='productTypes'
            [dataSource]='this.products()'
            [fields]="{ text: 'name' }"
            (change)="this.setProductType($event)"
          ></ejs-combobox>
        </div>
      </div>


      <div class="row">
        <!-- Product Type -->
        <div *ngIf="this.selectedProduct" class="col-6 form-group">
          <label for="productType">[Mængde]</label>
          <span *ngIf="this.formGroup.get('amount')?.hasError('required')" class="required-text">
            {{'message.required' | translate }}
          </span>
          <!-- <ejs-combobox id='productTypes' [dataSource]='this.products()' [fields]="{ text: 'name', value: 'id' }" (change)="this.setProductType($event)"></ejs-combobox> -->
          <input type="number" class="form-control" formControlName="amount">
        </div>

        <!-- Freight Unit -->
        <div *ngIf="this.formGroup.get('amount')?.value" class="col-4 form-group">
          <label for="freightTypeId">[FreightType*]</label>
          <span *ngIf="this.formGroup.get('freightTypeId')?.hasError('required')" class="required-text">
            {{'message.required' | translate }}
          </span>
          <ejs-combobox id='freightTypeId' [dataSource]='this.selectedProduct.freightUnit' [fields]="{ text: 'name' }" (change)="this.setFreightType($event)"></ejs-combobox>
        </div>
      </div>


      <!-- Surcharges -->
      <div class="row align-items-center">
        <div class="col-12 pb-1 pt-4">
          <!-- <label>
            [Tilføj tillæg?]
            <input type="checkbox" [(ngModel)]="this.showAdditionalSurcharges" [ngModelOptions]="{standalone: true}">
          </label> -->

          <h5>[Tillæg]</h5>
        </div>

        <div class="col-12 pb-2">
          <div *ngFor="let surcharge of this.surcharges; let index = index" class="row form-group">
            <!-- Existing surcharges -->
             <div class="col-11">
               <ejs-combobox
                  [dataSource]="surchargesList()"
                  [fields]="{ text: 'name', value: 'id' }"
                  [value]="surcharge.value.name"
                  [placeholder]="'old'"
                  (change)="setSurcharge($event, index)">
               </ejs-combobox>
             </div>

             <div class="col-1">
              <button class="btn btn-link py-1 text-danger bg-transparent" (click)="this.removeSurcharge(surcharge, index)">
                <fa-icon [icon]="faTrash"></fa-icon>
              </button>
             </div>
          </div>


          <div *ngIf="canAddEmptySurcharge()" class="row form-group">
            <!-- Extra empty surcharge field -->
             <div class="col-11">
              <ejs-combobox
                #newSurcharge
                [dataSource]="surchargesList()"
                [fields]="{ text: 'name' }"
                [placeholder]="'new'"
                (change)="addEmptySurcharge($event)">
              </ejs-combobox>
             </div>

             <div class="col-1">&nbsp;</div>
          </div>
        </div>
      </div>




      <!-- Price -->
      <!-- <div class="form-group">
        <label for="productType">Godstype pris</label>
        @if (this.productData && this.productData.price) {
          <input type="text" class="form-control">
        }
        @else {
          <input type="text" class="form-control" [value]="'Ingen priser'" [disabled]="true">
        }
      </div> -->
    </form>
  </div>
</app-modal>
