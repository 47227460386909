import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AgreementDetails, AgreementOverviewModel } from './agreed-management.models';


const url = environment.agreedBaseUrl + '/v1';
@Injectable({
  providedIn: 'root'
})
export class AgreedManagementService
{

  constructor(private http: HttpClient) { }

  GetAgreementOverview(): Observable<AgreementOverviewModel[]>
  {
    return this.http.get<AgreementOverviewModel[]>(`${url}/management/agreements/overview`);
  }

  GetAgreementTemplateOverview(): Observable<AgreementOverviewModel[]>
  {
    return this.http.get<AgreementOverviewModel[]>(`${url}/management/agreements/templates/overview`);
  }

  GetAgreementDetails(id: number): Observable<AgreementDetails>
  {
    return this.http.get<AgreementDetails>(`${url}/management/agreements/${id}`);
  }

  GetTempalteDetails(id: number): Observable<AgreementDetails>
  {
    return this.http.get<AgreementDetails>(`${url}/management/agreements/templates/${id}`);
  }

  UpsertAgreement(model: AgreementDetails): Observable<number>
  {
    return this.http.post<number>(`${url}/management/agreements/upsert`, model);
  }

  UpsertTemplate(model: AgreementDetails): Observable<number>
  {
    return this.http.post<number>(`${url}/management/agreements/templates/upsert`, model);
  }
}
