import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { Component, EventEmitter, Input, Output, signal, ViewChild } from '@angular/core';
import { ModalComponent } from '../modal/modal.component';
import { ComboBoxAllModule, ComboBoxModule, ComboBoxComponent } from '@syncfusion/ej2-angular-dropdowns';
import { FormBuilder, FormsModule, UntypedFormGroup, Validators, ReactiveFormsModule, FormArray, FormGroup } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { TranslateModule } from '@ngx-translate/core';
import { SurchargeService } from 'src/app/pricing/surcharge/shared/surcharge.service';
import { FreightTypeService } from 'src/app/freight-types/shared/freight-types.service';
import { CustomerModel } from 'src/app/customers/shared/customer.model';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { DataManager, Query, ODataV4Adaptor } from '@syncfusion/ej2-data';


export interface Currency {
  exchangeRate: number;
  id: number;
  isoName: string;
  lastUpdated: string;
  name: string;
  source: string;
}

export interface SurchargeType {
  id: number;
  name: string;
}

export interface Surcharge {
  adjustmentIndexId: number;
  chargePerKm: number;
  currency: Currency;
  customer: CustomerModel;
  id: number;
  name: string;
  staticCharge: number;
  surchargeType: SurchargeType;
}



@Component({
  selector: 'app-product-type-picker',
  templateUrl: './product-type-picker.component.html',
  styleUrl: './product-type-picker.component.scss',
  standalone: true,
  imports: [ComboBoxModule, ModalComponent, FormsModule, CommonModule, ReactiveFormsModule, TranslateModule, FaIconComponent]
})
export class ProductTypePickerComponent {
  @ViewChild('productTypeModalComponent') productTypeModalComponent!: ModalComponent
  @ViewChild('newSurcharge') newSurcharge!: ComboBoxComponent
  @ViewChild('productType') productType!: ComboBoxComponent

  @Input() data: any = null
  @Input() addresses: string = ''

  @Output() dataEmit = new EventEmitter<any>()


  faTrash = faTrash

  products = signal<any[]>([])
  productData: any = {
    freightTypeId: 0,
    productType: {
      name: ''
    },
    freight: '',
    freightTotalAmount: 0,
    surcharge: [{
      name: "",
      km: ""
    }]
  }
  pricingTables: any = signal<any[]>([]);
  showModal: boolean = false
  selectedProduct: any = null
  selectedPriceTable: any = signal<any[]>([])
  showAdditionalSurcharges: boolean = false
  surchargesList: any = signal<any[]>([])
  formGroup: UntypedFormGroup
  type: number = 9999
  addressType: number = 9999
  surcharges: any[] = []
  addressIndex: number = 0




  constructor(private http: HttpClient, private formBuilder: FormBuilder, private surchargeService: SurchargeService, private freightService: FreightTypeService) {
    this.formGroup = this.formBuilder.group({
      productTypeId: 0,
      productType: {
        name: ''
      },
      type: '',
      surcharges: this.formBuilder.array([]),
      surchargesDisplay: this.formBuilder.array([]),
      priceUnitId: null,
      amount: 0
    })


  }


  ngOnInit(): void {

  }


  getProducts() {
    this.freightService.getAllProducts().subscribe((response) => {
      this.products.set(response)
      console.log('this.products: ', this.products())
    })

    console.log('this.products: ', this.products)
  }


  getSurcharges() {
    this.surchargeService.getAllSurcharges().subscribe((surcharges: any) => {
      this.surchargesList.set(surcharges)
    })
  }


  /**
   * Method is executed when the user selects a product type from the combobox
   * @param input the input from the combobox component
   */
  setProductType(input: any) {
    this.selectedProduct = this.products().find((product: any) => {
      return product.id == input.itemData.id
    })

    this.formGroup.patchValue({
      productTypeId: this.selectedProduct.id,
      productType: {
        name: this.selectedProduct.name
      },
      type: this.addressType
    })
  }


  setFreightType(input: any) {
    const selectedFreightType = this.selectedProduct.freightUnit.find((freightType: any) => {
      return freightType.id == input.itemData.id
    })

    this.formGroup.patchValue({
      freightTypeId: selectedFreightType.id,
      freightUnit: selectedFreightType.name
    })
  }


  setSurcharge(input: any, surchargeIndex: number) {
    const selectedSurcharge = this.surchargesList().find((surcharge: Surcharge) => surcharge.id === input.itemData.id);

    if (selectedSurcharge) {
      this.surcharges[surchargeIndex] = this.formBuilder.group(selectedSurcharge)
      this.surcharges.push(this.formBuilder.group({}))

      // Update form group
      this.formGroup.setControl('surcharges', this.formBuilder.array(this.surcharges))
      this.formGroup.setControl('surchargesDisplay', this.formBuilder.array(this.surcharges))
    }
  }


  addEmptySurcharge(input: any) {
    const selectedSurcharge = this.surchargesList().find((surcharge: Surcharge) => surcharge.id === input.itemData.id)

    if (selectedSurcharge) {
      // Correctly push to the FormArray
      this.surcharges.push(
        this.formBuilder.group({
          ...selectedSurcharge
        }),
        this.formBuilder.group({})
      )
    }

    this.formGroup.setControl('surcharges', this.formBuilder.array(this.surcharges))
    this.formGroup.setControl('surchargesDisplay', this.formBuilder.array(this.surcharges))
    this.newSurcharge.clear()
  }


  removeSurcharge(surcharge: Surcharge, surchargeIndex: number) {
    this.surcharges.splice(surchargeIndex, 1)
    this.formGroup.patchValue({ surcharges: this.surcharges })
  }


  canAddEmptySurcharge(): boolean {
    // Allow one empty field if the last item is not empty
    return this.surcharges.length === 0 || this.surcharges[this.surcharges.length - 1]?.id
  }


  clearForm() {
    const surchargesArray = this.formBuilder.array([]);
    this.productType.clear()
    this.surcharges = []

    this.formGroup.patchValue({
      productTypeId: 0,
      productType: {
        name: ''
      },
      type: '',
      priceUnitId: null,
      amount: 0
    });

    // Reset surcharges field as FormArray
    this.formGroup.setControl('surcharges', surchargesArray);
  }


  openModal() {
    // if (!this.data) {
    //   this.clearForm()
    // }
    this.getProducts()
    this.getSurcharges()

    if (this.data) {
      this.formGroup.patchValue({
        productTypeId: this.data.freightTypeId,
        productType: {
          name: this.data.productType.name
        },
        type: this.data.type,
        surcharges: this.data.surcharges,
        priceUnitId: this.data.priceUnitId,
        amount: this.data.amount
      })

      this.surcharges = this.data.surcharges
    }

    this.productTypeModalComponent.open()
  }

  confirmAndEmitForm() {
    // if the last object in this.formGroup.value.surcharges is empty, delete it
    if (Object.keys(this.formGroup.value.surcharges[this.formGroup.value.surcharges.length - 1]).length === 0) {
      this.formGroup.value.surcharges.splice(this.formGroup.value.surcharges.length - 1, 1)
    }

    // change surcharges in the formGroup to be a comma-separated string of surcharge ids
    this.formGroup.value.surcharges = this.formGroup.value.surcharges.map((surcharge: any) => surcharge.id)


    this.dataEmit.emit({ formGroup: this.formGroup, type: this.type, addressType: this.addressType })

    this.productTypeModalComponent.close()
  }


  closeAndReset() {
    this.clearForm()
    this.productTypeModalComponent.close()
  }
}
