import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Observable, lastValueFrom } from "rxjs";
import { tap } from "rxjs/operators";
import { TokenService } from "../services/token.service";
import { Router } from "@angular/router";
import { NotificationService } from "../shared/services/notification.service";
import { SharedService } from "../shared/services/shared.service";
import { environment } from "../../environments/environment";
import { OidcSecurityService } from 'angular-auth-oidc-client';

@Injectable({
  providedIn: 'root'
})
export class Interceptor implements HttpInterceptor {

  language: any;

  constructor(private tokenService: TokenService,
    private router: Router,
    private notifyService: NotificationService,
    private sharedService: SharedService,
    private oidc: OidcSecurityService) { }


  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!req.url.includes('https://api.aquillo.dk/') && !req.url.includes('teltonika') && !req.url.includes('/tcp') && !req.url.includes('tomtom')) {
      let access_token = '';
      this.oidc.getAccessToken().subscribe(x => {
        access_token = x;
      }); //ugly solution.

      //req = req.clone({ setHeaders: { Authorization: `Bearer ${this.tokenService.getToken()}` } });

      req = req.clone({ setHeaders: { Authorization: `Bearer ${access_token}` } });
      req = req.clone({ setHeaders: { 'tenant': this.tokenService.getCompanyGuid() } });
      req = req.clone({ setHeaders: { 'requestTimeZone': Intl.DateTimeFormat().resolvedOptions().timeZone } });

      let groupId = sessionStorage.getItem('groupId');

      if (!groupId) {
        groupId = ''; // Optional: Set a default value
      }

      req = req.clone({
        setHeaders: {
          'groupId': groupId
        }
      });
    }
    else {
      if (req.url.includes('teltonika')) {
        req = req.clone({ setHeaders: { Authorization: `Bearer ${environment.fotaApiKey}` } });
      }

      if (req.url.includes('/tcp')) {
        req = req.clone({ setHeaders: { Authorization: `ApiKey ${environment.boxApiKey}` } });
        req = req.clone({ setHeaders: { "ApiKey": environment.boxApiKey } });
        req = req.clone({ setHeaders: { "Accept": 'text/plain, text/html, application/xhtml+xml, */*' } });
      }

      if (req.url.includes('tomtom')) {

        //  req = req.clone({ setHeaders: { "Accept": 'text/plain, text/html, application/xhtml+xml, */*' } });

      }
    }

    if (!req.url.includes('tomtom')) {
      req = req.clone({ setHeaders: { "x-api-key": "cbf933f6-5807-45e1-9a1e-c1941de653d2" } });
    }

    return next.handle(req).pipe(tap(() => { },
      async (err: any) => {
        if (err instanceof HttpErrorResponse) {
          const language = await lastValueFrom<any>(this.sharedService.getLanguageJson());
          //const language = await this.sharedService.getLanguageJson().toPromise();

          if (err.status === 401) {
            this.router.navigate(['error'], { queryParams: { status: 401, error: language.message.unAuthorizedTitle + ' (' + err.url + ')', message: language.message.unAuthorizedMessage } });
            //this.oidc.logoff().subscribe(x =>
            //{
            //});
          }
          if (err.status === 500) {
            console.error(err);
            this.router.navigate(['error'], { queryParams: { status: 500, error: language.message.serverErrorTitle, message: err.error } });
            this.notifyService.errorMessage(language.message.serverErrorTitle);
          }
          if (err.status === 400) {
            if (err.error?.errors) {
              console.error(err.error?.errors)
            } else {
              console.error(err.error)
            }

            // var errors = JSON.parse(err.error?.errors);
            this.notifyService.errorMessage(language.message.badRequestTitle);
          }
          // if(err.status === 403) {
          //   this.notifyService.errorMessage(language.message.forbiddenTitle);
          // }
        }
      }));
  }
}
