import {Component, Input, OnInit, Output, EventEmitter, Inject} from '@angular/core';
import {UserEventModel, UserModel} from "../../../users/shared/user.model";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {Observable} from "rxjs";
import {UntypedFormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {SharedService} from "../../../shared/services/shared.service";
import {SelectComponent} from "../select-component";
import {UserService} from "../../../users/shared/user.service";
import {ITuple} from "../../../shared/tuple";
import {TourEventModel, TourSimpleModel} from "../../shared/tour.model";

@Component({
  selector: 'app-user-select',
  templateUrl: './user-select.component.html',
  styleUrls: ['./user-select.component.scss']
})
export class UserSelectComponent extends SelectComponent<UserModel> implements OnInit {

  @Input()
  dateTimes: ITuple[] = [];

  conflicts: TourSimpleModel[] = [];
  eventConflicts: UserEventModel[] = [];
  constructor( @Inject(NgbModal) modalService: NgbModal, @Inject(UntypedFormBuilder) formBuilder: UntypedFormBuilder,
               @Inject(SharedService) public sharedService: SharedService,
              private userService: UserService) {
    super(modalService, formBuilder, sharedService);
    this.formGroup = this.formBuilder.group({
      user: [null, Validators.required]
    });
  }

  ngOnInit(): void {
    this.userService.getAllAvailable(this.dateTimes).subscribe(response => {
      this.data = response;
      this.assignCopy();
    });
    document.getElementById('btn')?.click();
    document.getElementById('search')?.focus();
  }

  submit(user: any, event: any) : void {
    var path = (event.composedPath && event.composedPath());
    if (path.some((x: { id: string; }) => x.id === 'conflict-btn')){
      user["showConflicts"] = true;
    }
    else {
      if (this.formGroup != undefined)
        this.formGroup.patchValue({user: user});
      this.modalReference.close();
    }
  }

  openConflictsModal(user: UserModel, content: any): void {
    this.conflicts = user.tours;
    this.eventConflicts = user.events;
    this.modalService.open(content);
  }

}
