import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {NoteModel} from "./shared/note.model";
import {UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {AddressModel} from "../address-widget/shared/address.model";
import {formatDate} from "@angular/common";
import { faDesktop, faMobileAlt, faPencilAlt, faTrash, faTrashAlt } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-note-widget',
  templateUrl: './note-widget.component.html'
})

export class NoteWidgetComponent implements OnInit {
  @Input() note: NoteModel | undefined;
  @Input() createMode = false;
  @Input() text: string = "note.text";
  @Input() showInAppCheckBox = true;
  @Input() oldLook: boolean = true
  @Input() includeAccept = true;
  @Input() logBook: boolean = false;

  @Output() cancelCreate = new EventEmitter();
  @Output() createNote = new EventEmitter();
  @Output() deleteNote = new EventEmitter();

  formGroup: UntypedFormGroup;
  toggleEdit = false;
  readOnly = true

  faEdit = faPencilAlt;
  faTrash = faTrashAlt;
  faMobileAlt = faMobileAlt;
  faDesktop = faDesktop;



  constructor(private formBuilder: UntypedFormBuilder) {
    this.formGroup = this.formBuilder.group({
      notes: ['', [Validators.required, Validators.maxLength(255)]],
      showInApp: [true]
    });
  }


  ngOnInit(): void {
    this.formGroup.valueChanges.subscribe(() => {
      if (this.createMode && !this.includeAccept) {
        this.createNote.emit(this.formGroup.value as NoteModel);
      }
    });
  }

  create(): void {
    this.createNote.emit(this.formGroup.value as NoteModel);
    this.formGroup.reset();
    this.cancelCreate.emit();

  }
}
