import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ProductTypeService {

  constructor() { }


  /**
   * Return return the value left of the freight amount to help filtering
   * @param addressIndex the index of the address
   * @param addressType the type of the address, arrive or leave
   */
  getFreightAmountLeft(addresses: any, addressIndex?: number, product?: any) {
    let leaveSummary: Record<string, number> = {};
    let arriveSummary: Record<string, number> = {};
    let _addressIndex = null

    // Loop over the addresses up to the given index
    if (addressIndex) {
      _addressIndex = addressIndex
    }

    addresses.controls.slice(0, _addressIndex ? _addressIndex + 1 : addresses.length + 1).forEach((address: any) => {
      if (address.get('addressFreights').value) {
        let currentLeaveSummary: Record<string, number> = {};
        let currentArriveSummary: Record<string, number> = {};

        const isLeaveStop = address.get('addressFreights').value.filter((freight: any) => freight.type === 2); // leave stop
        const isArriveStop = address.get('addressFreights').value.filter((freight: any) => freight.type === 1); // arrive stop

        // Sum freights per address
        address.get('addressFreights').value.forEach((freight: any) => {
          const productName = freight.productType.name.toLowerCase();

          if (product && freight.productType.name !== product) {
            return; // Skip if the product doesn't match
          }

          if (isLeaveStop.includes(freight)) {
            currentLeaveSummary[productName] = (currentLeaveSummary[productName] || 0) + (freight.amount || freight.amountOverload);
          }

          if (isArriveStop.includes(freight)) {
            currentArriveSummary[productName] = (currentArriveSummary[productName] || 0) + (freight.amount || freight.amountOverload);
          }
        });

        // Merge the current address's summary into the overall summary
        Object.entries(currentLeaveSummary).forEach(([key, value]) => {
          leaveSummary[key] = (leaveSummary[key] || 0) + value;
        });

        Object.entries(currentArriveSummary).forEach(([key, value]) => {
          arriveSummary[key] = (arriveSummary[key] || 0) + value;
        });
      }
    });

    return {
      leaveSummary,
      arriveSummary,
    };
  }
}
