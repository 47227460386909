<div *ngIf="createMode" [ngClass]="{ 'bg-light': this.oldLook , 'bg-lightgray': !this.oldLook }">
  <form [formGroup]="formGroup" class="col-md-12">
    <div class="row">
      <div class="col-md-11" *ngIf="showInAppCheckBox">
        <label>{{'note.showInApp' | translate}}</label>
        &nbsp;
        <input type="checkbox" id="showInApp" formControlName="showInApp" />
      </div>
      <div class="col-md-11">
        <label>{{text | translate}}
          <span *ngIf="formGroup.controls['value'].hasError('maxlength')" class="required-text">
            {{'message.maxLength' | translate: {number: 255} }}
          </span>
          <span *ngIf="formGroup.controls['value'].hasError('required') && includeAccept" class="required-text">
          {{'message.required' | translate}}
        </span></label>
        <textarea autosize rows="1" id="value" type="text" formControlName="value" class="form-control form-control-sm w-100"></textarea>
      </div>
    </div>
  </form>
  <div *ngIf="createMode" class="btn-container">
    <app-btn-accept-widget *ngIf="includeAccept" (cancel)="cancelCreate.emit()" (accept)="create()" [formGroup]="formGroup"></app-btn-accept-widget>
  </div>
</div>

<div class="col-md-12" *ngIf="note" style="border-radius: 5px; padding-bottom: 5px;" [ngClass]="{'bg-light': toggleEdit}">
  <div class="row w-100">
    <div class="col-md-10" style="white-space: pre-line">
      <fa-icon *ngIf="!note.showInApp" [icon]="faDesktop" ngbTooltip="{{'tooltip.notShowInApp' | translate}}" placement="right"></fa-icon>
      <fa-icon *ngIf="note.showInApp" [icon]="faMobileAlt" ngbTooltip="{{'tooltip.showInApp' | translate}}" placement="right"></fa-icon> {{note.value}}
    </div>
    <div class="col-md-2 tekst-right d-flex pe-1" style="justify-content: right;" >
      <button *ngIf="!logBook" type="button" class="btn btn-primary btn-sm"
        (click)="toggleEdit = !toggleEdit;" placement="left" ngbTooltip="{{'btn.edit' | translate}}">
        <fa-icon [icon]="faEdit"></fa-icon>
      </button>
    </div>

    <div class="col-md-12 pe-1" style="margin-top: 10px; font-size: 12px; font-style: italic; opacity: 0.8">
      {{'note.written' | translate}} {{note.timestamp | date: 'yyyy-MM-dd HH:mm:ss'}} {{'note.by' | translate}} {{note.createdBy?.firstname}} {{note.createdBy?.lastname}}
    </div>

    <div class="col-md-12 text-center d-flex justify-content-center">
      <button *ngIf="toggleEdit" class="btn btn-danger" placement="left" ngbTooltip="{{'btn.deleteNote' | translate}}" (click)="deleteNote.emit(note.id);">
        <fa-icon [icon]="faTrash"></fa-icon>
      </button>
    </div>
  </div>
</div>
