<div class="svg-icon__component">
  @if (this.icon == 'truck-unload') {
    <svg role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512" [attr.width]="this.size" [attr.height]="this.size">
      <path [attr.fill]="this.fill" d="M640 0V400c0 61.9-50.1 112-112 112c-61 0-110.5-48.7-112-109.3L48.4 502.9c-17.1 4.6-34.6-5.4-39.3-22.5s5.4-34.6 22.5-39.3L352 353.8V64c0-35.3 28.7-64 64-64H640zM576 400a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zM23.1 207.7c-4.6-17.1 5.6-34.6 22.6-39.2l46.4-12.4 20.7 77.3c2.3 8.5 11.1 13.6 19.6 11.3l30.9-8.3c8.5-2.3 13.6-11.1 11.3-19.6l-20.7-77.3 46.4-12.4c17.1-4.6 34.6 5.6 39.2 22.6l41.4 154.5c4.6 17.1-5.6 34.6-22.6 39.2L103.7 384.9c-17.1 4.6-34.6-5.6-39.2-22.6L23.1 207.7z"/>

      <path [attr.fill]="this.fill" d="M450 150l-50 50 50 50" [attr.stroke]="this.stroke" [attr.stroke-width]="this.strokeWidth"/>
      <line x1="400" y1="200" x2="550" y2="200" [attr.stroke]="this.stroke" [attr.stroke-width]="this.strokeWidth" marker-end="url(#arrowhead)"/>
    </svg>
  }


  @if (this.icon == 'truck-load') {
    <svg role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512" [attr.width]="this.size" [attr.height]="this.size">
      <path [attr.fill]="this.fill" d="M640 0V400c0 61.9-50.1 112-112 112c-61 0-110.5-48.7-112-109.3L48.4 502.9c-17.1 4.6-34.6-5.4-39.3-22.5s5.4-34.6 22.5-39.3L352 353.8V64c0-35.3 28.7-64 64-64H640zM576 400a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zM23.1 207.7c-4.6-17.1 5.6-34.6 22.6-39.2l46.4-12.4 20.7 77.3c2.3 8.5 11.1 13.6 19.6 11.3l30.9-8.3c8.5-2.3 13.6-11.1 11.3-19.6l-20.7-77.3 46.4-12.4c17.1-4.6 34.6 5.6 39.2 22.6l41.4 154.5c4.6 17.1-5.6 34.6-22.6 39.2L103.7 384.9c-17.1 4.6-34.6-5.6-39.2-22.6L23.1 207.7z"/>

      <path [attr.fill]="this.fill" d="M500 150l50 50-50 50" [attr.stroke]="this.stroke" [attr.stroke-width]="this.strokeWidth"/>
      <line x1="550" y1="200" x2="400" y2="200" [attr.stroke]="this.stroke" [attr.stroke-width]="this.strokeWidth" marker-end="url(#arrowhead)"/>
    </svg>
  }

  @if (this.icon == 'backvehicle') {
    <svg [attr.width]="this.size" [attr.height]="this.size" viewBox="0 0 26 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M25 4.88133C25 3.98576 24.2686 3.25977 23.3663 3.25977H2.63375C1.73144 3.25977 1.00002 3.98576 1.00002 4.88133V16.8699C1.00002 17.7654 1.73144 18.4914 2.63375 18.4914C2.8997 18.4914 3.16181 18.4914 3.42034 18.4914C2.97598 16.8483 4.9331 15.8459 4.98078 15.8459C6.48439 15.3447 8.57894 15.8459 8.43007 18.4914C8.46702 18.4914 8.74699 18.4914 8.80767 18.4914C9.10569 18.4914 9.31293 18.4914 9.4916 18.4914C9.72534 16.8483 10.3141 15.8459 10.3141 15.8459C11.907 15.8459 11.9525 15.8459 13.5012 15.8459C14.5036 15.8459 14.55 18.4914 14.6641 18.4914C17.2925 18.4914 20.0268 18.4914 23.3663 18.4914C24.2686 18.4914 25 17.7654 25 16.8699V4.88133Z" [attr.fill]="this.fill"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M20.0457 17.8956C20.0457 17.8058 19.6079 18.3519 19.5156 18.3519C19.4234 18.3519 19.0433 17.8058 19.0433 17.8956V20.5777C19.0433 20.6675 19.1181 20.7403 19.2103 20.7403H19.8786C19.9709 20.7403 20.0457 20.6675 20.0457 20.5777V17.8956Z" [attr.fill]="this.fill"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M8.44232 18.2509C8.44232 16.9227 7.33164 15.8459 5.96155 15.8459C4.59146 15.8459 3.48078 16.9227 3.48078 18.2509C3.48078 19.5791 4.59146 20.6559 5.96155 20.6559C7.33164 20.6559 8.44232 19.5791 8.44232 18.2509Z" fill="white"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M14.4423 18.2509C14.4423 16.9227 13.3575 15.8459 12.0192 15.8459C10.681 15.8459 9.59616 16.9227 9.59616 18.2509C9.59616 19.5791 10.681 20.6559 12.0192 20.6559C13.3575 20.6559 14.4423 19.5791 14.4423 18.2509Z" fill="white"/>
      <path d="M14.6641 18.4914C17.2925 18.4914 20.0268 18.4914 23.3663 18.4914C24.2686 18.4914 25 17.7654 25 16.8699V4.88133C25 3.98576 24.2686 3.25977 23.3663 3.25977H2.63375C1.73144 3.25977 1.00002 3.98576 1.00002 4.88133V16.8699C1.00002 17.7654 1.73144 18.4914 2.63375 18.4914C2.8997 18.4914 3.16181 18.4914 3.42034 18.4914C2.97598 16.8483 4.9331 15.8459 4.98078 15.8459M14.6641 18.4914C14.55 18.4914 14.5036 15.8459 13.5012 15.8459M14.6641 18.4914L14.5036 16.8483L13.5012 15.8459M9.4916 18.4914C9.72534 16.8483 10.3141 15.8459 10.3141 15.8459M9.4916 18.4914L9.58934 16.9597L10.3141 15.8459M9.4916 18.4914C9.31293 18.4914 9.10569 18.4914 8.80767 18.4914M10.3141 15.8459L8.43007 18.4914M10.3141 15.8459C11.907 15.8459 11.9525 15.8459 13.5012 15.8459M10.3141 15.8459H13.5012M8.43007 18.4914C8.46702 18.4914 8.74699 18.4914 8.80767 18.4914M8.43007 18.4914C8.57894 15.8459 6.48439 15.3447 4.98078 15.8459M8.80767 18.4914L7.988 15.8459H4.98078M20.0457 17.8956C20.0457 17.8058 19.6079 18.3519 19.5156 18.3519C19.4234 18.3519 19.0433 17.8058 19.0433 17.8956V20.5777C19.0433 20.6675 19.1181 20.7403 19.2103 20.7403H19.8786C19.9709 20.7403 20.0457 20.6675 20.0457 20.5777V17.8956ZM8.44232 18.2509C8.44232 16.9227 7.33164 15.8459 5.96155 15.8459C4.59146 15.8459 3.48078 16.9227 3.48078 18.2509C3.48078 19.5791 4.59146 20.6559 5.96155 20.6559C7.33164 20.6559 8.44232 19.5791 8.44232 18.2509ZM14.4423 18.2509C14.4423 16.9227 13.3575 15.8459 12.0192 15.8459C10.681 15.8459 9.59616 16.9227 9.59616 18.2509C9.59616 19.5791 10.681 20.6559 12.0192 20.6559C13.3575 20.6559 14.4423 19.5791 14.4423 18.2509Z" [attr.stroke]="this.stroke" stroke-miterlimit="8"/>
    </svg>
  }

  @if (this.icon == 'equipment') {
    <svg [attr.width]="this.size" [attr.height]="this.size" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_424_90248)">
        <mask id="mask0_424_90248" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
          <path d="M24 0H0V24H24V0Z" fill="white"/>
        </mask>
        <g mask="url(#mask0_424_90248)">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M23.9628 16.3188C23.8541 16.0614 23.5371 15.9344 23.257 16.0346L13.6932 19.4435C13.5105 19.1697 13.281 18.9297 13.0156 18.7235L10.8932 13.4179C10.8844 13.3701 10.8763 13.3212 10.8516 13.2769C10.8446 13.2641 10.832 13.2559 10.8239 13.2443L7.0574 3.82718C7.01473 3.72116 6.9327 3.63029 6.82616 3.57088L2.17854 1.01475C2.17854 1.01009 2.18184 1.00543 2.18184 0.999607C2.18184 0.448539 1.69232 0 1.09092 0C0.489524 0 0 0.448539 0 0.999607C0 1.55184 0.489524 2.00039 1.09092 2.00039C1.28013 2.00039 1.44952 1.9433 1.60483 1.86524L6.09956 4.33747L7.68152 8.29282L6.07771 10.7429C5.99568 10.8676 5.97765 11.019 6.02819 11.1577L8.78425 18.7375C8.08737 19.2874 7.63632 20.0924 7.63632 21C7.63632 22.6544 9.10438 24 10.9091 24C12.7138 24 14.1818 22.6544 14.1818 21C14.1818 20.7833 14.1539 20.5724 14.106 20.3685L23.6522 16.9654C23.933 16.8652 24.072 16.5763 23.9628 16.3188ZM7.14209 11.0598L8.16406 9.49864L9.66845 13.2594L8.18857 13.9375L7.14209 11.0598ZM10.9091 18C10.4963 18 10.1049 18.0781 9.74095 18.2062L8.53714 14.8963L10.0463 14.2043L11.5917 18.0676C11.3712 18.0245 11.1435 18 10.9091 18Z" [attr.fill]="this.fill"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M13.7538 17.6132C13.8034 17.739 13.9055 17.8415 14.0382 17.8974C14.1091 17.9266 14.1857 17.9417 14.2624 17.9417C14.328 17.9417 14.394 17.9301 14.4569 17.9091L21.4945 15.4171C21.7699 15.3192 21.9095 15.0361 21.8085 14.7809L19.643 9.29823C19.5934 9.1724 19.4913 9.07104 19.3586 9.01511C19.227 8.96036 19.077 8.95687 18.9399 9.00347L16.3305 9.92734C16.0598 10.024 15.9192 10.299 16.0122 10.553L16.6757 12.3623C16.7217 12.4881 16.7123 12.6233 16.6483 12.7433C16.5837 12.8621 16.4733 12.9507 16.3342 12.9938C16.0555 13.0811 15.7431 12.9343 15.65 12.6804L15.0151 10.9491C14.9681 10.8209 14.866 10.7161 14.7323 10.6578C14.6003 10.6007 14.4469 10.5961 14.3078 10.6439L11.9023 11.4955C11.6269 11.5934 11.4874 11.8753 11.5883 12.1316L13.7538 17.6132Z" [attr.fill]="this.fill"/>
        </g>
      </g>
      <defs>
        <clipPath id="clip0_424_90248">
          <rect width="24" height="24" fill="white"/>
        </clipPath>
      </defs>
    </svg>
  }
</div>
