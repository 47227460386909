import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ToursRoutingModule } from './tours-routing.module';
import { ToursComponent } from './list/tours.component';
import { TourCreateComponent } from './tour-create/tour-create.component';
import { TourDetailComponent } from './tour-detail/tour-detail.component';
import { SharedModule } from "../shared/shared.module";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { AutosizeModule } from 'ngx-autosize';
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { UserSelectComponent } from './tour-create/user-select/user-select.component';
import { CustomerSelectComponent } from './tour-create/customer-select/customer-select.component';
import { FrontVehicleSelectComponent } from './tour-create/front-vehicle-select/front-vehicle-select.component';
import { BackVehicleSelectComponent } from './tour-create/back-vehicle-select/back-vehicle-select.component';
import { TransportEquipmentSelectComponent } from './tour-create/transport-equipment-select/transport-equipment-select.component';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { GoogleStreetViewModalComponent } from '../google-street-view-modal/google-street-view-modal.component';
import { ComboBoxAllModule, ComboBoxModule } from '@syncfusion/ej2-angular-dropdowns';



@NgModule({
  declarations: [
    ToursComponent,
    TourCreateComponent,
    TourDetailComponent,
    UserSelectComponent,
    CustomerSelectComponent,
    FrontVehicleSelectComponent,
    BackVehicleSelectComponent,
    TransportEquipmentSelectComponent,

  ],
  exports: [
    TourDetailComponent,
    FrontVehicleSelectComponent,
    BackVehicleSelectComponent,
    TransportEquipmentSelectComponent,
    TourCreateComponent,
    ComboBoxModule

  ],
  imports: [
    CommonModule,
    ToursRoutingModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    AutosizeModule,
    NgbModule,
    FontAwesomeModule,
    NgxSliderModule,
    GoogleStreetViewModalComponent,
    NgMultiSelectDropDownModule.forRoot(),
    ComboBoxModule
  ]
})
export class ToursModule { }
