import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-svg-icon',
  templateUrl: './svg-icon.component.html',
  styleUrl: './svg-icon.component.scss',
  providers: []
})
export class SvgIconComponent {
  @Input() icon: string = ''
  @Input() size: string = '24'
  @Input() fill: string = '#000000'
  @Input() stroke: string = '#ffffff'
  @Input() strokeWidth: string = '20'
}
