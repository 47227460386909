import { GroupingService } from './../../grouping/grouping.service';
import { Component, OnInit } from '@angular/core';
import { FrontVehicleService } from "../shared/front-vehicle.service";
import { FrontVehicleModel, TableFrontVehicleModel } from "../shared/front-vehicle.model";
import { DatePipe } from "@angular/common";
import { CalenderView, StatusType } from "../../constants";
import { SharedService } from "../../shared/services/shared.service";
import { UserModel } from "../../users/shared/user.model";
import { MainGroup, SubGroup } from 'src/app/grouping/grouping.models';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { EventProxyService, CustomProxyEvent, CreateGroup, DeleteGroup, UpdateGroup, SetTourDetailId, SetGroupId } from 'src/app/shared/services/event-proxy.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-front-vehicles',
  templateUrl: './front-vehicles.component.html',
  styleUrls: ['./front-vehicles.component.scss'],
  providers: [DatePipe]
})
export class FrontVehiclesComponent implements OnInit {
  private EventProxySubscription: Subscription | undefined

  frontVehicles: FrontVehicleModel[] | undefined;
  tableData: TableFrontVehicleModel[] = [];
  groups: MainGroup[] = []
  addNew: boolean = false;

  showDetails: boolean = false;
  showTourDetails: boolean = false;
  selectedItemId: number = 0;
  selectedSubItemId: number = 0;
  createNewTour: { frontVehicle?: FrontVehicleModel, event: { startDate: Date, allDay: boolean } } | undefined;
  tourId: number = 0;

  constructor(private frontVehicleService: FrontVehicleService, private datePipe: DatePipe, private sharedService: SharedService, private groupService: GroupingService, private translate: TranslateService, private toaster: ToastrService, private eventProxyService: EventProxyService) {
    this.EventProxySubscription = this.eventProxyService.getEvent().subscribe((param: CustomProxyEvent) => {
      if (param instanceof CreateGroup || param instanceof UpdateGroup || param instanceof DeleteGroup) {
        this.getGroupsList()
      }

      if (param instanceof SetTourDetailId) {
        if (param.data && param.data > 0) {
          this.showTourItemDetails(param.data)
          this.tourId = param.data
        }
      }

      if (param instanceof SetGroupId) {
        if (param.data && param.data <= 0) {
          sessionStorage.removeItem('groupId')
        }

        return this.getAll()
      }
    })
  }

  ngOnInit(): void {
    this.getGroups()
  }


  getAll(): void {
    this.frontVehicleService.getAll().subscribe(response => {
      this.frontVehicles = response;
      this.tableData = this.frontVehicles.map(x => ({
        id: x.id,
        no: x.unitIdentification,
        resourceType: x.resourceType?.resourceTypeName,
        reg: x.registrationNumber,
        manufacturer: x.manufacturer,
        axles: x.axleCount,
        eu: x.euroStandard,
        construction: x.construction,
        description: x.description,
        manufacturerDate: this.datePipe.transform(x?.manufactureDate, 'yyyy-MM-dd')?.toString(),
        chassisNumber: x.chassisNumber,
        deadWeight: x.deadWeight,
        totalWeight: x.totalWeight,
        status: x.status,
        nested: x.tours !== null ? x.tours.map(t => ({
          tourDescription: t.description,
          expectedStart: this.datePipe.transform(t?.expectedStartDate, 'yyyy-MM-dd HH:mm')?.toString(),
          actualStart: this.datePipe.transform(t?.actualStartDate, 'yyyy-MM-dd HH:mm')?.toString(),
          expectedEnd: this.datePipe.transform(t?.expectedEndDate, 'yyyy-MM-dd HH:mm')?.toString(),
          actualEnd: this.datePipe.transform(t?.actualEndDate, 'yyyy-MM-dd HH:mm')?.toString(),
          status: t.status,
        })) : []
      })
      );
    });
  }


  getGroups() {
    this.groupService.getGroupsList().subscribe((response) => {
      this.groups = response

      this.getAll()
    })
  }


  getGroupsList() {
    this.groupService.getGroupsList().subscribe((response) => {
      this.groups = response
    })
  }


  saveMainGroup(_event: any) {
    try {
      this.groupService.saveMainGroup({ name: _event.event.data.name, description: _event.event.data.description }).subscribe(response => {
        const event = { option: response }
        this.addSelectedToGroup({ event, selectedItems: _event.selected }, 'FrontVehicles')
        this.eventProxyService.setEvent(new CreateGroup(null))
      })
    } catch (error: any) {
      console.error('Error: ', error)
      this.toaster.error(this.translate.instant('grouping.misc.groupNotCreated'))
    }
  }

  showTourItemDetails(event: any): void {
    this.showTourDetails = true;
  }

  closeTourItemDetails(): void {
    this.showTourDetails = false;
  }

  showItemDetails(event: any): void {
    this.selectedItemId = event.id;
    this.showDetails = true;
  }

  closeItemDetails(): void {
    this.selectedItemId = 0;
    this.showDetails = false;
  }

  public get calenderView(): typeof CalenderView {
    return CalenderView;
  }

  createTour(e: { resourceId: string, event: { startDate: Date, allDay: boolean } }): void {
    this.createNewTour = {
      event: e.event,
      frontVehicle: this.frontVehicles?.find(x => x.id === Number.parseInt(e.resourceId))
    }
  }


  addSelectedToGroup(_event: any, str: string) {
    const { event, selectedItems } = _event

    let subgroup: SubGroup = event.option.subGroups.find((x: any) => x.resourceType === str) // select the subgroup in question
    const subgroupId = subgroup && subgroup.id ? subgroup.id : -1 // get the id or set to -1 if not found
    const itemIds: number[] = selectedItems.map((x: any) => x.id) // get the checked items from the table

    if (subgroupId < 0 && itemIds.length > 0) {
      this.privateSaveGroup(event, itemIds)
    } else {
      // Of the chosen items, remove the ones that already exist on the subgroup
      const adjustedMemberIds = itemIds.filter(id => !subgroup?.members?.some(member => member.id === id))

      if (adjustedMemberIds.length > 0) {
        this.privateSaveGroupMembers(subgroupId, itemIds, subgroup)
      }
    }
  }

  private privateSaveGroup(event: any, itemIds: number[]) {
    try {
      this.groupService.saveSubGroup({
        masterGroupId: event.option.id,
        resourceType: "FrontVehicles", // set the correct resourceType
        name: this.translate.instant('grouping.subgroups.frontVehicles')
      }).subscribe((response) => {
        this.groupService.saveSubGroupMembers({ id: response.id, memberIds: itemIds, resourceType: response.resourceType }).subscribe((res) => {
          this.toaster.success(this.translate.instant('grouping.misc.subgroupMembersAdded'))
        })
      })
    } catch (error: any) {
      console.error('Error: ', error)
      this.toaster.error(this.translate.instant('grouping.misc.subgroupMembersNotSaved'))
    }
  }

  private privateSaveGroupMembers(subgroupId: number, itemIds: number[], subgroup: SubGroup) {
    try {
      this.groupService.saveSubGroupMembers({
        id: subgroupId,
        memberIds: itemIds,
        resourceType: subgroup.resourceType
      }).subscribe((res) => {
        this.toaster.success(this.translate.instant('grouping.misc.subgroupMembersAdded'))
      })
    } catch (error: any) {
      console.error('Error: ', error)
      this.toaster.error(this.translate.instant('grouping.misc.subgroupMembersNotSaved'))
    }
  }

  ngOnDestroy() {
    if (this.EventProxySubscription) {
      this.EventProxySubscription.unsubscribe();
    }
  }
}
