import { Injectable } from '@angular/core'
import { Subject } from 'rxjs'


export class CustomProxyEvent {
  // Base class for your custom events
}

export class CreateGroup extends CustomProxyEvent {
  constructor(public data: any) {
    super();
  }
}

export class UpdateGroup extends CustomProxyEvent {
  constructor(public data: any) {
    super();
  }
}

export class DeleteGroup extends CustomProxyEvent {
  constructor(public data: any) {
    super();
  }
}
export class SetGroupId extends CustomProxyEvent {
  constructor(public data: any) {
    super();
  }
}
export class SetTourDetailId extends CustomProxyEvent {
  constructor(public data: any) {
    super();
  }
}
export class OtherEvent extends CustomProxyEvent {
  constructor(public data: any) {
    super();
  }
}
export class AuthCompleted extends CustomProxyEvent {
  constructor(public data: any) {
    super();
  }
}
export class NewMessage extends CustomProxyEvent {
  constructor(public data: any) {
    super();
  }
}


@Injectable({
  providedIn: 'root'
})
export class EventProxyService {
  private eventTracker = new Subject<CustomProxyEvent>();

  getEvent(): Subject<CustomProxyEvent> {
    return this.eventTracker;
  }

  setEvent(param: CustomProxyEvent): void {
    this.eventTracker.next(param);
  }
}
