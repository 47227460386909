<!-- Modal content -->
<ng-template #content let-modal>
    <div class="modal-header">
      <h4 class="modal-title">{{'google.streetview.title' | translate}}</h4>
      <button type="button" class="btn-close" aria-label="Close" (click)="emit(); modal.dismiss('Cross click')"></button>
    </div>
    <div class="modal-body custom-modal-body">
        <div id="map"></div>
    <div id="pano"></div>
    </div>
    <div class="modal-footer">
   
      <button type="button" class="btn btn-secondary" (click)="emit(); modal.close('Save click')">{{'modal.footer.cancel' | translate}}</button>
     
  
    </div>
  </ng-template>
  
  
  