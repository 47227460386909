import { Component, EventEmitter, OnInit, Output, ViewChild, ElementRef, Input, OnChanges, DoCheck, AfterContentChecked, AfterViewChecked, SimpleChanges, signal } from '@angular/core';
import { FormArray, FormGroup, UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { Observable, lastValueFrom } from "rxjs";
import { faPencilAlt, faPlus, faTrashAlt, faStop, faArrowDown, faArrowUp, faPlay, faExclamation, faLock, faLockOpen, faUnlock, faUnlockAlt, faForward } from "@fortawesome/free-solid-svg-icons";
import { DatePipe } from "@angular/common";
import * as moment from "moment";
import { NgbAccordionItem } from '@ng-bootstrap/ng-bootstrap';
import DurationConstructor = moment.unitOfTime.DurationConstructor;

import { AccordionToggleService } from './../../shared/services/accordion-toggle-service';
import { WidgetComponent } from "../../shared/widget-component";
import { UserModel, UserStartTimeRegistrationModel } from "../../users/shared/user.model";
import { FrontVehicleModel, UpdateFrontVehicleModel } from "../../front-vehicles/shared/front-vehicle.model";
import { BackVehicleModel } from "../../back-vehicles/shared/back-vehicle.model";
import { CustomerModel } from "../../customers/shared/customer.model";
import { TransportEquipmentModel } from "../../transport-equipments/shared/transport-equipment.model";
import { AddressModel } from "../../shared/address-widget/shared/address.model";
import { TourService } from "../shared/tour.service";
import { CustomerService } from "../../customers/shared/customer.service";
import { FrontVehicleService } from "../../front-vehicles/shared/front-vehicle.service";
import { BackVehicleService } from "../../back-vehicles/shared/back-vehicle.service";
import { UserService } from "../../users/shared/user.service";
import { TransportEquipmentService } from "../../transport-equipments/shared/transport-equipment.service";
import { NotificationService } from "../../shared/services/notification.service";
import { NoteModel } from "../../shared/note-widget/shared/note.model";
import { CancelTourModel, StartTourModel, TourAddressModel, TourModel, UpdateTourModel } from "../shared/tour.model";
import { TagModel } from "../../shared/tag/shared/tag.model";
import { SharedService } from "../../shared/services/shared.service";
import { CreateMode } from "../../constants";
import { DateValidator } from "../../form-validators/date-validator";
import { TimeValidator } from "../../form-validators/time-validator";
import { ITuple } from "../../shared/tuple";
import { NoteDateModel } from 'src/app/shared/note-date-widget/shared/noteDate.model';
import { AttachmentCreateModel } from 'src/app/shared/attachment-widget/shared/attachment.model';
import { GoogleStreetViewModalComponent } from 'src/app/google-street-view-modal/google-street-view-modal.component';
import { EventProxyService, ProductModalEdit, ProductModalOpen } from 'src/app/shared/services/event-proxy.service';
import { Product } from 'src/app/freight-types/shared/freight-types.model';

enum RegisterType {
  Start,
  End
}



@Component({
  selector: 'app-tour-detail',
  templateUrl: './tour-detail.component.html',
  styleUrls: ['./tour-detail.component.scss'],
  providers: [DatePipe]
})
export class TourDetailComponent extends WidgetComponent {
  @Input() backVehicles: BackVehicleModel[] | undefined;
  @Input() transportEquipment: TransportEquipmentModel[] | undefined


  readOnly = true;
  readAddressesOnly = true;

  formGroup!: FormGroup;

  tour: TourModel | undefined;
  repetitionDateTimes: ITuple[] = [];
  createMode: CreateMode = CreateMode.read
  expectedStartDate: Date | string = new Date()
  expectedEndDate: Date | string = new Date()
  repititionEnabled: boolean = false
  userSelect = false;
  customerSelect = false;
  frontVehicleSelect = false;
  backVehicleSelect = false;
  transportEquipmentSelect = false;
  addAttachment = false;
  addAddress = false;
  addNote = false;
  endTour = false;
  cancelRegistration = false;
  tourTimes: ITuple[] = [];
  streetviewLat: number = 55.850849;
  streetviewLng: number = 9.845612;
  customerAddresses: AddressModel[] = []
  products = signal<Product[]>([])

  showStreetViewModal: boolean = false;

  startEndFormGroup: UntypedFormGroup;
  language: any;
  editRegistration: any;
  startTour = false;

  freightTypeUnitTitle: string = '';
  freightTypeDescValue: string = '';

  currentLatestArriveBackVehicle = "";
  currentLatestArriveEquipment = "";
  currentLatestLeaveBackVehicle = ''
  currentLatestLeaveEquipment = ''



  faAdd = faPlus;
  faEdit = faPencilAlt;
  faTrash = faTrashAlt;
  faStart = faPlay;
  faRestart = faForward;
  loading = false;
  faStop = faStop;
  faUpIcon = faArrowUp;
  faDownIcon = faArrowDown;
  faExclamation = faExclamation;
  faLock = faLock;
  faLockOpen = faUnlock;


  @ViewChild('freightTypeDescSelect') freightTypeDescSelect!: ElementRef;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private tourService: TourService,
    private customerService: CustomerService,
    private frontVehicleService: FrontVehicleService,
    private backVehicleService: BackVehicleService,
    private userService: UserService,
    private transportEquipmentService: TransportEquipmentService,
    private notifyService: NotificationService,
    private datePipe: DatePipe,
    private sharedService: SharedService,
    private accordionToggleService: AccordionToggleService,
    private eventProxy: EventProxyService
  ) {
    super();
    this.formGroup = this.formBuilder.group({
      orderId: ['', [Validators.maxLength(40)]],
      description: ['', [Validators.maxLength(40), Validators.required]],
      expectedStartDate: ['', Validators.required],
      expectedEndDate: ['', DateValidator('expectedStartDate')],
      expectedStartTime: '',
      expectedEndTime: ['', TimeValidator('expectedStartDate', 'expectedStartTime', 'expectedEndDate')],
      actualStartDate: '',
      actualEndDate: '',
      actualStartTime: '',
      actualEndTime: '',
      freight: '',
      freightTotalAmount: [0, [Validators.required]],
      freightTypeId: { value: 0, disabled: true },
      startKilometerAge: 0,
      startLatitude: 0,
      startLongitude: 0,
      endKilometerAge: 0,
      endLatitude: 0,
      endLongitude: 0,
      adBlue: 0,
      fuel: 0,
      distance: 0,
      frontVehicleId: null,
      backVehicleIds: [],
      transportEquipmentId: null,
      assignedUserId: null,
      customerId: null,
      tagIds: [],
      addresses: this.formBuilder.array([]),
      type: [undefined, Validators.required],
      isSpecial: false,
      isLocked: false,
      calculatedTotalFreightAmount: 0
    });

    this.startEndFormGroup = this.formBuilder.group({
      kilometerAge: [undefined, Validators.required],
      latitude: [undefined, Validators.required],
      longitude: [undefined, Validators.required]
    })
  }


  async getLanguage() {
    this.sharedService.getLanguageJson().subscribe(response => this.language = response)
  }


  async ngOnInit(): Promise<void> {
    await this.getLanguage()

    this.refresh()
  }


  ngOnChanges(changes: SimpleChanges): void {
  }


  refresh(): void {
    this.loading = true
    this.tourService.getSingle(this.id).subscribe(response => {
      response.addresses.forEach((address) => {
        if (!address.contact) {
          address.contact = null
        }
      })
      this.tour = response
      this.getCustomerAddresses()

      this.patchFormValues()

      this.getNotes()
      this.getAttachments()
      this.tour.timeRegistrations = this.tour.timeRegistrations.filter((t) => t.timeRegistrationTypeId != 17)
      this.tour.timeRegistrations.sort((a, b) => new Date(a.startTime).getTime() - new Date(b.startTime).getTime())
      this.loading = false
      this.readOnly = true
    })
  }



  // This should be deprecated
  // addNewAddress(event: Event, accordionItem: NgbAccordionItem) {
  //   this.accordionToggleService.accordionStatus(event, accordionItem);

  //   if (!this.tour) return;
  //   this.addAddress = true
  // }


  addNewAttachment(event: Event, accordionItem: NgbAccordionItem): void {
    this.accordionToggleService.accordionStatus(event, accordionItem);
    this.addAttachment = true;
  }


  addNewNote(event: Event, accordionItem: NgbAccordionItem): void {
    this.accordionToggleService.accordionStatus(event, accordionItem);
    this.addNote = true;
  }


  showStreetview(event: any) {
    this.streetviewLat = event.lat;
    this.streetviewLng = event.lng;

    this.showStreetViewModal = true;

  }

  hideStreetview(event: any) {
    this.showStreetViewModal = false;
  }




  // get the customer address to fill the dropdown in the address-widget component
  getCustomerAddresses() {
    if (this.tour?.customer) {
      this.customerService.getSingle(this.tour?.customer.id!).subscribe(response => {
        this.customerAddresses = response.addresses
      })
    }
  }



  calculateRepetitionCount(): number {
    const startDate = moment(this.datePipe.transform(this.formGroup.value.expectedStartDate, 'yyyy-MM-dd'));
    let selectedStopDate = moment(this.formGroup.value.endRepetitionDate);
    switch (this.formGroup.value.repetition) {
      case 1: {
        const difference = selectedStopDate.diff(startDate, 'days', true);
        this.setRepetitionDateTimes('days', difference);
        return difference;
      }
      case 2: {
        const difference = selectedStopDate.diff(startDate, 'weeks');
        this.setRepetitionDateTimes('weeks', difference);
        return difference;
      }
      case 3: {
        const difference = selectedStopDate.diff(startDate, 'months');
        this.setRepetitionDateTimes('months', difference);
        return difference;
      }
      default: {
        this.setRepetitionDateTimes('days', 0);
        return 0;
      }
    }
  }


  setRepetitionDateTimes(unitOfTime: DurationConstructor, repetitions: number): void {
    this.repetitionDateTimes = [];
    for (let i = 0; i <= repetitions; i++) {
      const start = moment(this.datePipe.transform(`${this.formGroup.value.expectedStartDate}`, 'yyyy-MM-dd HH:mm'));
      const end = moment(this.datePipe.transform(`${this.formGroup.value.expectedEndDate}`, 'yyyy-MM-dd HH:mm'));
      this.repetitionDateTimes?.push({ item1: start.add(i, unitOfTime).toDate(), item2: end.add(i, unitOfTime).toDate() });
    }
  }


  get tourFreights() {
    return this.formGroup.get('tourFreights') as FormArray
  }

  /**
     * Sends event for tour.component to listen to and open the modal
     * This is a workaround to avoid a css nightmare by trying to place the fixed modal in the sidebar
     */
  openProductModal(addressIndex?: number) {
    if (this.formGroup.get('tourFreights')?.value.length > 0) {
      this.eventProxy.setEvent(new ProductModalOpen({
        addressIndex: addressIndex ? addressIndex : 9999,
        products: this.products,
        formGroup: this.tourFreights.at(0)
      }))
    } else {
      this.eventProxy.setEvent(new ProductModalOpen({
        addressIndex: addressIndex ? addressIndex : 9999,
        products: this.products,
        formGroup: this.formBuilder.group({
          productTypeId: 0,
          productType: { name: '' },
          type: '',
          surcharges: this.formBuilder.array([]),
          priceUnitId: null,
          amount: 0
        })
      }))
    }
  }




  patchFormValues(): void {
    if (!this.tour) return

    const model = this.tour.addresses[this.tour.addresses.length - 1]

    let arriveBVInputString = '';
    let leaveBVInputString = '';
    let arriveEquipmentString = ''
    let leaveEquipmentString = ''

    if (model != undefined) {
      if (model.arriveBackVehicles != undefined && model.arriveBackVehicles.length > 0) {
        let arriveBV = model.arriveBackVehicles.map((backVehicle) => backVehicle.unitIdentification + ' / ' + backVehicle.registrationNumber);
        arriveBVInputString = arriveBV.join(', ');
      }
      else {
        arriveBVInputString = model.arriveBackVehicle;
      }

      if (model.leaveBackVehicles != undefined && model.leaveBackVehicles.length > 0) {
        let leaveBV = model.leaveBackVehicles.map((backVehicle) => backVehicle.unitIdentification + ' / ' + backVehicle.registrationNumber);
        leaveBVInputString = leaveBV.join(', ');
      }
      else {
        leaveBVInputString = model.leaveBackVehicle;
      }

      if (model.arriveEquipment != undefined) {
        arriveEquipmentString = model.arriveEquipment;
      }

      if (model.leaveEquipment != undefined) {
        leaveEquipmentString = model.leaveEquipment;
      }
    }



    // if (model.arriveBackVehicle != undefined) arriveBVInputString = this.arriveBackVehicle
    // if (model.leaveBackVehicle != undefined) leaveBVInputString = this.leaveBackVehicle
    // if (model.arriveEquipment != undefined) arriveEquipmentString = this.arriveEquipment
    // if (model.leaveEquipment != undefined) leaveEquipmentString = this.leaveEquipment



    // this sets freghtType on addresses same as tour
    if (this.tour.freightTypeId > 0) {
      this.tour.addresses.forEach((addr) => {
        if (this.tour?.freightTypeId != undefined) {
          addr.freightTypeId = this.tour?.freightTypeId
        }
      })
    }

    this.tour.addresses = this.tour.addresses.sort((obj1, obj2) => {
      if (obj1.arrivedDate != null && obj2.arrivedDate != null) {
        if (obj1.arrivedDate > obj2.arrivedDate) {
          return 1
        }

        if (obj1.arrivedDate < obj2.arrivedDate) {
          return -1
        }
      }

      return 0
    })


    this.formGroup = this.formBuilder.group({
      description: this.tour.description,
      expectedStartDate: this.datePipe.transform(this.tour.expectedStartDate, 'yyyy-MM-dd HH:mm'),
      expectedEndDate: this.datePipe.transform(this.tour.expectedEndDate, 'yyyy-MM-dd HH:mm'),
      expectedStartTime: `${this.getFormattedTime(new Date(this.tour.expectedStartDate).getHours())}:${this.getFormattedTime(new Date(this.tour.expectedStartDate).getMinutes())}`,
      expectedEndTime: `${this.getFormattedTime(new Date(this.tour.expectedEndDate).getHours())}:${this.getFormattedTime(new Date(this.tour.expectedEndDate).getMinutes())}`,
      freight: this.tour?.freight,
      freightTotalAmount: this.tour?.freightTotalAmount,
      freightTypeId: this.tour?.freightTypeId,
      actualStartDate: this.datePipe.transform(this.tour.actualStartDate, 'yyyy-MM-dd'),
      actualEndDate: this.datePipe.transform(this.tour.actualEndDate, 'yyyy-MM-dd'),
      actualStartTime: `${this.getFormattedTime(new Date(this.tour.actualStartDate).getHours())}:${this.getFormattedTime(new Date(this.tour.actualStartDate).getMinutes())}`,
      actualEndTime: `${this.getFormattedTime(new Date(this.tour.actualEndDate).getHours())}:${this.getFormattedTime(new Date(this.tour.actualEndDate).getMinutes())}`,
      startKilometerAge: this.tour.startKilometerAge,
      startLatitude: this.tour.timeRegistrations.length > 0 ? this.tour.timeRegistrations[0].startLatitude : this.tour.startLatitude,
      startLongitude: this.tour.timeRegistrations.length > 0 ? this.tour.timeRegistrations[0].startLongitude : this.tour.startLongitude,
      endKilometerAge: this.tour.endKilometerAge,
      endLatitude: this.tour.endLatitude,
      endLongitude: this.tour.endLongitude,
      adBlue: this.tour.adBlue,
      fuel: this.tour.fuel ? this.tour.fuel : 0, //this.getFuelUsed(),
      distance: this.getDistanceTravelled(),
      frontVehicleId: this.tour?.frontVehicle?.id,
      backVehicleIds: this.tour?.backVehicles?.map(b => b.id),
      transportEquipmentId: this.tour?.transportEquipment?.id,
      assignedUserId: this.tour?.assignedUser?.id,
      customerId: this.tour?.customer?.id,
      tagIds: this.tour?.tags?.map(t => t.id),
      addresses: this.formBuilder.array([]),
      type: this.tour.type,
      isSpecial: this.tour.isSpecial,
      isLocked: this.tour.isLocked,
      orderId: this.tour.orderId,
      calculatedTotalFreightAmount: this.tour.calculatedTotalFreightAmount
    })



    this.tour.addresses.forEach((address: any, index: any) => {

      let addressFreights = this.formBuilder.array([]);

      if (address.addressFreights.length > 0) {
        address.addressFreights.forEach((freight: any) => {
          let surcharge = this.formBuilder.array([]);
          if (freight.surcharges) {
            surcharge = this.formBuilder.array(freight.surcharges);
          }
          addressFreights.push(this.formBuilder.group({
            id: freight.id,
            amount: freight.amount,
            productTypeId: freight.productTypeId,
            productType: { name: freight.productType ? freight.productType.name : '' },
            type: freight.type,
            priceUnitId: freight.priceUnitId,
            surcharges: freight.surcharges && freight.surcharges.length > 0 ? surcharge : [],
            freightUnit: freight.freightUnit ? freight.freightUnit : '',
            freightUnitId: freight.freightUnit ? freight.freightUnit.id : null
          }))
        })
      }

      const _address: FormGroup = this.formBuilder.group({
        country: [address.country, Validators.maxLength(255)],
        id: address.id,
        state: [address.state, Validators.maxLength(255)],
        addressFreights: addressFreights && addressFreights.value.length > 0 ? addressFreights : this.formBuilder.array([]), // if there is data on [0], then it should use everything in the array otherwise make it an empty array
        arriveWindow: [address.arriveWindow],
        zipcode: [address.zipcode, [Validators.maxLength(10), Validators.required]],
        city: [address.city, [Validators.maxLength(255), Validators.required]],
        street: [address.street, Validators.maxLength(255)],
        latitude: address.latitude,
        longitude: address.longitude,
        priority: [this.formGroup.value.addresses.length + 1, Validators.required],
        description: address.description,
        additionalInformation: address.additionalInformation,
        locationName: [address.locationName, Validators.maxLength(255)],
        locationId: [address.locationId, Validators.maxLength(255)],
        freight: [address.freight, [Validators.maxLength(255)]],
        removedFreightAmount: address.removedFreightAmount,
        removedFreightType: address.removedFreightType,
        freightAmount: address.freightAmount,
        notes: address.notes,
        attachments: address.attachments,
        freightTypeId: this.formGroup.value.freightTypeId > 0 ? this.formGroup.value.freightTypeId : 0,
        orderId: [address.orderId, [Validators.maxLength(40)]],
        arriveBackVehicle: [address.arriveBackVehicle, Validators.maxLength(255)],
        arriveBackVehicles: [address.arriveBackVehicles],
        selectArriveBackVehicle: ['', Validators.maxLength(255)],
        arriveEquipment: [address.arriveEquipment, Validators.maxLength(255)],
        selectArriveEquipment: ['', Validators.maxLength(255)],
        leaveBackVehicle: [address.leaveBackVehicle, Validators.maxLength(255)],
        leaveBackVehicles: [address.leaveBackVehicles],
        selectLeaveBackVehicle: ['', Validators.maxLength(255)],
        leaveEquipment: [address.leaveEquipment, Validators.maxLength(255)],
        selectLeaveEquipment: ['', Validators.maxLength(255)],
        addressDeliveryDocumentRequirements: this.formBuilder.array(address.addressDeliveryDocumentRequirements[0] ? address.addressDeliveryDocumentRequirements : []),
        customerId: address.customerId,
        contact: address.contact ? address.contact : null,
        contactId: address.contactId ? address.contactId : null
      })



      _address.get('arriveBackVehicle')?.setValue(address.arriveBackVehicle)

      this.addresses.push(_address)
    })

    if (this.tour?.addresses.length > 0) {
      this.currentLatestArriveBackVehicle = arriveBVInputString
      this.currentLatestLeaveBackVehicle = leaveBVInputString
      this.currentLatestArriveEquipment = arriveEquipmentString
      this.currentLatestLeaveEquipment = leaveEquipmentString
    }
  }


  getDistanceTravelled(): number {
    if (this.tour?.drivingRecords != null) {
      let sum: number = 0
      this.tour?.drivingRecords.forEach(d => (d.totalMileage >= 0 ? sum += d.totalMileage : 0))
      return this.convertToDecimal(sum)
    }
    return 0
  }


  convertToDecimal(n: number): number {
    return Number.parseFloat((n / 1000).toFixed(2))
  }


  getFuelUsed(): number {
    if (this.tour?.drivingRecords != null) {
      let sum: number = 0
      this.tour?.drivingRecords.forEach(d => sum += d.totalFuel)
      return this.convertToDecimal(sum)
    }
    return 0
  }


  getFormattedTime(time: number): string {
    return ('0' + time).slice(-2)
  }


  showEditStartDate(): any {
    return this.tour?.actualStartDate !== null && !this.readOnly
  }


  showEditEndDate(): any {
    return this.tour?.actualEndDate !== null && !this.readOnly
  }

  updateReadOnly(bool: any) {
    this.readOnly = bool
  }



  toggleEditMode() {
    this.readOnly = !this.readOnly

    if (this.readOnly) {
      this.createMode = this.createModes.read
    }

    if (!this.readOnly) {
      this.createMode = this.createModes.create_as_child
    }
  }



  update(message?: string): void {
    this.loading = true;
    this.formGroup.controls['freightTypeId'].enable();
    const values = this.formGroup.value;
    let model: UpdateTourModel | undefined


    // force to turn into array because the backend accepts it as an array
    if (values.backVehicleIds && !Array.isArray(values.backVehicleIds)) {
      values.backVehicleIds = [values.backVehicleIds]
    }

    model = {
      orderId: values.orderId,
      assignedUserId: values.assignedUserId,
      description: values.description,
      backVehicleIds: values.backVehicleIds,
      tagIds: values.tagIds,
      customerId: values.customerId,
      freight: values.freight,
      freightTotalAmount: values.freightTotalAmount,
      freightTypeId: values.freightTypeId,
      frontVehicleId: values.frontVehicleId,
      transportEquipmentId: values.transportEquipmentId,
      expectedStartDate: values.expectedStartDate ? new Date(values.expectedStartDate) : null,
      expectedEndDate: values.expectedEndDate ? new Date(values.expectedEndDate) : null,
      TourType: values.type,
      isSpecial: values.isSpecial,
      addresses: values.addresses ? values.addresses : [],
      actualStartDate: values.actualStartDate ? new Date(values.actualStartDate + ' ' + values.actualStartTime) : null,
      actualEndDate: values.actualEndDate ? new Date(values.actualEndDate + ' ' + values.actualEndTime) : null,
      isLocked: values.isLocked,
      contact: values.contact,
      contactId: values.contactId
    }

    model.addresses.forEach((address: any) => {
      address['addressDeliveryDocuments'] = address['addressTourDeliveryDocumentRequirement']
    })

    if (model)
      this.tourService.update(this.id, model).subscribe(response => {
        if (message && message === "updatedAddress")
          this.notifyService.successMessage(this.language?.message?.updatedAddress);
        else if (message && message === "updatedRegistration")
          this.notifyService.successMessage(this.language?.message?.updatedRegistration);
        else if (message && message === "startedRegistration")
          this.notifyService.successMessage(this.language?.message?.startedRegistration);
        else
          this.notifyService.successMessage(this.language?.message?.updatedTour);

        this.refresh();
        this.updateList.emit();
        this.loading = false;
      }, error => this.loading = false);
  }


  createAttachment(attachment: AttachmentCreateModel): void {
    this.tourService.createAttachment(this.id, attachment).subscribe(response => {
      if (this.tour) {
        this.tour.attachments = response.attachments;
        this.notifyService.successMessage(this.language?.message?.createdAttachment);
      }
    });
  }


  deleteAttachment(attachmentId: number): void {
    this.tourService.deleteAttachment(this.id, attachmentId).subscribe(response => {
      if (this.tour) {
        this.tour.attachments = response.attachments;
        this.notifyService.warningMessage(this.language?.message?.deletedAttachment);
      }
    });
  }


  getAttachments(): void {
    this.tourService.getAttachments(this.id).subscribe(response => {
      if (this.tour) this.tour.attachments = response.attachments;
    });
  }


  createNote(model: NoteModel): void {
    this.tourService.createNote(this.id, model).subscribe(response => {
      if (this.tour) {
        this.tour.notes = response.notes;
        this.notifyService.successMessage(this.language?.message?.createdNote);
      }
    });
  }


  getNotes(): void {
    this.tourService.getNotes(this.id).subscribe(response => {
      if (this.tour) this.tour.notes = response.notes;
    });
  }


  deleteNote(noteId: number): void {
    this.tourService.deleteNote(this.id, noteId).subscribe(response => {
      if (this.tour) {
        this.tour.notes = response.notes;
        this.notifyService.warningMessage(this.language?.message?.deletedNote);
      }
    });
  }


  delete(): void {
    this.tourService.delete(this.id).subscribe(() => {
      this.updateList.emit();
      this.close.emit();
      this.notifyService.warningMessage(this.language?.message?.deletedTour);
    });
  }

  inheritFromCustomer(): void {
    if (this.formGroup.value.customerId)
      this.getCustomer().subscribe(response => {
        this.setTagsFromCustomer(response);
      });
  }


  inheritFromChauffeur(): void {
    if (this.formGroup.value.assignedUserId)
      this.userService.getSingleWithMeta(this.formGroup.value.assignedUserId).subscribe(response => {
        this.selectedFrontVehicleCallback(response?.userMeta);
        this.selectedBackVehicleCallback(response?.userMeta);
        this.selectedBackVehicleCallback(response?.userMeta);
        this.selectedTransportEquipmentCallback(response?.userMeta);
      });
  }


  getCustomer(): Observable<CustomerModel> {
    return this.customerService.getSingle(this.formGroup.value.customerId);
  }


  setTagsFromCustomer(customer: CustomerModel): void {
    this.formGroup.value.tagIds = customer?.tags?.map(x => x.id);
  }


  setTags(tags: TagModel[]): void {
    const selectedTags = tags.filter(x => x.selected);
    const ids: number[] = selectedTags.map(x => x.id);
    this.formGroup.value.tagIds = ids;
  }

  get registerTypes() { return RegisterType };

  get addresses(): FormArray {
    return this.formGroup.get('addresses') as FormArray
  }

  selectedUserCallback(e: any): void {
    if (!this.tour) return;
    this.userSelect = false;
    this.tour.assignedUser = e.user;
    this.formGroup.patchValue({ assignedUserId: e.user?.id });
    if (this.tour.assignedUser) {
      this.inheritFromChauffeur();
    }
  }


  selectedCustomerCallback(e: any): void {
    if (!this.tour) return;
    this.customerSelect = false;
    this.tour.customer = e.customer;

    this.customerService.getSingle(e.customer.id).subscribe(response => {
      this.customerAddresses = response.addresses
    })


    this.formGroup.patchValue({ customerId: e.customer?.id });
    if (e.customer) {
      this.inheritFromCustomer();
    }
  }


  selectedFrontVehicleCallback(e: any): void {
    if (!this.tour) return;
    this.frontVehicleSelect = false;
    this.tour.frontVehicle = e?.frontVehicle;
    this.formGroup.patchValue({ frontVehicleId: e?.frontVehicle?.id });
  }


  selectedBackVehicleCallback(e: any): void {
    if (!this.tour) return;
    this.backVehicleSelect = false;
    this.tour.backVehicles = e?.backVehicles;
    this.formGroup.patchValue({ backVehicleIds: e?.backVehicles?.map((x: BackVehicleModel) => x.id) })
  }


  selectedTransportEquipmentCallback(e: any): void {
    if (!this.tour) return;
    this.transportEquipmentSelect = false;
    this.tour.transportEquipment = e?.transportEquipment;
    this.formGroup.patchValue({ transportEquipmentId: e?.transportEquipment?.id });
  }


  get createModes() {
    return CreateMode;
  }


  get backVehiclesString(): string {
    let str = '';

    this.tour?.backVehicles?.forEach(x => {
      str = str + ` ${x.unitIdentification} ${x.registrationNumber},`
    });

    str = str.slice(0, -1)
    return str;
  }


  setTourTimes(): void {
    const start = moment(this.datePipe.transform(`${this.formGroup.value.expectedStartDate}`, 'yyyy-MM-dd HH:mm'));
    const end = moment(this.datePipe.transform(`${this.formGroup.value.expectedEndDate}`, 'yyyy-MM-dd HH:mm'));
    this.tourTimes?.push({ item1: start.toDate(), item2: end.toDate() });
  }


  translateType(): string {
    return `tour.type.${this.tour?.type?.toLowerCase()}`;
  }


  editAddress(i: number, address: any): void {
    if (!this.tour) return;
    this.tour.addresses[i] = address;
    // this.update("updatedAddress");
  }


  deleteAddress(addressId: number) {
    if (this.tour)
      this.tourService.deleteAddress(this.tour.id, addressId).subscribe((response) => {
        this.tour = response;
        this.notifyService.warningMessage(this.language?.message?.deletedAddress);
      });
  }


  start() {
    if (!this.tour) return;
    this.startTour = !this.startTour;
  }


  startNoteDescription(model: any) {
    if (!this.tour) return;
    if (!this.tour.assignedUser) {
      this.notifyService.warningMessage(this.language?.message?.noAssignedUser);
      return;
    }

    this.formGroup.patchValue({
      actualStartDate: model.endDate,
      actualStartTime: model.endTime
    });

    let note: NoteModel = {
      id: 0,
      value: model.value,
      timestamp: new Date(),
      showInApp: false
    };

    let startModel: StartTourModel = {
      startTime: new Date(model.endDate + ' ' + model.endTime),
      mileage: 0,
      comment: note.value,
      fuelConsumption: 0,
      adBlueConsumption: 0,
      countryCode: "",
      latitude: 0,
      longitude: 0,
      createDrivingRecord: true,
      userId: this.tour.assignedUser.id
    };  //UserStartTimeRegistrationModel = { startLatitude: 0, startLongitude: 0, time: new Date(model.endDate + ' ' + model.endTime), tourRelationId: this.tour.id };

    if (this.tour.status == "OnHold") {
      lastValueFrom<any>(this.tourService.restartTour(this.tour.id, startModel)).then((response) => {
        this.createNote(note);
        this.update("startedRegistration");
      })
        .catch(ex => {
          this.notifyService.errorMessage(this.language?.message?.startedTourRegistrationFailed);
        })
        .finally(() => {
          this.readOnly = true;
        })
    } else {
      lastValueFrom<any>(this.tourService.startTour(this.tour.id, startModel)).then((response) => {
        this.createNote(note);
        this.update("startedRegistration");
      })
        .catch(ex => {
          this.notifyService.errorMessage(this.language?.message?.startedTourRegistrationFailed);
        })
        .finally(() => {
          this.readOnly = true;
        })
    }
  }


  editNoteRegistration(model: any) {
    if (!this.tour) return;

    let note: NoteModel = {
      id: 0,
      value: model.value,
      timestamp: new Date(),
      showInApp: false
    };

    this.createNote(note);
    this.update("updatedRegistration");
  }


  cancelTourRegistration(): void {
    if (!this.tour) return;
    this.cancelRegistration = !this.cancelRegistration;
  }


  cancelNoteRegistration(model: any) {
    if (!this.tour) return;

    const m = {
      cancelDescription: model.value,
    }


    lastValueFrom<any>(this.tourService.cancelTourRegistration(this.id, m)).then(_ => {
      this.refresh();
      this.updateList.emit();
      this.notifyService.warningMessage(this.language?.message?.canceledTourRegistration);
    })
      .catch(_ => {
        this.notifyService.errorMessage(this.language?.message?.canceledTourRegistrationFailed);
      })
      .finally(() => this.readOnly = true)
  }


  cancel(): void {
    if (!this.tour) return;
    this.endTour = !this.endTour;
  }


  cancelNoteDescription(model: any) {
    if (!this.tour) return;

    const m = {
      cancelDescription: model.value,
      actualEndDate: moment(this.datePipe.transform(`${model.endDate} ${model.endTime}`, 'yyyy-MM-dd HH:mm')).toDate(),
    }


    lastValueFrom<any>(this.tourService.cancelTour(this.id, m)).then(_ => {
      this.refresh();
      this.updateList.emit();
      this.notifyService.warningMessage(this.language?.message?.endedTour);
    })
      .catch(_ => {
        this.notifyService.errorMessage(this.language?.message?.endedTourFailed);
      })
      .finally(() => this.readOnly = true);
    /* this.tourService.cancelTour(this.id, m).toPromise()
      .then(_ => { */

    /*
    if (this.tour?.assignedUserTimeRegistrationId) {
      this.userService.getUserTimeRegistrations(this.tour?.assignedUser.id).toPromise().then((regs) => {
        regs.forEach((element: any) => {
          if (this.tour && element.id == this.tour?.assignedUserTimeRegistrationId && !element.endTime) {
            this.userService.updateUserTimeRegistration(this.tour.assignedUser.id, element.id, { id: element.id, startTime: new Date(new Date(element.startTime).toUTCString()), endTime: m.actualEndDate }).toPromise().then(result => {
            }).catch(result => {
            });
          }
        });
      });
    }*/

    /*   this.refresh();
      this.updateList.emit();
      this.notifyService.warningMessage(this.language?.message?.endedTour);
    })
    .catch(_ => {
      this.notifyService.errorMessage(this.language?.message?.endedTourFailed);
    })
    .finally(() => this.readOnly = true) */
  }


  createAddress(model: AddressModel): void {
    this.addAddress = false;

    // clear model before use
    if (this.tour) {
      this.tour.addresses.push(model);
      for (let i = 0; i < this.tour.addresses.length; i++) {
        this.setPriority(i);
      }

      this.update();
    }
  }


  onChangeFreightType(event: any): void {
    this.freightTypeDescValue = this.freightTypeDescSelect.nativeElement.value;
    this.freightTypeUnitTitle = this.language.tour.freightTypeUnit[this.freightTypeDescValue];
    if (this.tour != undefined) {
      this.tour.freightTotalAmount = 0;
      this.tour.freightTypeId = Number(this.freightTypeDescValue);
    }

    this.patchFormValues();
  }


  setFreight(amount: number) { }


  swap(index1: number, index2: number) {
    if (this.tour) {
      const extras = [...this.tour?.addresses];

      if (index2 > 0 && index1 < extras.length - 1) {
        [extras[index1], extras[index2]] = [extras[index2], extras[index1]];
        this.tour.addresses = extras;
      }

      for (let i = 0; i < this.tour.addresses.length; i++) {
        this.setPriority(i);
      }
    }
  }


  setPriority(i: number) {
    if (this.tour) {
      this.tour.addresses[i].priority = i + 1
      if (i == 0) {
        this.tour.addresses[i].type = 'start';
      }
      else if (i == this.tour.addresses.length - 1) {
        this.tour.addresses[i].type = 'stop';
      }
      else {
        this.tour.addresses[i].type = 'waypoint';
      }
    }
  }


  getVehicleDisplayText(vehicle: FrontVehicleModel): string {
    if (vehicle?.unitIdentification != null && vehicle?.registrationNumber != null) {
      return vehicle?.unitIdentification + ' ' + vehicle?.registrationNumber;
    }
    if (vehicle?.unitIdentification != null) {
      return vehicle.unitIdentification;
    }
    if (vehicle?.registrationNumber != null) {
      return vehicle.registrationNumber;
    }
    return '';
  }


  editProductModal(productIndex: number) {
    this.eventProxy.setEvent(new ProductModalEdit({
      addressIndex: 9999,
      products: this.products,
      formGroup: this.tourFreights.at(productIndex)
    }))
  }

  deleteProduct(productIndex: number) {
    this.tourFreights.removeAt(productIndex)
  }
}
