import { AfterViewInit, Component, inject, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { DetailRowService, EditService, EditSettingsModel, FilterService, GridComponent, GridModule, GroupService, IEditCell, PageService, PageSettingsModel, SortService, ToolbarItems, ToolbarService } from '@syncfusion/ej2-angular-grids';
import { NodeSelectEventArgs, SelectEventArgs, SidebarComponent, SidebarModule, TabModule, ToolbarModule, TreeViewComponent, TreeViewModule } from '@syncfusion/ej2-angular-navigations';
import { ListViewModule } from '@syncfusion/ej2-angular-lists';
import { ButtonModule, CheckBoxModule } from '@syncfusion/ej2-angular-buttons';
import { CreatePriceRoute, CreatePriceTable, CreatePriceUnit, CreatePriceUnitMember, PriceRoute, PriceTable, PriceUnitMember, TomTomCity, UpdatePriceRoute, UpdatePriceTable, UpdatePriceUnit } from './shared/pricing.model';
import { CheckBoxSelectionService, ComboBoxComponent, ComboBoxModule, DropDownListModule, ListBoxModule } from '@syncfusion/ej2-angular-dropdowns';
import { CustomerService } from 'src/app/customers/shared/customer.service';
import { CustomerModel } from 'src/app/customers/shared/customer.model';
import { CreateZone, CreateZoneMeta, Zone, ZoneExtended } from 'src/app/shared/zone/shared/zone.model';
import { AnimationSettingsModel, DialogComponent, DialogModule } from '@syncfusion/ej2-angular-popups';
import { NumericTextBoxComponent, NumericTextBoxModule, TextBoxModule } from '@syncfusion/ej2-angular-inputs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/shared/shared.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PricingService } from './shared/pricing.service';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { ZoneService } from 'src/app/shared/zone/shared/zone.service';
import { FreightTypeService } from 'src/app/freight-types/shared/freight-types.service';
import { TransportTypeService } from 'src/app/shared/transport-type/shared/transport-type.service';
import { BillingType, Product } from 'src/app/freight-types/shared/freight-types.model';
import { TransportType } from 'src/app/shared/transport-type/shared/transport-type.model';
import { Query, DataManager } from '@syncfusion/ej2-data';
import { MultiColumnComboBoxModule } from '@syncfusion/ej2-angular-multicolumn-combobox';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'app-pricing',
  standalone: true,
  imports: [
    NumericTextBoxModule,
    DropDownListModule,
    TextBoxModule,
    DialogModule,
    ButtonModule,
    GridModule,
    ToolbarModule,
    ListViewModule,
    SidebarModule,
    TreeViewModule,
    CheckBoxModule,
    TabModule,
    CommonModule,
    SharedModule,
    NgbModule,
    ReactiveFormsModule,
    MultiColumnComboBoxModule,
    FormsModule,
    ComboBoxModule,
    ListBoxModule
  ],
  providers: [
    DetailRowService,
    ToolbarService,
    SortService,
    PageService,
    FilterService,
    CheckBoxSelectionService,
    EditService,
    GroupService
  ],
  templateUrl: './pricing.component.html',
  styleUrl: './pricing.component.scss'
})

export class PricingComponent implements OnInit, AfterViewInit {
  private router: Router = inject(Router);
  private pricingService: PricingService = inject(PricingService);
  private toaster: ToastrService = inject(ToastrService);
  private translate: TranslateService = inject(TranslateService);

  @ViewChild('sidebar') sidebar!: SidebarComponent;
  @ViewChild('tree') treeview!: TreeViewComponent;
  @ViewChild('dateGrid') dateGrid!: GridComponent;
  @ViewChild('customerGrid') customerGrid!: GridComponent;
  @ViewChild('zoneGrid') zoneGrid!: GridComponent;
  @ViewChild('typeGrid') typeGrid!: GridComponent;
  @ViewChild('priceGrid') priceGrid!: GridComponent;

  @ViewChild('dropdownlistCityMul') dropdownlistCityMul!: MultiColumnComboBoxModule;
  @ViewChild('citySelector') citySelector!: ComboBoxComponent;

  treeMenuItems: Object[] = [];
  treeFields: Object = {};

  translations: any = {
    prices: '',
    pricing: '',
    indexAdjustment: '',
    surcharges: '',
    columnGrouping: '',
    gridSearch: '',
    noData: '',
    required: '',
    validDate: '',
    dateTodayOrLater: '',
    createZone: '',
    selectCountry: ''
  }

  customers: CustomerModel[] = [];
  zones: Zone[] = [];
  zonesMapped: ZoneExtended[] = [];

  billingTypes: BillingType[] = [];
  products: Product[] = [];
  transportTypes: TransportType[] = [];

  valueZoneFrom: number | null = null;
  valueZoneTo: number | null = null;
  valueFixedprice: number | null = null;

  valueTransportTypeId: number | null = null;
  valueBillingTypeId: number | null = null;
  valueProductTypeId: number | null = null;

  valueNewZoneFromZip: number | null = null;
  valueNewZoneToZip: number | null = null;

  valueTomTomCity: string | null = null;
  valueTomTomZip: number | null = null;

  valueTomTomResult: any;
  valueTomTomCitySel: any;

  valueCity: any;
  valueCitySelected: any;

  searchResults: any = [];

  loadingIndicator: any = { indicatorType: 'Spinner' };
  dateParams: IEditCell = { params: { format: 'dd/MM/yyyy' } };
  numericParams: IEditCell = { params: { decimals: 0, format: 'N', showClearButton: true, showSpinButton: false } };
  numericDecimalParams: IEditCell = { params: { decimals: 2, format: 'N', showClearButton: true, showSpinButton: false } };
  stringParams: IEditCell = { params: { showClearButton: true } };

  dropdownZonesEditParams: Object = { params: { popupHeight: '300px' } };
  dropdownProductseditParams: IEditCell = {
    params: {
      fields: { text: 'name', value: 'id' },
      placeholder: 'Select a Product',
      allowFiltering: false
    },
  };

  typeEditParams?: IEditCell;
  fieldsMultiZoneSelect: Object = { text: 'fromMeta1', value: 'id' };
  currentActiveTab: number = 0;

  dateToolbar: ToolbarItems[] = ['Add', 'Edit', 'Delete', 'Update', 'Cancel', 'Search'];
  dateEditSettings: EditSettingsModel = { allowAdding: true, allowEditing: true, allowDeleting: true };
  datePageSettings: PageSettingsModel = { pageSize: 10, pageCount: 10 };

  customerToolbar: (ToolbarItems | { text: string; id: string; prefixIcon: string })[] = [
    {
      text: 'Tilføj',
      id: 'addCustomer',
      prefixIcon: 'e-custom-icons e-add',
    }, {
      text: 'Fjern',
      id: 'removeCustomer',
      prefixIcon: 'e-custom-icons e-delete',
    }, {
      text: 'Vis alle',
      id: 'showAllCustomer',
      prefixIcon: 'e-custom-icons e-list-unordered',
    },
    'Search'
  ];

  customerEditSettings: EditSettingsModel = { allowAdding: false, allowEditing: false, allowDeleting: true, mode: 'Dialog' };
  customerPageSettings: PageSettingsModel = { pageSize: 10, pageCount: 10 };
  selectedNewCustomerId: number | null = null;

  fieldsCustomers: Object = { text: 'name', value: 'id' };
  filteredCustomersDataSource: { id: number; name: string }[] = [];

  zoneToolbar: (ToolbarItems | { text: string; id: string; prefixIcon: string })[] = [
    'Add',
    {
      text: 'Tilføj Zone',
      id: 'addZone',
      prefixIcon: 'e-custom-icons e-add',
    },
    {
      text: 'Fjern',
      id: 'removeZoneRoute',
      prefixIcon: 'e-custom-icons e-delete',
    },
    'Search'
  ];

  typeToolbar: (ToolbarItems | { text: string; id: string; prefixIcon: string })[] = [
    'Add', 'Delete', 'Search'
  ];

  zoneEditSettings: EditSettingsModel = { allowAdding: true, allowEditing: true, allowDeleting: true, mode: 'Dialog' };
  zonePageSettings: PageSettingsModel = { pageSize: 10, pageCount: 10 };

  typeEditSettings: EditSettingsModel = { allowAdding: true, allowEditing: true, allowDeleting: true, mode: 'Dialog' };
  typePageSettings: PageSettingsModel = { pageSize: 10, pageCount: 10 };

  priceToolbar: ToolbarItems[] = ['Add', 'Edit', 'Delete', 'Update', 'Cancel', 'Search'];
  priceEditSettings: EditSettingsModel = { allowAdding: true, allowEditing: true, allowDeleting: true };
  pricePageSettings: PageSettingsModel = { pageSize: 10, pageCount: 10 };

  selectedPriceTable: PriceTable | undefined;

  requiredRules: any = {
    required: []
  };

  validFromRules: any = {
    required: [],
    customDateValidation: [],
    customMinValidation: []
  };

  validToRules: any = {
    customDateValidation: [],
    customMinValidation: []
  };

  priceTables: PriceTable[] = [];
  priceTablesList: PriceTable[] = [];

  datePreviousRequestType: string = '';
  zonePreviousRequestType: string = '';
  typePreviousRequestType: string = '';
  pricePreviousRequestType: string = '';

  selectedDateId: number = 0;
  selectedZoneId: number = 0;
  selectedTypeId: number = 0;

  private customerService: CustomerService = inject(CustomerService);
  private ZoneService: ZoneService = inject(ZoneService);
  private transportTypeService: TransportTypeService = inject(TransportTypeService);
  private freightTypeService: FreightTypeService = inject(FreightTypeService);
  private http = inject(HttpClient);

  header: string = '';
  showCloseIcon: Boolean = true;
  width: string = '50%';
  animationSettings: AnimationSettingsModel = { effect: 'None' };
  height: string = '220px';

  @ViewChild('customDialogTemplate') customDialogTemplate!: TemplateRef<any>;
  @ViewChild('customerDialogTemplate') customerDialogTemplate!: TemplateRef<any>;

  countries: Object[] = [
    { Id: 'DK', Country: 'Danmark' },
    { Id: 'SE', Country: 'Sverige' },
    { Id: 'DE', Country: 'Tyskland' }
  ];

  // maps the appropriate column to fields property
  fieldsCountries: Object = { text: 'Country', value: 'Id' };

  dropdownDataCity: { postalCode: number; city: string }[] = [];
  fieldsCity: Object = { text: 'city', value: 'postalCode' };

  fields: Object = { text: 'name', value: 'id' };

  currentLat: number = 55.850849;
  currentLon: number = 9.845612;

  value: string = 'DK';
  waterMark: string = '';

  dialogButtons!: Object[];
  dialogButtonsCustomers!: any[];
  dialogButtonsZones!: any[];

  tomTomCities: TomTomCity[] = [];

  @ViewChild('dialog') dialog?: DialogComponent;
  @ViewChild('dialogCustomers') dialogCustomer?: DialogComponent;
  @ViewChild('dialogZones') dialogZones?: DialogComponent;
  @ViewChild('dialogZone') dialogZone?: DialogComponent;

  @ViewChild('zipFrom', { static: false }) zipFrom!: NumericTextBoxComponent;
  @ViewChild('zipTo', { static: false }) zipTo!: NumericTextBoxComponent;

  groupSettings: any = {
    columns: ['unitId'],  // Specify the column(s) to group by initially
    showGroupedColumn: true,   // Show the grouped column in the grid
    enableLazyLoading: false   // Optional: disable lazy loading of groupings
  };

  /* constructor(private formBuilder: UntypedFormBuilder) {
    this.zoneRouteForm = this.formBuilder.group({
      fixedPrice: [null, Validators.required]
    });
  } */

  async ngOnInit() {
    await this.getTranslations();
    this.getAllPriceTables();

    this.treeMenuItems = [
      {
        id: 1,
        name: this.translations.prices,
        url: 'pricing',
        expanded: true,
        childNodes: [
          {
            id: 1.1,
            name: this.translations.pricing,
            url: 'pricing',
            selected: true
          },
          {
            id: 1.2,
            name: this.translations.indexAdjustment,
            url: 'pricing/index-adjustment'
          },
          {
            id: 1.3,
            name: this.translations.surcharges,
            url: 'pricing/surcharge'
          }
        ]
      }
    ];

    this.treeFields = {
      dataSource: this.treeMenuItems,
      id: 'id',
      text: 'name',
      selected: 'selected',
      expanded: 'expanded',
      child: 'childNodes'
    };

    this.dialogButtons = [
      {
        click: this.onSaveClick.bind(this), // Binding the function to the button click
        buttonModel: { content: 'Save', isPrimary: true }
      },
      {
        click: this.onCancelClick.bind(this), // Binding the function to the button click
        buttonModel: { content: 'Cancel' }
      }
    ];

    this.dialogButtonsCustomers = [
      {
        click: this.onSaveClickCustomers.bind(this), // Binding the function to the button click
        buttonModel: { content: 'Save', isPrimary: true, disabled: true }
      },
      {
        click: this.onCancelClickCustomers.bind(this), // Binding the function to the button click
        buttonModel: { content: 'Cancel' }
      }
    ];

    this.dialogButtonsZones = [
      {
        click: this.onSaveClickZones.bind(this), // Binding the function to the button click
        buttonModel: { content: 'Save', isPrimary: true, disabled: true }
      },
      {
        click: this.onCancelClickZones.bind(this), // Binding the function to the button click
        buttonModel: { content: 'Cancel' }
      }
    ];

    this.getAllCustomers();
    this.getAllZones();
    this.getAllProducts();
    this.getAllTransportTypes();
    this.getAllBillingTypes();

    this.requiredRules.required = [true, this.translations.required];
    this.validFromRules.required = this.requiredRules.required;

    this.validFromRules.customDateValidation = [
      (args: { [key: string]: any }) => {
        const parsedDate = typeof args.value === 'string' ? this.parseDate(args.value) : args.value;
        return parsedDate instanceof Date && !isNaN(parsedDate.getTime());
      },
      this.translations.validDate
    ];

    this.validFromRules.customMinValidation = [
      (args: { [key: string]: any }) => {
        const parsedDate = typeof args.value === 'string' ? this.parseDate(args.value) : args.value;
        const today = new Date(); // Today's date
        today.setHours(0, 0, 0, 0); // Ensure comparison ignores time
        return parsedDate instanceof Date && parsedDate >= today;
      },
      this.translations.dateTodayOrLater
    ]

    this.validToRules.customDateValidation = [
      (args: { [key: string]: any }) => {
        const parsedDate = typeof args.value === 'string' ? this.parseDate(args.value) : args.value;
        return parsedDate instanceof Date && !isNaN(parsedDate.getTime());
      },
      this.translations.validDate
    ],

      this.validToRules.customMinValidation = [
        (args: { [key: string]: any }) => {
          const parsedDate = typeof args.value === 'string' ? this.parseDate(args.value) : args.value;
          const today = new Date(); // Today's date
          today.setHours(0, 0, 0, 0); // Ensure comparison ignores time
          return parsedDate instanceof Date && parsedDate >= today;
        },
        this.translations.dateTodayOrLater
      ]

    this.header = this.translations.createZone;
    this.waterMark = this.translations.selectCountry;

    setTimeout(() => {
      document.querySelectorAll('.e-groupdroparea').forEach((groupingArea) => {
        (groupingArea as HTMLElement).textContent = this.translations.columnGrouping;
      });

      document.querySelectorAll('.e-toolbar .e-input-group > input').forEach((searchField) => {
        (searchField as HTMLInputElement).placeholder = this.translations.gridSearch;
      });

      document.querySelectorAll('.e-emptyrow > td').forEach((noData) => {
        (noData as HTMLElement).textContent = this.translations.noData;
      });

      document.querySelectorAll('.e-grid .e-toolbar-items .e-toolbar-item').forEach((toolbarItem) => {
        (toolbarItem as HTMLElement).title = '';
      });

      document.querySelectorAll('.e-grid .e-toolbar-items .e-toolbar-right .e-toolbar-item .e-input-group-icon').forEach((searchIcon) => {
        (searchIcon as HTMLElement).title = '';
      });

      document.querySelectorAll('.e-gridpager .e-pagercontainer .e-icons').forEach((pagingButton) => {
        (pagingButton as HTMLElement).title = '';
      });

      this.typeGrid.toolbarModule.toolbar.disable(true);
      this.priceGrid.toolbarModule.toolbar.disable(true);
    }, 1000);
  }

  async getTranslations() {
    try {
      this.translations.prices = await firstValueFrom(this.translate.get('prices'));
      this.translations.pricing = await firstValueFrom(this.translate.get('pricing'));
      this.translations.indexAdjustment = await firstValueFrom(this.translate.get('indexAdjustment'));
      this.translations.columnGrouping = await firstValueFrom(this.translate.get('columnGrouping'));
      this.translations.gridSearch = await firstValueFrom(this.translate.get('company.search'));
      this.translations.noData = await firstValueFrom(this.translate.get('company.basicInformation.noData'));
      this.translations.required = await firstValueFrom(this.translate.get('message.required'));
      this.translations.validDate = await firstValueFrom(this.translate.get('validDate'));
      this.translations.dateTodayOrLater = await firstValueFrom(this.translate.get('dateTodayOrLater'));
      this.translations.createZone = await firstValueFrom(this.translate.get('createZone'));
      this.translations.selectCountry = await firstValueFrom(this.translate.get('selectCountry'));
      this.translations.surcharges = await firstValueFrom(this.translate.get('surcharge'));
    }

    catch(error) {
      console.error('Fejl: ', error);
    }
  }

  removePaginationTitles(): void {
    setTimeout(() => {
      document.querySelectorAll('.e-gridpager .e-pagercontainer .e-icons').forEach((pagingButton) => {
        (pagingButton as HTMLElement).title = '';
      });
    }, 1000);
  }

  createFormGroup(data: PriceRoute): FormGroup {
    return new FormGroup({
      zoneFrom: new FormControl(data.zoneFrom),
      zoneTo: new FormControl(data.zoneTo),
      fixedprice: new FormControl(data.fixedprice)
    });
  }

  //data: PriceUnit
  createTypeFormGroup(data: any): FormGroup {
    return new FormGroup({
      transportType: new FormControl(data.transportType),
      billingType: new FormControl(data.billingType),
      productType: new FormControl(data.productType)
    });
  }

  createZoneFormGroup(): FormGroup {
    return new FormGroup({
      // transportType: new FormControl(data.transportType),
      // billingType: new FormControl(data.billingType),
      // productType: new FormControl(data.productType)
    });
  }

  onSubmit() {
    const value1 = this.zipFrom.value;
    const value2 = this.zipTo.value;
    // Process values as needed
  }

  public onMultiZoneSelectChange(args: any): void {
    let valueEle: HTMLInputElement = document.getElementsByClassName('e-input')[0] as HTMLInputElement;
    // let text: Element = document.getElementById('text');
    // make empty the input value when typed characters is 'null' in input element
    // if (this.multicomboBoxObj.value === "null" || this.multicomboBoxObj.value === null || this.multicomboBoxObj.value === "") {
    //     valueEle.value = '';
    //  }
    // set null text to the input value when clear the text in ComboBox element
    //  if (this.multicomboBoxObj.text === "null" || this.multicomboBoxObj.text === null || this.multicomboBoxObj.text === "") {
    //      text.innerHTML =  'John Smith';
    //  } else {
    //       text.innerHTML = this.multicomboBoxObj.text.toString();
    //   }
  }

  loadRoutingContent(args: NodeSelectEventArgs): void {
    let data: any = this.treeview.getTreeData(args.node);
    this.router.navigate([data[0].url]);
  }

  parseDate(value: string): Date | null {
    if (!value) return null;
    const [day, month, year] = value.split('/').map(Number);
    if (day && month && year) {
      return new Date(year, month - 1, day); //Adjust month (0-based index)
    }
    return null;
  }

  actionEndDates(args: any): void {
    if (args.requestType === 'add') {
      this.datePreviousRequestType = 'add';
    }

    if (args.requestType === 'beginEdit') {
      this.datePreviousRequestType = 'beginEdit';
    }

    if (args.requestType === 'save' && this.datePreviousRequestType === 'add') {
      let customer: any | null = null;

      const selectedRecords = this.customerGrid.getSelectedRecords();
      const selectedRowIndex = this.customerGrid.getSelectedRowIndexes()[0]; // Get the first selected index
      if (selectedRecords.length > 0) {
        // Extract specific fields
        const firstSelectedRecord = selectedRecords;

        customer = firstSelectedRecord.map((record: any) => record.id);
        customer = customer.join(",");
      }

      const model: CreatePriceTable = {
        validFrom: args.data.validFrom,
        validTo: args.data?.validTo,
        customers: customer
      }

      this.createPriceTable(model);
    }

    if (args.requestType === 'save' && this.datePreviousRequestType === 'beginEdit') {
      const model: UpdatePriceTable = {
        id: args.data.id,
        validFrom: args.data.validFrom,
        validTo: args.data?.validTo
      }

      this.updatePriceTable(model);
    }

    if (args.requestType === 'delete') {
      this.deletePriceTable(args.data[0].id);
    }
  }

  setPriceTableFromCustomer(args: any): void {
    if (this.selectedPriceTable == null) {
      const filteredList = this.priceTablesList.filter(mainObj =>
        mainObj.customers?.some(customer => customer.id === args.data.id)
      );

      this.priceTables = filteredList;

      this.zoneGrid.dataSource = [];
      this.typeGrid.dataSource = [];
      this.priceGrid.dataSource = [];

      this.selectedZoneId = 0;
      this.selectedTypeId = 0;
      this.typeGrid.toolbarModule.toolbar.disable(true);
      this.priceGrid.toolbarModule.toolbar.disable(true);
      this.removePaginationTitles();
    }
  }

  onDatesRowSelected(args: any): void {
    this.customerGrid.dataSource = args.data.customers;
    this.zoneGrid.dataSource = args.data.priceRoutes;
    this.typeGrid.dataSource = [];
    this.priceGrid.dataSource = [];
    this.selectedDateId = args.data.id;
    this.selectedPriceTable = args.data;
    this.selectedZoneId = 0;
    this.selectedTypeId = 0;
    this.typeGrid.toolbarModule.toolbar.disable(true);
    this.priceGrid.toolbarModule.toolbar.disable(true);
    this.removePaginationTitles();
  }

  actionEndZones(args: any): void {
    if (args.requestType === 'add') {
      this.zonePreviousRequestType = 'add';
    }

    if (args.requestType === 'beginEdit') {
      this.zonePreviousRequestType = 'beginEdit';
      this.valueZoneFrom = args.rowData.zoneFrom.id;
      this.valueZoneTo = args.rowData.zoneTo.id;
      this.valueFixedprice = args.rowData.fixedPrice;
    }

    if (args.requestType === 'save' && this.zonePreviousRequestType === 'add') {
      const model: CreatePriceRoute = {
        priceTableId: this.selectedDateId,
        zoneFromId: this.valueZoneFrom!, //backend takes id, but input field is text for a property on zoneMeta
        zoneToId: this.valueZoneTo!, //backend takes id, but input field is text for a property on zoneMeta
        fixedPrice: this.valueFixedprice!
      }

      this.createPriceRoute(model);
    }

    if (args.requestType === 'save' && this.zonePreviousRequestType === 'beginEdit') {
      const model: UpdatePriceRoute = {
        id: args.data.id,
        priceTableId: this.selectedDateId,
        zoneFromId: this.valueZoneFrom!, //backend takes id, but input field is text for a property on zoneMeta
        zoneToId: this.valueZoneTo!, //backend takes id, but input field is text for a property on zoneMeta
        fixedPrice: this.valueFixedprice!
      }

      this.updatePriceRoute(model);
    }

    if (args.requestType === 'delete') {
      this.deletePriceRoute(args.data[0].id);
    }
  }

  onZoneRowSelected(args: any): void {
    this.typeGrid.dataSource = args.data.priceUnits;
    this.priceGrid.dataSource = [];
    this.selectedZoneId = args.data.id;
    this.selectedTypeId = 0;
    this.typeGrid.toolbarModule.toolbar.disable(false);
    this.priceGrid.toolbarModule.toolbar.disable(true);
    this.removePaginationTitles();
  }

  actionEndTypes(args: any): void {
    if (args.requestType === 'add') {
      this.typePreviousRequestType = 'add';
    }

    if (args.requestType === 'beginEdit') {
      this.typePreviousRequestType = 'beginEdit';
      this.valueTransportTypeId = args.rowData.transportType.id;
      this.valueBillingTypeId = args.rowData.billingType.id;
      this.valueProductTypeId = args.rowData.productType.id;
    }

    if (args.requestType === 'save' && this.typePreviousRequestType === 'add') {
      const model: CreatePriceUnit = {
        priceRouteId: this.selectedZoneId,
        transportTypeId: this.valueTransportTypeId!,
        billingTypeId: this.valueBillingTypeId!,
        productTypeId: this.valueProductTypeId!
      }

      this.createPriceUnit(model);
    }

    if (args.requestType === 'save' && this.typePreviousRequestType === 'beginEdit') {
      const model: UpdatePriceUnit = {
        id: args.data.id,
        priceRouteId: this.selectedZoneId,
        transportTypeId: this.valueTransportTypeId!,
        billingTypeId: this.valueBillingTypeId!,
        productTypeId: this.valueProductTypeId!
      }

      this.updatePriceUnit(model);
    }

    if (args.requestType === 'delete') {
      this.deletePriceUnit(args.data[0].id);
    }
  }

  onTypeRowSelected(args: any): void {
    this.priceGrid.dataSource = args.data.priceUnitMembers;
    this.selectedTypeId = args.data.id;
    this.priceGrid.toolbarModule.toolbar.disable(false);
    this.removePaginationTitles();
  }

  onTabSelected(event: SelectEventArgs): void {
    this.currentActiveTab = event.selectedIndex;
  }

  actionEndPrices(args: any): void {
    if (args.requestType === 'add') {
      this.pricePreviousRequestType = 'add';
    }

    if (args.requestType === 'beginEdit') {
      this.pricePreviousRequestType = 'beginEdit';
    }

    if (args.requestType === 'save' && this.pricePreviousRequestType === 'add') {
      const model: CreatePriceUnitMember = {
        priceUnitId: this.selectedTypeId,
        amountFrom: args.data.amountFrom,
        amountTo: args.data.amountTo,
        price: args.data.price,
        perUnit: args.data.perUnit,
        minAmount: args.data.minAmount,
        maxAmount: args.data.maxAmount
      }

      this.createPriceUnitMember(model);
    }

    if (args.requestType === 'save' && this.pricePreviousRequestType === 'beginEdit') {
      const model: PriceUnitMember = {
        id: args.data.id,
        priceUnitId: this.selectedTypeId,
        amountFrom: args.data.amountFrom,
        amountTo: args.data.amountTo,
        price: args.data.price,
        perUnit: args.data.perUnit,
        minAmount: args.data.minAmount,
        maxAmount: args.data.maxAmount
      }

      this.updatePriceUnitMember(model);
    }

    if (args.requestType === 'delete') {
      this.deletePriceUnitMember(args.data[0].id);
    }
  }

  getAllPriceTables(): void {
    this.pricingService.getAllPriceTables().subscribe({
      next: (response) => this.priceTablesList = response,
      error: (error) => console.error('Fejl: ', error)
    });
  }

  createPriceTable(model: CreatePriceTable): void {
    this.pricingService.createPriceTable(model).subscribe({
      error: (error) => console.error('Fejl: ', error),
      complete: () => {
        this.toaster.success(this.translate.instant('periodCreated'));
        this.getAllPriceTables();
      }
    });
  }

  updatePriceTable(model: UpdatePriceTable): void {
    this.pricingService.updatePriceTable(model).subscribe({
      error: (error) => console.error('Fejl: ', error),
      complete: () => {
        this.toaster.success(this.translate.instant('periodUpdated'));
        this.getAllPriceTables();
      }
    });
  }

  deletePriceTable(id: number): void {
    this.pricingService.deletePriceTable(id).subscribe({
      error: (error) => console.error('Fejl: ', error),
      complete: () => {
        this.toaster.error(this.translate.instant('periodDeleted'));
        this.getAllPriceTables();
      }
    });
  }

  createPriceRoute(model: CreatePriceRoute): void {
    this.pricingService.createPriceRoute(model).subscribe({
      error: (error) => console.error('Fejl: ', error),
      complete: () => {
        this.toaster.success(this.translate.instant('zoneCreated'));
      }
    });
  }

  updatePriceRoute(model: UpdatePriceRoute): void {
    this.pricingService.updatePriceRoute(model).subscribe({
      error: (error) => console.error('Fejl: ', error),
      complete: () => {
        this.toaster.success(this.translate.instant('zoneUpdated'));
      }
    });
  }

  deletePriceRoute(id: number): void {
    this.pricingService.deletePriceRoute(id).subscribe({
      error: (error) => console.error('Fejl: ', error),
      complete: () => {
        this.toaster.error(this.translate.instant('zoneDeleted'));
      }
    });
  }

  createPriceUnit(model: CreatePriceUnit): void {
    this.pricingService.createPriceUnit(model).subscribe({
      error: (error) => console.error('Fejl: ', error),
      complete: () => {
        this.toaster.success(this.translate.instant('typeCreated'));
      }
    });
  }

  updatePriceUnit(model: UpdatePriceUnit): void {
    this.pricingService.updatePriceUnit(model).subscribe({
      error: (error) => console.error('Fejl: ', error),
      complete: () => {
        this.toaster.success(this.translate.instant('typeUpdated'));
      }
    });
  }

  deletePriceUnit(id: number): void {
    this.pricingService.deletePriceUnit(id).subscribe({
      error: (error) => console.error('Fejl: ', error),
      complete: () => {
        this.toaster.error(this.translate.instant('typeDeleted'));
      }
    });
  }

  createPriceUnitMember(model: CreatePriceUnitMember): void {
    this.pricingService.createPriceUnitMember(model).subscribe({
      error: (error) => console.error('Fejl: ', error),
      complete: () => {
        this.toaster.success(this.translate.instant('priceCreated'));
      }
    });
  }

  updatePriceUnitMember(model: PriceUnitMember): void {
    this.pricingService.updatePriceUnitMember(model).subscribe({
      error: (error) => console.error('Fejl: ', error),
      complete: () => {
        this.toaster.success(this.translate.instant('priceUpdated'));
      }
    });
  }

  deletePriceUnitMember(id: number): void {
    this.pricingService.deletePriceUnitMember(id).subscribe({
      error: (error) => console.error('Fejl: ', error),
      complete: () => {
        this.toaster.error(this.translate.instant('priceDeleted'));
      }
    });
  }

  onSelectTomTomSelector(event: any) {
    if (event.itemData) {
    } else {
      console.error('Selected item is null or does not have a valid property.');
    }
  }

  ngAfterViewInit(): void {
    // Make sure to access the ViewChild after the view has initialized
    if (this.customDialogTemplate) {

    }

    if (this.dialog)
      this.dialog.hide();
  }

  getAllCustomers(): void {
    this.customerService.getAll().subscribe({
      next: (response) => this.setCustomers(response)
    });
  }

  setCustomers(data: CustomerModel[]): void {
    this.customers = data;
    this.customerGrid.dataSource = data;
  }

  getAllProducts(): void {
    this.freightTypeService.getAllProducts().subscribe({
      next: (response) => this.setProducts(response)
    });
  }

  setProducts(data: Product[]): void {
    this.products = data;

    this.typeEditParams = {
      params: {
        dataSource: new DataManager(this.products),
        fields: { text: 'name', value: 'id' },
        query: new Query(),
        actionComplete: () => false
      }
    };

    // this.dropdownProductseditParams.params.dataSource = [...this.products];
    // this.dropdownProductseditParams.
    //  this.customerGrid.dataSource =data;
  }

  getAllTransportTypes(): void {
    this.transportTypeService.getAllTransportTypes().subscribe({
      next: (response) => this.setTransportTypes(response)
    });
  }

  setTransportTypes(data: TransportType[]): void {
    this.transportTypes = data;
    //  this.customerGrid.dataSource =data;
  }

  getAllBillingTypes(): void {
    this.freightTypeService.getAllBillingTypes().subscribe({
      next: (response) => this.setBillingTypes(response)
    });
  }

  setBillingTypes(data: BillingType[]): void {
    this.billingTypes = data;
    //  this.customerGrid.dataSource =data;
  }

  getAllZones(): void {
    this.ZoneService.getAllZones().subscribe({
      next: (response) => this.setZones(response)
    });
  }

  setZones(data: Zone[]): void {
    this.zones = data;
    //this.dropdownZonesEditParams.params.dataSource = data;
    //this.dropdownProductseditParams.params?.
    //  this.customerGrid.dataSource =data;

    this.zonesMapped = data.map(item => ({
      id: item.id,
      toMeta1: item.toZone?.meta1,
      fromMeta1: item.fromZone.meta1,
      fromZone: item.fromZone,
      toZone: item.toZone,
      country: item.country
    }));
  }

  onSaveClick() {
    if (this.currentActiveTab == 0) {
      const value1 = this.zipFrom.value;
      const value2 = this.zipTo.value;

      const modelFrom: CreateZoneMeta = {
        meta1: value1.toString(),
        meta2: '',
        mapDataId: 0
      }

      const modelTo: CreateZoneMeta = {
        meta1: value2.toString(),
        meta2: '',
        mapDataId: 0
      }

      let zoneMeta1Data: any;
      let zoneMeta2Data: any;

      this.ZoneService.createZoneMeta(modelFrom).subscribe({
        error: (error) => console.error('Fejl: ', error),
        next: (zoneMeta1Data) => {

          this.ZoneService.createZoneMeta(modelTo).subscribe({
            error: (error) => console.error('Fejl: ', error),
            next: (zoneMeta2Data) => {

              const model: CreateZone = {
                zoneTypeId: 1,
                fromId: zoneMeta1Data.id,
                toId: zoneMeta2Data.id,
                country: this.value
              }

              this.ZoneService.createZone(model).subscribe({
                error: (error) => console.error('Fejl: ', error),
                complete: () => {
                  this.toaster.success(this.translate.instant('zoneCreated'));
                }
              });
            }
          });
        }
      });
    }

    if (this.currentActiveTab == 1) {
      const modelFrom: CreateZoneMeta = {
        meta1: this.valueCitySelected.city,
        meta2: this.valueCitySelected.postalCode.toString(),
        mapDataId: 0
      }

      let zoneMeta1Data: any;

      this.ZoneService.createZoneMeta(modelFrom).subscribe({
        error: (error) => console.error('Fejl: ', error),
        next: (zoneMeta1Data) => {
          const model: CreateZone = {
            zoneTypeId: 2,
            fromId: zoneMeta1Data.id,
            country: this.value
          }

          this.ZoneService.createZone(model).subscribe({
            error: (error) => console.error('Fejl: ', error),
            complete: () => {
              this.toaster.success(this.translate.instant('zoneCreated'));
            }
          });
        }
      });
    }

    // Add your save logic here¨
    if (this.dialog)
      this.dialog.hide();
  }

  public onChangeCityCombo(args: any): void {
    this.valueCitySelected = args.itemData;
  }

  onCancelClick() {
    if (this.dialog)
      this.dialog.hide();
  }

  onSaveClickCustomers() {
    const cRecords = this.customerGrid.getCurrentViewRecords();
    const cIds = cRecords.map((record: any) => record.id);
    cIds.push(this.selectedNewCustomerId);
    let cIdsSplit = cIds.join(",");

    //   cIdsSplit = cIdsSplit.slice(0, -1);

    const model: UpdatePriceTable = {
      id: this.selectedPriceTable!.id,
      validFrom: this.selectedPriceTable!.validFrom,
      validTo: this.selectedPriceTable?.validTo,
      customers: cIdsSplit,
      surchargeMembers: this.selectedPriceTable!.surchargeMembers
    }

    this.updatePriceTable(model);

    const currCustomer = this.customers.find(item => item.id === this.selectedNewCustomerId);
    this.customerGrid.addRecord(currCustomer);

    // Add your save logic here¨
    if (this.dialogCustomer)
      this.dialogCustomer.hide();

    const saveButton = this.dialogButtonsCustomers.find(
      (button) => button.buttonModel.content === 'Save'
    );

    if (saveButton) {
      saveButton.buttonModel.disabled = true;
      this.dialogButtonsCustomers = [...this.dialogButtonsCustomers];
    }
  }

  onCancelClickCustomers() {
    if (this.dialogCustomer)
      this.dialogCustomer.hide();
  }

  onSaveClickZones() {
    //   const currCustomer = this.customers.find(item => item.id === this.selectedNewCustomerId);
    //   this.customerGrid.addRecord(currCustomer);

    // Add your save logic here¨
    if (this.dialogZones)
      this.dialogZones.hide();

    const saveButton = this.dialogButtonsZones.find(
      (button) => button.buttonModel.content === 'Save'
    );

    if (saveButton) {
      saveButton.buttonModel.disabled = true;
      this.dialogButtonsZones = [...this.dialogButtonsZones];
    }
  }

  onCancelClickZones() {
    if (this.dialogZones)
      this.dialogZones.hide();
  }

  setnewCustomer(args: any): void {
    this.selectedNewCustomerId = args.value;

    const saveButton = this.dialogButtonsCustomers.find(
      (button) => button.buttonModel.content === 'Save'
    );

    if (saveButton) {
      saveButton.buttonModel.disabled = false;
      this.dialogButtonsCustomers = [...this.dialogButtonsCustomers];
    }
  }

  toolbarCustomersClick(args: any): void {
    // Handle the click event for the custom button
    if (args.item.properties.id === 'addCustomer' && this.selectedPriceTable != null) {
      const localDataSource: { id: number; name: string }[] =
        Array.isArray(this.customerGrid.dataSource) ? this.customerGrid.dataSource : [];

      this.filteredCustomersDataSource = this.customers.filter(
        (item) =>
          !localDataSource.some(
            (removeItem) =>
              removeItem.id === item.id && removeItem.name === item.name // Match both id and name
          )
      );

      // Ensure that we only add the content from the template
      if (this.dialogCustomer) {
        this.dialogCustomer.show();
      }
    }
    if (args.item.properties.id === 'addCustomer' && this.selectedPriceTable == null) {
      this.toaster.error(this.translate.instant('selectPeriodFirst'));
    }

    if (args.item.properties.id === 'removeCustomer') {
      const selectedRowIndex = this.customerGrid.getSelectedRowIndexes()[0]; // Get the first selected index
      if (selectedRowIndex !== undefined && this.selectedPriceTable != null) {
        // Remove the selected row
        const selectedRecord = this.customerGrid.getCurrentViewRecords()[selectedRowIndex];
        this.customerGrid.deleteRecord('id', selectedRecord);
      }

      if (args.item.properties.id === 'removeCustomer' && this.selectedPriceTable == null) {
        this.toaster.error(this.translate.instant('selectPeriodFirst'));
      }

      if (args.item.properties.id === 'removeCustomer' && selectedRowIndex == undefined) {
        this.toaster.error(this.translate.instant('selectCustomerFirst'));
      }
    }

    if (args.item.properties.id === 'showAllCustomer') {
      this.customerGrid.dataSource = this.customers;
      this.priceTables = [];
      this.zoneGrid.dataSource = [];
      this.typeGrid.dataSource = [];
      this.priceGrid.dataSource = [];
      this.selectedPriceTable = undefined;
      this.selectedNewCustomerId = null;
    }
  }

  onActionCompleteCustomers(event: any): void {
    if (event.requestType === 'delete') {
      // Retrieve updated records after deletion
      const cRecords = this.customerGrid.getCurrentViewRecords();
      const cIds = cRecords.map((record: any) => record.id);

      cIds.push(this.selectedNewCustomerId);

      let cIdsSplit = cIds.join(",");
      cIdsSplit = cIdsSplit.slice(0, -1);

      const model: UpdatePriceTable = {
        id: this.selectedPriceTable!.id,
        validFrom: this.selectedPriceTable!.validFrom,
        validTo: this.selectedPriceTable?.validTo,
        customers: cIdsSplit,
        surchargeMembers: this.selectedPriceTable!.surchargeMembers
      }

      this.updatePriceTable(model);
    }
  }

  toolbarZonesClick(args: any): void {
    // Handle the click event for the custom button
    if (args.item.properties.id === 'addZoneRoute' && this.selectedPriceTable != null) {
      if (this.dialogZones) {
        this.dialogZones.show();
      }
    }

    if (args.item.properties.id === 'addZone') {
      //this.zoneForm = this.createZoneFormGroup();
      this.http.get<{ postalCode: number; city: string }[]>('assets/data/dkPostal.json').subscribe({
        next: (data) => {
          this.dropdownDataCity = data.map(item => ({
            city: item.city,
            postalCode: item.postalCode
          }));

          // this.dropdownDataCity = data;
          // this.dropdownlistCityMul.dataSource = data;
        },
        error: (err) => {
          console.error('Failed to load JSON data', err);
        },
      });

      if (this.dialog) {
        this.dialog.show();
      }
    }

    if (args.item.properties.id === 'removeZoneRoute') {
      const selectedRowIndex = this.zoneGrid.getSelectedRowIndexes()[0]; // Get the first selected index

      if (selectedRowIndex !== undefined && this.selectedPriceTable != null) {
        // Remove the selected row
        const selectedRecord = this.zoneGrid.getCurrentViewRecords()[selectedRowIndex];
        this.zoneGrid.deleteRecord('id', selectedRecord);
        //this.deletePriceRoute();
      }
    }
  }

  toolbarTypesClick(args: any): void {
    // Handle the click event for the custom button
    if (args.item.properties.id === 'addZoneRoute' && this.selectedPriceTable != null) {
      if (this.dialogZones) {
        this.dialogZones.show();
      }
    }

    if (args.item.properties.id === 'addZone' && this.selectedPriceTable != null) {
      if (this.dialog) {
        this.dialog.show();
      }
    }

    if (args.item.properties.id === 'removeZoneRoute') {
      const selectedRowIndex = this.zoneGrid.getSelectedRowIndexes()[0]; // Get the first selected index

      if (selectedRowIndex !== undefined && this.selectedPriceTable != null) {
        // Remove the selected row
        const selectedRecord = this.zoneGrid.getCurrentViewRecords()[selectedRowIndex];
        this.zoneGrid.deleteRecord('id', selectedRecord);
        //this.deletePriceRoute();
      }
    }
  }

  getValueFromTomTom(value: any) {
    this.http.get(
      'https://api.tomtom.com/search/2/search/' +
      value.value + '.json?' +
      'lat=' + this.currentLat + '&' +
      'lon=' + this.currentLon + '&' +
      'minFuzzyLevel=1&' +
      'maxFuzzyLevel=2&' +
      'view=Unified&' +
      'relatedPois=off&' +
      'extendedPostalCodesFor=Geo&' +
      'key=' + environment.tomtom.key).subscribe((data: any) => (
        this.valueTomTomCity = data['results'][0]['address']['municipality'],
        this.valueTomTomZip = data['results'][0]['address']['postalCode'],

        this.valueTomTomResult = data['results'],

        this.evaluateTomTomData(),

        this.searchResults = data['results']
      ));
  }

  fillAddressForm(result: any) {
    //this.searchResults = null;
  }

  generateAdressTitle(result: any) {
    var returnString = "";
    if (result.poi !== undefined) {
      if (result.poi.name !== undefined) returnString += result.poi.name + " ";
    }

    if (result.address !== undefined) {
      if (result.address.streetName !== undefined) returnString += result.address.streetName + " ";
      if (result.address.streetNumber !== undefined) returnString += result.address.streetNumber + " ";
      if (result.address.municipality !== undefined) returnString += result.address.municipality + " ";
      if (result.address.country !== undefined) returnString += ", " + result.address.country + " ";
    }

    return returnString;
  }

  evaluateTomTomData() {
    //let localCities: TomTomCity[] | null = null;
    let localCities: any[];
    this.tomTomCities = [];
    let localData = this.valueTomTomResult;

    if (Array.isArray(localData)) {
      localData.forEach((value) => {
        if (value['address']['postalCode'] != null && value['address']['municipality'] != null) {
          this.tomTomCities.push({ city: value['address']['municipality'], zip: value['address']['postalCode'] });
        }
      });
    }

    //this.tomTomCities = localCities;
  }

  toolbarClicked() {
    this.sidebar.toggle();
  }
}
