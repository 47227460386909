import { JsonPipe } from '@angular/common'
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core'
import { FormsModule } from '@angular/forms'
import { NgbDropdownConfig, NgbDropdownModule, NgbTypeaheadModule } from '@ng-bootstrap/ng-bootstrap'
import { faSliders, IconDefinition } from '@fortawesome/free-solid-svg-icons'
import { Observable, OperatorFunction } from 'rxjs'
import { debounceTime, map } from 'rxjs/operators'
import { FaIconComponent } from '@fortawesome/angular-fontawesome'
import { TranslateModule, TranslateService } from '@ngx-translate/core'


interface Option {
  key: string;
  displayName: string;
}

interface Column {
  key: string;
  displayName: string;
}

interface DynamicColumnData {
  [key: string]: Column[];
}


@Component({
  selector: 'app-filter-button',
  templateUrl: './filter-button.component.html',
  standalone: true,
  imports: [NgbTypeaheadModule, FormsModule, JsonPipe, NgbDropdownModule, FaIconComponent, TranslateModule],
  styleUrl: './filter-button.component.scss',
  providers: [NgbDropdownConfig]
})
export class FilterButtonComponent implements OnInit {
  @Input() searchString: string = ''
  @Input() options: any[] = [] // unknown type
  @Input() multiselect: boolean = false

  @Output() selectedOption = new EventEmitter()

  @ViewChild('filterDropdown') filterDropdown: any | undefined // unknown type

  searchableKeys: string[] = []

  faSliders: IconDefinition = faSliders


  constructor(config: NgbDropdownConfig, private translate: TranslateService) {
    config.autoClose = true
  }


  ngOnInit(): void {
    // filter out duplicate objects in the this.options array
    this.options = this.options.filter((v, i, a) => a.findIndex(t => (t.key === v.key)) === i)
  }


  onSelect(optionKey: Option) {
    this.selectedOption.emit(optionKey)
  }
}
