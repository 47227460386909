<div class="mb-3" [ngClass]="{'bg-light' : createMode||!readOnly}">
  <form [formGroup]="formGroup" class="col-md-12">
    <div class="row">
      <div *ngIf="!createMode" class="col-md-12 text-end" style="margin-top: 10px;">
        <button *ngIf="readOnly" type="button" class="btn btn-default btn-sm" (click)="readOnly = ! readOnly; this.patchFormValues();" placement="left" ngbTooltip="{{'btn.edit' | translate}}">
          <fa-icon [icon]="faEdit"></fa-icon>
        </button>
        <app-btn-accept-widget *ngIf="!readOnly" [formGroup]="formGroup" (accept)="update()" (cancel)="cancel()"></app-btn-accept-widget>
      </div>
      <div class="col-md-5 pe-0">
        <label for="title">{{'contact.title' | translate}}
          <span *ngIf="formGroup.controls['title'].hasError('maxlength')" class="required-text">{{'message.maxLength' | translate: {number: 255} }}</span>
          <span *ngIf="formGroup.controls['title'].hasError('required')" class="required-text">{{'message.required' | translate}}</span>
        </label>
        <input id="title" type="text" formControlName="title" class="form-control form-control-sm" [readonly]="readOnly">
      </div>
      <div class="col-md-7">
        <label for="name">{{'contact.name' | translate}}
          <span *ngIf="formGroup.controls['name'].hasError('maxlength')" class="required-text">{{'message.maxLength' | translate: {number: 255} }}</span>
          <span *ngIf="formGroup.controls['name'].hasError('required')" class="required-text">{{'message.required' | translate}}</span>
        </label>
        <input id="name" type="text" formControlName="name" class="form-control form-control-sm" [readonly]="readOnly">
      </div>
      <div class="col-md-6">
        <label for="phone">{{'contact.phone' | translate}}
          <span *ngIf="formGroup.controls['phone'].hasError('maxlength')" class="required-text">{{'message.maxLength' | translate: {number: 12} }}</span>
        </label>
        <input id="phone" type="text" formControlName="phone" class="form-control form-control-sm" [readonly]="readOnly">
      </div>
      <div class="col-md-6">
        <label for="mobile">{{'contact.mobile' | translate}}
          <span *ngIf="formGroup.controls['mobile'].hasError('maxlength')" class="required-text">{{'message.maxLength' | translate: {number: 12} }}</span>
        </label>
        <input id="mobile" type="text" formControlName="mobile" class="form-control form-control-sm" [readonly]="readOnly">
      </div>
      <div class="col-md-12">
        <label for="email">{{'contact.email' | translate}}
          <span *ngIf="formGroup.controls['email'].hasError('maxlength')" class="required-text">{{'message.maxLength' | translate: {number: 255} }}</span>
          <span *ngIf="formGroup.controls['email'].hasError('email')" class="required-text">{{'message.mustBeAnEmail' | translate}}</span>
          <span *ngIf="formGroup.controls['email'].hasError('required')" class="required-text">{{'message.required' | translate}}</span>
        </label>
        <input id="email" type="text" formControlName="email" class="form-control form-control-sm" [readonly]="readOnly">
      </div>
      <div class="col-md-12 text-left btn-container" *ngIf="this.createMode === this.createModes.create_as_child">
        <app-btn-accept-widget [formGroup]="formGroup" (accept)="create()" (cancel)="cancelCreate.emit()"></app-btn-accept-widget>
      </div>
    </div>
  </form>
  <div *ngIf="!readOnly && !createMode" class="col-md-12 btn-container">
    <div class="d-flex justify-content-end">
      <button *ngIf="!readOnly" class="btn btn-danger" placement="left" ngbTooltip="{{'btn.deleteContact' | translate}}" (click)="delete();">
        <fa-icon [icon]="faTrash"></fa-icon>
      </button>
    </div>
  </div>
</div>
