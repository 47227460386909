import { Component, inject, OnInit, ViewChild } from '@angular/core';
import { DetailRowService, EditService, EditSettingsModel, FilterService, GridComponent, GridModule, GroupService, IEditCell, PageService, PageSettingsModel, SortService, ToolbarItems, ToolbarService } from '@syncfusion/ej2-angular-grids';
import { NodeSelectEventArgs, SelectEventArgs, SidebarComponent, SidebarModule, TabModule, ToolbarModule, TreeViewComponent, TreeViewModule } from '@syncfusion/ej2-angular-navigations';
import { ListViewModule } from '@syncfusion/ej2-angular-lists';
import { ButtonModule, CheckBoxModule } from '@syncfusion/ej2-angular-buttons';
import { CreatePriceRoute, CreatePriceTable, CreatePriceUnit, CreatePriceUnitMember, PriceRoute, PriceTable, PriceUnit, PriceUnitMember, TomTomCity, UpdatePriceRoute, UpdatePriceTable, UpdatePriceUnit } from './shared/pricing.model';
import { CheckBoxSelectionService, ComboBoxComponent, ComboBoxModule, DropDownListModule, ListBoxModule } from '@syncfusion/ej2-angular-dropdowns';
import { CustomerService } from 'src/app/customers/shared/customer.service';
import { CustomerModel } from 'src/app/customers/shared/customer.model';
import { CreateZone, CreateZoneMeta, Zone, ZoneExtended } from 'src/app/shared/zone/shared/zone.model';
import { AnimationSettingsModel, DialogComponent, DialogModule } from '@syncfusion/ej2-angular-popups';
import { NumericTextBoxComponent, NumericTextBoxModule, TextBoxModule } from '@syncfusion/ej2-angular-inputs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/shared/shared.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { PricingService } from './shared/pricing.service';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { ZoneService } from 'src/app/shared/zone/shared/zone.service';
import { FreightTypeService } from 'src/app/freight-types/shared/freight-types.service';
import { TransportTypeService } from 'src/app/shared/transport-type/shared/transport-type.service';
import { BillingType, DeliveryRequirement, Product } from 'src/app/freight-types/shared/freight-types.model';
import { TransportType } from 'src/app/shared/transport-type/shared/transport-type.model';
import { MultiColumnComboBoxModule } from '@syncfusion/ej2-angular-multicolumn-combobox';
import { firstValueFrom, lastValueFrom } from 'rxjs';

@Component({
  selector: 'app-pricing',
  standalone: true,
  imports: [
    NumericTextBoxModule,
    DropDownListModule,
    TextBoxModule,
    DialogModule,
    ButtonModule,
    GridModule,
    ToolbarModule,
    ListViewModule,
    SidebarModule,
    TreeViewModule,
    CheckBoxModule,
    TabModule,
    CommonModule,
    SharedModule,
    NgbModule,
    ReactiveFormsModule,
    MultiColumnComboBoxModule,
    FormsModule,
    ComboBoxModule,
    ListBoxModule
  ],
  providers: [
    DetailRowService,
    ToolbarService,
    SortService,
    PageService,
    FilterService,
    CheckBoxSelectionService,
    EditService,
    GroupService
  ],
  templateUrl: './pricing.component.html',
  styleUrl: './pricing.component.scss'
})

export class PricingComponent implements OnInit {
  private router: Router = inject(Router);
  private pricingService: PricingService = inject(PricingService);
  private toaster: ToastrService = inject(ToastrService);
  private translate: TranslateService = inject(TranslateService);
  private customerService: CustomerService = inject(CustomerService);
  private ZoneService: ZoneService = inject(ZoneService);
  private transportTypeService: TransportTypeService = inject(TransportTypeService);
  private freightTypeService: FreightTypeService = inject(FreightTypeService);
  private http = inject(HttpClient);

  @ViewChild('sidebar') sidebar!: SidebarComponent;
  @ViewChild('tree') treeview!: TreeViewComponent;
  @ViewChild('dateGrid') dateGrid!: GridComponent;
  @ViewChild('customerGrid') customerGrid!: GridComponent;
  @ViewChild('zoneGrid') zoneGrid!: GridComponent;
  @ViewChild('typeGrid') typeGrid!: GridComponent;
  @ViewChild('priceGrid') priceGrid!: GridComponent;

  @ViewChild('dropdownlistCityMul') dropdownlistCityMul!: MultiColumnComboBoxModule;
  @ViewChild('citySelector') citySelector!: ComboBoxComponent;

  treeMenuItems: Object[] = [];
  treeFields: Object = {};

  translations: any = {
    prices: '',
    pricing: '',
    indexAdjustment: '',
    surcharges: '',
    columnGrouping: '',
    gridAdd: '',
    gridEdit: '',
    gridDelete: '',
    gridUpdate: '',
    gridCancel: '',
    gridSearch: '',
    noData: '',
    required: '',
    validDate: '',
    dateTodayOrLater: '',
    createZone: '',
    createType: '',
    selectCountry: ''
  }

  customers: any[] = [];
  zones: Zone[] = [];
  zonesMapped: ZoneExtended[] = [];

  billingTypes: BillingType[] = [];
  products: Product[] = [];
  //transportTypes: TransportType[] = [];
  deliveryRequirements: DeliveryRequirement[] = [];

  valueZoneFrom: number | null = null;
  valueZoneTo: number | null = null;
  valueFixedprice: number | null = null;

  valueTransportTypeId: number | null = null;
  valueBillingTypeId: number | null = null;
  valueProductTypeId: number | null = null;

  valueNewZoneFromZip: number | null = null;
  valueNewZoneToZip: number | null = null;

  valueTomTomCity: string | null = null;
  valueTomTomZip: number | null = null;

  valueTomTomResult: any;
  valueTomTomCitySel: any;

  valueAddZoneAddress: string | null = null;
  valueAddZonePos: string | null = null;
  valueAddCountryCode: string | null = null;

  valueCity: any;
  valueCitySelected: any;

  searchResults: any = [];

  loadingIndicator: any = { indicatorType: 'Spinner' };
  dateParams: IEditCell = { params: { format: 'dd/MM/yyyy' } };
  numericParams: IEditCell = { params: { decimals: 0, format: 'N', showClearButton: true, showSpinButton: false } };
  numericDecimalParams: IEditCell = { params: { decimals: 2, format: 'N', showClearButton: true, showSpinButton: false } };

  fieldsMultiZoneSelect: Object = { text: 'fromMeta1', value: 'id' };
  currentActiveTab: number = 0;

  dateToolbar: (ToolbarItems | { text: string; id: string; prefixIcon: string })[] = [];
  dateEditSettings: EditSettingsModel = { allowAdding: true, allowEditing: true, allowDeleting: true };
  datePageSettings: PageSettingsModel = { pageSize: 10, pageCount: 10 };

  customerToolbar: (ToolbarItems | { text: string; id: string; prefixIcon: string })[] = [];

  customerEditSettings: EditSettingsModel = { allowAdding: false, allowEditing: false, allowDeleting: true, mode: 'Dialog' };
  customerPageSettings: PageSettingsModel = { pageSize: 10, pageCount: 10 };
  selectedNewCustomerId: number | null = null;

  fieldsCustomers: Object = { text: 'name', value: 'id' };
  filteredCustomersDataSource: { id: number; name: string }[] = [];

  zoneToolbar: (ToolbarItems | { text: string; id: string; prefixIcon: string })[] = [];
  typeToolbar: (ToolbarItems | { text: string; id: string; prefixIcon: string })[] = [
    'Add', 'Delete', 'Search'
  ];

  zoneEditSettings: EditSettingsModel = {};
  zonePageSettings: PageSettingsModel = { pageSize: 10, pageCount: 10 };

  typeEditSettings: EditSettingsModel = {};
  typePageSettings: PageSettingsModel = { pageSize: 10, pageCount: 10 };

  priceToolbar: ToolbarItems[] = ['Add', 'Edit', 'Delete', 'Update', 'Cancel', 'Search'];
  priceEditSettings: EditSettingsModel = { allowAdding: true, allowEditing: true, allowDeleting: true };
  pricePageSettings: PageSettingsModel = { pageSize: 10, pageCount: 10 };

  selectedPriceTable: PriceTable | undefined;

  requiredRules: any = {
    required: []
  };

  validFromRules: any = {
    required: [],
    customDateValidation: [],
    customMinValidation: []
  };

  validToRules: any = {
    customDateValidation: [],
    customMinValidation: []
  };

  priceTablesList: PriceTable[] = [];

  datePreviousRequestType: string = '';
  zonePreviousRequestType: string = '';
  typePreviousRequestType: string = '';
  pricePreviousRequestType: string = '';

  selectedDateId: number = 0;
  selectedZoneId: number = 0;
  selectedTypeId: number = 0;

  showCloseIcon: Boolean = true;
  width: string = '50%';
  animationSettings: AnimationSettingsModel = { effect: 'None' };
  height: string = '220px';

  countries: Object[] = [
    { Id: 'DK', Country: 'Danmark' },
    { Id: 'SE', Country: 'Sverige' },
    { Id: 'DE', Country: 'Tyskland' }
  ];

  fieldsCountries: Object = { text: 'Country', value: 'Id' };
  dropdownDataCity: { postalCode: number; city: string }[] = [];
  fieldsCity: Object = { text: 'city', value: 'postalCode' };
  fields: Object = { text: 'name', value: 'id' };

  currentLat: number = 55.850849;
  currentLon: number = 9.845612;

  value: string = 'DK';
  waterMark: string = '';

  dialogButtons!: Object[];
  dialogButtonsCustomers!: any[];

  tomTomCities: TomTomCity[] = [];

  @ViewChild('dialog') dialog?: DialogComponent;
  @ViewChild('dialogCustomers') dialogCustomer?: DialogComponent;

  @ViewChild('zipFrom', { static: false }) zipFrom!: NumericTextBoxComponent;
  @ViewChild('zipTo', { static: false }) zipTo!: NumericTextBoxComponent;

  groupSettings: any = {
    columns: ['unitId'],
    showGroupedColumn: true,
    enableLazyLoading: false
  };

  zoneForm: UntypedFormGroup;

  standardPricesRow: any = { standardPrices: true };

  reuseDate: boolean = false;
  reuseData: PriceTable | undefined;

  constructor(private formBuilder: UntypedFormBuilder) {
    this.zoneForm = this.formBuilder.group({
      fixedPrice: [null, Validators.required]
    });
  }

  async ngOnInit() {
    await this.getTranslations();
    this.getAllPriceTables();
    this.getAllCustomers();
    this.getAllZones();
    this.getAllProducts();
   // this.getAllTransportTypes();
    this.getAllBillingTypes();
    this.getAllDeliveryRequirements();

    this.treeMenuItems = [
      {
        id: 1,
        name: this.translations.prices,
        url: 'pricing',
        expanded: true,
        childNodes: [
          {
            id: 1.1,
            name: this.translations.pricing,
            url: 'pricing',
            selected: true
          },
          {
            id: 1.2,
            name: this.translations.indexAdjustment,
            url: 'pricing/index-adjustment'
          },
          {
            id: 1.3,
            name: this.translations.surcharges,
            url: 'pricing/surcharge'
          }
        ]
      }
    ];

    this.treeFields = {
      dataSource: this.treeMenuItems,
      id: 'id',
      text: 'name',
      selected: 'selected',
      expanded: 'expanded',
      child: 'childNodes'
    };

    this.dialogButtons = [
      {
        click: this.onSaveClick.bind(this),
        buttonModel: { content: this.translate.instant('companyUsers.companyUsersRolesSaveBtn'), isPrimary: true }
      },
      {
        click: this.onCancelClick.bind(this),
        buttonModel: { content: this.translate.instant('timeTable.cancel') }
      }
    ];

    this.dialogButtonsCustomers = [
      {
        click: this.onSaveClickCustomers.bind(this),
        buttonModel: { content: this.translate.instant('companyUsers.companyUsersRolesSaveBtn'), isPrimary: true, disabled: true }
      },
      {
        click: this.onCancelClickCustomers.bind(this),
        buttonModel: { content: this.translate.instant('timeTable.cancel') }
      }
    ];

    this.requiredRules.required = [true, this.translations.required];
    this.validFromRules.required = this.requiredRules.required;

    this.validFromRules.customDateValidation = [
      (args: { [key: string]: any }) => {
        const parsedDate = typeof args.value === 'string' ? this.parseDate(args.value) : args.value;
        return parsedDate instanceof Date && !isNaN(parsedDate.getTime());
      },
      this.translations.validDate
    ];

    this.validFromRules.customMinValidation = [
      (args: { [key: string]: any }) => {
        const parsedDate = typeof args.value === 'string' ? this.parseDate(args.value) : args.value;
        const today = new Date();
        today.setHours(0, 0, 0, 0);
        return parsedDate instanceof Date && parsedDate >= today;
      },
      this.translations.dateTodayOrLater
    ]

    this.validToRules.customDateValidation = [
      (args: { [key: string]: any }) => {
        const parsedDate = typeof args.value === 'string' ? this.parseDate(args.value) : args.value;
        return parsedDate instanceof Date && !isNaN(parsedDate.getTime());
      },
      this.translations.validDate
    ],

      this.validToRules.customMinValidation = [
        (args: { [key: string]: any }) => {
          const parsedDate = typeof args.value === 'string' ? this.parseDate(args.value) : args.value;
          const today = new Date();
          today.setHours(0, 0, 0, 0);
          return parsedDate instanceof Date && parsedDate >= today;
        },
        this.translations.dateTodayOrLater
      ]

    this.waterMark = this.translations.selectCountry;

    this.customerToolbar = [
      {
        text: this.translate.instant('adminpanel.add'),
        id: 'addCustomer',
        prefixIcon: 'e-add',
      }, {
        text: this.translate.instant('tour.create.frontVehicle-select.remove-btn'),
        id: 'removeCustomer',
        prefixIcon: 'e-delete',
      }, {
        text: this.translate.instant('freightTypes.showAll'),
        id: 'showAllCustomer',
        prefixIcon: 'e-list-unordered',
      },
      'Search'
    ];

    this.dateToolbar = [
      'Add',
      {
        text: this.translate.instant('reusePeriod'),
        id: 'reusePeriod',
        prefixIcon: 'e-add'
      },
      'Edit',
      'Delete',
      'Update',
      'Cancel',
      'Search'
    ];

    this.zoneToolbar = [
      'Add',
      {
        text: this.translate.instant('addZone'),
        id: 'addZone',
        prefixIcon: 'e-add',
      },
      {
        text: this.translate.instant('tour.create.frontVehicle-select.remove-btn'),
        id: 'removeZoneRoute',
        prefixIcon: 'e-delete',
      },
      'Search'
    ];

    this.zoneEditSettings = { allowAdding: true, allowEditing: true, allowDeleting: true, mode: 'Dialog', headerTemplate: this.translations.createZone };
    this.typeEditSettings = { allowAdding: true, allowEditing: true, allowDeleting: true, mode: 'Dialog', headerTemplate: this.translations.createType };

    setTimeout(() => {
      document.querySelectorAll('.e-groupdroparea').forEach((groupingArea) => {
        (groupingArea as HTMLElement).textContent = this.translations.columnGrouping;
      });

      document.querySelectorAll('.e-toolbar .e-input-group > input').forEach((searchField) => {
        (searchField as HTMLInputElement).placeholder = this.translations.gridSearch;
      });

      document.querySelectorAll('.e-emptyrow > td').forEach((noData) => {
        (noData as HTMLElement).textContent = this.translations.noData;
      });

      document.querySelectorAll('.e-grid .e-toolbar-items .e-toolbar-item').forEach((toolbarItem) => {
        (toolbarItem as HTMLElement).title = '';
      });

      document.querySelectorAll('.e-toolbar-left .e-toolbar-item .e-add').forEach((addTextSibling) => {
        if((addTextSibling as HTMLElement).parentElement?.id != 'addCustomer' && (addTextSibling as HTMLElement).parentElement?.id != 'addZone' && (addTextSibling as HTMLElement).parentElement?.id != 'reusePeriod') {
          (addTextSibling as HTMLElement).nextSibling!.textContent = this.translations.gridAdd;
        }
      });

      document.querySelectorAll('.e-toolbar-left .e-toolbar-item .e-edit').forEach((editTextSibling) => {
        (editTextSibling as HTMLElement).nextSibling!.textContent = this.translations.gridEdit;
      });

      document.querySelectorAll('.e-toolbar-left .e-toolbar-item .e-delete').forEach((deleteTextSibling) => {
        if((deleteTextSibling as HTMLElement).parentElement?.id != 'removeCustomer' && (deleteTextSibling as HTMLElement).parentElement?.id != 'removeZoneRoute') {
          (deleteTextSibling as HTMLElement).nextSibling!.textContent = this.translations.gridDelete;
        }
      });

      document.querySelectorAll('.e-toolbar-left .e-toolbar-item .e-update').forEach((updateTextSibling) => {
        (updateTextSibling as HTMLElement).nextSibling!.textContent = this.translations.gridUpdate;
      });

      document.querySelectorAll('.e-toolbar-left .e-toolbar-item .e-cancel').forEach((cancelTextSibling) => {
        (cancelTextSibling as HTMLElement).nextSibling!.textContent = this.translations.gridCancel;
      });

      document.querySelectorAll('.e-grid .e-toolbar-items .e-toolbar-right .e-toolbar-item .e-input-group-icon').forEach((searchIcon) => {
        (searchIcon as HTMLElement).title = '';
      });

      document.querySelectorAll('.e-gridpager .e-pagercontainer .e-icons').forEach((pagingButton) => {
        (pagingButton as HTMLElement).title = '';
      });

      this.dateGrid.toolbarModule?.toolbar?.disable(true);
      this.zoneGrid.toolbarModule?.toolbar?.disable(true);
      this.typeGrid.toolbarModule?.toolbar?.disable(true);
      this.priceGrid.toolbarModule?.toolbar?.disable(true);
    }, 1000);
  }

  async getTranslations() {
    try {
      this.translations.prices = await firstValueFrom(this.translate.get('prices'));
      this.translations.pricing = await firstValueFrom(this.translate.get('pricing'));
      this.translations.indexAdjustment = await firstValueFrom(this.translate.get('indexAdjustment'));
      this.translations.columnGrouping = await firstValueFrom(this.translate.get('columnGrouping'));
      this.translations.gridAdd = await firstValueFrom(this.translate.get('freightTypes.create'));
      this.translations.gridDelete = await firstValueFrom(this.translate.get('freightTypes.delete'));
      this.translations.gridEdit = await firstValueFrom(this.translate.get('btn.edit'));
      this.translations.gridUpdate = await firstValueFrom(this.translate.get('companyUsers.companyUsersRolesSaveBtn'));
      this.translations.gridCancel = await firstValueFrom(this.translate.get('organization.cancel'));
      this.translations.gridSearch = await firstValueFrom(this.translate.get('company.search'));
      this.translations.noData = await firstValueFrom(this.translate.get('company.basicInformation.noData'));
      this.translations.required = await firstValueFrom(this.translate.get('message.required'));
      this.translations.validDate = await firstValueFrom(this.translate.get('validDate'));
      this.translations.dateTodayOrLater = await firstValueFrom(this.translate.get('dateTodayOrLater'));
      this.translations.createZone = await firstValueFrom(this.translate.get('createZone'));
      this.translations.createType = await firstValueFrom(this.translate.get('createType'));
      this.translations.selectCountry = await firstValueFrom(this.translate.get('selectCountry'));
      this.translations.surcharges = await firstValueFrom(this.translate.get('surcharge'));
    }

    catch(error) {
      console.error('Fejl: ', error);
    }
  }

  removePaginationTitles(): void {
    setTimeout(() => {
      document.querySelectorAll('.e-gridpager .e-pagercontainer .e-icons').forEach((pagingButton) => {
        (pagingButton as HTMLElement).title = '';
      });
    }, 1000);
  }

  onSubmit() {
    const value1 = this.zipFrom.value;
    const value2 = this.zipTo.value;
    // Process values as needed
  }

  onMultiZoneSelectChange(args: any): void {
    let valueEle: HTMLInputElement = document.getElementsByClassName('e-input')[0] as HTMLInputElement;
    // let text: Element = document.getElementById('text');
    // make empty the input value when typed characters is 'null' in input element
    // if (this.multicomboBoxObj.value === "null" || this.multicomboBoxObj.value === null || this.multicomboBoxObj.value === "") {
    //     valueEle.value = '';
    //  }
    // set null text to the input value when clear the text in ComboBox element
    //  if (this.multicomboBoxObj.text === "null" || this.multicomboBoxObj.text === null || this.multicomboBoxObj.text === "") {
    //      text.innerHTML =  'John Smith';
    //  } else {
    //       text.innerHTML = this.multicomboBoxObj.text.toString();
    //   }

    let zoneFrom = args.item.fromMeta1;
    let zoneTo = args.item.toMeta1;
    let combined = zoneFrom + ' - ' + zoneTo;

    //this.fieldsMultiZoneSelect = { text: combined, value: 'id' };
  }

  loadRoutingContent(args: NodeSelectEventArgs): void {
    let data: any = this.treeview.getTreeData(args.node);
    this.router.navigate([data[0].url]);
  }

  parseDate(value: string): Date | null {
    if (!value) return null;
    const [day, month, year] = value.split('/').map(Number);
    if (day && month && year) {
      return new Date(year, month - 1, day);
    }
    return null;
  }

  setPriceTableFromCustomer(args: any): void {
    this.selectedPriceTable = undefined;

    if (this.selectedPriceTable == null) {
      let standardPrices: any = [];
      let filteredList: any = [];

      if(args.data.standardPrices) {
        standardPrices = this.priceTablesList.filter(period => {
          return !period.customers;
        });
      }
      else {
        filteredList = this.priceTablesList.filter(mainObj =>
          mainObj.customers?.some(customer => customer.id === args.data.id)
        );
      }

      this.dateGrid.dataSource = args.data.standardPrices ? standardPrices : filteredList;
      this.dateGrid.toolbarModule.toolbar.enableItems(1, false);
      this.zoneGrid.dataSource = [];
      this.typeGrid.dataSource = [];
      this.priceGrid.dataSource = [];
      this.selectedDateId = 0;
      this.selectedZoneId = 0;
      this.selectedTypeId = 0;
      this.dateGrid.toolbarModule.toolbar.disable(false);
      this.zoneGrid.toolbarModule.toolbar.disable(true);
      this.typeGrid.toolbarModule.toolbar.disable(true);
      this.priceGrid.toolbarModule.toolbar.disable(true);
      this.removePaginationTitles();
    }
  }

  toolbarDatesClick(args: any): void {
    if(args.item.properties.id === 'reusePeriod') {
      this.reuseData = this.selectedPriceTable;
      this.reuseDate = true;
      this.dateGrid.addRecord();
      this.dateGrid.toolbarModule.toolbar.enableItems(1, false);
      this.dateGrid.toolbarModule.toolbar.enableItems(4, true);
      this.dateGrid.toolbarModule.toolbar.enableItems(5, true);
    }
  }

  async onActionCompleteDates(args: any): Promise<void> {
    if (args.requestType === 'add') {
      this.datePreviousRequestType = 'add';
    }

    if (args.requestType === 'beginEdit') {
      this.datePreviousRequestType = 'beginEdit';
    }

    if (args.requestType === 'save' && this.datePreviousRequestType === 'add' && !this.reuseDate) {
      let customer: any | null = null;

      const selectedRecords = this.customerGrid.getSelectedRecords();
      if (selectedRecords.length > 0) {
        const firstSelectedRecord = selectedRecords;

        customer = firstSelectedRecord.map((record: any) => record.id);
        customer = customer.join(",");
      }

      const argsValidFrom = new Date(args.data.validFrom);
      const validFromMilliseconds = Date.UTC(argsValidFrom.getFullYear(), argsValidFrom.getMonth(), argsValidFrom.getDate());
      const validFrom = new Date(validFromMilliseconds);

      const argsValidTo = new Date(args.data.validTo);
      const validToMilliseconds = Date.UTC(argsValidTo.getFullYear(), argsValidTo.getMonth(), argsValidTo.getDate());
      const validTo = new Date(validToMilliseconds);

      const model: CreatePriceTable = {
        validFrom: validFrom,
        validTo: !args.data.validTo ? undefined : validTo,
        customers: customer
      }

      this.createPriceTable(model);
    }

    if (args.requestType === 'save' && this.datePreviousRequestType === 'beginEdit') {
      const argsValidFrom = new Date(args.data.validFrom);
      const validFromMilliseconds = Date.UTC(argsValidFrom.getFullYear(), argsValidFrom.getMonth(), argsValidFrom.getDate());
      const validFrom = new Date(validFromMilliseconds);

      const argsValidTo = new Date(args.data.validTo);
      const validToMilliseconds = Date.UTC(argsValidTo.getFullYear(), argsValidTo.getMonth(), argsValidTo.getDate());
      const validTo = new Date(validToMilliseconds);

      let customer: any | null = null;

      const selectedRecords = this.customerGrid.getCurrentViewRecords();
      if (selectedRecords.length > 0) {
        const firstSelectedRecord = selectedRecords;

        customer = firstSelectedRecord.map((record: any) => record.id);
        customer = customer.join(",");
      }

      const model: UpdatePriceTable = {
        id: args.data.id,
        validFrom: validFrom,
        validTo: !args.data.validTo ? undefined : validTo,
        customers: customer
      }

      this.updatePriceTable(model);
    }

    if(args.requestType === 'save' && this.reuseDate) {
      let customer: any | null = null;

      const selectedRecords = this.customerGrid.getCurrentViewRecords();
      if (selectedRecords.length > 0) {
        const firstSelectedRecord = selectedRecords;

        customer = firstSelectedRecord.map((record: any) => record.id);
        customer = customer.join(",");
      }

      const argsValidFrom = new Date(args.data.validFrom);
      const validFromMilliseconds = Date.UTC(argsValidFrom.getFullYear(), argsValidFrom.getMonth(), argsValidFrom.getDate());
      const validFrom = new Date(validFromMilliseconds);

      const argsValidTo = new Date(args.data.validTo);
      const validToMilliseconds = Date.UTC(argsValidTo.getFullYear(), argsValidTo.getMonth(), argsValidTo.getDate());
      const validTo = new Date(validToMilliseconds);

      const priceTableModel: CreatePriceTable = {
        validFrom: validFrom,
        validTo: !args.data.validTo ? undefined : validTo,
        customers: customer
      }

      const newPriceTable: PriceTable = await lastValueFrom(this.pricingService.createPriceTable(priceTableModel));

      this.reuseData?.priceRoutes?.forEach(async (priceRoute) => {
        const priceRouteModel: CreatePriceRoute = {
          priceTableId: newPriceTable.id,
          zoneFromId: priceRoute.zoneFrom.id,
          zoneToId: priceRoute.zoneTo?.id,
          fixedPrice: priceRoute.fixedPrice
        }

        const newPriceRoute: PriceRoute = await lastValueFrom(this.pricingService.createPriceRoute(priceRouteModel));

        priceRoute.priceUnits?.forEach(async (priceUnit) => {
          const priceUnitModel: CreatePriceUnit = {
            priceRouteId: newPriceRoute.id,
            transportTypeId: priceUnit.transportType?.id,
            billingTypeId: priceUnit.billingType?.id,
            productTypeId: priceUnit.productType?.id
          }

          const newPriceUnit: PriceUnit = await lastValueFrom(this.pricingService.createPriceUnit(priceUnitModel));

          priceUnit.priceUnitMembers?.forEach(async (priceUnitMember) => {
            const priceUnitMemberModel: CreatePriceUnitMember = {
              priceUnitId: newPriceUnit.id,
              amountFrom: priceUnitMember.amountFrom,
              amountTo: priceUnitMember.amountTo,
              price: priceUnitMember.price,
              perUnit: priceUnitMember.perUnit,
              minAmount: priceUnitMember.minAmount,
              maxAmount: priceUnitMember.maxAmount
            }

            const newPriceUnitMember: PriceUnitMember = await lastValueFrom(this.pricingService.createPriceUnitMember(priceUnitMemberModel));
          });
        });
      });

      this.reuseDate = false;
    }

    if (args.requestType === 'delete') {
      this.deletePriceTable(args.data[0].id);
      let periodIndex = this.priceTablesList.indexOf(args.data[0]);
      this.priceTablesList.splice(periodIndex, 1);
    }

    if(args.requestType === 'cancel') {
      this.reuseDate = false;
    }
  }

  onDatesRowSelected(args: any): void {
    if(!args.data.customers) {
      this.customerGrid.toolbarModule.toolbar.items.forEach((item) => {
        if(item.id != 'showAllCustomer') {
          item.disabled = true;
        }
      });
    }

    this.customerGrid.dataSource = !args.data.customers ? [this.standardPricesRow] : args.data.customers;
    this.dateGrid.toolbarModule.toolbar.enableItems(1, true);
    this.selectedPriceTable = args.data;
    this.zoneGrid.dataSource = args.data.priceRoutes;
    this.typeGrid.dataSource = [];
    this.priceGrid.dataSource = [];
    this.selectedDateId = args.data.id;
    this.selectedZoneId = 0;
    this.selectedTypeId = 0;
    this.zoneGrid.toolbarModule.toolbar.disable(false);
    this.typeGrid.toolbarModule.toolbar.disable(true);
    this.priceGrid.toolbarModule.toolbar.disable(true);
    this.removePaginationTitles();
  }

  onActionCompleteZones(args: any): void {
    if (args.requestType === 'add') {
      this.zonePreviousRequestType = 'add';
    }

    if (args.requestType === 'save' && this.zonePreviousRequestType === 'add') {
      const model: CreatePriceRoute = {
        priceTableId: this.selectedDateId,
        zoneFromId: this.valueZoneFrom!, //backend takes id, but input field is text for a property on zoneMeta
        zoneToId: this.valueZoneTo!, //backend takes id, but input field is text for a property on zoneMeta
        fixedPrice: this.zoneForm.get('fixedPrice')?.value
      }

      this.createPriceRoute(model);
    }

    if (args.requestType === 'delete') {
      this.deletePriceRoute(args.data[0].id);
    }
  }

  onZoneRowSelected(args: any): void {
    this.typeGrid.dataSource = args.data.priceUnits;
    this.priceGrid.dataSource = [];
    this.selectedZoneId = args.data.id;
    this.selectedTypeId = 0;
    this.typeGrid.toolbarModule.toolbar.disable(false);
    this.priceGrid.toolbarModule.toolbar.disable(true);
    this.removePaginationTitles();
  }

  onActionCompleteTypes(args: any): void {
    if (args.requestType === 'add') {
      this.typePreviousRequestType = 'add';
    }

    if (args.requestType === 'beginEdit') {
      this.typePreviousRequestType = 'beginEdit';
      this.valueTransportTypeId = args.rowData.transportType.id;
      this.valueBillingTypeId = args.rowData.billingType.id;
      this.valueProductTypeId = args.rowData.productType.id;
    }

    if (args.requestType === 'save' && this.typePreviousRequestType === 'add') {
      const model: CreatePriceUnit = {
        priceRouteId: this.selectedZoneId,
        transportTypeId: this.valueTransportTypeId!,
        billingTypeId: this.valueBillingTypeId!,
        productTypeId: this.valueProductTypeId!
      }

      this.createPriceUnit(model);
    }

    if (args.requestType === 'save' && this.typePreviousRequestType === 'beginEdit') {
      const model: UpdatePriceUnit = {
        id: args.data.id,
        priceRouteId: this.selectedZoneId,
        transportTypeId: this.valueTransportTypeId!,
        billingTypeId: this.valueBillingTypeId!,
        productTypeId: this.valueProductTypeId!
      }

      this.updatePriceUnit(model);
    }

    if (args.requestType === 'delete') {
      this.deletePriceUnit(args.data[0].id);
    }
  }

  onTypeRowSelected(args: any): void {
    this.priceGrid.dataSource = args.data.priceUnitMembers;
    this.selectedTypeId = args.data.id;
    this.priceGrid.toolbarModule.toolbar.disable(false);
    this.removePaginationTitles();
  }

  onTabSelected(event: SelectEventArgs): void {
    this.currentActiveTab = event.selectedIndex;
    console.log(this.currentActiveTab);
  }

  onActionCompletePrices(args: any): void {
    if (args.requestType === 'add') {
      this.pricePreviousRequestType = 'add';
    }

    if (args.requestType === 'beginEdit') {
      this.pricePreviousRequestType = 'beginEdit';
    }

    if (args.requestType === 'save' && this.pricePreviousRequestType === 'add') {
      const model: CreatePriceUnitMember = {
        priceUnitId: this.selectedTypeId,
        amountFrom: args.data.amountFrom,
        amountTo: args.data.amountTo,
        price: args.data.price,
        perUnit: args.data.perUnit,
        minAmount: args.data.minAmount,
        maxAmount: args.data.maxAmount
      }

      this.createPriceUnitMember(model);
    }

    if (args.requestType === 'save' && this.pricePreviousRequestType === 'beginEdit') {
      const model: PriceUnitMember = {
        id: args.data.id,
        priceUnitId: this.selectedTypeId,
        amountFrom: args.data.amountFrom,
        amountTo: args.data.amountTo,
        price: args.data.price,
        perUnit: args.data.perUnit,
        minAmount: args.data.minAmount,
        maxAmount: args.data.maxAmount
      }

      this.updatePriceUnitMember(model);
    }

    if (args.requestType === 'delete') {
      this.deletePriceUnitMember(args.data[0].id);
    }
  }

  getAllPriceTables(): void {
    this.pricingService.getAllPriceTables().subscribe({
      next: (response) => this.priceTablesList = response,
      error: (error) => console.error('Fejl: ', error)
    });
  }

  createPriceTable(model: CreatePriceTable) {
    this.pricingService.createPriceTable(model).subscribe({
      error: (error) => console.error('Fejl: ', error),
      complete: () => {
        this.toaster.success(this.translate.instant('periodCreated'));
      }
    });
  }

  updatePriceTable(model: UpdatePriceTable): void {
    this.pricingService.updatePriceTable(model).subscribe({
      error: (error) => console.error('Fejl: ', error),
      complete: () => {
        this.toaster.success(this.translate.instant('periodUpdated'));
      }
    });
  }

  deletePriceTable(id: number): void {
    this.pricingService.deletePriceTable(id).subscribe({
      error: (error) => console.error('Fejl: ', error),
      complete: () => {
        this.toaster.error(this.translate.instant('periodDeleted'));
      }
    });
  }

  createPriceRoute(model: CreatePriceRoute): void {
    this.pricingService.createPriceRoute(model).subscribe({
      error: (error) => console.error('Fejl: ', error),
      complete: () => {
        this.toaster.success(this.translate.instant('zoneCreated'));
      }
    });
  }

  updatePriceRoute(model: UpdatePriceRoute): void {
    this.pricingService.updatePriceRoute(model).subscribe({
      error: (error) => console.error('Fejl: ', error),
      complete: () => {
        this.toaster.success(this.translate.instant('zoneUpdated'));
      }
    });
  }

  deletePriceRoute(id: number): void {
    this.pricingService.deletePriceRoute(id).subscribe({
      error: (error) => console.error('Fejl: ', error),
      complete: () => {
        this.toaster.error(this.translate.instant('zoneDeleted'));
      }
    });
  }

  createPriceUnit(model: CreatePriceUnit): void {
    this.pricingService.createPriceUnit(model).subscribe({
      error: (error) => console.error('Fejl: ', error),
      complete: () => {
        this.toaster.success(this.translate.instant('typeCreated'));
      }
    });
  }

  updatePriceUnit(model: UpdatePriceUnit): void {
    this.pricingService.updatePriceUnit(model).subscribe({
      error: (error) => console.error('Fejl: ', error),
      complete: () => {
        this.toaster.success(this.translate.instant('typeUpdated'));
      }
    });
  }

  deletePriceUnit(id: number): void {
    this.pricingService.deletePriceUnit(id).subscribe({
      error: (error) => console.error('Fejl: ', error),
      complete: () => {
        this.toaster.error(this.translate.instant('typeDeleted'));
      }
    });
  }

  createPriceUnitMember(model: CreatePriceUnitMember): void {
    this.pricingService.createPriceUnitMember(model).subscribe({
      error: (error) => console.error('Fejl: ', error),
      complete: () => {
        this.toaster.success(this.translate.instant('priceCreated'));
      }
    });
  }

  updatePriceUnitMember(model: PriceUnitMember): void {
    this.pricingService.updatePriceUnitMember(model).subscribe({
      error: (error) => console.error('Fejl: ', error),
      complete: () => {
        this.toaster.success(this.translate.instant('priceUpdated'));
      }
    });
  }

  deletePriceUnitMember(id: number): void {
    this.pricingService.deletePriceUnitMember(id).subscribe({
      error: (error) => console.error('Fejl: ', error),
      complete: () => {
        this.toaster.error(this.translate.instant('priceDeleted'));
      }
    });
  }

  onSelectTomTomSelector(event: any) {
    console.log(event);

    if (event.items) {

      
      this.valueAddZoneAddress = "";
      this.valueAddZonePos = "";
      this.valueAddCountryCode = "";

      if (event.items[0].address !== undefined) {
        if (event.items[0].address.streetName !== undefined) this.valueAddZoneAddress += event.items[0].address.streetName + " ";
        if (event.items[0].address.streetNumber !== undefined) this.valueAddZoneAddress += event.items[0].address.streetNumber + " ";
        if (event.items[0].address.municipality !== undefined) this.valueAddZoneAddress += event.items[0].address.municipality + "";
        if (event.items[0].address.country !== undefined) this.valueAddZoneAddress += ", " + event.items[0].address.country + " ";

         if (event.items[0].address.countryCode !== undefined) this.valueAddCountryCode = event.items[0].address.countryCode;

      }
      
      if (event.items[0].position !== undefined) {
        if (event.items[0].position.lat !== undefined) this.valueAddZonePos += event.items[0].position.lat  + ",";
        if (event.items[0].position.lon !== undefined) this.valueAddZonePos += event.items[0].position.lon + "";
       }

       //console.log("Add set", this.valueAddZoneAddress , this.valueAddZonePos);
       
    }

    else {
      console.error('Selected item is null or does not have a valid property.');
    }
  }

  getAllCustomers(): void {
    this.customerService.getAll().subscribe({
      next: (response) => {
        this.customers = [this.standardPricesRow, ...response];
        this.customerGrid.dataSource = [this.standardPricesRow, ...response];
      },
      error: (error) => console.error('Fejl: ', error)
    });
  }

  getAllProducts(): void {
    this.freightTypeService.getAllProducts().subscribe({
      next: (response) => this.products = response,
      error: (error) => console.error('Fejl: ', error)
    });
  }

  /*
  getAllTransportTypes(): void {
    this.transportTypeService.getAllTransportTypes().subscribe({
      next: (response) => this.transportTypes = response,
      error: (error) => console.error('Fejl: ', error)
    });
  }*/

  getAllDeliveryRequirements(): void {
    this.freightTypeService.getAllDeliveryRequirements().subscribe({
      next: (response) => this.deliveryRequirements = response,
      error: (error) => console.error('Fejl: ', error)
    });
  }

  getAllBillingTypes(): void {
    this.freightTypeService.getAllBillingTypes().subscribe({
      next: (response) => this.billingTypes = response,
      error: (error) => console.error('Fejl: ', error)
    });
  }

  getAllZones(): void {
    this.ZoneService.getAllZones().subscribe({
      next: (response) => this.setZones(response),
      error: (error) => console.error('Fejl: ', error)
    });
  }

  setZones(data: Zone[]): void {
    this.zones = data;
    //this.dropdownZonesEditParams.params.dataSource = data;
    //this.dropdownProductseditParams.params?.

    this.zonesMapped = data.map(item => ({
      id: item.id,
      toMeta1: item.toZone?.meta1,
      fromMeta1: item.fromZone.meta1,
      fromZone: item.fromZone,
      toZone: item.toZone,
      country: item.country
    }));
  }

  onSaveClick() {

    if (this.currentActiveTab == 0) {
      const value1 = this.zipFrom.value;
      const value2 = this.zipTo.value;

      const modelFrom: CreateZoneMeta = {
        meta1: value1.toString(),
        meta2: '',
        mapDataId: 0
      }

      const modelTo: CreateZoneMeta = {
        meta1: value2.toString(),
        meta2: '',
        mapDataId: 0
      }

      let zoneMeta1Data: any;
      let zoneMeta2Data: any;

      this.ZoneService.createZoneMeta(modelFrom).subscribe({
        next: (zoneMeta1Data) => {
          this.ZoneService.createZoneMeta(modelTo).subscribe({
            next: (zoneMeta2Data) => {
              const model: CreateZone = {
                zoneTypeId: 1,
                fromId: zoneMeta1Data.id,
                toId: zoneMeta2Data.id,
                country: this.value
              }

              this.ZoneService.createZone(model).subscribe({
                error: (error) => console.error('Fejl: ', error),
                complete: () => {
                  this.toaster.success(this.translate.instant('zoneCreated'));
                }
              });
            },
            error: (error) => console.error('Fejl: ', error)
          });
        },
        error: (error) => console.error('Fejl: ', error)
      });
    }

    if (this.currentActiveTab == 1) {
      const modelFrom: CreateZoneMeta = {
        meta1: this.valueCitySelected.city,
        meta2: this.valueCitySelected.postalCode.toString(),
        mapDataId: 0
      }

      let zoneMeta1Data: any;

      this.ZoneService.createZoneMeta(modelFrom).subscribe({
        next: (zoneMeta1Data) => {
          const model: CreateZone = {
            zoneTypeId: 2,
            fromId: zoneMeta1Data.id,
            country: this.value
          }

          this.ZoneService.createZone(model).subscribe({
            error: (error) => console.error('Fejl: ', error),
            complete: () => {
              this.toaster.success(this.translate.instant('zoneCreated'));
            }
          });
        },
        error: (error) => console.error('Fejl: ', error)
      });
    }

    if (this.currentActiveTab == 2 && this.valueAddZoneAddress && this.valueAddZonePos) {
      const value1 = this.valueAddZoneAddress;
      const value2 = this.valueAddZonePos;

      const modelFrom: CreateZoneMeta = {
        meta1: value1,
        meta2: value2,
        mapDataId: 0
      }
 
      let zoneMeta1Data: any;
      let zoneMeta2Data: any;

      this.ZoneService.createZoneMeta(modelFrom).subscribe({
        next: (zoneMeta1Data) => {
          this.valueAddZoneAddress = "";
          this.valueAddZonePos = "";

          const model: CreateZone = {
            zoneTypeId: 3,
            fromId: zoneMeta1Data.id,
            toId: undefined,
            country: this.valueAddCountryCode? this.valueAddCountryCode : ""
          }

          this.ZoneService.createZone(model).subscribe({
            error: (error) => console.error('Fejl: ', error),
            complete: () => {
              this.valueAddCountryCode = "";
              this.toaster.success(this.translate.instant('zoneCreated'));
            }
          });

        },
        error: (error) => console.error('Fejl: ', error)
      });
    }
    // Add your save logic here¨
    if (this.dialog)
      this.dialog.hide();
  }

  onChangeCityCombo(args: any): void {
    this.valueCitySelected = args.itemData;
  }

  onCancelClick() {
    if (this.dialog)
      this.dialog.hide();
  }

  onSaveClickCustomers() {
    const cRecords = this.customerGrid.getCurrentViewRecords();
    const cIds = cRecords.map((record: any) => record.id);
    cIds.push(this.selectedNewCustomerId);
    let cIdsSplit = cIds.join(",");
    //   cIdsSplit = cIdsSplit.slice(0, -1);

    const model: UpdatePriceTable = {
      id: this.selectedPriceTable!.id,
      validFrom: this.selectedPriceTable!.validFrom,
      validTo: this.selectedPriceTable?.validTo,
      customers: cIdsSplit,
      surchargeMembers: this.selectedPriceTable!.surchargeMembers
    }

    this.updatePriceTable(model);

    const currCustomer = this.customers.find(item => item.id === this.selectedNewCustomerId);
    let selectedPeriod = (this.dateGrid.getSelectedRecords()[0] as PriceTable);
    selectedPeriod.customers?.push(currCustomer);
    this.customerGrid.getCurrentViewRecords().push(currCustomer);
    this.customerGrid.dataSource = this.customerGrid.getCurrentViewRecords();

    // Add your save logic here
    if (this.dialogCustomer)
      this.dialogCustomer.hide();

    const saveButton = this.dialogButtonsCustomers.find(
      (button) => button.buttonModel.content === this.translate.instant('companyUsers.companyUsersRolesSaveBtn')
    );

    if (saveButton) {
      saveButton.buttonModel.disabled = true;
      this.dialogButtonsCustomers = [...this.dialogButtonsCustomers];
    }
  }

  onCancelClickCustomers() {
    if (this.dialogCustomer) {
      this.dialogCustomer.hide();
    }
  }

  setNewCustomer(args: any): void {
    this.selectedNewCustomerId = args.value;

    const saveButton = this.dialogButtonsCustomers.find(
      (button) => button.buttonModel.content === this.translate.instant('companyUsers.companyUsersRolesSaveBtn')
    );

    if (saveButton) {
      saveButton.buttonModel.disabled = false;
      this.dialogButtonsCustomers = [...this.dialogButtonsCustomers];
    }
  }

  toolbarCustomersClick(args: any): void {
    if (args.item.properties.id === 'addCustomer' && this.selectedPriceTable != null) {
      this.filteredCustomersDataSource = this.customers.filter(customer => {
        if(!customer.standardPrices) {
          return !(this.customerGrid.dataSource as Array<CustomerModel>).some((customerToRemove) =>
            customerToRemove.id === customer.id
          );
        }
        else {
          return null;
        }
      });

      if (this.dialogCustomer) {
        this.dialogCustomer.show();
      }
    }

    if (args.item.properties.id === 'addCustomer' && this.selectedPriceTable == null) {
      this.toaster.error(this.translate.instant('selectPeriodFirst'));
    }

    if (args.item.properties.id === 'removeCustomer') {
      const selectedRowIndex = this.customerGrid.getSelectedRowIndexes()[0];
      if (selectedRowIndex !== undefined && this.selectedPriceTable != null) {
        const selectedRecord = this.customerGrid.getCurrentViewRecords()[selectedRowIndex];
        this.customerGrid.deleteRecord('id', selectedRecord);
      }

      if (args.item.properties.id === 'removeCustomer' && this.selectedPriceTable == null) {
        this.toaster.error(this.translate.instant('selectPeriodFirst'));
      }

      if (args.item.properties.id === 'removeCustomer' && selectedRowIndex == undefined) {
        this.toaster.error(this.translate.instant('selectCustomerFirst'));
      }
    }

    if (args.item.properties.id === 'showAllCustomer') {
      this.customerGrid.toolbarModule.toolbar.items.forEach((item) => {
        if(item.id != 'showAllCustomer') {
          item.disabled = false;
        }
      });

      this.customerGrid.dataSource = this.customers;
      this.dateGrid.dataSource = [];
      this.zoneGrid.dataSource = [];
      this.typeGrid.dataSource = [];
      this.priceGrid.dataSource = [];
      this.selectedDateId = 0;
      this.selectedZoneId = 0;
      this.selectedTypeId = 0;
      this.selectedPriceTable = undefined;
      this.selectedNewCustomerId = null;
      this.dateGrid.toolbarModule.toolbar.disable(true);
      this.zoneGrid.toolbarModule.toolbar.disable(true);
      this.typeGrid.toolbarModule.toolbar.disable(true);
      this.priceGrid.toolbarModule.toolbar.disable(true);
    }
  }

  onActionCompleteCustomers(event: any): void {
    if (event.requestType === 'delete') {
      // Retrieve updated records after deletion
      const cRecords = this.customerGrid.getCurrentViewRecords();
      const cIds = cRecords.map((record: any) => record.id);

      cIds.push(this.selectedNewCustomerId);

      let cIdsSplit = cIds.join(",");
      cIdsSplit = cIdsSplit.slice(0, -1);

      const model: UpdatePriceTable = {
        id: this.selectedPriceTable!.id,
        validFrom: this.selectedPriceTable!.validFrom,
        validTo: this.selectedPriceTable?.validTo,
        customers: cIdsSplit,
        surchargeMembers: this.selectedPriceTable!.surchargeMembers
      }

      this.updatePriceTable(model);
    }
  }

  toolbarZonesClick(args: any): void {
    if (args.item.properties.id === 'addZoneRoute' && this.selectedPriceTable != null) {

    }

    if (args.item.properties.id === 'addZone') {
      this.http.get<{ postalCode: number; city: string }[]>('assets/data/dkPostal.json').subscribe({
        next: (data) => {
          this.dropdownDataCity = data.map(item => ({
            city: item.city,
            postalCode: item.postalCode
          }));

          // this.dropdownDataCity = data;
          // this.dropdownlistCityMul.dataSource = data;
        },
        error: (err) => {
          console.error('Failed to load JSON data', err);
        }
      });

      if (this.dialog) {
        this.dialog.show();
      }
    }

    if (args.item.properties.id === 'removeZoneRoute') {
      const selectedRowIndex = this.zoneGrid.getSelectedRowIndexes()[0]; // Get the first selected index

      if (selectedRowIndex !== undefined && this.selectedPriceTable != null) {
        // Remove the selected row
        const selectedRecord = this.zoneGrid.getCurrentViewRecords()[selectedRowIndex];
        this.zoneGrid.deleteRecord('id', selectedRecord);
        //this.deletePriceRoute();
      }
    }
  }

  toolbarTypesClick(args: any): void {
    if (args.item.properties.id === 'addZoneRoute' && this.selectedPriceTable != null) {

    }

    if (args.item.properties.id === 'addZone' && this.selectedPriceTable != null) {
      if (this.dialog) {
        this.dialog.show();
      }
    }

    if (args.item.properties.id === 'removeZoneRoute') {
      const selectedRowIndex = this.zoneGrid.getSelectedRowIndexes()[0]; // Get the first selected index

      if (selectedRowIndex !== undefined && this.selectedPriceTable != null) {
        // Remove the selected row
        const selectedRecord = this.zoneGrid.getCurrentViewRecords()[selectedRowIndex];
        this.zoneGrid.deleteRecord('id', selectedRecord);
        //this.deletePriceRoute();
      }
    }
  }

  getValueFromTomTom(value: any) {
    this.http.get(
      'https://api.tomtom.com/search/2/search/' +
      value.value + '.json?' +
      'lat=' + this.currentLat + '&' +
      'lon=' + this.currentLon + '&' +
      'minFuzzyLevel=1&' +
      'maxFuzzyLevel=2&' +
      'view=Unified&' +
      'relatedPois=off&' +
      'extendedPostalCodesFor=Geo&' +
      'returnPostalCodeForPOI=true&' +
      'typeahead=true&' +
      'key=' + environment.tomtom.key).subscribe((data: any) => (
        this.valueTomTomCity = data['results'][0]['address']['municipality'],
        this.valueTomTomZip = data['results'][0]['address']['postalCode'],

        this.valueTomTomResult = data['results'],

        this.evaluateTomTomData(),

        this.searchResults = data['results']
      ));
  }

  fillAddressForm(result: any) {
    //this.searchResults = null;
  }

  generateAdressTitle(result: any): string {
    var returnString = "";
    if (result.poi !== undefined) {
      if (result.poi.name !== undefined) returnString += result.poi.name + " ";
    }

    if (result.address !== undefined) {
      if (result.address.streetName !== undefined) returnString += result.address.streetName + " ";
      if (result.address.streetNumber !== undefined) returnString += result.address.streetNumber + " ";
      if (result.address.municipality !== undefined) returnString += result.address.municipality + " ";
      if (result.address.country !== undefined) returnString += ", " + result.address.country + " ";
    }

    return returnString;
  }

  evaluateTomTomData() {
    //let localCities: TomTomCity[] | null = null;
    let localCities: any[];
    this.tomTomCities = [];
    let localData = this.valueTomTomResult;

    if (Array.isArray(localData)) {
      localData.forEach((value) => {
        if (value['address']['postalCode'] != null && value['address']['municipality'] != null) {
          this.tomTomCities.push({ city: value['address']['municipality'], zip: value['address']['postalCode'] });
        }
      });
    }

    //this.tomTomCities = localCities;
  }

  toolbarClicked() {
    this.sidebar.toggle();
  }
}
