import { Component, EventEmitter, Input, Output } from '@angular/core'
import { FormControl } from '@angular/forms'


@Component({
  selector: 'app-combobox',
  templateUrl: './combobox.component.html',
  styleUrl: './combobox.component.scss'
})
export class ComboboxComponent {

  @Input() options: any[] = [] // unknown type
  @Input() keyToSearch: string = ''
  @Input() readonly: boolean = false
  @Input() inputValue: string = ''

  @Output() selectedOption = new EventEmitter()

  filteredOptions: any[] = [] // unknown type
  isDropdownOpen: boolean = false

  constructor() {

  }


  // Filter the options based on user input
  filterOptions() {
    this.filteredOptions = this.options.filter(option =>
      option[this.keyToSearch].toLowerCase().includes(this.inputValue.toLowerCase())
    )

    this.isDropdownOpen = this.filteredOptions.length > 0

    if (this.filterOptions.length === 0) {
      this.selectedOption.emit(this.inputValue)
    }
  }

  // Select an option from the dropdown
  selectOption(option: string) {
    this.inputValue = option
    this.selectedOption.emit(option)

    this.isDropdownOpen = false
  }

  // Handle user pressing 'Enter' to select or use the current input
  handleEnter() {
    if (this.filteredOptions.length === 0 || this.inputValue === '') {
      // Use the current input if no match is found
      this.inputValue = this.inputValue
      this.selectedOption.emit(this.inputValue)

      this.isDropdownOpen = false;
    } else {
      // Select the first option if there's a match
      this.selectOption(this.filteredOptions[0]);
    }
  }

  // Close dropdown when clicked outside
  closeDropdown() {
    this.isDropdownOpen = false;
  }
}
