<!--table download btn when no csv-->
<button *ngIf="!report.hasCsv && isTableDownload" type="button" class="float-end btn btn-sm btn-default" (click)="downloadPdf()">
  <fa-icon [icon]="faDownload"></fa-icon>
</button>

<!--table download btn when csv-->
<div *ngIf="report.hasCsv && isTableDownload" ngbDropdown class="float-end">
  <button type="button" class="btn btn-sm btn-default" id="selectFileType" ngbDropdownToggle>
    <fa-icon [icon]="faDownload"></fa-icon>
  </button>
  <div ngbDropdownMenu aria-labelledby="selectFileType">
    <button ngbDropdownItem (click)="downloadPdf()">{{'common.downloadPDF' | translate}}</button>
    <button ngbDropdownItem (click)="downloadCsv()">{{'common.downloadCSV' | translate}}</button>
  </div>
</div>


<!--report view download btn when no csv-->
<div *ngIf="!report.hasCsv && !isTableDownload" class="col-12 text-end">
  <span style="padding-right: 20px;">{{ report.name }}</span>
  <div class="btn-group" role="group">
    <button type="button" class="btn btn-default" (click)="backToList()">
      <fa-icon [icon]="faArrowLeft"></fa-icon>
    </button>
    <button type="button" class="btn btn-default" (click)="downloadPdf()">
      <fa-icon [icon]="faDownload"></fa-icon>
    </button>
  </div>
</div>

<!--report view download btn when csv-->
<div *ngIf="report.hasCsv && !isTableDownload" class="col-12">
  <div class="row">
    <div class="col-10 text-end">
      <span style="padding-right: 20px;">{{ report.name }}</span>
    </div>
    <div class="col-1">
      <button type="button" class="btn btn-default" (click)="backToList()">
        <fa-icon [icon]="faArrowLeft"></fa-icon>
      </button>
    </div>
    <div class="col-1">
      <div ngbDropdown class="float-end">
        <button type="button" class="btn btn-default" id="selectFileType" ngbDropdownToggle>
          <fa-icon [icon]="faDownload"></fa-icon>
        </button>
        <div ngbDropdownMenu aria-labelledby="selectFileType">
          <button ngbDropdownItem (click)="downloadPdf()">{{'common.downloadPDF' | translate}}</button>
          <button ngbDropdownItem (click)="downloadCsv()">{{'common.downloadCSV' | translate}}</button>
        </div>
      </div>
    </div>
  </div>
</div>
