<div class="col-md-12 px-3" *ngIf="eye" style="margin-bottom: 10px;">
  <div class="row">
    <div class="col-md-12 text-end" style="margin-top: 10px;">
      <button type="button" class="btn btn-default btn-sm" (click)="readOnlyEye = ! readOnlyEye" placement="left"
        ngbTooltip="{{'btn.edit' | translate}}">
        <fa-icon [icon]="faEdit"></fa-icon>
      </button>
    </div>
    <form [formGroup]="formGroup" class="col-md-12 row">
      <div class="col-md-6 padding-right">
        <label>{{'eye.mac' | translate}}</label>
        <input class="form-control form-control-sm" formControlName="MAC" [readOnly]="true">
      </div>
      
      <div class="col-md-6 padding-right">
        <label>{{'eye.sn' | translate}}</label>
        <input class="form-control form-control-sm" formControlName="SN" [readOnly]="true"
         >
      </div>
      <div class="col-md-12 padding-right">
        <label>{{'eye.devicename' | translate}}</label>
        <input class="form-control form-control-sm" formControlName="DeviceName" [readOnly]="true"
        >
      </div>
      <div class="col-md-12 padding-right">
        <label>{{'eye.displayname' | translate}}</label>
        <input class="form-control form-control-sm" formControlName="DisplayName" [readOnly]="true"
        >
      </div>
    
     
    </form>
  </div>
</div>
<div class="ng-container" *ngIf="!readOnlyEye" [ngClass]="{'bg-light': !readOnlyEye}">
  <div *ngIf="!readOnlyEye" class="col-md-12 btn-container ">
    <div class="d-flex justify-content-center">
      <button class="btn btn-danger" (click)="remove()" placement="top" ngbTooltip="{{'btn.removeEye' | translate}}">
        <fa-icon [icon]="faTrash"></fa-icon>
      </button>
    </div>
  </div>
</div>
 