import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { firstValueFrom } from 'rxjs';
import { SharedService } from 'src/app/shared/services/shared.service';

@Component({
  selector: 'app-unauthorized',
  templateUrl: './unauthorized.component.html'
})

export class UnauthorizedComponent implements OnInit {
  unauthorizedTranslate: string = '';
  unauthorizedMessageTranslate: string = ''
  language: string = ''


  async getLanguage() {
    try {
      const response = await firstValueFrom(this.sharedService.getLanguageJson())
      this.language = response
      return response
    } catch (error) {
      console.error('Error fetching language JSON', error)
      throw error
    }
  }


  constructor(private translate: TranslateService, private sharedService: SharedService) { }

  async ngOnInit(): Promise<void> {
    await this.getLanguage()

    this.unauthorizedTranslate = this.translate.instant('auth.unauthorized')
    this.unauthorizedMessageTranslate = this.translate.instant('auth.unauthorizedMessage')
  }
}
