import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { CreateSurcharge, Surcharge, SurchargeType, UpdateSurcharge } from "./surcharge.model";
import { Observable } from "rxjs";

const url = environment.baseUrl + '/V1/Surcharge';

@Injectable({
  providedIn: 'root'
})

export class SurchargeService {
  constructor(private http: HttpClient) { }

  getAllSurchargeTypes(): Observable<SurchargeType[]> {
    return this.http.get<SurchargeType[]>(`${url}/type`);
  }

  getSurchargeTypeById(id: number): Observable<SurchargeType> {
    return this.http.get<SurchargeType>(`${url}/type/${id}`);
  }

  createSurchargeType(name: string): Observable<SurchargeType> {
    const params = new HttpParams().set('name', name);
    return this.http.put<SurchargeType>(`${url}/type`, null, { params });
  }

  updateSurchargeType(id: number, name: string): Observable<SurchargeType> {
    const params = new HttpParams().set('name', name);
    return this.http.put<SurchargeType>(`${url}/type/${id}`, null, { params });
  }

  deleteSurchargeType(id: number): Observable<SurchargeType> {
    return this.http.delete<SurchargeType>(`${url}/type/${id}`);
  }

  getAllSurcharges(): Observable<Surcharge[]> {
    return this.http.get<Surcharge[]>(url);
  }

  getSurchargeById(id: number): Observable<Surcharge> {
    return this.http.get<Surcharge>(`${url}/${id}`);
  }

  createSurcharge(model: CreateSurcharge): Observable<Surcharge> {
    return this.http.put<Surcharge>(url, model);
  }

  updateSurcharge(model: UpdateSurcharge): Observable<Surcharge> {
    return this.http.put<Surcharge>(url, model);
  }

  deleteSurcharge(id: number): Observable<Surcharge> {
    return this.http.delete<Surcharge>(`${url}/${id}`);
  }
}
