import {FrontVehicleModel} from "../../../front-vehicles/shared/front-vehicle.model";
import {BackVehicleModel} from "../../../back-vehicles/shared/back-vehicle.model";
import {TransportEquipmentModel} from "../../../transport-equipments/shared/transport-equipment.model";
import {CustomerModel} from "../../../customers/shared/customer.model";
import {TagModel} from "../../../shared/tag/shared/tag.model";
import {UserModel} from "../../../users/shared/user.model";
import {AttachmentModel} from "../../../shared/attachment-widget/shared/attachment.model";
import {NoteModel} from "../../../shared/note-widget/shared/note.model";
import {EventModel} from "../../../shared/calendar/shared/event.model";
import { TimeRegistrationModel } from "src/app/shared/time-registrations-table/shared/timeregistration";

export interface BoxService {
  id: number;
  timestamp: Date;
 
}

 export interface BoxServiceCompanyModel {
  guid: string;
  name: string;
  description: string;
  address: string;
  zipCode: string;
  city: string;
  countryId: number;
  countryName: string;
  currencyCode: string;
  installmentCode: string;
}

export interface BoxServiceResultModel {
result: BoxServiceVehicleModel[];
resultBackvehicle: BoxServiceVehicleModel[]; 
}

export interface BoxServiceVehicleModel {
  box: any;
  id: number;
  chassisNumber: string;
  registrationNumber: string;
  eyeDevice: any;
}


export interface BoxServiceVehicleSelect {
  chassisNumber: string;
  id: number;
  registrationNumber: string;

}

export interface BoxServiceCreateBoxRequestModel {
  imei: string;
  iccid: string;
  canBaud1: string;
  vehicleId: number;
  boxType: string;

}

export interface BoxServiceCreateEyeRequestModel {
  mac: string;
  vehicleId: number;
  boxType: string;

}