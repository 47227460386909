
export interface AgreementOverviewModel
{
  id: number;
  name: string;
  typeId: AgreementType;
}
export enum AgreementType
{
  Inland = 1,
  Export = 2
}

export interface AgreementDetails
{
  id: number;
  name: string;
  description: string;
  typeId: AgreementType;
  subtractBreaks: boolean;
  fixedBreakTimeSubtractions: FixedBreakTimeSubtractionDetails[];
  breakIntervals: BreakIntervalDetails[];
  workTimes: WorkTimeDetails[];
}

export interface FixedBreakTimeSubtractionDetails
{
  id: number;
  priority: number;
  minWorkMinutes: number;
  maxWorkMinutes: number;
  minBraktimeSpent: number;
  maxBreaktimeSpent: number;  
  breaktimeMinutes: number;
}

export interface BreakIntervalDetails
{
  id: number;
  weekDay: number;
  startTime?: number;
  endtime?: number;
  breakMinutes: number;
}
export interface WorkTimeDetails
{
  id: number;
  name: string;
  fromHours: number;
  toHOurs?: number;
  hexColor: string;
  isNormTime: boolean;
  addHoursOrder: number;
  subtractBreaksOrder?: number;
  isHoliday: boolean;
  maxHoursPrDay: MaxHourPrDayDetail;
  intervals: WorktimeIntervalDetail[];
}

export interface MaxHourPrDayDetail
{
  monday: number;
  tuesday: number;
  wednesday: number;
  thursday: number;
  friday: number;
  saturday: number;
  sunday: number;
}

export interface WorktimeIntervalDetail
{
  id: number;
  weekday: number;
  startTime?: string;
  endTime?: string;
}
