import { AccordionToggleService } from './../../shared/services/accordion-toggle-service';
import { Component, OnInit } from '@angular/core';
import { WidgetComponent } from "../../shared/widget-component";
import { CreateUserMetaModel, UpdateUserMetaModel, UserEventModel, CreateUserEventModel, UserModel, UpdateUserEventModel } from "../shared/user.model";
import { UserService } from "../shared/user.service";
import { faPaperPlane, faPencilAlt, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FormArray, FormGroup, UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { DatePipe } from "@angular/common";
import { BackVehicleType, CreateMode, PermissionType } from "../../constants";
import { AddressModel } from "../../shared/address-widget/shared/address.model";
import { Router } from "@angular/router";
import { FrontVehicleModel } from "../../front-vehicles/shared/front-vehicle.model";
import { BackVehicleModel } from "../../back-vehicles/shared/back-vehicle.model";
import { TransportEquipmentModel } from "../../transport-equipments/shared/transport-equipment.model";
import { SharedService } from "../../shared/services/shared.service";
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { AgreedManagementService } from 'src/app/shared/services/agreed-management.service'
import { AgreementOverviewModel, AgreementType } from '../../shared/services/agreed-management.models';
import { CommonModule } from "@angular/common";
import { Observable } from 'rxjs';
import { NgbAccordionItem } from '@ng-bootstrap/ng-bootstrap';
import { StreetViewCoordinates } from 'src/app/tours/tour-create/tour-create.component';


@Component({
  selector: 'app-user-detail',
  templateUrl: './user-detail.component.html',
  styleUrls: ['./user-detail.component.scss'],
  providers: [DatePipe]
})
export class UserDetailComponent extends WidgetComponent {
  get createModes() {
    return CreateMode;
  }


  get permissionTypes(): Array<string> {
    var keys = Object.keys(PermissionType);
    return keys.slice(keys.length / 2);
  }

  users: UserModel[] = [];

  readOnly = true;
  user: UserModel | undefined;
  address: FormArray<FormGroup>

  name: any;
  mobile: any;
  email: any;


  debugvar = false

  loading = false
  frontVehicleSelect = false
  transportEquipmentSelect: boolean = false
  backVehicleSelect: boolean = false
  faAdd = faPlus
  faMail = faPaperPlane
  faEdit = faPencilAlt
  frontVehicle: FrontVehicleModel | undefined
  backVehicles: BackVehicleModel[] | undefined
  transportEquipment: TransportEquipmentModel | undefined
  streetviewLat: number = 55.850849
  streetviewLng: number = 9.845612
  showStreetViewModal = false

  overrideSkilled = false
  onlyPull = false

  FixedWorktime: boolean = false

  metaFormGroup: UntypedFormGroup

  addEvent = false

  agreementOverview: AgreementOverviewModel[] = []

  private dayValidators = [
    Validators.required,
    Validators.pattern(/^(?:\d{1,3}(?:,\d{3})*|\d+)(?:\,\d+)?$/)
  ]

  constructor(
    private userService: UserService,
    public sharedService: SharedService,
    private formBuilder: UntypedFormBuilder,
    private datePipe: DatePipe,
    private router: Router,
    private toaster: ToastrService,
    private translate: TranslateService,
    private accordionToggleService: AccordionToggleService,
    private agreement: AgreedManagementService
  ) {
    super();

    this.metaFormGroup = this.formBuilder.group({
      salaryNumber: ['', Validators.compose([Validators.required, Validators.maxLength(20)])],
      salaryNumberHomeCountry: ['', Validators.maxLength(20)],
      title: ['', Validators.maxLength(50)],
      driversLicenseNumber: ['', Validators.maxLength(20)],
      driversLicenseNumberExpires: undefined,
      startDate: ['', Validators.required],
      skilledDate: undefined,
      frontVehicleId: undefined,
      transportEquipmentId: undefined,
      backVehicleIds: [],
      country: ['', Validators.maxLength(255)],
      state: ['', Validators.maxLength(255)],
      zipcode: ['', Validators.maxLength(10)],
      city: ['', Validators.maxLength(255)],
      street: ['', Validators.maxLength(255)],
      permission: [{ value: 'Read', disabled: true }, Validators.required],
      resignationDate: undefined,
      inlandAgreement: this.formBuilder.group({
        hours: this.formBuilder.group({
          mo: [undefined, this.dayValidators],
          tu: [undefined, this.dayValidators],
          we: [undefined, this.dayValidators],
          th: [undefined, this.dayValidators],
          fr: [undefined, this.dayValidators],
          sa: [undefined, this.dayValidators],
          su: [undefined, this.dayValidators],
        }),
        inlandAgreementType: [undefined]
      }),
      exportAgreementType: undefined,
      overrideSkilled: { value: undefined, disabled: true },
      onlyPull: { value: undefined, disabled: true },
      agreementInlandId: [{ value: 0, disabled: this.readOnly }],
      agreementExportId: [{ value: 0, disabled: this.readOnly }],
      deliveryRequirementIds: ['']
    });

    this.address = this.formBuilder.array([])

  }


  ngOnInit() {
    this.getAgreementOverview();
    this.refresh();
  }

  drawCalendar(): void {
    window.dispatchEvent(new Event('resize'));
  }

  refresh(): void {
    this.loading = true;
    if (this.id != 0) {
      this.userService.getSingleWithMeta(this.id).subscribe(response => {
        this.user = response;

        this.name = `${this.user.firstname} ${this.user.lastname}`;
        this.mobile = this.user.mobile;
        this.email = this.user.email;
        this.patchMetaForm();
        this.loading = false;
      });
    } else {
      this.userService.getAll().subscribe(response => {
        this.users = response;

        this.users = this.users.sort((a, b) => {
          if (a.firstname < b.firstname) {
            return -1;
          }
          if (a.firstname > b.firstname) {
            return 1;
          }
          return 0;
        });

        this.loading = false;
      });
    }
  }

  onToggleFixedWorkTime(state: boolean) {
    this.FixedWorktime = state;

    this.setDaysValidators();
  }

  addNewAbsence(event: Event, accordionItem: NgbAccordionItem): void {
    this.accordionToggleService.accordionStatus(event, accordionItem);
    this.addEvent = true;
  }


  patchMetaForm(): void {
    if (!this.user?.userMeta) {
      return;
    }
    this.frontVehicle = this.user.userMeta.frontVehicle;
    this.backVehicles = this.user.userMeta.backVehicles;
    this.transportEquipment = this.user.userMeta.transportEquipment;

    const model = this.user.userMeta;
    this.FixedWorktime = /[1-9]/.test(model.hoursAgreement);

    this.metaFormGroup.patchValue({
      salaryNumber: model.salaryNumber,
      salaryNumberHomeCountry: model.salaryNumberHomeCountry,
      title: model.title,
      startDate: this.datePipe.transform(model.startDate, 'yyyy-MM-dd'),
      skilledDate: this.datePipe.transform(model.skilledDate, 'yyyy-MM-dd'),
      country: model.country,
      state: model.state,
      zipcode: model.zipcode,
      city: model.city,
      street: model.street,
      frontVehicleId: model?.frontVehicle?.id,
      transportEquipmentId: model?.transportEquipment?.id,
      backVehicleIds: model.backVehicles.map(x => x.id),
      permission: this.user.permission,
      resignationDate: this.datePipe.transform(model.resignationDate, 'yyyy-MM-dd'),
      inlandAgreement: {
        hours: {
          mo: model.hoursAgreement?.split(';')[0],
          tu: model.hoursAgreement?.split(';')[1],
          we: model.hoursAgreement?.split(';')[2],
          th: model.hoursAgreement?.split(';')[3],
          fr: model.hoursAgreement?.split(';')[4],
          sa: model.hoursAgreement?.split(';')[5],
          su: model.hoursAgreement?.split(';')[6]
        },
        inlandAgreementType: model.inlandAgreement
      },
      exportAgreementType: model.exportAgreement,
      driversLicenseNumber: this.user.driversLicenseNumber,
      driversLicenseNumberExpires: this.datePipe.transform(this.user.driversLicenseNumberExpires, 'yyyy-MM-dd'),
      overrideSkilled: model.overrideSkilled,
      onlyPull: model.onlyPull,
      agreementInlandId: model.inlandAgreementId,
      agreementExportId: model.exportAgreementId,
      deliveryRequirementIds: model.deliveryRequirementIds
    });


    this.setAddress();

    this.setDaysValidators();
  }


  setAddress(): void {
    if (this.address.length > 0) {
      this.address.at(0).patchValue({
        city: this.metaFormGroup.get('city')?.value,
        country: this.metaFormGroup.get('country')?.value,
        state: this.metaFormGroup.get('state')?.value,
        zipcode: this.metaFormGroup.get('zipcode')?.value,
        street: this.metaFormGroup.get('street')?.value
      })
    } else {
      this.address.push(this.formBuilder.group({
        city: this.metaFormGroup.get('city')?.value,
        country: this.metaFormGroup.get('country')?.value,
        state: this.metaFormGroup.get('state')?.value,
        zipcode: this.metaFormGroup.get('zipcode')?.value,
        street: this.metaFormGroup.get('street')?.value
      }))
    }
  }


  patchAddress(address: AddressModel): void {
    this.metaFormGroup.patchValue({
      city: address.city,
      country: address.country,
      state: address.state,
      zipcode: address.zipcode,
      street: address.street
    });
    this.setAddress();
  }


  sendMail(): void {
    window.location.href = "mailto:" + this.email;
  }



  updateDeliveryReqs(selectedValues2: any): void {
    const selectedValues: number[] = selectedValues2;

    if (this.user != undefined) {
      this.user.deliveryRequirementIds = selectedValues.join(",");
      this.metaFormGroup.patchValue({ deliveryRequirementIds: this.user.deliveryRequirementIds });
    }
  }


  update(): void {
    // update metaFormGroup values from address form
    this.metaFormGroup.patchValue({
      city: this.address.at(0).get('city')?.value,
      country: this.address.at(0).get('country')?.value,
      state: this.address.at(0).get('state')?.value,
      zipcode: this.address.at(0).get('zipcode')?.value,
      street: this.address.at(0).get('street')?.value
    })


    if (!this.FixedWorktime) {
      const hoursFormGroup = this.metaFormGroup.get('inlandAgreement')?.get('hours')
      hoursFormGroup?.get('mo')?.setValue('')
      hoursFormGroup?.get('tu')?.setValue('')
      hoursFormGroup?.get('we')?.setValue('')
      hoursFormGroup?.get('th')?.setValue('')
      hoursFormGroup?.get('fr')?.setValue('')
      hoursFormGroup?.get('sa')?.setValue('')
      hoursFormGroup?.get('su')?.setValue('')
    }

    if (this.metaFormGroup.valid) {
      this.loading = true
      this.readOnly = true
      this.metaFormGroup.controls['permission'].enable()
      this.metaFormGroup.controls['overrideSkilled'].enable()
      this.metaFormGroup.controls['onlyPull'].enable()
      this.metaFormGroup.controls['agreementInlandId'].enable()
      this.metaFormGroup.controls['agreementExportId'].enable()
      this.metaFormGroup.controls['deliveryRequirementIds'].enable()

      if (this.user?.userMeta) {
        this.userService.updateMeta(this.id, this.metaFormGroup.value as UpdateUserMetaModel).subscribe(() => this.refresh(), error => this.loading = false)
      } else {
        this.userService.createMeta(this.id, this.metaFormGroup.value as CreateUserMetaModel).subscribe(() => this.refresh(), error => this.loading = false)
      }
    }
    else {
      this.toaster.error(this.translate.instant('message.invalidForm'))
    }
  }


  goTo(route: string, params: any): void {
    this.router.navigate([route], { queryParams: { item: params } })
  }



  selectedFrontVehicleCallback(e: any): void {
    this.frontVehicleSelect = false
    this.frontVehicle = e.frontVehicle

    if (this.user?.userMeta) this.user.userMeta.frontVehicle = e.frontVehicle

    this.metaFormGroup.patchValue({ frontVehicleId: e?.frontVehicle?.id })
  }


  selectedBackVehicleCallback(e: any): void {
    this.backVehicleSelect = false
    this.backVehicles = e.backVehicles

    if (this.user?.userMeta) this.user.userMeta.backVehicles = e.backVehicles

    this.metaFormGroup.patchValue({ backVehicleIds: e?.backVehicles?.map((x: BackVehicleModel) => x.id) })
  }


  selectedTransportEquipmentCallback(e: any): void {
    this.transportEquipmentSelect = false
    this.transportEquipment = e.transportEquipment

    if (this.user?.userMeta) this.user.userMeta.transportEquipment = e.transportEquipment

    this.metaFormGroup.patchValue({ transportEquipmentId: e?.transportEquipment?.id })
  }


  setDaysValidators() {
    const hoursFormGroup = this.metaFormGroup.get('inlandAgreement')?.get('hours')
    if (this.FixedWorktime) {
      hoursFormGroup?.get('mo')?.setValidators(this.dayValidators)
      hoursFormGroup?.get('tu')?.setValidators(this.dayValidators)
      hoursFormGroup?.get('we')?.setValidators(this.dayValidators)
      hoursFormGroup?.get('th')?.setValidators(this.dayValidators)
      hoursFormGroup?.get('fr')?.setValidators(this.dayValidators)
      hoursFormGroup?.get('sa')?.setValidators(this.dayValidators)
      hoursFormGroup?.get('su')?.setValidators(this.dayValidators)
    } else {
      hoursFormGroup?.get('mo')?.removeValidators(Validators.required)
      hoursFormGroup?.get('tu')?.removeValidators(Validators.required)
      hoursFormGroup?.get('we')?.removeValidators(Validators.required)
      hoursFormGroup?.get('th')?.removeValidators(Validators.required)
      hoursFormGroup?.get('fr')?.removeValidators(Validators.required)
      hoursFormGroup?.get('sa')?.removeValidators(Validators.required)
      hoursFormGroup?.get('su')?.removeValidators(Validators.required)
    }
    hoursFormGroup?.get('mo')?.updateValueAndValidity()
    hoursFormGroup?.get('tu')?.updateValueAndValidity()
    hoursFormGroup?.get('we')?.updateValueAndValidity()
    hoursFormGroup?.get('th')?.updateValueAndValidity()
    hoursFormGroup?.get('fr')?.updateValueAndValidity()
    hoursFormGroup?.get('sa')?.updateValueAndValidity()
    hoursFormGroup?.get('su')?.updateValueAndValidity()
  }




  createEvent(model: any): void {
    this.loading = true
    const m: CreateUserEventModel = {
      title: model.title,
      description: model.description,
      startDate: moment(this.datePipe.transform(`${model.startDate} ${model.startTime}`, 'yyyy-MM-dd HH:mm')).toDate(),
      endDate: moment(this.datePipe.transform(`${model.endDate} ${model.endTime}`, 'yyyy-MM-dd HH:mm')).toDate(),
      userEventTypeId: Number(model.type)
    }
    this.userService.createUserEvent(this.id, m).subscribe(() => this.refresh(), error => this.loading = false)
  }



  deleteEvent(model: any): void {
    this.loading = true
    this.userService.deleteUserEvent(this.id, model.id).subscribe(() => this.refresh(), error => this.loading = false)
  }



  updateEvent(model: any): void {
    this.loading = true
    const m: UpdateUserEventModel = {
      id: model.id,
      title: model.title,
      description: model.description,
      startDate: moment(this.datePipe.transform(`${model.startDate} ${model.startTime}`, 'yyyy-MM-dd HH:mm')).toDate(),
      endDate: moment(this.datePipe.transform(`${model.endDate} ${model.endTime}`, 'yyyy-MM-dd HH:mm')).toDate(),
      userEventTypeId: model.type,
    }
    this.userService.updateUserEvent(this.id, m).subscribe(() => this.refresh(), error => this.loading = false)
  }


  cancelEvent(): void {
    this.refresh()
  }


  lastEventIndex(): number {
    if (this.user === null || this.user === undefined || this.user.events === null || this.user.events === undefined) {
      return 0
    }

    return this.user.events.length
  }


  getAgreementOverview(): void {
    this.agreement.GetAgreementOverview().subscribe(x => {
      this.agreementOverview = x
    })
  }


  inlandAgreements(): AgreementOverviewModel[] {
    return this.agreementOverview.filter(x => x.typeId == AgreementType.Inland)
  }


  exportAgreement(): AgreementOverviewModel[] {
    return this.agreementOverview.filter(x => x.typeId == AgreementType.Export)
  }


  inlandIdSelected(inland: number) {
    if (!this.user?.userMeta?.inlandAgreementId) {
      return
    }
    this.user.userMeta.inlandAgreementId = inland
  }


  exportIdSelected(exportid: number) {
    if (!this.user?.userMeta?.exportAgreementId) {
      return
    }
    this.user.userMeta.exportAgreementId = exportid
  }


  emitStreetview(form: StreetViewCoordinates) {
    if (form.lat != undefined && form.lng != undefined) {
      this.streetviewLat = form.lat
      this.streetviewLng = form.lng
      this.showStreetViewModal = true
    }
  }



  hideStreetview(event: any) {
    this.showStreetViewModal = false
  }
}
