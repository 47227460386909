import { HttpClient } from "@angular/common/http";
import { inject, Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { CreatePriceRoute, CreatePriceUnit, CreatePriceUnitMember, CreatePriceTable, PriceRoute, PriceUnit, PriceUnitMember, PriceTable, UpdatePriceTable, UpdatePriceRoute, UpdatePriceUnit } from "./pricing.model";

const url = environment.baseUrl + '/V1/Pricing';

@Injectable({
  providedIn: 'root'
})

export class PricingService {
  private http: HttpClient = inject(HttpClient);

  getAllPriceTables(): Observable<PriceTable[]> {
    return this.http.get<PriceTable[]>(`${url}`);
  }
 
  getPriceTableById(id: number): Observable<PriceTable> {
    return this.http.get<PriceTable>(`${url}/${id}`);
  }

  getPricTablesByValidFrom(validFrom: Date): Observable<PriceTable[]> {
    return this.http.get<PriceTable[]>(`${url}/${validFrom}`);
  }

  getPriceTablesByValidFromAndValidTo(validFrom: Date, validTo: Date): Observable<PriceTable[]> {
    return this.http.get<PriceTable[]>(`${url}/${validFrom}?validTo=${validTo}`);
  }

  getPriceTablesByValidFromAndIds(validFrom: Date, ids: string): Observable<PriceTable[]> {
    return this.http.get<PriceTable[]>(`${url}/${validFrom}?ids=${ids}`);
  }

  getPriceTablesByValidFromAndValidToAndIds(validFrom: Date, validTo: Date, ids: string): Observable<PriceTable[]> {
    return this.http.get<PriceTable[]>(`${url}/${validFrom}?validTo=${validTo}&ids=${ids}`);
  }

  createPriceTable(model: CreatePriceTable): Observable<PriceTable> {
    return this.http.put<PriceTable>(`${url}/priceTable` ,model);
  }

  updatePriceTable(model: UpdatePriceTable): Observable<PriceTable> {
    return this.http.put<PriceTable>(`${url}/priceTable` ,model);
  }

  deletePriceTable(id: number): Observable<PriceTable> {
    return this.http.delete<PriceTable>(`${url}/priceTable/${id}`);
  }

  createPriceRoute(model: CreatePriceRoute): Observable<PriceRoute> {
    return this.http.put<PriceRoute>(`${url}/priceRoute`, model);
  }

  updatePriceRoute(model: UpdatePriceRoute): Observable<PriceRoute> {
    return this.http.put<PriceRoute>(`${url}/priceRoute`, model);
  }

  deletePriceRoute(id: number): Observable<PriceRoute> {
    return this.http.delete<PriceRoute>(`${url}/priceRoute/${id}`);
  }

  createPriceUnit(model: CreatePriceUnit): Observable<PriceUnit> {
    return this.http.put<PriceUnit>(`${url}/priceUnit`, model);
  }

  updatePriceUnit(model: UpdatePriceUnit): Observable<PriceUnit> {
    return this.http.put<PriceUnit>(`${url}/priceUnit`, model);
  }

  deletePriceUnit(id: number): Observable<PriceUnit> {
    return this.http.delete<PriceUnit>(`${url}/priceUnit/${id}`);
  }

  createPriceUnitMember(model: CreatePriceUnitMember): Observable<PriceUnitMember> {
    return this.http.put<PriceUnitMember>(`${url}/priceUnitMember`, model);
  }

  updatePriceUnitMember(model: PriceUnitMember): Observable<PriceUnitMember> {
    return this.http.put<PriceUnitMember>(`${url}/priceUnitMember`, model);
  }

  deletePriceUnitMember(id: number): Observable<PriceUnitMember> {
    return this.http.delete<PriceUnitMember>(`${url}/priceUnitMember/${id}`);
  }
}
