import { EventProxyService, LogbookModalOpen } from './../../shared/services/event-proxy.service';
import { DeliveryDocumentRequirement } from './../../freight-types/shared/freight-types.model';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { Component, EventEmitter, Input, Output, signal, ViewChild } from '@angular/core';
import { ModalComponent } from '../modal/modal.component';
import { ComboBoxAllModule, ComboBoxModule, ComboBoxComponent } from '@syncfusion/ej2-angular-dropdowns';
import { FormBuilder, FormsModule, UntypedFormGroup, Validators, ReactiveFormsModule, FormArray, FormGroup } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { TranslateModule } from '@ngx-translate/core';
import { SurchargeService } from 'src/app/pricing/surcharge/shared/surcharge.service';
import { FreightTypeService } from 'src/app/freight-types/shared/freight-types.service';
import { CustomerModel } from 'src/app/customers/shared/customer.model';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { DataManager, Query, ODataV4Adaptor } from '@syncfusion/ej2-data';
import { ProductTypeService } from './helpers/product-type.service';


export interface Currency {
  exchangeRate: number;
  id: number;
  isoName: string;
  lastUpdated: string;
  name: string;
  source: string;
}

export interface SurchargeType {
  id: number;
  name: string;
}

export interface Surcharge {
  adjustmentIndexId: number;
  chargePerKm: number;
  currency: Currency;
  customer: CustomerModel;
  id: number;
  name: string;
  staticCharge: number;
  surchargeType: SurchargeType;
}



@Component({
  selector: 'app-product-type-picker',
  templateUrl: './product-type-picker.component.html',
  styleUrl: './product-type-picker.component.scss',
  standalone: true,
  imports: [ComboBoxModule, ModalComponent, FormsModule, CommonModule, ReactiveFormsModule, TranslateModule, FaIconComponent]
})
export class ProductTypePickerComponent {
  @ViewChild('productTypeModalComponent') productTypeModalComponent!: ModalComponent
  @ViewChild('newSurcharge') newSurcharge!: ComboBoxComponent
  @ViewChild('surchargesListBox') surchargesListBox!: ComboBoxComponent
  @ViewChild('productType') productType!: ComboBoxComponent

  @Input() data: any = null
  @Input() addresses: string = ''

  @Output() dataEmit = new EventEmitter<any>()


  faTrash = faTrash
  canAdd: boolean = false

  products: any[] = []
  productData: any = {
    productTypeId: 0,
    productType: {
      name: ''
    },
    type: '',
    freightUnit: '',
    freightUnitId: '',
    surcharges: [],
    priceUnitId: null,
    amount: 0,
    amountOverload: 0,
    freightUnitOverload: '',
    freightUnitOverloadId: null
  }
  idtfValues: number[] = []
  currentAddresses: any[] = []
  pricingTables: any = signal<any[]>([]);
  showModal: boolean = false
  selectedProduct: any = null
  selectedPriceTable: any = signal<any[]>([])
  showAdditionalSurcharges: boolean = false
  surchargesList: any = signal<any[]>([])
  formGroup: UntypedFormGroup
  type: number = 9999
  addressType: number = 9999
  surcharges: any[] = []
  addressIndex: number = 0
  existingFreights: any = []
  disableFreightType: boolean = false
  showFallbackSection: boolean = false




  constructor(private http: HttpClient, private formBuilder: FormBuilder, private surchargeService: SurchargeService, private freightService: FreightTypeService, private productTypeService: ProductTypeService,
    private eventProxyService: EventProxyService,) {
    this.formGroup = this.formBuilder.group({
      productTypeId: 0,
      productType: {
        name: ''
      },
      type: '',
      freightUnit: '',
      freightUnitId: '',
      surcharges: this.formBuilder.array([]),
      surchargesDisplay: this.formBuilder.array([]),
      priceUnitId: null,
      amount: 0,
      addressDeliveryDocumentRequirements: this.formBuilder.array([]),
      amountOverload: 0,
      freightUnitOverload: '',
      freightUnitOverloadId: null
    })
  }


  ngOnInit(): void {
    // get the products at the init state
    this.getProducts()
  }


  getProducts() {
    this.freightService.getAllProducts().subscribe((response) => {
      this.products = response.filter((product: any) => product.mainCategory == false)
    })
  }


  getSurcharges() {
    this.surchargeService.getAllSurcharges().subscribe((surcharges: any) => {
      this.surchargesList.set(surcharges)
    })
  }



  /**
   * Method is executed when the user selects a product type from the combobox
   * @param input the input from the combobox component
   */
  setProductType(input: any) {
    this.showFallbackSection = false
    this.disableFreightType = false

    if (input.itemData && input.itemData.id) {
      this.selectedProduct = this.products.find((product: any) => {
        return product.id == input.itemData.id
      })






      // If you're on an arrive stop and you select a product, run a helper method for calculating the pool of amount left
      if (this.addressType === 1) {
        this.productTypeService.getFreightAmountLeft(this.currentAddresses, this.addressIndex, this.selectedProduct.name)
      }

      if (this.addressType == 2 && (this.existingFreights.value && this.existingFreights.value.length > 0)) {
        const previousFreight = this.existingFreights.value[this.existingFreights.value.length - 1]

        // if the freight type exists on the previous and selected product
        if (this.selectedProduct.freightUnit.find((freightType: any) => freightType.name == previousFreight.freightUnit)) {
          this.formGroup.patchValue({
            freightUnit: previousFreight.freightUnit,
            freightUnitId: previousFreight.freightUnitId
          })
          this.disableFreightType = true
        } else {
          this.formGroup.patchValue({
            freightUnit: '',
            freightUnitId: 0,
            freightUnitOverload: previousFreight.freightUnit ? previousFreight.freightUnit : previousFreight.freightUnitOverload,
            freightUnitOverloadId: previousFreight.freightUnitId > 0 ? previousFreight.freightUnitId : previousFreight.freightUnitOverloadId,
          })

          this.showFallbackSection = true
        }
      }

      this.formGroup.setControl('addressDeliveryDocumentRequirements', this.formBuilder.array(this.selectedProduct.deliveryDocumentRequirements))

      this.formGroup.patchValue({
        productTypeId: this.selectedProduct.id,
        productType: {
          name: this.selectedProduct.name
        },
        type: this.addressType,
        addressDeliveryDocumentRequirements: this.selectedProduct.deliveryDocumentRequirements
      })

      this.canAdd = true
    }
  }


  setFreightType(input: any) {
    const selectedFreightType = this.selectedProduct.freightUnit.find((freightType: any) => {
      return freightType.id == input.itemData.id
    })

    this.formGroup.patchValue({
      freightUnit: selectedFreightType.name,
      freightUnitId: selectedFreightType.id
    })
  }


  setSurcharge(input: any, surchargeIndex: number) {
    const selectedSurcharge = this.surchargesList().find((surcharge: Surcharge) => surcharge.id === input.itemData.id);

    if (selectedSurcharge) {
      this.surcharges[surchargeIndex] = this.formBuilder.group(selectedSurcharge)
      this.surcharges.push(this.formBuilder.group({}))

      // Update form group
      this.formGroup.setControl('surcharges', this.formBuilder.array(this.surcharges))
    }
  }




  addEmptySurcharge(input: any) {
    const selectedSurcharge = this.surchargesList().find((surcharge: Surcharge) => surcharge.id === input.itemData.id)

    if (selectedSurcharge) {
      // Correctly push to the FormArray
      this.surcharges.push(
        this.formBuilder.group({
          ...selectedSurcharge
        }),
        this.formBuilder.group({})
      )
    }

    this.formGroup.setControl('surcharges', this.formBuilder.array(this.surcharges))
    this.newSurcharge.clear()
  }


  removeSurcharge(surcharge: Surcharge, surchargeIndex: number) {
    this.surcharges.splice(surchargeIndex, 1)
    this.formGroup.patchValue({ surcharges: this.surcharges })
  }


  canAddEmptySurcharge(): boolean {
    // Allow one empty field if the last item is not empty
    return this.surcharges.length === 0 || this.surcharges[this.surcharges.length - 1]?.id
  }


  clearForm() {
    const surchargesArray = this.formBuilder.array([]);
    this.productType.clear()
    this.productType.value = null

    this.selectedProduct = {
      productTypeId: 0,
      productType: {
        name: ''
      },
      type: '',
      freightUnit: '',
      freightUnitId: '',
      surcharges: [],
      priceUnitId: null,
      amount: 0,
      amountOverload: 0,
      freightUnitOverload: '',
      freightUnitOverloadId: null
    }

    this.surcharges = []

    this.formGroup.patchValue({
      productTypeId: 0,
      productType: {
        name: ''
      },
      type: '',
      freightUnit: '',
      freightUnitId: '',
      surcharges: [],
      priceUnitId: null,
      amount: 0,
      amountOverload: 0,
      freightUnitOverload: '',
      freightUnitOverloadId: null
    });

    // Reset surcharges field as FormArray
    this.formGroup.setControl('surcharges', surchargesArray);
  }



  getProductsForDelivery(addresses: any, addressIndex: number, freightType: number) {
    let freightsCollection: any[] = []

    // if it's the first address, do no check because we need the full list of products
    addresses.controls.forEach((address: any, index: number) => {
      if (address.get('addressFreights').value && index <= addressIndex) {
        const isLeaveStop = address.get('addressFreights').value.find((freight: any) => freight.type === 2)

        if (isLeaveStop) {
          address.get('addressFreights').value.forEach((freight: any) => {
            // get the products that match the freight name
            const matchingFreight = this.products.filter((product: any) => {
              return product.name === freight.productType.name && (freight.amount > 0)
            })


            // filter away freightUnits that don't match the entered freightUnit
            matchingFreight.forEach((_freight: any) => {
              _freight.freightUnit = _freight.freightUnit.filter((freightUnit: any) => freightUnit.name === freight.freightUnit)
            })

            if (matchingFreight && !freightsCollection.find((freight: any) => freight.name === matchingFreight[0].name)) {
              freightsCollection.push(...matchingFreight)
            }
          })
        }
      }


      if (index === addressIndex && addressIndex > 0) {
        this.products = freightsCollection
        this.productType.dataSource = freightsCollection
      }
    })
  }



  openModal(data: any, clearForm: boolean, addresses?: any, addressIndex?: number) {
    this.disableFreightType = false

    if (data.existingFreights.value) {
      this.existingFreights = data.existingFreights
    }


    if (addresses.length > 0) {
      this.currentAddresses = addresses
    }

    // if the addressType is 2, then it's a leave stop and should have all options available
    // otherwise it's an arrive stop and should only have the products available that are available for delivery
    if (data.addressType == 2) {
      this.getProducts()
    }

    this.getProductsForDelivery(addresses, addressIndex!, data.addressType)
    this.getSurcharges()

    if (!data && clearForm) {
      this.clearForm()
    }

    if (clearForm) {
      if (this.surchargesListBox) {
        this.surchargesListBox.clear()
      }

      this.formGroup.value.surcharges = []
    }


    // Set data in the model from the event
    if (data) {
      this.addressIndex = data.addressIndex;
      this.addressType = data.addressType;
      this.type = data.addressIndex;

      // Handle surcharges as a FormArray
      const surchargesArray = this.formGroup.get('surcharges') as FormArray;
      surchargesArray.clear(); // Clear existing FormArray controls to avoid old data on new form

      // Populate the FormArray with new controls
      data.formGroup.value.surcharges.forEach((surcharge: any) => {
        surchargesArray.push(this.formBuilder.control(surcharge));
      });

      // Patch the rest of the form group
      this.formGroup.patchValue({
        productTypeId: data.formGroup.value.freightUnitId,
        productType: {
          name: data.formGroup.value.productType.name
        },
        type: data.addressType,
        freightUnitId: data.formGroup.value.freightUnitId,
        freightUnit: data.formGroup.value.freightUnit,
        priceUnitId: data.formGroup.value.priceUnitId,
        amount: data.formGroup.value.amount,
        amountOverload: data.formGroup.value.amountOverload,
        freightUnitOverload: data.formGroup.value.freightUnitOverload,
        freightUnitOverloadId: data.formGroup.value.freightUnitOverloadId
      });

      this.surcharges = data.formGroup.value.surcharges;

      this.setProductType({ itemData: { id: data.formGroup.value.productTypeId } });

      // If the opened freight has overload, it should be reflected as such
      if (data.formGroup.value.amountOverload && data.formGroup.value.freightUnitOverload) {
        this.showFallbackSection = true;
        this.disableFreightType = true;
      }

      this.productTypeModalComponent.open();
    } else {
      this.addressIndex = addressIndex!
      this.addressType = data.addressType
      this.type = data.addressIndex
    }
  }

  confirmAndEmitForm() {
    // if the last object in this.formGroup.value.surcharges is empty, delete it
    if (this.formGroup.value.surcharges && this.formGroup.value.surcharges.length >= 1) {
      if (Object.keys(this.formGroup.value.surcharges[this.formGroup.value.surcharges.length - 1]).length === 0) {
        this.formGroup.value.surcharges.splice(this.formGroup.value.surcharges.length - 1, 1)
      }

      // change surcharges in the formGroup to be a comma-separated string of surcharge ids
      this.formGroup.value.surcharges = this.formGroup.value.surcharges
    }

    this.dataEmit.emit({ formGroup: this.formGroup, type: this.type, addressType: this.addressType })

    // close the modal
    this.productTypeModalComponent.close()


    // if any of the ids exist, open the logbook modal, otherwise just add the product type
    const idtfRequirementExists = this.formGroup.value.addressDeliveryDocumentRequirements
      .find((doc: any) => doc.id == 2 || doc.id == 3 || doc.id == 4)

    if (
      this.formGroup &&
      this.formGroup.value.addressDeliveryDocumentRequirements &&
      idtfRequirementExists
    ) {
      this.eventProxyService.setEvent(new LogbookModalOpen({
        formGroup: this.formGroup,
        type: this.type,
        selectedProduct: this.selectedProduct,
        addressType: this.addressType,
        addresses: this.currentAddresses,
        addressIndex: this.addressIndex
      }))
    }
  }


  closeAndReset() {
    this.productTypeModalComponent.close()
  }
}
